
import { Component, Input, OnChanges, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewChecked, ChangeDetectorRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService, MenuId, MenuDef } from '../services/menu.service';
import { MenuItem, MenuItemDesc } from '../models/menu-item';
import { CommandHandler, MenuItemSetter } from '../models/command-handler';
import { LocalizeService } from '../services/localize.service';
import { MenuComponent } from './menu.component';
import { TabSelectorComponent } from './tab-selector.component';
import { Util, UserInterface, OrientationListener } from '../utils/utils.module';
import { KeyCommand, ShortcutsService } from '../services/shortcuts.service';

/**
 * Created by kevin on 2016-10-07.
 *
 * Implements a horizontal action bar with iconic and textual elements that interacts with a list
 *
 */

@Component({
  selector: 'edx-action-bar',
  styleUrls: ['action-bar.component.scss'],
  template: `
    <div class="action-bar-body" [ngClass]="{mobile: isMobileLook(), oai: officeAddin}">
      <div #container class="action-bar display-flex" id='edx_action_bar' [ngClass]="{mobile: isMobileLook(), oai: officeAddin, newmenu:menuID===20||menuID===27, single:isSingle()}" [style.left]="containerLeft" tabindex="0" [attr.aria-label]="altLabels['actionBar']" tabindex="-1">
        <div *ngFor="let item of items; let i = index" [tabindex]="item.right||(item.separator && showSeparator) || getTabIndexStatus(item.cmd) ?-1:0"
          class="actionitem" role="button" [ngClass]="{separator:item.separator && showSeparator, hidden:(!item.enabled || (item.cmd === 'separator' && !showSeparator)) && !showOverflowMenu(item), menu:item.submenu && item.cmd !== 'view_ellipsis',compare: item.cmd === 'versions_compare', measuring: !layoutComplete, right:item.right, margin_left_auto:i == firstVisibleRightItemIndex(), nopad:!!item.segments || item.cmd === 'view_ellipsis'}"
          (keyup.enter)="item.cmd==='versions_compare'?null:itemClick(items.indexOf(item), $event)" (keyup.space)="item.cmd==='versions_compare' || item.cmd === 'attachments_newmenu' || item.cmd === 'versions_newmenu'?null:itemClick(items.indexOf(item), $event)" (click)="item.cmd==='versions_compare'?null:itemClick(items.indexOf(item), $event)" id="{{'edx_action_'+item.cmd}}" (keydown)="item.cmd ==='showfilter' || item.cmd ==='lookup_filter'?onKeyDown($event):null">
          <img *ngIf="item.iconic" src="{{item.icon}}"  class="action-icon" title="{{item.name}}" alt="{{item.name}}">
          <div *ngIf="item.separator"></div>
          <div *ngIf="item.submenu || item.cmd==='versions_compare'" class="sub-menu">
            <edx-menu *ngIf="item.cmd === 'view_ellipsis'; else noEllipsis" #submenu [menuTitle]="menuTitle" [menuId]="'edx_overflow_menu'" [ariaLabel]="altLabels['overFlowMenu']" [tabIndex]="0" [menuIcon]="menuIcon" [menuID]=3 [callback]="this" [ngClass]="{menu_button: true, hidden: !hasOverflow}" id="edx_overflow_submenu"></edx-menu>
            <ng-template #noEllipsis>
              <div *ngIf="item.submenu && item.cmd==='versions_compare'">{{this.localizer.getTranslation('METADATA.VERSIONS_ACTIONS.COMPARE')}}</div>
              <edx-menu *ngIf="item.submenu" [menuID]="item.submenu" [tabIndex]="0" [isCompare]="item.cmd==='versions_compare'" [callback]="this" class="menu-button" [ariaLabel]="item.cmd" #action_submenu id="edx_action_submenu"></edx-menu>
            </ng-template>
          </div>
          <span *ngIf="!item.iconic && !item.submenu && !item.segments">
            {{item.name}}
          </span>
          <edx-tab-selector *ngIf="!!item.segments" [receiver]="this" [tabdefs]="item.segments" [tabID]="item.cmd" [allowOverflow]="false" [tabIndex]="0"></edx-tab-selector>
        </div>
      </div>
    </div>
  `
})

export class ActionBarComponent implements OnChanges, OnDestroy, AfterViewChecked, CommandHandler, OrientationListener {
  @ViewChild('container') container: ElementRef;
  @ViewChild('submenu') overflowMenu: MenuComponent = null;
  @ViewChild('action_submenu') newActionMenu: MenuComponent = null;
  @ViewChildren(TabSelectorComponent) tabs: QueryList<TabSelectorComponent>;
  @Input() menuID: MenuId;
  @Input() target: CommandHandler;
  @Input() menuTitle?: string;
  @Input() menuIcon?: string;
  @Input() forceDekstopLook = false;
  static optionMenuWidth = 50;
  public officeAddin: boolean;
  public items: MenuItem[] = [];
  public hasOverflow = false;
  public containerLeft = '0';
  protected ui: UserInterface;
  private menuDef: MenuDef;
  private layoutComplete = false;
  private overflowStartIdx = -1;
  private allItems: MenuItem[] = [];
  private showSeparator = false;
  private curTab: string = null;
  public altLabels: any;
  private shortcutsSubscription: Subscription;
  private isNewMenuEnabled = false;
  private newMenuWrapperCommandList: string[] = ['newmenu','versions_compare','view_ellipsis','attachments_newmenu','versions_newmenu'];
  private commandElementFocusMap = {
    editprofile: '.edx_select',
    checkout: '.edx_select',
    copy: '.edx_select',
    export: '.edx_select',
    security: '.edx_select',
    link: '#secondaryBtn-Cancel',
    checkin: '#\\%CHECKIN_LOCATION',
    readonly: '#edx_notify_btn_ok',
    removereadonly: '#edx_notify_btn_ok',
    profile: '#edx_header_menu',
    addto: '#edx_list_box_global_search',
    delete: '#edx_radio_radio_0',
    versions: 'img[id="edx_action_submenu"]',
    attachments: 'img[id="edx_action_submenu"]',
    'where-used': '#edx_action_whereused_add',
    history: '#edx_action_showfilter',
    related: '#edx_action_related_add'
  };

  constructor(private menuService: MenuService, private localizer: LocalizeService, private cdr: ChangeDetectorRef, private shortcutsService: ShortcutsService) {
    this.ui = Util.Device.ui;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.altLabels = {
      actionBar: this.localizer.getTranslation('ALT_TEXT.ACTION_BAR'),
      overFlowMenu: this.localizer.getTranslation('ALT_TEXT.OVERFLOW_MENU')
    };
    ActionBarComponent.optionMenuWidth = this.ui<2 ? 50 : 108;
    Util.Device.registerOrientationListener(this);
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    let cmd = command.name.replace('doc-', '');
    if (cmd === 'new') {
      if (!!this.newActionMenu && this.isNewMenuEnabled) {
        this.newActionMenu.enableMenuItems();
        cmd = this.newActionMenu.getMenuItems()[this.newActionMenu.getNextIndex(-1, 1)].cmd;
      }
    }
    if (!!cmd && this.target.commandEnabled && this.target.commandEnabled(cmd)) {
      this.doCommand(cmd);
    }
    setTimeout(() => {
      if (command.name !== 'doc-new') {
        Util.Transforms.focusOnElementByQuerySelector(this.commandElementFocusMap[cmd]);
      }
    }, 500);
  }

  ngOnDestroy() {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
    Util.Device.deregisterOrientationListener(this);
  }

  public getTabIndexStatus(cmd: string): boolean {
    return this.newMenuWrapperCommandList.indexOf(cmd) !== -1 ? true : false;
  }

  public deviceDidRotate(isPortrait: boolean): void {  // work around iOS/Android cordova crap
    this.updateActions();
  }

  public firstVisibleRightItemIndex(): number {
    return this.items && this.items.findIndex(item => item.right && item.enabled);
  }

  public showOverflowMenu(item): boolean {
    return item && item.cmd === 'view_ellipsis' && this.hasOverflow;
  }

  @HostListener('window:resize')
  public updateActions(): void {
    this.copyAllItems();
    this.layoutComplete = false;
    this.hasOverflow = false;
    this.isNewMenuEnabled = false;
    let enabledActionsCount = 0;
    for (const itemDef of this.items) {
      if (this.target.commandEnabled && (itemDef.cmd !== 'separator' || !itemDef.enabled)) {
        itemDef.enabled = this.target.commandEnabled(itemDef.cmd);
      }
      // Check the number of actions available[Other than separator],to decide if separator is really needed.
      if (itemDef.enabled === true && itemDef.cmd !== 'separator') {
        enabledActionsCount++;
      }
      // Check if 'newmenu' action is available.
      if (itemDef.enabled === true && itemDef.cmd === 'newmenu') {
        this.isNewMenuEnabled = true;
      }
    }
    // If count of actions is zero or if the only action available is 'newmenu' then don't need separator.
    this.showSeparator = !((enabledActionsCount === 0) || (enabledActionsCount === 1 && this.isNewMenuEnabled));
    this.cdr.markForCheck();
  }

  ngOnChanges(): void {
    this.menuDef = this.menuID >= 0 ? this.menuService.getMenu(this.menuID) : null;
    const libraryName = this.target['desc']?.lib;
    if (Util.RestAPI.isLibraryLoaded(libraryName)) {
      this.onChanges();
    } else {
      Util.RestAPI.checkLibrarySettingsByName(libraryName).subscribe((libraryInfo: any) => {
        this.onChanges();
      });
    }
  }

  ngAfterViewChecked(): void {
    if (!this.layoutComplete) {
      setTimeout(() => {
        this.doLayout();
      }, 1);
    }
  }

  public setCmdValue(cmd: string, value: string): void {
    //'view_mode', 'summary'
    if (this.tabs) {
      const tab: TabSelectorComponent = this.tabs.find((t: TabSelectorComponent, index: number, tabs: TabSelectorComponent[]): boolean => {
        if (t.tabID === cmd) {
          return true;
        }
        return false;
      });
      if (!!tab) {
        tab.selectTabById(value);
      }
    }
  }

  private fillOverflowMenu(): void {
    const ellipsisIndex = this.items.findIndex(item => item.cmd === 'view_ellipsis');
    if (this.overflowStartIdx > 0 && this.overflowStartIdx < ellipsisIndex) {
      this.hasOverflow = true;
      this.overflowMenu.replaceMenuItems(this.items.splice(this.overflowStartIdx, ellipsisIndex-this.overflowStartIdx));
      this.overflowStartIdx = -1;
    }
    this.layoutComplete = true;
    this.cdr.markForCheck();
  }

  private doLayout(): void {
    if (!this.layoutComplete) {
      this.hasOverflow = false;
      this.overflowStartIdx = -1;
      const tablet: boolean = Util.Device.isTabletLook();
      let availableWidth: number = this.container.nativeElement.offsetWidth;
      let rightSideVisibleItemsWidth = 0;
      const menuEl = this.container.nativeElement.children;
      const widths: number[] = [];
      let lastVisibleIndex = 0;

      this.containerLeft = availableWidth>=592 && tablet && this.isMobileLook() ? 'calc(50% - 18.5rem)' : '0';
      for (const itemEl of menuEl) {
        if (itemEl.classList.contains('measuring') && typeof itemEl.offsetWidth === 'number') {
          widths.push(itemEl.offsetWidth);
          if (!itemEl.classList.contains('hidden') && !itemEl.classList.contains('right')) {
            lastVisibleIndex = widths.length - 1;
          }
          if (!itemEl.classList.contains('hidden') && itemEl.classList.contains('right')) {
            rightSideVisibleItemsWidth += itemEl.offsetWidth;
          }
        }
      }
      availableWidth -=  rightSideVisibleItemsWidth;
      if (this.ui<2) {
        availableWidth -= ActionBarComponent.optionMenuWidth;
      }
      const nItems: number = widths.length;
      let nVisItems = 0;
      for (let i=0; i<nItems; i++) {
        const width: number = widths[i];
        availableWidth -= width;
        if (width) {
          ++nVisItems;
        }
        if ((i < lastVisibleIndex && availableWidth < ActionBarComponent.optionMenuWidth) || (availableWidth<0) || (tablet && nVisItems>4)) {
          this.overflowStartIdx = i;
          break;
        }
      }
      // swap overflow items to overflow menu
      setTimeout(() => {
        this.fillOverflowMenu();
      }, 0);
    }
  }

  private onChanges(): void {
    this.allItems = [];
    let addEllipsisMenuItem = true;

    if (this.menuDef) {
      for (const itemDef of this.menuDef.items) {
        const item: MenuItem = new MenuItem(itemDef);
        item.name = this.localizer.getTranslation(item.name);
        if (this.target.commandEnabled && (itemDef.cmd !== 'separator' || !itemDef.enabled)) {
          item.enabled = this.target.commandEnabled(item.cmd);
        }
        if (!!item.icon && !item.icon.startsWith('http') && !item.icon.startsWith('assets/images/')) {
          item.icon = 'assets/images/' + item.icon;
        }
        if (item.cmd === 'view_ellipsis') {
          addEllipsisMenuItem = false;
        }
        this.allItems.push(item);
      }
      if (addEllipsisMenuItem) {
        const firstRightItemIndex = this.allItems.findIndex(item => item.right);
        const ellipsisMenuItem = this.createMenuItem(this.getEllipsisMenuItem());
        if (firstRightItemIndex > -1) {
          this.allItems.splice(firstRightItemIndex, 0, ellipsisMenuItem);
        } else {
          this.allItems.push(ellipsisMenuItem);
        }
      }
      this.updateActions();
    }
  }

  private getEllipsisMenuItem(): MenuItemDesc {
    return {
      submenu: MenuId.MENU_WEB_BREAD_CRUMB_OVERFLOW,
      name: 'ellipsis',
      cmd: 'view_ellipsis'
    };
  }

  private createMenuItem(menuItemDesc: MenuItemDesc): MenuItem {
    return new MenuItem(menuItemDesc);
  }

  // copy all menu items into current list
  private copyAllItems(): void {
    this.items = [];
    for (const item of this.allItems) {
      this.items.push(new MenuItem(null, item));
    }
  }

  private itemClick(itemIndex: number, event: Event): void {
    if (itemIndex >= 0 && itemIndex < this.items.length) {
      const menuItem: MenuItem = this.items[itemIndex];
      const cmd: string = menuItem.cmd + ((!!menuItem.segments && !!this.curTab) ? this.curTab : '');
      if (this.target) {
        this.target.doCommand(cmd);
      }
      if (menuItem.cmd === 'showfilter' || menuItem.cmd === 'lookup_filter' || menuItem.cmd === 'profile') {
        setTimeout(()=> {
          Util.Transforms.focusOnElementByClassName(menuItem.cmd === 'profile' ? 'fileitem selected' : 'sidebar-title');
        }, 100);
      }
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public isSingle(): boolean {
    let nEnabled = 0;
    for (const item of this.items) {
      nEnabled += item.enabled ? 1 : 0;
      if (nEnabled>1) {
        return false;
      }
    }
    return true;
  }

  public isMobileLook(): boolean {
    return !this.forceDekstopLook && this.ui>=2;
  }

  // *** Implement commandHandler for proxying sub-commands

  public doCommand(cmd: string): boolean {
    return this.target.doCommand(cmd);
  }

  public commandEnabled(cmd: string): boolean {
    let enabled = true;
    if (this.target.commandEnabled) {
      enabled = this.target.commandEnabled(cmd);
    }
    return enabled;
  }

  public menuOpening(menuItemSetter: MenuItemSetter, id: number): void {
    if (this.target.menuOpening) {
      this.target.menuOpening(menuItemSetter, id);
    }
  }

  public menuClosing(menuItemSetter: MenuItemSetter, id: number): void {
    if (this.target.menuClosing) {
      this.target.menuClosing(menuItemSetter, id);
    }
  }

  public checkCommand(cmd: string, setChecked: boolean): boolean {
    if (this.target.checkCommand) {
      return this.target.checkCommand(cmd, setChecked);
    }
    return setChecked;
  }

  public getItems(): MenuItem[] {
    return this.items;
  }

  // TablistReceiver interface
  public tabSelected(id: string): void {
    this.curTab = id;
  }

  public tabEnabled(id: string): boolean {
    return true;
  }

  public focusOnSelectedTab() {
    Util.Transforms.focusOnElementByClassName('tabitem selected');
  }

  public onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey && Util.Transforms.getHTMLElementByClassName('tabitem selected enabled')?.title === 'History') {
          Util.Transforms.focusOnElementById('Security');
          event?.preventDefault?.();
        }
        break;
      case 'ArrowLeft':
        Util.Transforms.focusOnElementByAnyClassFromArray(['sidebar-title', 'fileitem selected']);
        break;
    }
  }
}
