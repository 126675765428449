<ng-template [ngIf]="ui<2">
  <div *ngIf="canShowInheritance" class="inheritance-wrapper">
      <input type="text" tabindex="0" [formControlName]="checkbox" class="checkbox" [ngClass]="{checked:isChecked}" (click)="onCheckChange($event)">
      <span class="checklabel customLabelSpan" (click)="onCheckChange($event)">{{ this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.INHERIT_FROM', [getInheritedFromName()]) }}</span>
  </div>
  <edx-action-bar [menuID]="actionMenuId" [target]="this" #actionbar></edx-action-bar>
  <div *ngIf="massProfile" class="selwrapper" [ngClass]="{disablelist:isReadOnly}">
    <edx-select id="edx_security_option"
                [items]="trusteesDirectives"
                [value]="trusteesDirective"
                (change)="setRrusteesDirective($event)">
    </edx-select>
  </div>
  <div class="list" [ngClass]="{footer:showFooter,
                                disablelist:isReadOnly,
                                massprofile:massProfile,
                                canInherit: canShowInheritance}">
    <edx-list-table #table *ngIf="detailView"
                    (detailviewnotify)='onGranularRightChange($event)'
                    [schemaId]="'ACCESS_CONTROL'"
                    [desc]="desc"
                    [params]="'security'"
                    [inlineActionMenuId]="6"
                    [inlineActionTarget]="this"
                    [parent]="this"
                    [readOnly]="isChecked" [disableSecurity]="isReadOnly">
    </edx-list-table>
    <edx-list-table #table *ngIf="!detailView"
                    (summaryviewnotify)='onTemplateRightChange($event)'
                    [schemaId]="'SECURITY'"
                    [desc]="desc"
                    [params]="'security'"
                    [inlineActionMenuId]="6"
                    [inlineActionTarget]="this"
                    [parent]="this"
                    [readOnly]="isChecked" [disableSecurity]="isReadOnly">
    </edx-list-table>
  </div>
  <edx-list-info-footer *ngIf="showFooter" [displayItems]="footerItems" [heading]="footerHeading"></edx-list-info-footer>
</ng-template>

<ng-template [ngIf]="ui>=2">
  <div *ngIf="canShowInheritance"
       class="inheritance-wrapper"
       [ngClass]="{tablet: tablet, mobile: mobile}">
    <otc-checkbox #inheritCheckbox [aria-checked]="isChecked"
                  [tabindex]=0
                  [checked]="isChecked"
                  (change)="onCheckChange($event)">
                  {{ this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.INHERIT_FROM', [getInheritedFromName()]) }}
    </otc-checkbox>
  </div>
  <edx-action-bar *ngIf="tablet" [menuID]="actionMenuId" [target]="this" [forceDekstopLook]="true" #actionbar></edx-action-bar>
  <div class="header">{{permissions}}</div>
  <div *ngIf="massProfile" class="selwrapper" [ngClass]="{disablelist:isReadOnly}">
    <edx-select id="edx_security_option"
                [items]="trusteesDirectives"
                [value]="trusteesDirective"
                (change)="setRrusteesDirective($event)">
    </edx-select>
  </div>
  <div class="list mobile" [ngClass]="{tablet:tablet, ios:iOS,
                                        massprofile:massProfile,
                                        disablelist:isReadOnly,
                                        canInherit: canShowInheritance}"
                           [edx-gesture-longpress]="table" [edx-gesture-swipe]="table" [edx-gesture-doubletap]="table">
    <edx-list-mobile #table (summaryviewnotify)='onTemplateRightChange($event)'
                     [schemaId]="'SECURITY'"
                     [desc]="desc"
                     [params]="'security'"
                     [inlineActionMenuId]="6"
                     [inlineActionTarget]="this"
                     [parent]="this"
                     [splitView]="splitView"
                     [hasFootprint]="inDialog"
                     [hasHeader]="true"
                     [disableSecurity] = "isReadOnly"
                     >
    </edx-list-mobile>
  </div>
  <edx-action-bar *ngIf="!tablet" [menuID]="actionMenuId" [target]="this" #actionbar></edx-action-bar>
</ng-template>