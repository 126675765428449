<div *ngIf="dualLists" #header class="header" id="edx_list_picker_header">
  <div class="libwrapper" [ngClass]="{web:ui==0,desktop:ui==1}">
    <edx-select class="inlineselect" [items]="libSelItems" [id]="'edx_lib_selector'" [value]="displayLibName()" (change)="libChanged($event)"></edx-select>
  </div>
  <div *ngFor="let crumb of crumbs; let isLast=last; let isFirst=first;" class="crumb" title="{{crumb.name}}" [ngClass]="{last:isLast,first:isFirst}">
    <span tabindex="0" [attr.aria-label]="breadCrumbText+' '+crumb.DOCNAME" (keyup.enter)="crumbClicked(crumb)" (keydown.space)="crumbClicked(crumb)" (click)="crumbClicked(crumb)">{{crumb.DOCNAME}}</span>
    <img [ngClass]="{last:isLast}" src="assets/images/caret_right12.svg">
  </div>
</div>
<div class="container" [style.height]="containerHeight()" [ngClass]="{expandleft:splitAction==1, expandright:splitAction==2, collapseleft:splitAction==3, collapseright:splitAction==4, shiftleft:splitAction==5, shiftright:splitAction==6}" (animationend)="animationComplete()" id="edx_list_picker_body">
  <div *ngIf="dualLists" class="parentparentlist">
    <div class="listtitle">{{desc.DOCNAME}}</div>
    <div class="listcontainer"></div>
  </div>
  <div class="parentlist" [ngClass]="{shrunk:splitView}" id="edx_list_picker_parentlist" tabindex="0" (keyup.arrowright)="focusOnList('edx_list_picker_childlist')">
    <button *ngIf="showMobileFilter && canShowCreateContainer()" class="createcontainer mobile secondary" [ngClass]="{oai:officeAddin}" (click)="createContainerClick()" id="edx_list_picker_btnchild_create">{{createContainerStr}}</button>
    <div *ngIf="dualLists" class="listtitle">{{desc.DOCNAME}}</div>
    <edx-search-filter *ngIf="showMobileFilter && canSearchFilter()" [desc]="child.desc" [list]="this" [asFilter]="true"></edx-search-filter>
    <div class="listcontainer" [ngClass]="{duallists:dualLists}" (keydown)="onKeyDown($event, true)">
      <div *ngIf="!singleSelect&&showSelectAll(getList())" class="selectall">
        <div class="selector" role="checkbox" [attr.aria-checked]="allSelected?'true':somethingSelected?'mixed':'false'" [attr.aria-label]="isChrome?(allSelected?selectAll:selectAll+' '):selectAll" [ngClass]="{partialselect:somethingSelected, allselect:allSelected}" (click)="toggleAllParents($event)" (keydown.space)="toggleAllParents($event)" id="edx_list_picker_selectall" tabindex="0"></div>
        <div class="name">{{selectAll}}</div>
      </div>
      <ng-template ngFor let-item [ngForOf]="getList()" let-i="index">
        <div *ngIf="canAddToLeft(item)" class="item" id="{{ 'edx_list_folder_selector_parent_' + i }}" [ngClass]="{selected:selectedList.indexOf(item)>=0,open:(item.type==child.desc.type&&item.id==child.desc.id&&item.lib==child.desc.lib)}" tabindex="0" (keydown.space)="parentItemClicked(item,$event)" (click)="parentItemClicked(item)" role="row" [attr.aria-label]="item.ignoreTypeOnFocus?item.DOCNAME: getLabel(item,true)">
          <div *ngIf="canSelectItem(item)" class="selector" role="checkbox" [attr.aria-checked]="selectedList.indexOf(item)>=0" [attr.aria-label]="isChrome?(selectedList.indexOf(item)>=0?item.DOCNAME:item.DOCNAME+' '):item.DOCNAME" tabindex="0" (keydown.space)="toggleSelected($event,'parent',item)" (click)="toggleSelected($event,'parent',item)"></div>
          <div *ngIf="!canSelectItem(item)" class="selector disabled"></div>
          <img class="icon" src="{{getIconSrc(item)}}" alt="{{getIconText(item)}}">
          <img *ngIf="displayMiniStatus(item, 'APP_ID')" class="overlaystatusicon" src="{{formatStatusIcon(item)}}" alt="{{formatStatusText(item)}}">
          <img *ngIf="displayTopMiniStatus(item, 'APP_ID')" class="overlaystatusicon top" src="{{formatTopStatusIcon(item)}}" alt="{{formatStatusText(item)}}">
          <div class="name" title="{{getTooltip(item)}}" tabindex="0" (keydown.space)="parentItemClicked(item,$event)">{{item.DOCNAME}}</div>
        </div>
      </ng-template>
      <div *ngIf="canShowMore()" class="showmore">
        <button [ngClass]="{mobile:ui>=2, oai:officeAddin}" (click)="fetchMoreItems()" id="edx_list_picker_btnparent_loadmore">{{loadMore}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="dualLists" class="childlist" [ngClass]="{expanded:splitView}" id="edx_list_picker_childlist" [tabindex]="splitView ? 0 : -1" (keyup.arrowleft)="focusOnList('edx_list_picker_parentlist')">
    <button *ngIf="!phoneLook && canShowCreateContainer()" [tabindex]="splitView ? 0 : -1" class="createcontainer secondary" (click)="createContainerClick()" id="edx_list_picker_btnchild_create">{{createContainerStr}}</button>
    <div class="listtitle">{{child.desc.DOCNAME}}</div>
    <edx-search-filter *ngIf="!phoneLook && canSearchFilter()" [desc]="child.desc" [list]="this" [asFilter]="true" [hideFilter]="!splitView"></edx-search-filter>
    <div *ngIf="showChildSelectAll()" class="selectall">
      <ng-template [ngIf]="pickDocs && !singleSelect">
        <div class="selector" role="checkbox" [attr.aria-checked]="child.allSelected?'true':child.somethingSelected?'mixed':'false'" [attr.aria-label]="child.allSelected?selectAll:selectAll+' '" [ngClass]="{partialselect:child.somethingSelected, allselect:child.allSelected}" tabindex="0" (keydown.space)="toggleAllChildren($event)" (click)="toggleAllChildren($event)"></div>
        <div class="name">{{selectAll}}</div>
      </ng-template>
    </div>
    <div class="listcontainer" [ngClass]="{duallists:dualLists, selectshown:showChildSelectAll(), filtershown:canSearchFilter()}" (keydown)="onKeyDown($event, false)">
      <div class="item" *ngFor="let item of child.list; let i = index" id="{{ 'edx_list_folder_selector_' + i }}" [ngClass]="{selected:child.selectedList.indexOf(item)>=0}" tabindex="0" (keydown.space)="childItemClicked(item,$event)" (click)="childItemClicked(item)" role="row" [attr.aria-label]=getLabel(item)>
        <ng-template [ngIf]="pickDocs || pickDocsOnly || pickFilePart || originalType === 'filepartworkspaces'">
          <div *ngIf="canSelectItem(item)" class="selector" role="checkbox" [attr.aria-checked]="child.selectedList.indexOf(item)>=0" [attr.aria-label]="isChrome?(child.selectedList.indexOf(item)>=0?item.DOCNAME:item.DOCNAME+' '):item.DOCNAME" tabindex="0" (keydown.space)="toggleSelected($event,'child',item)"  (click)="toggleSelected($event,'child',item)"></div>
          <div *ngIf="!canSelectItem(item)" class="selector disabled"></div>
        </ng-template>
        <img class="icon" src="{{getIconSrc(item)}}" alt="{{getTypeText(item)}}">
        <img *ngIf="displayMiniStatus(item, 'APP_ID')" class="overlaystatusicon" [ngClass]="{hasselector:pickDocs||pickDocsOnly||pickFilePart}" src="{{formatStatusIcon(item)}}" alt="{{formatStatusText(item)}}">
        <img *ngIf="displayTopMiniStatus(item, 'APP_ID')" class="overlaystatusicon top" [ngClass]="{hasselector:pickDocs||pickDocsOnly||pickFilePart}" src="{{formatTopStatusIcon(item)}}" alt="{{formatStatusText(item)}}">
        <div *ngIf="isTwoLine(item)" class="namewrapper twoline" tabindex="0" title="{{getTooltip(item)}}" (keydown.space)="childItemClicked(item,$event)">
          <div class="name">{{item.DOCNAME}}</div>
          <div class="name docnum">{{item.id}}</div>
        </div>
        <div *ngIf="!isTwoLine(item)" class="namewrapper" tabindex="0" title="{{getTooltip(item)}}" (keydown.space)="childItemClicked(item,$event)">
          <div class="name">{{item.DOCNAME}}</div>
        </div>
      </div>
      <div *ngIf="(pageSizeIncremental) > 0 && !child.allItemsIn && (child.list.length > 0)" class="showmore">
        <button [ngClass]="{mobile:ui>=2, oai:officeAddin}" (click)="fetchMoreChildItems()" id="edx_list_picker_btnchild_loadmore">{{loadMore}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="dualLists" class="childchildlist">
    <div class="listtitle">{{child.desc.DOCNAME}}</div>
    <div class="listcontainer"></div>
  </div>
</div>
<edx-spinner *ngIf="reloading"></edx-spinner>
