
/**
 * Created by kevin on 2016-10-03.
 *
 * Service to provide menus for the menu widget; menus are provided using the getMenu function, selected by the
 * menuId enumeration.
 *
 */


import { Injectable } from '@angular/core';
import { MenuItemDesc } from '../models/menu-item';
import { Util } from '../utils/utils.module';


export enum MenuId {
  MENU_MAIN = 0,
  MENU_TILE = 1,
  MENU_LIST_MAIN = 2,
  MENU_LIST_SUB = 3,
  MENU_PAGINATOR = 4,
  MENU_METADATA_HEADER = 5,
  MENU_LIST_INLINE = 6,
  MENU_USER_SETTINGS = 7,
  MENU_LIST_NEWITEMS = 8,
  MENU_SECURITY_ACTIONS = 9,
  MENU_RELATED_ACTIONS = 10,
  MENU_WHERE_USED_ACTIONS = 11,
  MENU_VERSIONS_ACTIONS = 12,
  MENU_VERSIONS_SUB = 13,
  MENU_LIST_WINDOW = 14,
  MENU_SEARCH = 15,
  MENU_COLUMN_LIST = 16,
  MENU_DESKTOP_BREAD_CRUMB_OVERFLOW = 17,
  MENU_WEB_BREAD_CRUMB_OVERFLOW = 18,
  MENU_MOBILE_LIST_MAIN = 19,
  MENU_MOBILE_LIST_NEWITEMS = 20,
  MENU_MOBILE_HAMBURGER = 21,
  MENU_MOBILE_ACTION_LIST = 22,
  MENU_MOBILE_ACTION_DOC = 23,
  MENU_MOBILE_SEARCH = 24,
  MENU_MOBILE_VERSIONS_ACTIONS = 25,
  MENU_MOBILE_SECURITY_ACTIONS = 26,
  MENU_TABLET_LIST_NEWITEMS = 27,
  MENU_MOBILE_METADATA = 28,
  MENU_RECORDS_MANAGEMENT = 29,
  MENU_OAI_HAMBURGER = 30,
  MENU_MOBILE_CHOOSE_COLUMN = 31,
  MENU_OAI_METADATA_TABS = 32,
  MENU_MOBILE_EMPTY = 33,
  MENU_TILE_LIST_INLINE = 34,
  MENU_SEARCH_SCOPE = 35,
  MENU_ATTACHMENTS_ACTIONS = 36,
  MENU_MOBILE_ATTACHMENTS_ACTIONS = 37,
  MENU_OAI_ACTION_LIST = 38,
  MENU_MOBILE_RELATED_ACTIONS = 39,
  MENU_MOBILE_WHERE_USED_ACTIONS = 40,
  MENU_ATTACHMENTS_SUB = 41,
  MENU_CONFIGURE_RED = 42,
  MENU_HISTORY_ACTIONS = 43,
  MENU_MOBILE_HISTORY_ACTIONS = 44,
  MENU_VERSIONS_COMPARE = 45,
  MENU_HELP_SETTINGS = 46,
  MENU_PRIMARY_TILES = 47,
  MENU_LOOKUP_ACTIONS = 48
}

// icon size 0=default, 1=mini, 2= maxi
export interface MenuDef {
  buttonIcon: string;
  buttonIconLight?: string;
  buttonIconSize?: number;
  drawToRight: boolean;
  drawUp?: boolean;
  allowChecks: boolean;
  allowEditChecks?: boolean;
  noSelect?: boolean;
  wide?: boolean;
  items: MenuItemDesc[];
  toolTip?: string;
}

@Injectable()
export class MenuService {
  private menus: MenuDef[] = [
    // #0 MENU_MAIN - home burger menu
    // NOTE: command strings MUST EQUAL ids of corresponding tiles (except home)
    {
      buttonIcon: 'hamburger_menu.svg',
      drawToRight: true,
      allowChecks: true,
      items: [
        {
          name: 'TILE_NAMES.QUICK_SEARCH',
          checked: true,
          enabled: true,
          cmd: 'searches'
        },
        {
          name: 'TILE_NAMES.FAVORITES',
          checked: false,
          enabled: true,
          cmd: 'workspaces/favorites'
        },
        {
          name: 'TILE_NAMES.RECENTLY_EDITED',
          checked: false,
          enabled: true,
          cmd: 'folders/recentedits'
        },
        {
          name: 'TILE_NAMES.MY_WORKSPACES',
          checked: true,
          enabled: true,
          cmd: 'workspaces'
        },
        {
          name: 'TILE_NAMES.MY_FLEXFOLDERS',
          checked: true,
          enabled: true,
          cmd: 'flexfolders'
        },
        {
          name: 'TILE_NAMES.CHECKED_OUT',
          checked: false,
          enabled: true,
          cmd: 'folders/checkedout'
        },
        {
          name: 'TILE_NAMES.FILEPLANS',
          checked: true,
          enabled: true,
          cmd: 'fileplans'
        }
      ]
    },
    // #1 MENU_TILE - tile header menu
    {
      buttonIcon: 'ellipses_step.svg',
      drawToRight: false,
      allowChecks: false,
      toolTip:'TOOLTIP.TILE_OPTIONS',
      items: [
        {
          name: 'TILE_MENU.REMOVE_TILE',
          cmd: 'remove'
        },
        {
          name: 'TILE_MENU.LARGE_TILE',
          cmd: 'large'
        },
        {
          name: 'TILE_MENU.SMALL_TILE',
          cmd: 'small'
        },
        {
          name: 'TILE_MENU.RENAME',
          cmd: 'rename'
        }
      ]
    },
    // #2 MENU_LIST_MAIN - list action bar menu
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'filter.svg',
          name: 'TOOLTIP.FILTER',
          cmd: 'showfilter'
        },
        {
          submenu: MenuId.MENU_LIST_NEWITEMS,
          name: 'FOLDER_ACTIONS.NEW',
          cmd: 'newmenu'
        },
        {
          separator: true,
          cmd: 'separator'
        },
        {
          icon: 'metadata.svg',
          name: 'FOLDER_ACTIONS.PROFILE',
          cmd: 'profile'
        },
        {
          icon: 'action_edit24.svg',
          name: 'FOLDER_ACTIONS.EDIT_PROFILE',
          cmd: 'editprofile'
        },
        {
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'email'
        },
        {
          name: 'FOLDER_ACTIONS.SHARE',
          cmd: 'share'
        },
        {
          name: 'FOLDER_ACTIONS.LINK',
          cmd: 'link'
        },
        {
          name: 'FOLDER_ACTIONS.CHECK_OUT',
          cmd: 'checkout'
        },
        {
          name: 'FOLDER_ACTIONS.CHECK_IN',
          cmd: 'checkin'
        },
        {
          name: 'FOLDER_ACTIONS.UNDO_CHECKOUT',
          cmd: 'unlock'
        },
        {
          name: 'FOLDER_ACTIONS.PRINT',
          cmd: 'print'
        },
        {
          name: 'FOLDER_ACTIONS.COPY',
          cmd: 'copy'
        },
        {
          name: 'FOLDER_ACTIONS.COPY_TREE',
          cmd: 'copytree'
        },
        {
          name: 'FOLDER_ACTIONS.SUBSCRIBE',
          cmd: 'subscribe'
        },
        {
          name: 'FOLDER_ACTIONS.UNSUBSCRIBE',
          cmd: 'unsubscribe'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_TO',
          cmd: 'addto'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          cmd: 'create_tile'
        },
        {
          name: 'FOLDER_ACTIONS.SAVE_TO_EDOCS',
          cmd: 'savetoedocs'
        },
        {
          name: 'FOLDER_ACTIONS.EDIT_SEARCH',
          cmd: 'editsearch'
        },
        {
          name: 'FOLDER_ACTIONS.RENAME',
          cmd: 'rename'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT',
          cmd: 'export'
        },
        {
          name: 'FOLDER_ACTIONS.READ_ONLY',
          cmd: 'readonly'
        },
        {
          name: 'FOLDER_ACTIONS.REMOVE_READ_ONLY',
          cmd: 'removereadonly'
        },
        {
          name: 'FOLDER_ACTIONS.DECLARE_RECORD',
          cmd: 'declarerecord'
        },
        {
          name: 'FOLDER_ACTIONS.UNDECLARE_RECORD',
          cmd: 'undeclarerecord'
        },
        {
          name: 'FOLDER_ACTIONS.REMOVE',
          cmd: 'removefromfolder'
        },
        {
          name: 'FOLDER_ACTIONS.DELETE',
          cmd: 'delete'
        },
        {
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'open'
        },
        {
          name: 'FOLDER_ACTIONS.OPEN_ONLINE',
          cmd: 'openonline'
        },
        {
          name: 'FOLDER_ACTIONS.SHARE_ONEDRIVE',
          cmd: 'shareonedrive'
        },
        {
          name: 'FOLDER_ACTIONS.STOP_SHARE_ONEDRIVE',
          cmd: 'stopshareonedrive'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT_RESULTS',
          cmd: 'exportresults'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT_FOLDER',
          cmd: 'exportfolder'
        },
        {
          name: 'REQUESTS_ACTIONS.CANCEL',
          cmd: 'rm_cancelrequest'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.PUT_IN_BOX',
          cmd: 'rm_putinbox'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.REMOVE_FROM_BOX',
          cmd: 'rm_removefrombox'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.SUSPEND',
          cmd: 'fp_rm_suspend'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.RELEASE',
          cmd: 'fp_rm_release'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.CLOSE_FILEPART',
          cmd: 'rm_close'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.REOPEN_FILEPART',
          cmd: 'rm_reopen'
        },
        {
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'fp_box_rm_loanrequest'
        },
        {
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'fp_box_returnrequest'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.REQUEST',
          cmd: 'fp_box_rm_request'
        },
        {
          name: 'FOLDER_ACTIONS.EDIT_SECURITY',
          cmd: 'editsecurity'
        },
        {
          name: 'FOLDER_ACTIONS.SET_SECURITY',
          cmd: 'setsecurity'
        },
        {
          submenu: MenuId.MENU_RECORDS_MANAGEMENT,
          name: 'FOLDER_ACTIONS.RECORDS',
          cmd: 'records',
          right: true
        },
        {
          submenu: MenuId.MENU_CONFIGURE_RED,
          name: 'FOLDER_ACTIONS.CONFIGURE_RED',
          cmd: 'configure_red',
          right: true
        },
        {
          submenu: MenuId.MENU_COLUMN_LIST,
          name: 'FOLDER_ACTIONS.SETTINGS',
          cmd: 'settings',
          right: true
        },
        {
          separator: true,
          cmd: 'view_mode_separator',
          enabled: true,
          right: true
        },
        {
          segments: [
            {
              title: 'FOLDER_ACTIONS.VIEW_AS_GRID',
              id: 'grid',
              icon: 'grid.svg'
            },
            {
              title: 'FOLDER_ACTIONS.VIEW_AS_SUMMARY',
              id: 'summary',
              icon: 'summary.svg'
            }
          ],
          cmd: 'view_mode_',
          right: true
        }
      ]
    },
    // #3 MENU_LIST_SUB - list action bar submenu
    {
      buttonIcon: 'ellipses_step.svg',
      drawToRight: false,
      allowChecks: false,
      items: []
    },
    // #4 MENU_PAGINATOR - paginator number of items menu
    {
      buttonIcon: 'caret_down12.svg',
      buttonIconSize: 1,
      drawToRight: false,
      allowChecks: true,
      allowEditChecks: false,
      drawUp: true,
      items: [
        {
          name: 'PAGINATOR.PER_PAGE',
          nameargs: ['25'],
          cmd: 'pageSize25',
          checked: false
        },
        {
          name: 'PAGINATOR.PER_PAGE',
          nameargs: ['50'],
          cmd: 'pageSize50',
          checked: false
        },
        {
          name: 'PAGINATOR.PER_PAGE',
          nameargs: ['100'],
          cmd: 'pageSize100',
          checked: false
        }
      ]
    },
    // #5 MENU_METADATA_HEADER - menu for document-level operations from metadata window header
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: false,
      toolTip: 'TOOLTIP.OPTIONS',
      items: [
        {
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'doc_open'
        },
        {
          name: 'FOLDER_ACTIONS.EDIT_PROFILE',
          cmd: 'doc_editprofile'
        },
        {
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'doc_email'
        },
        {
          name: 'FOLDER_ACTIONS.SHARE',
          cmd: 'doc_share'
        },
        {
          name: 'FOLDER_ACTIONS.LINK',
          cmd: 'doc_link'
        },
        {
          name: 'FOLDER_ACTIONS.PRINT',
          cmd: 'doc_print'
        },
        {
          name: 'FOLDER_ACTIONS.CHECK_OUT',
          cmd: 'doc_checkout'
        },
        {
          name: 'FOLDER_ACTIONS.CHECK_IN',
          cmd: 'doc_checkin'
        },
        {
          name: 'FOLDER_ACTIONS.UNDO_CHECKOUT',
          cmd: 'doc_unlock'
        },
        {
          name: 'FOLDER_ACTIONS.COPY',
          cmd: 'doc_copy'
        },
        {
          name: 'FOLDER_ACTIONS.COPY_TREE',
          cmd: 'doc_copytree'
        },
        {
          name: 'FOLDER_ACTIONS.SUBSCRIBE',
          cmd: 'doc_subscribe'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_TO',
          cmd: 'doc_addto'
        },
        {
          name: 'FOLDER_ACTIONS.SAVE_TO_EDOCS',
          cmd: 'doc_savetoedocs'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          cmd: 'doc_create_tile'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT',
          cmd: 'doc_export'
        },
        {
          name: 'FOLDER_ACTIONS.READ_ONLY',
          cmd: 'doc_readonly'
        },
        {
          name: 'FOLDER_ACTIONS.REMOVE_READ_ONLY',
          cmd: 'doc_removereadonly'
        },
        {
          name: 'FOLDER_ACTIONS.REMOVE',
          cmd: 'removefromfolder'
        },
        {
          name: 'FOLDER_ACTIONS.DELETE',
          cmd: 'doc_delete'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT_RESULTS',
          cmd: 'doc_exportresults'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT_FOLDER',
          cmd: 'doc_exportfolder'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.REQUEST',
          cmd: 'doc_rm_request'
        },
        {
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'doc_rm_returnrequest'
        },
        {
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'doc_rm_loanrequest'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.CHANGE_FILEPART',
          cmd: 'doc_rm_changefilepart'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.ASSIGN_FILEPART',
          cmd: 'doc_rm_assignfilepart'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.CHANGE_VITAL_STATUS',
          cmd: 'doc_rm_changevitalstatus'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.VITAL_REVIEW_DATE',
          cmd: 'doc_rm_vitalreviewdate'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.DISPOSAL_INSTRUCTION',
          cmd: 'doc_rm_disposalinstructions'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.SUSPEND',
          cmd: 'doc_rm_suspend'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.RELEASE',
          cmd: 'doc_rm_release'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.SUPERSEDE',
          cmd: 'doc_rm_supersede'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.PUT_IN_BOX',
          cmd: 'doc_rm_putinbox'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.REMOVE_FROM_BOX',
          cmd: 'doc_rm_removefrombox'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.PRINT_LABEL',
          cmd: 'doc_rm_printlabel'
        },
        {
          name: 'FOLDER_ACTIONS.DECLARE_RECORD',
          cmd: 'doc_declarerecord'
        },
        {
          name: 'FOLDER_ACTIONS.UNDECLARE_RECORD',
          cmd: 'doc_undeclarerecord'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.CLOSE_FILEPART',
          cmd: 'doc_rm_close'
        },
        {
          name: 'FILEPART_ACTIONS_INLINE.REOPEN_FILEPART',
          cmd: 'doc_rm_reopen'
        }
      ]
    },
    // #6 MENU_LIST_INLINE - menu for inline action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          name: 'METADATA.TABS.PREVIEW',
          iconic: true,
          icon: 'action:action_preview32',
          cmd: 'inlinepreview_mini'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.PROFILE',
          iconic: true,
          icon: 'action:action_properties32',
          cmd: 'profile_mini'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.EMAIL',
          iconic: true,
          icon: 'action:action_email32',
          cmd: 'email_mini'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.EDIT',
          iconic: true,
          icon: 'action:action_edit32',
          cmd: 'edit_mini'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.SHARE',
          iconic: true,
          icon: 'action:action_share32',
          cmd: 'share_mini'
        }
      ]
    },
    // #7 MENU_USER_SETTINGS - user settings menu from top toolbar
    {
      buttonIcon: 'li_header_user42.svg',
      drawToRight: false,
      allowChecks: false,
      buttonIconSize: -2,
      items: [
        {
          name: 'SETTINGS.PREFERENCES',
          enabled: true,
          cmd: 'preferences'
        },
        {
          separator: true,
          enabled: true
        },
        {
          name: 'SETTINGS.LOGOFF',
          enabled: true,
          cmd: 'logoff'
        }
      ]
    },
    // #8 MENU_LIST_NEWITEMS - new-item submenu on folder lists
    {
      buttonIcon: 'add_item32.svg',
      drawToRight: true,
      allowChecks: false,
      toolTip:'TOOLTIP.ADD_ITEM',
      items: [
        {
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          cmd: 'newdocument'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_PAPER_DOCUMENT',
          cmd: 'newpaperdocument'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          cmd: 'newfolder'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_FLEXFOLDER',
          cmd: 'newflexfolder'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_WORKSPACE',
          cmd: 'newworkspace'
        },
        {
          name: 'FOLDER_ACTIONS.SUBSCRIBE_TO',
          cmd: 'addflexfolder'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_URL',
          cmd: 'newurl'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_SEARCHES',
          cmd: 'addquicksearch'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_DOCUMENTS_FOLDERS',
          cmd: 'adddocuments'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_DOCUMENTS_FOLDERS_WORKSPACES',
          cmd: 'additems'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_FILEPART',
          cmd: 'addfilepart'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          cmd: 'createfolder'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          cmd: 'uploadfile'
        },
        {
          name: 'FOLDER_ACTIONS.UPLOAD_FOLDERS',
          cmd: 'uploadfolders'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_OBJECT',
          cmd: 'createobject'
        }
      ]
    },
    // #9 MENU_SECURITY_ACTIONS -  metadata security tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        { name: 'METADATA.SECURITY_ACTIONS.ADD',
          iconic: true,
          icon: 'add_item32.svg',
          cmd: 'security_add'
        },
        {
          separator: true,
          cmd: 'security_separator'
        },
        { name: 'METADATA.SECURITY_ACTIONS.DETAILS',
          cmd: 'security_details'
        },
        { name: 'METADATA.SECURITY_ACTIONS.SUMMARY',
          cmd: 'security_summary'
        },
        {
          name: 'METADATA.SECURITY_ACTIONS.REMOVE',
          cmd: 'security_remove'
        }
      ]
    },
    // #10 MENU_RELATED_ACTIONS - metadata related tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        { name: 'METADATA.RELATED_ACTIONS.ADD',
          iconic: true,
          icon: 'add_item32.svg',
          cmd: 'related_add'
        },
        {
          separator: true,
          cmd: 'related_separator'
        },
        { name: 'METADATA.RELATED_ACTIONS.INFO',
          iconic: true,
          icon: 'metadata.svg',
          cmd: 'related_info'
        },
        { name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'related_open'
        },
        { name: 'METADATA.RELATED_ACTIONS.REMOVE',
          cmd: 'related_remove'
        }
      ]
    },
    // #11 MENU_WHERE_USED_ACTIONS - metadata where-used tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        { name: 'METADATA.WHERE_USED_ACTIONS.ADD',
          iconic: true,
          icon: 'add_item32.svg',
          cmd: 'whereused_add'
        },
        {
          separator: true,
          cmd: 'whereused_separator'
        },
        {
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'whereused_open'
        },
        {
          name: 'METADATA.WHERE_USED_ACTIONS.REMOVE',
          cmd: 'whereused_remove'
        }
      ]
    },
    // #12 MENU_VERSIONS_ACTIONS - metadata versions tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          submenu: MenuId.MENU_VERSIONS_SUB,
          name: 'METADATA.VERSIONS_ACTIONS.ADD',
          cmd: 'versions_newmenu'
        },
        {
          separator: true,
          cmd: 'versions_separator'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS_ADD.NEW_VERSION',
          cmd: 'newversion'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.OPEN',
          cmd: 'versions_open'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.EXPORT',
          cmd: 'versions_export'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_VERSION',
          cmd: 'versions_editprofile'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.CHECKOUT',
          cmd: 'versions_checkout'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.CHECKIN',
          cmd: 'versions_checkin'
        },
        {
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'versions_email'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.READONLY',
          cmd: 'versions_readonly'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.REMOVEREADONLY',
          cmd: 'versions_removereadonly'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.PUBLISH',
          cmd: 'versions_publish'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.UNPUBLISH',
          cmd: 'versions_unpublish'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.DELETE',
          cmd: 'versions_delete'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.COMPARE',
          cmd: 'versions_compare',
          submenu: MenuId.MENU_VERSIONS_COMPARE
        }
      ]
    },
    // #13 MENU_VERSIONS_SUB - metadata versions tab new-item submenu
    {
      buttonIcon: 'add_item32.svg',
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          name: 'METADATA.VERSIONS_ACTIONS.ADD',
          cmd: 'versions_new'
        }
      ]
    },
    // #14 MENU_LIST_WINDOW - menu for container operations from window header
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: false,
      toolTip: 'TOOLTIP.OPTIONS',
      items: [
        {
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          enabled: true,
          cmd: 'doc_create_tile'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          cmd: 'primary_tile_add'
        },
        {
          name: 'FOLDER_ACTIONS.PROFILE',
          enabled: true,
          cmd: 'doc_profile'
        },
        {
          name: 'FOLDER_ACTIONS.SHARE',
          cmd: 'doc_share'
        },
        {
          name: 'FOLDER_ACTIONS.LINK',
          cmd: 'doc_link'
        },
        {
          name: 'FOLDER_ACTIONS.EDIT_SEARCH',
          cmd: 'doc_editsearch'
        },
        {
          name: 'FOLDER_ACTIONS.ADD_TO',
          cmd: 'doc_addto'
        },
        {
          name: 'FOLDER_ACTIONS.RENAME',
          cmd: 'doc_rename'
        },
        {
          name: 'FOLDER_ACTIONS.DELETE',
          enabled: true,
          cmd: 'doc_delete'
        },
        {
          name: 'FOLDER_ACTIONS.EDIT_SECURITY',
          cmd: 'doc_editsecurity'
        },
        {
          name: 'FOLDER_ACTIONS.SET_SECURITY',
          cmd: 'doc_setsecurity'
        },
        {
          name: 'FOLDER_ACTIONS.READ_ONLY',
          cmd: 'doc_readonly'
        },
        {
          name: 'FOLDER_ACTIONS.REMOVE_READ_ONLY',
          cmd: 'doc_removereadonly'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT_RESULTS',
          cmd: 'doc_exportresults'
        },
        {
          name: 'FOLDER_ACTIONS.EXPORT_FOLDER',
          cmd: 'doc_exportfolder'
        },
        {
          name: 'FOLDER_ACTIONS.COPY_TREE',
          cmd: 'copytree'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.SHOW_CLOSED_FILES',
          cmd: 'rm_showfiles'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.HIDE_CLOSED_FILES',
          cmd: 'rm_hidefiles'
        }
        /*,
        These command should be in the file-part list view menu but there is
        no way of updating desc of the file-part to filter the menu correctly
        for SUSPEND/RELEASE. For now these commands are in the profile view.
        {
          name: 'METADATA.RECORDS_ACTIONS.REQUEST',
          cmd: 'fp_rm_request'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.SUSPEND',
          cmd: 'fp_rm_suspend'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.RELEASE',
          cmd: 'fp_rm_release'
        }*/
      ]
    },
    //#15 MENU_SEARCH - Advanced search menu
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: true,
      allowEditChecks: true,
      items: [
        {
          name: 'GLOBAL_SEARCHSCOPE.PROFILE_AND_CONTENT',
          enabled: true,
          checked:true,
          cmd:'FULLTEXT_CONTENT_PROFILE'
        },
        {
          name: 'GLOBAL_SEARCHSCOPE.PROFILE',
          enabled: true,
          checked:false,
          cmd:'FULLTEXT_PROFILE'
        },
        {
          name: 'GLOBAL_SEARCHSCOPE.CONTENT',
          enabled: true,
          checked:false,
          cmd:'FULLTEXT_EASY_SEARCH'
        }
      ]
    },
    // #16 MENU_COLUMN_LIST - submenu for customizing columns
    {
      buttonIcon: 'settings.svg',
      buttonIconLight: 'settings24_li.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: true,
      allowEditChecks: true,
      noSelect: true,
      toolTip: 'TOOLTIP.CONFIGURE_COLUMNS',
      items: []
    },
    // #17 MENU_DESKTOP_BREAD_CRUMB_OVERFLOW - breadcrumbs overflow
    {
      buttonIcon: 'ellipses_step_white.svg',
      buttonIconSize: 3,
      drawToRight: true,
      allowChecks: false,
      items: []
    },
    // #18 MENU_WEB_BREAD_CRUMB_OVERFLOW - breadcrumbs overflow
    {
      buttonIcon: 'ellipses_step_white.svg',
      buttonIconSize: 3,
      drawToRight: true,
      allowChecks: false,
      items: []
    },
    // #19 MENU_MOBILE_LIST_MAIN - list action bar menu for mobile
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'toolbar_email24.svg',
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'email'
        },
        {
          iconic: true,
          icon: 'share.svg',
          name: 'FOLDER_ACTIONS.SHARE',
          cmd: 'share'
        },
        {
          iconic: true,
          icon: 'action_add_to_home24.svg',
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          cmd: 'create_tile'
        },
        {
          iconic: true,
          icon: 'toolbar_link24.svg',
          name: 'FOLDER_ACTIONS.LINK',
          cmd: 'link'
        },
        {
          iconic: true,
          icon: 'insert_into_document.svg',
          name: 'FOLDER_ACTIONS.INSERT',
          cmd: 'insertintodocument'
        },
        {
          iconic: true,
          icon: 'toolbar_insert_link24.svg',
          name: 'FOLDER_ACTIONS.INSERT_LINK',
          cmd: 'insertlinkintodocument'
        },
        {
          iconic: true,
          icon: 'action_export24.svg',
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'open'
        },
        {
          iconic: true,
          icon: 'action_open_online24.svg',
          name: 'FOLDER_ACTIONS.OPEN_ONLINE',
          cmd: 'openonline'
        },
        {
          iconic: true,
          icon: 'action_share24.svg',
          name: 'FOLDER_ACTIONS.SHARE_ONEDRIVE',
          cmd: 'shareonedrive'
        },
        {
          iconic: true,
          icon: 'action_unshare24.svg',
          name: 'FOLDER_ACTIONS.STOP_SHARE_ONEDRIVE',
          cmd: 'stopshareonedrive'
        },
        {
          iconic: true,
          icon: 'metadata.svg',
          name: 'FOLDER_ACTIONS.PROFILE',
          cmd: 'profile'
        },
        {
          iconic: true,
          icon: 'action_edit24.svg',
          name: 'FOLDER_ACTIONS.EDIT_PROFILE',
          cmd: 'editprofile'
        },
        {
          iconic: true,
          icon: 'action_edit24.svg',
          name: 'FOLDER_ACTIONS.EDIT_SEARCH',
          cmd: 'editsearch'
        },
        {
          iconic: true,
          icon: 'action_download24.svg',
          name: 'FOLDER_ACTIONS.EXPORT',
          cmd: 'export'
        },
        {
          iconic: true,
          icon: 'action_view24.svg',
          name: 'FOLDER_ACTIONS.VIEW',
          cmd: 'view'
        },
        {
          iconic: true,
          icon: 'action_check_out24.svg',
          name: 'FOLDER_ACTIONS.CHECK_OUT',
          cmd: 'checkout'
        },
        {
          iconic: true,
          icon: 'action_check_in24.svg',
          name: 'FOLDER_ACTIONS.CHECK_IN',
          cmd: 'checkin'
        },
        {
          iconic: true,
          icon: 'unlock.svg',
          name: 'FOLDER_ACTIONS.UNDO_CHECKOUT',
          cmd: 'unlock'
        },
        {
          iconic: true,
          icon: 'action_reserve24.svg',
          name: 'FOLDER_ACTIONS.READ_ONLY',
          cmd: 'readonly'
        },
        {
          iconic: true,
          icon: 'unlock.svg',
          name: 'FOLDER_ACTIONS.REMOVE_READ_ONLY',
          cmd: 'removereadonly'
        },
        {
          iconic: true,
          icon: 'action_subscribe_24.svg',
          name: 'FOLDER_ACTIONS.SUBSCRIBE',
          cmd: 'subscribe'
        },
        {
          iconic: true,
          icon: 'action_unsubscribe_24.svg',
          name: 'FOLDER_ACTIONS.UNSUBSCRIBE',
          cmd: 'unsubscribe'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.EDIT_SECURITY',
          icon: 'security24.svg',
          cmd: 'editsecurity'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.SET_SECURITY',
          icon: 'security24.svg',
          cmd: 'setsecurity'
        },
        {
          iconic: true,
          icon: 'action_copy24.svg',
          name: 'FOLDER_ACTIONS.COPY',
          cmd: 'copy'
        },
        {
          iconic: true,
          icon: 'add_item32.svg',
          name: 'FOLDER_ACTIONS.ADD_TO',
          cmd: 'addto'
        },
        {
          iconic: true,
          icon: 'action_upload24.svg',
          name: 'FOLDER_ACTIONS.SAVE_TO_EDOCS',
          cmd: 'savetoedocs'
        },
        {
          iconic: true,
          icon: 'action_rename24.svg',
          name: 'FOLDER_ACTIONS.RENAME',
          cmd: 'rename'
        },
        {
          iconic: true,
          icon: 'action_print24.svg',
          name: 'FOLDER_ACTIONS.PRINT',
          cmd: 'print'
        },
        {
          iconic: true,
          icon: 'action_upload24.svg',
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          cmd: 'import'
        },
        {
          iconic: true,
          icon: 'action_folder_remove_from24.svg',
          name: 'FOLDER_ACTIONS.REMOVE',
          cmd: 'removefromfolder'
        },
        {
          iconic: true,
          icon: 'action_add_version24.svg',
          name: 'METADATA.VERSIONS_ACTIONS_ADD.NEW_VERSION',
          cmd: 'newversion'
        },
        {
          iconic: true,
          icon: 'delete_grey.svg',
          name: 'FOLDER_ACTIONS.DELETE',
          cmd: 'delete'
        },
        {
          iconic: true,
          icon: 'rm_declare.svg',
          name: 'FOLDER_ACTIONS.DECLARE_RECORD',
          cmd: 'declarerecord'
        },
        {
          iconic: true,
          icon: 'rm_undeclare.svg',
          name: 'FOLDER_ACTIONS.UNDECLARE_RECORD',
          cmd: 'undeclarerecord'
        },
        {
          iconic: true,
          icon: 'fp_rm_close.svg',
          name: 'FILEPART_ACTIONS_INLINE.CLOSE_FILEPART',
          cmd: 'rm_close'
        },
        {
          iconic: true,
          icon: 'fp_rm_reopen.svg',
          name: 'FILEPART_ACTIONS_INLINE.REOPEN_FILEPART',
          cmd: 'rm_reopen'
        },
        {
          iconic: true,
          icon: 'rm_request.svg',
          name: 'METADATA.RECORDS_ACTIONS.REQUEST',
          cmd: 'rm_request'
        },
        {
          iconic: true,
          icon: 'rm_request.svg',
          name: 'FILEPART_ACTIONS_INLINE.REQUEST',
          cmd: 'fp_box_rm_request'
        },
        {
          iconic: true,
          icon: 'rm_cancelrequest.svg',
          name: 'REQUESTS_ACTIONS.CANCEL',
          cmd: 'rm_cancelrequest'
        },
        {
          iconic: true,
          icon: 'rm_loanrequest.svg',
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'fp_box_rm_loanrequest'
        },
        {
          iconic: true,
          icon: 'rm_loanrequest.svg',
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'rm_loanrequest'
        },
        {
          iconic: true,
          icon: 'rm_returnrequest.svg',
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'fp_box_returnrequest'
        },
        {
          iconic: true,
          icon: 'rm_returnrequest.svg',
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'rm_returnrequest'
        },
        {
          iconic: true,
          icon: 'rm_suspend.svg',
          name: 'FILEPART_ACTIONS_INLINE.SUSPEND',
          cmd: 'fp_rm_suspend'
        },
        {
          iconic: true,
          icon: 'rm_suspend.svg',
          name: 'METADATA.RECORDS_ACTIONS.SUSPEND',
          cmd: 'rm_suspend'
        },
        {
          iconic: true,
          icon: 'rm_release.svg',
          name: 'FILEPART_ACTIONS_INLINE.RELEASE',
          cmd: 'fp_rm_release'
        },
        {
          iconic: true,
          icon: 'rm_release.svg',
          name: 'METADATA.RECORDS_ACTIONS.RELEASE',
          cmd: 'rm_release'
        },
        {
          iconic: true,
          icon: 'rm_putinbox.svg',
          name: 'FILEPART_ACTIONS_INLINE.PUT_IN_BOX',
          cmd: 'rm_putinbox'
        },
        {
          iconic: true,
          icon: 'rm_removefrombox.svg',
          name: 'FILEPART_ACTIONS_INLINE.REMOVE_FROM_BOX',
          cmd: 'rm_removefrombox'
        }
      ]
    },
    // #20 MENU_MOBILE_LIST_NEWITEMS - new-item submenu on folder lists mobile variant
    {
      buttonIcon: 'add_item32.svg',
      drawUp: true,
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          icon: 'upload24_li.svg',
          cmd: 'newdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_PAPER_DOCUMENT',
          icon: 'upload24_li.svg',
          cmd: 'newpaperdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'action_add_folder24_li.svg',
          cmd: 'newfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'action_add_folder24.svg',
          cmd: 'createfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_WORKSPACE',
          icon: 'action_add_workspace24_li.svg',
          cmd: 'newworkspace'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_PHOTO',
          icon: 'add_photo24_li.svg',
          cmd: 'addphoto'
        }
      ]
    },
    // #21 MENU_MOBILE_HAMBURGER
    {
      buttonIcon: 'li_header_hamburger24.svg',
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          name: 'HEADER.HOME',
          cmd: 'home'
        },
        {
          separator: true,
          cmd: 'separator'
        },
        {
          name: 'TILE_NAMES.DOWNLOADS',
          cmd: 'downloads'
        },
        {
          name: 'TILE_NAMES.IMPORTS',
          cmd: 'imports'
        },
        {
          name: 'HERO.SECTIONS.UPLOAD_FILES',
          cmd: 'newdocument'
        },
        {
          separator: true,
          cmd: 'separator'
        },
        {
          name: 'SETTINGS.PREFERENCES',
          cmd: 'preferences'
        },
        {
          name: 'SETTINGS.ABOUT',
          cmd: 'about'
        },
        {
          name: 'SETTINGS.LOGOFF',
          cmd: 'logoff'
        }
      ]
    },
    // #22 MENU_MOBILE_ACTION_LIST mobile action menu for lists
    {
      buttonIcon: 'ellipses_step_white.svg',
      drawToRight: false,
      allowChecks: true,
      items: [
        {
          iconic: true,
          icon: 'checkbox_focus.svg',
          name: 'PLACEHOLDER.SELECT_MODE',
          cmd: 'list_selectmode'
        },
        {
          iconic: true,
          icon: 'checkbox_all_selected.svg',
          name: 'PLACEHOLDER.SELECT_ALL',
          cmd: 'list_selectall'
        },
        {
          iconic: true,
          icon: 'filter.svg',
          name: 'TOOLTIP.FILTER',
          cmd: 'showfilter'
        },
        {
          separator: true,
          cmd: 'separator'
        },
        {
          iconic: true,
          name: 'FORMS.LOCAL.SAVE_SEARCH.SAVE_SEARCH',
          icon: 'search24.svg',
          cmd: 'savesearch'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          icon: 'upload24.svg',
          cmd: 'newdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_PAPER_DOCUMENT',
          icon: 'upload24.svg',
          cmd: 'newpaperdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'folder_add24.svg',
          cmd: 'newfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'action_add_folder24.svg',
          cmd: 'createfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_WORKSPACE',
          icon: 'action_add_workspace24.svg',
          cmd: 'newworkspace'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_DOCUMENTS_FOLDERS',
          icon: 'add_item32.svg',
          cmd: 'adddocuments'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_URL',
          icon: 'li_wiki_view_links24.svg',
          cmd: 'newurl'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_SEARCHES',
          icon: 'action_search24.svg',
          cmd: 'addquicksearch'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_FILEPART',
          icon: 'add_item32.svg',
          cmd: 'addfilepart'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.EDIT_SECURITY',
          icon: 'security24.svg',
          cmd: 'doc_editsecurity'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.SET_SECURITY',
          icon: 'security24.svg',
          cmd: 'doc_setsecurity'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          cmd: 'uploadfile',
          icon: 'upload24.svg'
        }
      ]
    },
    // #23 MENU_MOBILE_ACTION_DOC mobile action menu for docs
    {
      buttonIcon: 'ellipses_step_white.svg',
      drawToRight: false,
      allowChecks: true,
      items: [
        {
          name: 'PLACEHOLDER.SELECT_MODE',
          cmd: 'list_selectmode'
        }
      ]
    },
    //#24 MENU_MOBILE_SEARCH - Advanced search menu
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: true,
      allowEditChecks: true,
      items: [
        {
          name: 'GLOBAL_SEARCHSCOPE.PROFILE_AND_CONTENT_SHORT',
          enabled: true,
          checked:true,
          cmd:'FULLTEXT_CONTENT_PROFILE'
        },
        {
          name: 'GLOBAL_SEARCHSCOPE.PROFILE_SHORT',
          enabled: true,
          checked:false,
          cmd:'FULLTEXT_PROFILE'
        },
        {
          name: 'GLOBAL_SEARCHSCOPE.CONTENT_SHORT',
          enabled: true,
          checked:false,
          cmd:'FULLTEXT_EASY_SEARCH'
        }
      ]
    },
    // 25 MENU_MOBILE_VERSIONS_ACTIONS - metadata versions tab action bar
    {
      buttonIcon: 'ellipses_step_white.svg',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'add_item32.svg',
          name: 'METADATA.VERSIONS_ACTIONS.ADD',
          cmd: 'versions_new'
        },
        {
          iconic: true,
          icon: 'add_item32.svg',
          name: 'METADATA.VERSIONS_ACTIONS_ADD.NEW_VERSION',
          cmd: 'newversion'
        },
        {
          iconic: true,
          icon: 'action_edit24.svg',
          name: 'FOLDER_ACTIONS.NEW_VERSION',
          cmd: 'versions_editprofile'
        },
        {
          iconic: true,
          icon: 'action_export24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.OPEN',
          cmd: 'versions_open'
        },
        {
          iconic: true,
          icon: 'action_view24.svg',
          name: 'FOLDER_ACTIONS.VIEW',
          cmd: 'versions_view'
        },
        {
          iconic: true,
          icon: 'action_download24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.EXPORT',
          cmd: 'versions_export'
        },
        {
          iconic: true,
          icon: 'action_check_out24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.CHECKOUT',
          cmd: 'versions_checkout'
        },
        {
          iconic: true,
          icon: 'action_check_in24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.CHECKIN',
          cmd: 'versions_checkin'
        },
        {
          iconic: true,
          icon: 'toolbar_email24.svg',
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'versions_email'
        },
        {
          iconic: true,
          icon: 'insert_into_document.svg',
          name: 'FOLDER_ACTIONS.INSERT',
          cmd: 'versions_insertintodocument'
        },
        {
          iconic: true,
          icon: 'toolbar_insert_link24.svg',
          name: 'FOLDER_ACTIONS.INSERT_LINK',
          cmd: 'versions_insertlinkintodocument'
        },
        {
          iconic: true,
          icon: 'action_reserve24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.READONLY',
          cmd: 'versions_readonly'
        },
        {
          iconic: true,
          icon: 'unlock.svg',
          name: 'METADATA.VERSIONS_ACTIONS.REMOVEREADONLY',
          cmd: 'versions_removereadonly'
        },
        {
          iconic: true,
          icon: 'action_publish24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.PUBLISH',
          cmd: 'versions_publish'
        },
        {
          iconic: true,
          icon: 'action_publish24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.UNPUBLISH',
          cmd: 'versions_unpublish'
        },
        {
          iconic: true,
          icon: 'delete_grey.svg',
          name: 'METADATA.VERSIONS_ACTIONS.DELETE',
          cmd: 'versions_delete'
        },
        {
          iconic: true,
          icon: 'toolbar_preview24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.COMPARE_REPORT',
          cmd: 'versions_comparereport'
        }
      ]
    },
    // 26 MENU_MOBILE_SECURITY_ACTIONS -  metadata security tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'add_item32.svg',
          name: 'METADATA.SECURITY_ACTIONS.ADD',
          cmd: 'security_add'
        },
        {
          iconic: true,
          icon: 'delete_grey.svg',
          name: 'METADATA.SECURITY_ACTIONS.REMOVE',
          cmd: 'security_remove'
        }
      ]
    },
    // #27 MENU_TABLET_LIST_NEWITEMS - new-item submenu on folder lists tablet variant
    {
      buttonIcon: 'add_item32.svg',
      drawUp: true,
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'filter24_li.svg',
          name: 'TOOLTIP.FILTER',
          cmd: 'showfilter'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          icon: 'upload24_li.svg',
          cmd: 'newdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_PAPER_DOCUMENT',
          icon: 'upload24_li.svg',
          cmd: 'newpaperdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'folder_add24_li.svg',
          cmd: 'newfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'action_add_folder24_li.svg',
          cmd: 'createfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_WORKSPACE',
          icon: 'action_add_workspace24_li.svg',
          cmd: 'newworkspace'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_PHOTO',
          icon: 'add_photo24_li.svg',
          cmd: 'addphoto'
        }
      ]
    },
    // #28 MENU_MOBILE_METADATA - menu for document-level operations from metadata mobile popup
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: false,
      toolTip: 'TOOLTIP.OPTIONS',
      items: [
        {
          iconic: true,
          icon: 'toolbar_email24.svg',
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'doc_email'
        },
        {
          iconic: true,
          icon: 'share.svg',
          name: 'FOLDER_ACTIONS.SHARE',
          cmd: 'doc_share'
        },
        {
          iconic: true,
          icon: 'toolbar_link24.svg',
          name: 'FOLDER_ACTIONS.LINK',
          cmd: 'doc_link'
        },
        {
          iconic: true,
          icon: 'action_export24.svg',
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'doc_open'
        },
        {
          iconic: true,
          icon: 'action_view24.svg',
          name: 'FOLDER_ACTIONS.VIEW',
          cmd: 'doc_view'
        },
        {
          iconic: true,
          icon: 'insert_into_document.svg',
          name: 'FOLDER_ACTIONS.INSERT',
          cmd: 'insertintodocument'
        },
        {
          iconic: true,
          icon: 'toolbar_insert_link24.svg',
          name: 'FOLDER_ACTIONS.INSERT_LINK',
          cmd: 'insertlinkintodocument',
        },
        {
          iconic: true,
          icon: 'action_edit24.svg',
          name: 'FOLDER_ACTIONS.EDIT_PROFILE',
          cmd: 'doc_editprofile'
        },
        {
          iconic: true,
          icon: 'action_print24.svg',
          name: 'FOLDER_ACTIONS.PRINT',
          cmd: 'doc_print'
        },
        {
          iconic: true,
          icon: 'action_check_out24.svg',
          name: 'FOLDER_ACTIONS.CHECK_OUT',
          cmd: 'doc_checkout'
        },
        {
          iconic: true,
          icon: 'action_check_in24.svg',
          name: 'FOLDER_ACTIONS.CHECK_IN',
          cmd: 'doc_checkin'
        },
        {
          iconic: true,
          icon: 'unlock.svg',
          name: 'FOLDER_ACTIONS.UNDO_CHECKOUT',
          cmd: 'doc_unlock'
        },
        {
          iconic: true,
          icon: 'action_copy24.svg',
          name: 'FOLDER_ACTIONS.COPY',
          cmd: 'doc_copy'
        },
        {
          iconic: true,
          icon: 'action_subscribe_24.svg',
          name: 'FOLDER_ACTIONS.SUBSCRIBE',
          cmd: 'doc_subscribe'
        },
        {
          iconic: true,
          icon: 'action_unsubscribe_24.svg',
          name: 'FOLDER_ACTIONS.UNSUBSCRIBE',
          cmd: 'doc_unsubscribe'
        },
        {
          iconic: true,
          icon: 'action_add_to_folder24.svg',
          name: 'FOLDER_ACTIONS.ADD_TO',
          cmd: 'doc_addto'
        },
        {
          iconic: true,
          icon: 'action_upload24.svg',
          name: 'FOLDER_ACTIONS.SAVE_TO_EDOCS',
          cmd: 'doc_savetoedocs'
        },
        {
          iconic: true,
          icon: 'action_add_to_home24.svg',
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          cmd: 'doc_create_tile'
        },
        {
          iconic: true,
          icon: 'action_download24.svg',
          name: 'FOLDER_ACTIONS.EXPORT',
          cmd: 'doc_export'
        },
        {
          iconic: true,
          icon: 'action_reserve24.svg',
          name: 'FOLDER_ACTIONS.READ_ONLY',
          cmd: 'doc_readonly'
        },
        {
          iconic: true,
          icon: 'unlock.svg',
          name: 'FOLDER_ACTIONS.REMOVE_READ_ONLY',
          cmd: 'doc_removereadonly'
        },
        {
          iconic: true,
          icon: 'action_folder_remove_from24.svg',
          name: 'FOLDER_ACTIONS.REMOVE',
          cmd: 'removefromfolder'
        },
        {
          iconic: true,
          icon: 'delete_grey.svg',
          name: 'FOLDER_ACTIONS.DELETE',
          cmd: 'doc_delete'
        },
        {
          iconic: true,
          icon: 'rm_declare.svg',
          name: 'FOLDER_ACTIONS.DECLARE_RECORD',
          cmd: 'doc_declarerecord'
        },
        {
          iconic: true,
          icon: 'rm_undeclare.svg',
          name: 'FOLDER_ACTIONS.UNDECLARE_RECORD',
          cmd: 'doc_undeclarerecord'
        },
        {
          iconic: true,
          icon: 'fp_rm_close.svg',
          name: 'FILEPART_ACTIONS_INLINE.CLOSE_FILEPART',
          cmd: 'doc_rm_close'
        },
        {
          iconic: true,
          icon: 'fp_rm_reopen.svg',
          name: 'FILEPART_ACTIONS_INLINE.REOPEN_FILEPART',
          cmd: 'doc_rm_reopen'
        },
        {
          iconic: true,
          icon: 'rm_request.svg',
          name: 'METADATA.RECORDS_ACTIONS.REQUEST',
          cmd: 'doc_rm_request'
        },
        {
          iconic: true,
          icon: 'rm_cancelrequest.svg',
          name: 'REQUESTS_ACTIONS.CANCEL',
          cmd: 'doc_rm_cancelrequest'
        },
        {
          iconic: true,
          icon: 'rm_returnrequest.svg',
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'doc_rm_returnrequest'
        },
        {
          iconic: true,
          icon: 'rm_loanrequest.svg',
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'doc_rm_loanrequest'
        },
        {
          iconic: true,
          icon: 'rm_changevitalstatus.svg',
          name: 'METADATA.RECORDS_ACTIONS.CHANGE_VITAL_STATUS',
          cmd: 'doc_rm_changevitalstatus'
        },
        {
          iconic: true,
          icon: 'rm_vitalreviewdate.svg',
          name: 'METADATA.RECORDS_ACTIONS.VITAL_REVIEW_DATE',
          cmd: 'doc_rm_vitalreviewdate'
        },
        {
          iconic: true,
          icon: 'rm_changefilepart.svg',
          name: 'METADATA.RECORDS_ACTIONS.CHANGE_FILEPART',
          cmd: 'doc_rm_changefilepart'
        },
        {
          iconic: true,
          icon: 'rm_changefilepart.svg',
          name: 'METADATA.RECORDS_ACTIONS.ASSIGN_FILEPART',
          cmd: 'doc_rm_assignfilepart'
        },
        {
          iconic: true,
          icon: 'rm_disposalinstructions.svg',
          name: 'METADATA.RECORDS_ACTIONS.DISPOSAL_INSTRUCTION',
          cmd: 'doc_rm_disposalinstructions'
        },
        {
          iconic: true,
          icon: 'rm_suspend.svg',
          name: 'METADATA.RECORDS_ACTIONS.SUSPEND',
          cmd: 'doc_rm_suspend'
        },
        {
          iconic: true,
          icon: 'rm_release.svg',
          name: 'METADATA.RECORDS_ACTIONS.RELEASE',
          cmd: 'doc_rm_release'
        },
        {
          iconic: true,
          icon: 'rm_supersede.svg',
          name: 'METADATA.RECORDS_ACTIONS.SUPERSEDE',
          cmd: 'doc_rm_supersede'
        },
        {
          iconic: true,
          icon: 'rm_putinbox.svg',
          name: 'METADATA.RECORDS_ACTIONS.PUT_IN_BOX',
          cmd: 'doc_rm_putinbox'
        },
        {
          iconic: true,
          icon: 'rm_removefrombox.svg',
          name: 'METADATA.RECORDS_ACTIONS.REMOVE_FROM_BOX',
          cmd: 'doc_rm_removefrombox'
        },
        {
          iconic: true,
          icon: 'rm_printlabel.svg',
          name: 'METADATA.RECORDS_ACTIONS.PRINT_LABEL',
          cmd: 'doc_rm_printlabel'
        },
        {
          iconic: true,
          icon: 'fp_rm_close.svg',
          name: 'FILEPART_ACTIONS_INLINE.CLOSE_FILEPART',
          cmd: 'doc_rm_close'
        },
        {
          iconic: true,
          icon: 'fp_rm_reopen.svg',
          name: 'FILEPART_ACTIONS_INLINE.REOPEN_FILEPART',
          cmd: 'doc_rm_reopen'
        },
        {
          iconic: true,
          icon: 'rm_putinbox.svg',
          name: 'METADATA.RECORDS_ACTIONS.PUT_IN_BOX',
          cmd: 'rm_putinbox'
        },
        {
          iconic: true,
          icon: 'rm_request.svg',
          name: 'METADATA.RECORDS_ACTIONS.REQUEST',
          cmd: 'fp_box_rm_request'
        },
        {
          iconic: true,
          icon: 'rm_suspend.svg',
          name: 'METADATA.RECORDS_ACTIONS.SUSPEND',
          cmd: 'fp_rm_suspend'
        },
        {
          iconic: true,
          icon: 'rm_release.svg',
          name: 'METADATA.RECORDS_ACTIONS.RELEASE',
          cmd: 'fp_rm_release'
        },
        {
          iconic: true,
          icon: 'rm_loanrequest.svg',
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'fp_box_rm_loanrequest'
        },
        {
          iconic: true,
          icon: 'rm_returnrequest.svg',
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'fp_box_returnrequest'
        }
      ]
    },
    // #29 MENU_RECORDS_MANAGEMENT - submenu on records
    {
      buttonIcon: 'record_management_24.svg',
      drawToRight: false,
      allowChecks: false,
      toolTip:'TOOLTIP.RECORDS',
      items: [
        {
          name: 'METADATA.RECORDS_ACTIONS.REQUEST',
          cmd: 'rm_request'
        },
        {
          name: 'REQUESTS_ACTIONS.RETURN',
          cmd: 'rm_returnrequest'
        },
        {
          name: 'REQUESTS_ACTIONS.LOAN',
          cmd: 'rm_loanrequest'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.CHANGE_FILEPART',
          cmd: 'rm_changefilepart'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.ASSIGN_FILEPART',
          cmd: 'rm_assignfilepart'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.CHANGE_VITAL_STATUS',
          cmd: 'rm_changevitalstatus'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.VITAL_REVIEW_DATE',
          cmd: 'rm_vitalreviewdate'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.DISPOSAL_INSTRUCTION',
          cmd: 'rm_disposalinstructions'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.SUSPEND',
          cmd: 'rm_suspend'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.RELEASE',
          cmd: 'rm_release'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.SUPERSEDE',
          cmd: 'rm_supersede'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.PUT_IN_BOX',
          cmd: 'rm_putinbox'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.REMOVE_FROM_BOX',
          cmd: 'rm_removefrombox'
        },
        {
          name: 'METADATA.RECORDS_ACTIONS.PRINT_LABEL',
          cmd: 'rm_printlabel'
        },
      ]
    },
    // #30 MENU_OAI_HAMBURGER
    {
      buttonIcon: 'li_header_hamburger24.svg',
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          name: 'HEADER.HOME',
          cmd: 'home'
        },
        {
          name: 'HEADER.START',
          cmd: 'hdr_setstarthere'
        },
        {
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          cmd: 'newdocument'
        },
        {
          name: 'FOLDER_ACTIONS.UPLOAD_FOLDERS',
          cmd: 'uploadfolders'
        },
        {
          name: 'FORMS.LOCAL.FOOTER_OPTIONS.FOOTER_OPTIONS',
          cmd: 'footer_options'
        },
        {
          name: 'SETTINGS.PREFERENCES',
          cmd: 'preferences'
        },
        {
          name: 'SETTINGS.ABOUT',
          cmd: 'about'
        },
        {
          name: 'SETTINGS.LOGOFF',
          cmd: 'logoff'
        }
      ]
    },
    // #31 MENU_MOBILE_CHOOSE_COLUMN - choose sort column
    {
      buttonIcon: 'caret_down24.svg',
      buttonIconLight: 'caret_down24_li.svg',
      drawToRight: true,
      allowChecks: false,
      items: []
    },
    // #32 MENU_OAI_METADATA_TABS - meta data tabs
    {
      buttonIcon: 'ellipses_step.svg',
      buttonIconSize: 3,
      allowChecks: true,
      drawToRight: false,
      allowEditChecks: false,
      items: [
      {
        name: 'METADATA.TABS.PROFILE',
        cmd: 'tab_profile',
        checked: false
      },
      {
        name: 'METADATA.TABS.PREVIEW',
        cmd: 'tab_preview',
        checked: false
      },
      {
        name: 'METADATA.TABS.VIEWER',
        cmd: 'tab_viewer',
        checked: false
      },
      {
        name: 'METADATA.TABS.VERSIONS',
        cmd: 'tab_versions',
        checked: false
      },
      {
        name: 'METADATA.TABS.HISTORY',
        cmd: 'tab_history',
        checked: false
      },
      {
        name: 'METADATA.TABS.WHERE_USED',
        cmd: 'tab_whereused',
        checked: false
      },
      {
        name: 'METADATA.TABS.ATTACHMENTS',
        cmd: 'tab_attachments',
        checked: false
      },
      {
        name: 'METADATA.TABS.RELATED',
        cmd: 'tab_related',
        checked: false
      },
      {
        name: 'METADATA.TABS.SECURITY',
        cmd: 'tab_security',
        checked: false
      }
    ]
    },
    // #33 Mobile action empty menu for fileplans
    {
      buttonIcon: 'ellipses_step_white.svg',
      drawToRight: false,
      allowChecks: true,
      items: [
        {
          name: '',
          cmd: 'dummy'
        }
      ]
    },
    // #34 MENU_TILE_LIST_INLINE - menu for inline action bar in tiles
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          iconic: true,
          icon: 'action:action_add32',
          cmd: 'create_tile'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.PROFILE',
          iconic: true,
          icon: 'action:action_properties32',
          cmd: 'profile_mini'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.EMAIL',
          iconic: true,
          icon: 'action:action_email32',
          cmd: 'email_mini'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.SHARE',
          iconic: true,
          icon: 'action:action_share32',
          cmd: 'share_mini'
        }
      ]
    },
    // #35 MENU_SEARCH_SCOPE - menu for search scope
    {
      buttonIcon: '',
      buttonIconSize: -3,
      drawToRight: false,
      drawUp: false,
      allowChecks: true,
      wide: true,
      toolTip: 'GLOBAL_SEARCHSCOPE.SEARCH_WHERE',
      items: []
    },
    // 36 MENU_ATTACHMENTS_ACTIONS - metadata attachments tab action bar
    {
      buttonIcon: 'ellipses_step_white.svg',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          submenu: MenuId.MENU_ATTACHMENTS_SUB,
          name: 'METADATA.ATTACHMENTS_ACTIONS.ADD',
          cmd: 'attachments_newmenu'
        },
        {
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'attachments_open'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.EXPORT',
          cmd: 'versions_export'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.PUBLISH',
          cmd: 'versions_publish'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.UNPUBLISH',
          cmd: 'versions_unpublish'
        },
        {
          name: 'METADATA.ATTACHMENTS_ACTIONS.ATTACHMENT_PROPERTIES',
          cmd: 'versions_editprofile'
        },
        {
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'versions_email'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.DELETE',
          cmd: 'attachments_delete'
        }
      ]
    },
    // 37 MENU_MOBILE_ATTACHMENTS_ACTIONS - metadata attachments tab action bar
    {
      buttonIcon: 'ellipses_step_white.svg',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'add_item32.svg',
          name: 'METADATA.ATTACHMENTS_ACTIONS.ADD',
          cmd: 'attachments_new'
        },
        {
          iconic: true,
          icon: 'action_export24.svg',
          name: 'FOLDER_ACTIONS.OPEN',
          cmd: 'attachments_open'
        },
        {
          iconic: true,
          icon: 'action_download24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.EXPORT',
          cmd: 'versions_export'
        },
        {
          iconic: true,
          icon: 'action_publish24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.PUBLISH',
          cmd: 'versions_publish'
        },
        {
          iconic: true,
          icon: 'action_publish24.svg',
          name: 'METADATA.VERSIONS_ACTIONS.UNPUBLISH',
          cmd: 'versions_unpublish'
        },
        {
          iconic: true,
          icon: 'action_edit24.svg',
          name: 'METADATA.ATTACHMENTS_ACTIONS.ATTACHMENT_PROPERTIES',
          cmd: 'versions_editprofile'
        },
        {
          iconic: true,
          icon: 'toolbar_email24.svg',
          name: 'FOLDER_ACTIONS.EMAIL',
          cmd: 'versions_email'
        },
        {
          iconic: true,
          icon: 'delete_grey.svg',
          name: 'METADATA.VERSIONS_ACTIONS.DELETE',
          cmd: 'attachments_delete'
        }
      ]
    },
    // #38 MENU_OAI_ACTION_LIST add in action menu for lists
    {
      buttonIcon: 'add_item32.svg',
      drawToRight: false,
      allowChecks: true,
      items: [
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_TO_EDOCS',
          icon: 'upload24.svg',
          cmd: 'uploadofficeitem'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_PAPER_DOCUMENT',
          icon: 'upload24.svg',
          cmd: 'newpaperdocument'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'folder_add24.svg',
          cmd: 'newfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_FOLDER',
          icon: 'action_add_folder24.svg',
          cmd: 'createfolder'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_OBJECT',
          icon: 'action_add_folder24.svg',
          cmd: 'createobject'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_WORKSPACE',
          icon: 'action_add_workspace24.svg',
          cmd: 'newworkspace'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_DOCUMENTS_FOLDERS',
          icon: 'add_item32.svg',
          cmd: 'adddocuments'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_DOCUMENTS_FOLDERS_WORKSPACES',
          icon: 'add_item32.svg',
          cmd: 'additems'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_URL',
          icon: 'li_wiki_view_links24.svg',
          cmd: 'newurl'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_SEARCHES',
          icon: 'action_search24.svg',
          cmd: 'addquicksearch'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.ADD_FILEPART',
          icon: 'add_item32.svg',
          cmd: 'addfilepart'
        },
        {
          iconic: true,
          name: 'FORMS.LOCAL.SAVE_SEARCH.SAVE_SEARCH',
          icon: 'search24.svg',
          cmd: 'savesearch'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.EDIT_SECURITY',
          icon: 'security24.svg',
          cmd: 'doc_editsecurity'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.SET_SECURITY',
          icon: 'security24.svg',
          cmd: 'doc_setsecurity'
        },
        {
          iconic: true,
          name: 'FOLDER_ACTIONS.NEW_DOCUMENT',
          cmd: 'uploadfile',
          icon: 'upload24.svg'
        }
      ]
    },
    // #39 MENU_MOBILE_RELATED_ACTIONS - metadata related tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        { name: 'METADATA.RELATED_ACTIONS.ADD',
          iconic: true,
          icon: 'add_item32.svg',
          cmd: 'related_add'
        },
        {
          separator: true,
          cmd: 'related_separator'
        },
        { name: 'METADATA.RELATED_ACTIONS.INFO',
          iconic: true,
          icon: 'metadata.svg',
          cmd: 'related_info'
        },
        { name: 'FOLDER_ACTIONS.OPEN',
          iconic: true,
          icon: 'action_export24.svg',
          cmd: 'related_open'
        },
        { name: 'METADATA.RELATED_ACTIONS.REMOVE',
          iconic: true,
          icon: 'delete_grey.svg',
          cmd: 'related_remove'
        }
      ]
    },
    // #40 MENU_MOBILE_WHERE_USED_ACTIONS - metadata where used tab action bar
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        { name: 'METADATA.WHERE_USED_ACTIONS.ADD',
          iconic: true,
          icon: 'add_item32.svg',
          cmd: 'whereused_add'
        },
        {
          separator: true,
          cmd: 'whereused_separator'
        },
        { name: 'METADATA.WHERE_USED_ACTIONS.INFO',
          iconic: true,
          icon: 'metadata.svg',
          cmd: 'whereused_info'
        },
        { name: 'FOLDER_ACTIONS.OPEN',
          iconic: true,
          icon: 'action_export24.svg',
          cmd: 'whereused_open'
        },
        { name: 'METADATA.WHERE_USED_ACTIONS.REMOVE',
          iconic: true,
          icon: 'action_folder_remove_from24.svg',
          cmd: 'whereused_remove'
        }
      ]
    },
    // #41 MENU_ATTACHMENTS_SUB - metadata attachments tab new-item submenu
    {
      buttonIcon: 'add_item32.svg',
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          name: 'METADATA.ATTACHMENTS_ACTIONS.ADD',
          cmd: 'attachments_new'
        }
      ]
    },
    // #42 MENU_CONFIGURE_RED - submenu for customizing red contents
    {
      buttonIcon: 'filter.svg',
      buttonIconLight: 'filter24_li.svg',
      drawToRight: false,
      drawUp: false,
      allowChecks: true,
      allowEditChecks: true,
      noSelect: true,
      toolTip: 'FOLDER_ACTIONS.CONFIGURE_RED',
      items: []
    },
    // 43 MENU_HISTORY_ACTIONS
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'filter.svg',
          name: 'TOOLTIP.FILTER',
          cmd: 'showfilter'
        }
      ]
    },
    // 44 MENU_MOBILE_HISTORY_ACTIONS
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'filter.svg',
          name: 'TOOLTIP.FILTER',
          cmd: 'showfilter'
        }
      ]
    },
    // 45 MENU_VERSIONS_COMPARE
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: true,
      allowChecks: false,
      items: [
        {
          name: 'METADATA.VERSIONS_ACTIONS.COMPARE_GRAPHICAL',
          cmd: 'versions_compare'
        },
        {
          name: 'METADATA.VERSIONS_ACTIONS.COMPARE_REPORT',
          cmd: 'versions_comparereport'
        }
      ]
    },
    // #46 MENU_HELP_SETTINGS - help settings menu from top toolbar
    {
      buttonIcon: 'Help.svg',
      drawToRight: false,
      allowChecks: false,
      buttonIconSize: -2,
      items: [
        {
          name: 'HEADER.HELP',
          enabled: !Util.RestAPI.siteConfigurations.disableUserHelp,
          cmd: 'help'
        },
        {
          separator: true,
          enabled: true,
          cmd: 'separator'
        },
        {
          name: 'SETTINGS.ABOUT',
          enabled: true,
          cmd: 'about'
        },
        {
          separator: true,
          enabled: true,
          cmd: 'separator'
        },
        {
          name: 'FORMS.LOCAL.PREFERENCES.SHORTCUTS',
          enabled: true,
          cmd: 'shortcuts'
        }
      ]
    },
    // #47 MENU_PRIMARY_TILES - menu for primary tiles after expanded
    {
      buttonIcon: 'caret_down.svg',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          name: 'FOLDER_ACTIONS.ADD_TO_HOME',
          cmd: 'primary_tile_add'
        }
      ]
    },
    // #48 MENU_LOOKUP_ACTIONS - menu for Validation tables
    {
      buttonIcon: '',
      drawToRight: false,
      allowChecks: false,
      items: [
        {
          iconic: true,
          icon: 'filter.svg',
          name: 'TOOLTIP.FILTER',
          cmd: 'lookup_filter'
        },
        {
          iconic: true,
          icon: 'add_item32.svg',
          name: 'ADMIN.ACTIONS.ADD',
          cmd: 'lookup_add'
        },
        {
          separator: true,
          cmd: 'lookup_separator'
        },
        {
          name: 'FOLDER_ACTIONS.COPY',
          cmd: 'lookup_copy'
        },
        {
          name: 'FOLDER_ACTIONS_INLINE.EDIT',
          cmd: 'lookup_edit'
        }
      ]
    }
  ];

  getMenu(id: MenuId): MenuDef {
    let menuDef: MenuDef = this.menus[id];
    if (menuDef) {
      menuDef = Util.deepCopy(menuDef);
      if (!!menuDef.items && Util.Device.bIsOfficeAddinOutlookMessageEditable) {
        const attach = menuDef.items.find(i => i.icon === 'toolbar_email24.svg');
        if (!!attach) {
          attach.icon = 'add_attachment_md24.svg';
          attach.name = 'FOLDER_ACTIONS.ATTACH';
        }
      }
      const getAllCustomCommands = (type: string): void => {
        const allExtCmds: any = Util.RestAPI.getCustomCommands();
        const vendors: string[] = Object.keys(allExtCmds);
        let customStartIndex = type === 'container' ? menuDef.items.findIndex(m => m.cmd === 'records') : menuDef.items.length;
        for (const vendor of vendors) {
          const extCmds = allExtCmds[vendor];
          if (!!extCmds) {
            for (const cmd of extCmds) {
              if (cmd.type.indexOf(type) >= 0) {
                const key: string = cmd.cmd;
                let iconPath: string;
                if (!!cmd.icon) {
                  iconPath = Util.RestAPI.getServerURLOrigin() + '/custom/' + vendor + '/' + cmd.icon;
                }
                menuDef.items.splice(customStartIndex, 0, {name: cmd.label, icon: iconPath, cmd: '_custom_' + vendor + '_' + key});
                customStartIndex++;
              }
            }
          }
        }
      };
      if (id === MenuId.MENU_LIST_MAIN || id === MenuId.MENU_MOBILE_LIST_MAIN || id === MenuId.MENU_MOBILE_ACTION_LIST) {
        getAllCustomCommands('container');
      } else if (id === MenuId.MENU_METADATA_HEADER || id === MenuId.MENU_MOBILE_METADATA) {
        getAllCustomCommands('properties');
      } else if (id === MenuId.MENU_LIST_INLINE) {
        getAllCustomCommands('hover');
      } else if (id === MenuId.MENU_OAI_HAMBURGER && !Util.Device.bIsOfficeAddinWord) {
        menuDef.items = menuDef.items.filter(item => item.cmd !== 'footer_options');
      } else if (id === MenuId.MENU_COLUMN_LIST && Util.Device.bIsOfficeAddin) {
        menuDef.toolTip = 'TOOLTIP.CONFIGURE_LIST_VIEW';
      }
    }
    return menuDef;
  }
}
