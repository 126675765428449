import { _RestAPI } from './restful';
import { _Device } from './device';
import { _Notify } from './notify';
import { _Transforms } from './transforms';
import { _FieldMappings } from './field-mappings';
import { _Help } from './help';
import { ColumnDesc } from '../models/column';

/* eslint-disable prefer-arrow/prefer-arrow-functions */

export * from './restful';
export * from './device';
export * from './notify';
export * from './field-mappings';
export * from './help';

export class Util {
  static Device = new _Device();
  static FieldMappings = new _FieldMappings(Util.Device);
  static Transforms = new _Transforms(Util.Device);
  static Notify = new _Notify(Util.Transforms);
  static Help = new _Help('v230400', Util.Device);
  static RestAPI = new _RestAPI(Util.Device, Util.Notify, Util.Transforms, Util.Help);

  static kLocalFormPrefix = '__local_';
  static kCustomFormPrefix = '__custom_';
  static kMaxPopupDialogWidth = 1088;
  static kSearchFolderQuery = '&infolder=true';
  static kPopupDismissMS = 500;
  static kMaxActivityTypes = 152;
  static redundantPropertyPostfix = '!REDUNDANT';

  // to get around a circular reference between Util and RestAPI functions that RestAPI needs must be in RestAPI
  static isContainer = function(type: string): boolean {
    return this.RestAPI.isContainer(type);
  };
  static isContainerWithId = function(desc: any): boolean {
    return this.RestAPI.isContainerWithId(desc);
  };
  static isASearchContainer = function(desc): boolean {
    return this.RestAPI.isAContainerOfGivenType(desc, 'searches');
  };
  static isASearchContainerInRemoteLib = function(desc): boolean {
    return this.isASearchContainer(desc, 'searches') && desc['lib'] !== this.RestAPI.getPrimaryLibrary();
  };
  static isUrlItem = function(type): boolean {
    return type === 'urls';
  };
  static isPaperDocument = function(data): boolean {
    return !!data && data['type'] === 'documents' && data['STORAGE'] ==='P';
  };
  static isFlexfolderLevelNode = function(desc: any): boolean {
    return this.RestAPI.isFlexfolderLevelNode(desc);
  };
  static isSharedDownloads = function(desc: any): boolean {
    return this.RestAPI.isSharedDownloads(desc);
  };
  static isSharedImports = function(desc: any): boolean {
    return this.RestAPI.isSharedImports(desc);
  };
  static isUnmangedFile = function(desc: any): boolean {
    return this.RestAPI.isUnmangedFile(desc);
  };
  static dragHasFiles = function(dt: DataTransfer): boolean {
    return this.RestAPI.dragHasFiles(dt);
  };
  static dragHasJSON = function(dt: DataTransfer): boolean {
    return this.RestAPI.dragHasJSON(dt);
  };
  static dragHasPromisedFile = function(dt: DataTransfer): boolean {
    return this.RestAPI.dragHasPromisedFile(dt);
  };
  static getDropFiles = function (dt: DataTransfer, dropDesc?): Promise<any[]> {
    return this.RestAPI.getDropFiles(dt, dropDesc);
  };
  static getDropContents = function(dt: DataTransfer): Promise<any[]> {
    return this.RestAPI.getDropContents(dt);
  };
  static isExternalLib = function(lib: string): boolean {
    return this.RestAPI.isExternalLib(lib);
  };
  static isEmailField = function(name: string): boolean {
    return this.RestAPI.isEmailField(name);
  };
  static decodeFileName = function(fileName: string): any {
    return this.RestAPI.decodeFileName(fileName);
  };
  static deepCopy = function(data: any): any {
    return this.RestAPI.deepCopy(data);
  };
  static isItemInList = function(item: any, list: any[]): boolean {
    return this.RestAPI.isItemInList(item,list);
  };
  static isSameItem = function(item1: any, item2: any): boolean {
    return this.RestAPI.isItemInList(item1,[item2]);
  };
  static isSameDesc = function(desc1: any, desc2: any): boolean {
    return this.RestAPI.isSameDesc(desc1,desc2);
  };
  static getRootSiteUrl = function(): string {
    return this.RestAPI.getRootSiteUrl();
  };
  static rand = function(min: number, max: number): number {
    return this.RestAPI.rand(min, max);
  };
  // end

  static remsToPx = function(rems: number) {
    return rems * parseFloat(getComputedStyle(document.documentElement).fontSize);
  };
  static pxToRems = function(px: number) {
    return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
  };
  static pointInRect = function(locX: number, locY: number, x: number, y: number, w: number, h: number): boolean {
    if (locX >= x && locX <= (x+w) && locY >= y && locY <= (y+h)) {
      return true;
    }
    return false;
  };
  static removeLocalFormPrefix = function(name: string) {
    if (name.startsWith(Util.kLocalFormPrefix)) {
      return name.slice(Util.kLocalFormPrefix.length);
    }
    return name;
  };
  static removeCustomFormPrefix = function(name: string) {
  if (name.startsWith(Util.kCustomFormPrefix)) {
   return name.slice(Util.kCustomFormPrefix.length);
  }
  return name;
  };
  static findSelfOrParentWithClass = function(ele: any,aClass: string): any {
    while (ele) {
      if (ele.classList && ele.classList.contains(aClass)) {
        return ele;
      }
      ele = ele.parentElement;
    }
    return null;
  };
  static isFolder = function(type: string) {
    if (type==='folders'||type==='flexfolders'||type==='workspaces' || type ==='fileplans') {
      return true;
    }
    return false;
  };
  static isFolderWithId = function(desc: any) {
    if (this.isFolder(desc.type) && (desc.id.indexOf('FilePart') !== -1 || !isNaN((parseInt(desc.id))))) {
      return true;
    }
    return false;
  };
  static isUploadTarget = function(desc: any, set?: any) {
    const isExtAppTeams = Util.RestAPI.isExtAppTeams(desc.lib);
    const isTeamsAppUploadTarget = Util.RestAPI.isTeamsAppUploadTarget(desc, set);
    return (desc && desc.STATUS!=='19' && ((desc.type === 'folders' && desc.id!=='templates' && desc.id!=='deleted' && !isExtAppTeams) ||
                                           (desc.type === 'folders' && isExtAppTeams && isTeamsAppUploadTarget) ||
                                            desc.type === 'workspaces' ||
                                           (desc.type === 'flexfolders' && (!!desc.id && (desc.id.indexOf('LEVEL_NODE') > -1 ||
                                            desc.id.indexOf('ENUMERATION_NODE') > -1))) ||
                                           (desc.type === 'fileplans' && desc.id && desc.id.startsWith('FP-FilePart-') && desc['PD_ACTIVE_STATUS'] !== 'C')) && desc.id !== 'checkedout');
  };
  static isSaveToLocation = function(desc: any) {
    return (desc && desc.STATUS !== '19' && (((desc.type === 'folders' || desc.type === 'workspaces') && (!isNaN(parseInt(desc.id)))) || ((desc.type === 'flexfolders') && (!!desc.id && (desc.id.indexOf('LEVEL_NODE') > -1 || desc.id.indexOf('ENUMERATION_NODE') > -1))) || (desc.type === 'fileplans' && !!desc.id && desc.id.startsWith('FP-FilePart-'))));
  };
  static isExternalSaveToLocation = function(desc: any) {
    const extAppInfo: any = Util.RestAPI.findExternalApp(desc.lib);
    return (desc.type === 'folders' && !!extAppInfo && ((extAppInfo['access'].indexOf('copy-to') !== -1) || (extAppInfo['access'].indexOf('checkout-to') !== -1)));
  };
  static isDraggableItem = function(type: string) {
    if (type==='searches'||type==='flexfolders') {
      return false;
    }
    return true;
  };
  static isInteger = function(value: any) {
    if (value && value.length) {
      try {
        return (value%1===0 && value[0]!==' ');
      } catch (e) {}
    }
    return false;
  };
  static isDocIDs = function(value: any) {
    if (typeof value==='string') {
      let words: string[];
      let word = value.replace(/\s/g,'');
      if (word.indexOf(',')!==-1) {
        words = word.split(',');
      } else if (word.indexOf(';')!==-1) {
        words = word.split(';');
      } if (words && words.length) {
        for (word of words) {
          if (!Util.isInteger(word)) {
            return false;
          }
        }
        return true;
      }
    }
    return Util.isInteger(value);
  };
  static isRMFilePart = function(desc: any) {
    if (desc.type==='fileplans' && desc.id.startsWith('FP-FilePart')) {
      return true;
    }
    return false;
  };
  static isRMFile = function(desc: any) {
    if (desc.type==='fileplans' && desc.id.startsWith('FP-File') && !desc.id.startsWith('FP-FilePart')) {
      return true;
    }
    return false;
  };
  static isRMTerm = function(desc: any) {
    if (desc.type==='fileplans' && (desc.id.startsWith('FP-Term') || desc.id.startsWith('FP-NarrowerTerm'))) {
      return true;
    }
    return false;
  };
  static copyFieldIfExists = function(sorc: any,dest: any,field: string) {
    if (!!sorc[field]) {
      dest[field] = sorc[field];
    }
  };
  static versionFromLabel = function(versLabel: string) {
    const len=versLabel.length;
    let lastDigit=len-1;
    for (let i=len-1;i>=0;i--) {
      if (isNaN(parseInt(versLabel.charAt(i)))) {
        --lastDigit;
      } else {
        break;
      }
    }
    return versLabel.substring(0,lastDigit+1);
  };
  static canHaveSummary = function(desc: any) {
    return !!desc.id && desc.id!=='public' && (desc.type==='folders' || desc.type==='searches' || desc.type==='workspaces' || (desc.type==='fileplans' && desc.id.startsWith('FP-FilePart-')));
  };
  static mergeArrays = function(a: any[], b: any[]): any[] {
    return Array.from(new Set([...a, ...b]));
  };
  static mergeArraysNoDuplicates = function(baseSchema: any[], defCols: any[]): any[] {
    let merged = Array.from(new Set([...baseSchema, ...defCols]));
    merged = merged.filter((item: ColumnDesc, i: number, arr: any[]) =>
      arr.findIndex(ar => ar.property === item.property) === i
    );
   return merged;
  };
  static versionFloatFromLabel(versLabel: string): number {
    let rc = 0;
    if (!!versLabel) {
      const nVersLabel = versLabel.length;
      let mainVers = versLabel;
      let i: number;
      for (i=0; i<nVersLabel; i++) {
        if (isNaN(parseInt(versLabel.charAt(i)))) {
          mainVers = versLabel.substring(0, i);
          break;
        }
      }
      const nMainVers = mainVers.length;
      rc = parseInt(mainVers);
      if (nVersLabel !== nMainVers) {
        const subVersLC = versLabel.substring(nMainVers).toLocaleLowerCase();
        const charCode = subVersLC.charCodeAt(0);
        rc += ((charCode-('a'.charCodeAt(0)) + 1) * 0.1);
      }
    }
    return rc;
  };
}
