/**
 * Created by kevin on 2016-10-13.
 *
 * Service to provide display schema (column sets) for data tables
 *
 */

import { Injectable } from '@angular/core';
import { ColumnDesc, ColFormat } from '../models/column';
import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { BaseDesc } from '../models/base';

export enum SortControl {
  NONE = 0,
  LOCAL = 1,
  SERVER = 2
}

export const kDocNameMinWidth = 280;

const kSchemas: any = {
  // folders root and recentedits lists
  BASIC_LIST: {
    sortControl: SortControl.SERVER,
    sortDescending: 'LAST_EDIT_DATE',
    columns: [
      {
        label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: kDocNameMinWidth
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 7
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.MOD',
        property: 'LAST_EDIT_DATE',
        format: ColFormat.DATETIME,
        width: 12
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.AUTHOR',
        property: 'AUTHOR_ID',
        format: ColFormat.STRING,
        width: 10
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.STATUS',
        property: 'STATUS',
        format: ColFormat.STATUS,
        width: 10
      },
      {
        label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  PUBLIC_LIST: {
    sortControl: SortControl.SERVER,
    sortAscending: 'DOCNAME',
    columns: [
      {
        label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: kDocNameMinWidth
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 7
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.MOD',
        property: 'LAST_EDIT_DATE',
        format: ColFormat.DATETIME,
        width: 12
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.AUTHOR',
        property: 'AUTHOR_ID',
        format: ColFormat.STRING,
        width: 10
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.STATUS',
        property: 'STATUS',
        format: ColFormat.STATUS,
        width: 10
      },
      {
        label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  DELETE_LIST: {
    sortControl: SortControl.LOCAL,
    columns: [
      {
        label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3,
        nonsortable: true
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: kDocNameMinWidth,
        nonsortable: true
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 7,
        nonsortable: true
      }
    ]
  },
   // folder lists
   FOLDERS: {
    sortControl: SortControl.SERVER,
    sortDescending: 'LAST_EDIT_DATE',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: kDocNameMinWidth
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.MOD',
        property: 'LAST_EDIT_DATE',
        format: ColFormat.DATETIME,
        width: 12
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.AUTHOR',
        property: 'AUTHOR_ID',
        format: ColFormat.STRING,
        width: 10
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.STATUS',
        property: 'STATUS',
        format: ColFormat.STATUS,
        width: 10
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.VERSIONTYPE',
        property: 'VS.VERSION_LABEL',
        format: ColFormat.STRING,
        width: 10
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  SAVED_SEARCHES: {
    sortControl: SortControl.SERVER,
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DESCRIPTION',
        format: ColFormat.STRING,
        width: 25,
        minwidth: 280
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.SYSTEM_ID',
        property: 'SYSTEM_ID',
        format: ColFormat.NUMBER,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      }
    ]
  },
  CHECKED_OUT: {
    sortControl: SortControl.SERVER,
    sortDescending: 'CHECKOUT_DATE',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4,
        nonsortable: true
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: 400,
        nonsortable: true
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.CHECKED_OUT.DATE',
        property: 'CHECKOUT_DATE',
        format: ColFormat.DATETIME,
        width: 12
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.TYPIST',
        property: 'TYPIST_ID',
        format: ColFormat.STRING,
        width: 10,
        nonsortable: true
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  // search results
  SEARCH: {
    sortControl: SortControl.SERVER,
    columns: []
  },
  // list on history tab
  HISTORY: {
    sortControl: SortControl.LOCAL,
    sortDescending: 'START_DATE',
    notSelectable: true,
    readOnly: true,
    columns: [
      { label: 'COLUMN_HEADINGS.HISTORY.DATE',
        property: 'START_DATE',
        format: ColFormat.DATETIME,
        width: 30,
        minwidth: 165
      },
      { label: 'COLUMN_HEADINGS.HISTORY.ACTION',
        property: 'ACTIVITY_TYPE',
        format: ColFormat.ACTION,
        width: 30
      },
      { label: 'COLUMN_HEADINGS.HISTORY.VERSION',
        property: 'VERSION_LABEL',
        format: ColFormat.STRING,
        width: 10
      },
      { label: 'COLUMN_HEADINGS.HISTORY.AUTHOR',
        property: 'TYPIST_ID',
        format: ColFormat.STRING,
        width: 30
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  // list on versions tab
  VERSIONS: {
    sortControl: SortControl.LOCAL,
    sortDescending: 'VERSION_LABEL',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.VERSION',
        property: 'VERSION_LABEL',
        format: ColFormat.NUMBER,
        width: 10,
        minwidth: 86
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.EDITED',
        property: 'LASTEDITDATE',
        format: ColFormat.DATETIME,
        width: 30
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.STATUS',
        property: 'STATUS',
        format: ColFormat.STATUS,
        width: 20
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.SIZE',
        property: 'FILE_SIZE',
        format: ColFormat.NUMBER,
        width: 10,
        minwidth: 86
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.AUTHOR',
        property: 'AUTHOR_ID',
        format: ColFormat.STRING,
        width: 18
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.TYPIST',
        property: 'TYPIST_ID',
        format: ColFormat.STRING,
        width: 18
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'FILE_EXTENSION',
        format: ColFormat.STRING,
        width: 10,
        minwidth: 86
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  // list on Attachments tab
  ATTACHMENTS: {
    sortControl: SortControl.LOCAL,
    sortDescending: 'LASTEDITDATE',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'VERSION_LABEL',
        format: ColFormat.STRING,
        width: 10,
        minwidth: 86
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.MOD',
        property: 'LASTEDITDATE',
        format: ColFormat.DATETIME,
        width: 30
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.STATUS',
        property: 'STATUS',
        format: ColFormat.STATUS,
        width: 20
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.SIZE',
        property: 'FILE_SIZE',
        format: ColFormat.NUMBER,
        width: 10,
        minwidth: 86
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.AUTHOR',
        property: 'AUTHOR_ID',
        format: ColFormat.STRING,
        width: 10
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  // list on related items tab
  RELATED: {
    sortControl: SortControl.LOCAL,
    sortAscending: 'DOCNAME',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 60,
        minwidth: 300
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 7
      },
      { label: 'COLUMN_HEADINGS.RELATED.LOCATION',
        property: 'lib',
        format: ColFormat.PATH,
        width: 25
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  // multiselect items table on checkout form
  FORM_DOCUMENT_LIST: {
    sortControl: SortControl.NONE,
    name: 'DOCUMENTS',
    columns: [
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 8
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 36
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'id',
        format: ColFormat.STRING,
        width: 15
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.MOD',
        property: 'LAST_EDIT_DATE',
        format: ColFormat.DATETIME,
        width: 40
      }
    ]
  },
  // multiselect items table on RM form
  RM_DOCUMENT_LIST: {
    sortControl: SortControl.NONE,
    name: 'DOCUMENTS',
    columns: [
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 8
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 36
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.ITEMID',
        property: 'DOCNUM',
        format: ColFormat.STRING,
        width: 15
      }
    ]
  },
  // Metadata Security tab
  SECURITY: {
    sortControl: SortControl.LOCAL,
    sortAscending: 'USER_ID',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 7,
        minwidth: 36
      },
      { label: 'COLUMN_HEADINGS.SECURITY.TRUSTEES',
        property: 'USER_ID',
        format: ColFormat.STRING,
        width: 40
      },
      { label: 'COLUMN_HEADINGS.SECURITY.FULL_NAME',
        property: 'FULL_NAME',
        format: ColFormat.STRING,
        width: 40
      },
      { label: 'COLUMN_HEADINGS.SECURITY.ACCESS',
        property: 'rights',
        format: ColFormat.EDITRIGHTS,
        width: 40
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 6
      }
    ]
  },
   // Metadata Security tab
  ACCESS_CONTROL: {
    sortControl: SortControl.LOCAL,
    sortAscending: 'USER_ID',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 7,
        minwidth: 36
      },
       { label: 'COLUMN_HEADINGS.SECURITY.TRUSTEES',
        property: 'USER_ID',
        format: ColFormat.STRING,
        width: 40
      },
      { label: 'SECURITY.RIGHTS.VIEW_PROFILE',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_view_profile24.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.EDIT_PROFILE',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_edit_profile24.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.VIEW_DOCUMENT',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_view_document24.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.RETRIEVE_DOCUMENT',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_view_document_retrieve24.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.EDIT_CONTENT',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_edit_content4.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.COPY',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_document_copy24.svg',
        nonsortable: true
      },
       { label: 'SECURITY.RIGHTS.DELETE',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_delete24.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.CONTROL_ACCESS',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_control_access24.svg',
        nonsortable: true
      },
      { label: 'SECURITY.RIGHTS.ALLOW_VIEW_PUBLISHED',
        property: 'rights',
        format: ColFormat.RIGHTS,
        width: 10,
        img:'assets/images/col_hdr_view_published24.svg',
        nonsortable: true
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 6
      }
    ],
    columnHeadings: [
      {
        colspan: 5,
        label: ''
      },
      {
        colspan: 3,
        label: 'SECURITY.RIGHTS_GROUPS.VIEW',
        bottomcolor: '#11609A'
      },
      {
        colspan: 3,
        label: 'SECURITY.RIGHTS_GROUPS.EDIT',
        bottomcolor: '#FBDBA2'
      },
      {
        colspan: 3,
        label: 'SECURITY.RIGHTS_GROUPS.MANAGE',
        bottomcolor: '#545454'
      }
    ]
  },
  // Metadata Where Used tab
  WHERE_USED: {
    sortControl: SortControl.LOCAL,
    sortAscending: 'DOCNAME',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 5
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 5
      },
      { label: 'COLUMN_HEADINGS.WHERE_USED.PARENT',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 30,
        minwidth: kDocNameMinWidth
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.VERSION',
        property: 'VS.VERSION_LABEL',
        format: ColFormat.STRING,
        width: 10
      },
      { label: 'COLUMN_HEADINGS.WHERE_USED.PATH',
        property: 'lib',
        format: ColFormat.PATH,
        width: 15
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  // list on versions tab
  DOWNLOAD: {
    sortControl: SortControl.NONE,
    name: 'DOCUMENTS',
    columns: [
      { label: 'COLUMN_HEADINGS.VERSIONS.VERSION',
        property: 'VERSION_LABEL',
        format: ColFormat.NUMBER,
        width: 10
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.EDITED',
        property: 'LASTEDITDATE',
        format: ColFormat.DATETIME,
        width: 30
      },
      { label: 'COLUMN_HEADINGS.VERSIONS.TYPIST',
        property: 'TYPIST_ID',
        format: ColFormat.STRING,
        width: 18
      }
    ]
  },
  // list on file transfer progress
  FILE_TRANSFER: {
    sortControl: SortControl.NONE,
    name: 'DOCUMENTS',
    columns: [
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 5
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 48
      },
      { label: 'COLUMN_HEADINGS.FILE_TRANSFER.PROGRESS',
        property: '$edx_progress',
        format: ColFormat.PROGRESS,
        width: 44
      }
    ]
  },
  // FLEXFOLDERS Root Levels
  FLEXFOLDERS_ROOT: {
    sortControl: SortControl.NONE,
    sortAscending: 'DOCNAME',
    columns: [
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: kDocNameMinWidth
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      }
    ]
  },
  // FLEXFOLDERS lists
  FLEXFOLDERS: {
    sortControl: SortControl.NONE,
    sortAscending: 'DOCNAME',
    columns: [
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25,
        minwidth: kDocNameMinWidth
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  FILEPLAN_ROOT: {
    sortControl: SortControl.SERVER,
    sortAscending: 'DOCNAME',
    columns:[
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.FILEPLANS.DISPLAYNAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 20,
        minwidth: kDocNameMinWidth
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.LIBRARY',
        property: 'lib',
        format: ColFormat.STRING,
        width: 7
      }
    ]
  },
  FILEPLAN_TERMFILE: {
    sortControl: SortControl.SERVER,
    sortAscending: 'DOCNAME',
    columns:[
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 8
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.DISPLAYNAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 20,
        minwidth: kDocNameMinWidth
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.DISPOSAL',
        property: 'PD_ACTION_NAME',
        format: ColFormat.STRING,
        width: 8
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.CUTOFFROLLOVER',
        property: 'PD_ROLLOVER_NAME',
        format: ColFormat.STRING,
        width: 26
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.AUTHORITY',
        property: 'PD_AUTHORITY_NAME',
        format: ColFormat.STRING,
        width: 15
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.STORAGE',
        property: 'PD_SCHEDULE_NAME',
        format: ColFormat.STRING,
        width: 10
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.FILESERIES',
        property: 'PD_FILE_TYPE',
        format: ColFormat.STRING,
        width: 20
      }
    ]
  },
  FILEPLAN_FILEPART: {
    sortControl: SortControl.SERVER,
    sortAscending: 'DOCNAME',
    columns:[
      {
        label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.DISPLAYNAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 15,
        minwidth: kDocNameMinWidth
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.LOCATION',
        property: 'PD_LOCATION_CODE',
        format: ColFormat.STRING,
        width: 15
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.OPENDATE',
        property: 'PD_POPEN_DATE',
        format: ColFormat.STRING,
        width: 15
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.PARTSTATUS',
        property: 'PD_PART_STATUS',
        format: ColFormat.STRING,
        width: 15
      }
    ]
  },
  FILEPLAN_DOCUMENT: {
    sortControl: SortControl.SERVER,
    sortDescending: 'LAST_EDIT_DATE',
    columns:[
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.DOCNUM',
        property: 'DOCNUM',
        format: ColFormat.STRING,
        width: 10
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.DOCNAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 15
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.LAST_EDIT_DATE',
        property: 'LAST_EDIT_DATE',
        format: ColFormat.DATETIME,
        width: 10
      },
      {
        label: 'COLUMN_HEADINGS.FILEPLANS.AUTHOR',
        property: 'AUTHOR_ID',
        format: ColFormat.STRING,
        width: 15
      },
      { label: '',
        property: 'expand',
        format: ColFormat.EXPANDER,
        width: 5
      }
    ]
  },
  FILEPLAN_REQUESTS: {
    sortControl: SortControl.SERVER,
    sortDescending: 'RQ.PD_REQUEST_DATE',
    columns:[
      { label: '',
        property: 'select',
        format: ColFormat.SELECTOR,
        width: 3
      },
      {
        label: 'COLUMN_HEADINGS.REQUESTS.TYPE',
        property: 'PD_OBJ_TYPE',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      {
        label: 'COLUMN_HEADINGS.REQUESTS.DESCRIPTION',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width:18
      },
      {
        label: 'COLUMN_HEADINGS.REQUESTS.ITEM_NO',
        property: 'DOCNUM',
        format: ColFormat.STRING,
        width: 7
      },
      {
        label: 'COLUMN_HEADINGS.REQUESTS.REQUEST_DATE',
        property: 'PD_REQUEST_DATE',
        format: ColFormat.DATETIME,
        width: 12
      },
      {
        label: 'COLUMN_HEADINGS.REQUESTS.NOT_REQUIRED',
        property: 'PD_EXPIRE_DATE',
        format: ColFormat.DATETIME,
        width: 12
      },
      {
        label: 'COLUMN_HEADINGS.REQUESTS.BORROWER',
        property: 'BORROWER',
        format: ColFormat.STRING,
        width: 15
      }
    ]
  },
  APP_ID_FORMS: {
    sortControl: SortControl.NONE,
    sortDescending: 'DOCNAME',
    notSelectable: true,
    columns:[
      {
        label: 'COLUMN_HEADINGS.DEFAULT.DEFAULT',
        property: 'default_form',
        format: ColFormat.CHECKBOX,
        width: 5
      },
      {
        label: 'PROFILE_DEFAULTS.FORM_NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 12
      },
      {
        label: 'PROFILE_DEFAULTS.FORM_DESC',
        property: '%FORM_TITLE',
        format: ColFormat.STRING,
        width: 20
      },
      {
        label: 'COLUMN_HEADINGS.DEFAULT.ITEMID',
        property: 'DOCNUM',
        format: ColFormat.STRING,
        width: 10
      }
    ]
  },
  LIBRARY_MATRIX: {
    sortControl: SortControl.NONE,
    notSelectable: true,
    columns:[
      {
        label: 'FORMS.LOCAL.PREFERENCES.LIBRARY_NAME',
        property: 'lib',
        format: ColFormat.STRING,
        width: 12
      },
      {
        label: 'FORMS.LOCAL.PREFERENCES.REMOTE',
        property: 'remote',
        format: ColFormat.CHECKBOX,
        width: 9
      },
      {
        label: 'FORMS.LOCAL.PREFERENCES.SEARCH',
        property: 'search',
        format: ColFormat.CHECKBOX,
        width: 5
      }
    ]
  },
  ACTIVITY: {
    sortControl: SortControl.LOCAL,
    sortDescending: 'START_DATE',
    columns: [
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.HISTORY.ACTION',
        property: 'ACTIVITY_TYPE',
        format: ColFormat.ACTION,
        width: 30
      },
      { label: 'COLUMN_HEADINGS.HISTORY.DATE',
        property: 'START_DATE',
        format: ColFormat.DATETIME,
        width: 30
      }
    ]
  },
  ACTIVITY_AUTHOR: {
    sortControl: SortControl.LOCAL,
    sortDescending: 'START_DATE',
    columns: [
      {
        label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 4
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.NAME',
        property: 'DOCNAME',
        format: ColFormat.STRING,
        width: 25
      },
      { label: 'COLUMN_HEADINGS.HISTORY.DATE',
        property: 'START_DATE',
        format: ColFormat.DATETIME,
        width: 30
      }
    ]
  },
  MASSPROFILE_RESULTS: {
    sortControl: SortControl.NONE,
    name: 'DOCUMENTS',
    columns: [
      { label: 'COLUMN_HEADINGS.DEFAULT.TYPE',
        property: 'APP_ID',
        format: ColFormat.OBJTYPE,
        width: 8
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.DOCID',
        property: 'DOCNUM',
        format: ColFormat.NUMBER,
        width: 12
      },
      { label: 'FORMS.LOCAL.MASS_PROFILE.CODE',
        property: 'error.code',
        format: ColFormat.NUMBER,
        width: 30
      },
      { label: 'COLUMN_HEADINGS.DEFAULT.STATUS',
        property: 'error.message',
        format:  ColFormat.STRING,
        width: 50
      }
    ]
  }
};

export interface ColumnHeading {
  colspan: number;
  label: string;
  bottomcolor?: string;
}

export class SchemaDef {
  sortControl: SortControl;
  sortAscending?: string;
  sortDescending?: string;
  name?: string;
  columns: ColumnDesc[];
  leadingCols?: number[];
  columnHeadings?: ColumnHeading[];
  notSelectable?: boolean;
  readOnly?: boolean;
  filterFormTemplate?: any;
}

@Injectable()
export class SchemaService {
  private bLoadedCustomSchemas = false;
  private customSchemas: any = {};
  private lookupSchemas: any = {};
  private serverSchemas: any = {};
  private vendorSchemas: any = {};

  constructor(private localizer: LocalizeService) {
    this.loadCustomSchemas();
  }

  private makeLookupID(id: string, formType: string, lookupKey: string): string {
    return id + '_' + formType + '_' + lookupKey;
  }

  private makeCustomKey(id: string, library: string,form: string): string {
    const aLib: string = library ? library.toUpperCase() : Util.RestAPI.getPrimaryLibrary();
    return id + '_' + aLib + '_' + form;
  }

  private loadCustomSchemas(): void {
    const customSchemas: string = localStorage.getItem('custom_schemas');
    if (customSchemas && customSchemas.length) {
      this.customSchemas = JSON.parse(customSchemas);
    }
    this.bLoadedCustomSchemas = true;
  }

  private getVendorSchema(vendor: string, id: string): any {
    return !!this.vendorSchemas[vendor] ? this.vendorSchemas[vendor][id] : null;
  }

  private getFavoriteColumn(): ColumnDesc {
    return {
      label: '',
      property: 'is_favorite',
      format: ColFormat.OBJTYPE,
      width: 3,
      nonsortable: true
    };
  }

  private getFavoriteColumnIndex(columns: ColumnDesc[]) {
    return !!columns && columns.findIndex(col => this.isFavoriteColumn(col.property));
  }

  private addOrRemoveFavoriteColumn(columns: ColumnDesc[], schemaId: string, desc?: BaseDesc): void {
    if (Util.RestAPI.IsFavoritesEnabled() && this.isFavoriteSchema(schemaId)) {
      const favColIndex = this.getFavoriteColumnIndex(columns);
      if (Util.isASearchContainerInRemoteLib(desc)) {
        if (favColIndex >= 0) {
          columns.splice(favColIndex,1);
        }
      } else if (favColIndex === -1) {
        const expandColIndex = columns.findIndex(c => c.property === 'expand');
        if (expandColIndex !== -1) {
          columns.splice(expandColIndex, 0, this.getFavoriteColumn());
        } else {
          columns.push(this.getFavoriteColumn());
        }
      }
    }
  }

  private finishGetSchema(id: string, def: any, desc: BaseDesc, bBasicSearch: boolean): any {
    if (!!def) {
      def = Util.deepCopy(def);
      this.addOrRemoveFavoriteColumn(def.columns, id, desc);
      let col = def.columns.find(c => c.property === 'PARENTMAIL_ID');
      if (col) {
        col.label = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.PARENTMAIL_ID');
      }
      if (bBasicSearch) {
        const relevanceColIndex = def.columns.length - this.ignoreColumnCountAtLast(def.columns);
        def.columns.splice(relevanceColIndex, 0, {
          label: 'COLUMN_HEADINGS.DEFAULT.RELEVANCE',
          property: 'relevance',
          format: ColFormat.PROGRESS,
          width: 10
        });
      } else if (id === 'APP_ID_FORMS' && Util.Device.isPhoneLook()) {
        def.columns.forEach(column => {
          if (column.property === 'DOCNAME') {
            column.label = 'PROFILE_DEFAULTS.FORM_NAME_SHORT';
          } else if (column.property === '%FORM_TITLE') {
            column.label = 'PROFILE_DEFAULTS.FORM_DESC_SHORT';
          }
        });
      }
      if (!!desc) {
        if (desc.type === 'folders') {
          if (desc.id === 'templates' || desc.id === 'all') {
            col = def.columns.find(c => c.property === 'lib');
            if (col) {
              col.nonsortable = true;
            }
          } else if (desc.id === 'downloads' || desc.id === 'imports') {
            col = def.columns.find(c => c.property === 'LAST_EDIT_DATE');
            if (col) {
              col.label = 'COLUMN_HEADINGS.DOWNLOADS.MOD';
            }
          }
        } else if (desc.type === 'activities') {
          const parts = desc.id.split('-');
          if (!isNaN(parseInt(parts[parts.length-1]))) {
            col = def.columns.find(c => c.property === 'LAST_EDIT_DATE');
            if (col) {
              col.label = 'COLUMN_HEADINGS.ACTIVITY.START_DATE';
              col.property = 'START_DATE';
            }
          }
          if (Util.Device.isMobile()) {
            def.columns.splice(0, 0, {
              label: '',
              property: 'select',
              format: ColFormat.SELECTOR,
              width: 3
            });
          }
        }
      }
    }
    if (id === 'SECURITY' && Util.RestAPI.getSystemDefaultSetting('SHOW_FULLNAME_SECURITY') === 'N') {
      const fullNameColumnIndex = def.columns.findIndex(column => column.property === 'FULL_NAME');
      if (fullNameColumnIndex > -1) {
        def.columns.splice(fullNameColumnIndex, 1);
      }
    }
    return def;
  }

  public isFavoriteColumn(columnProperty): boolean {
    return columnProperty === 'is_favorite';
  }

  public isFavoriteSchema(schemaId): boolean {
    if (schemaId === 'BASIC_LIST' || schemaId === 'PUBLIC_LIST' || schemaId === 'FOLDERS' ||
      schemaId === 'SAVED_SEARCHES' || schemaId === 'CHECKED_OUT' || schemaId === 'FILEPLAN_FILEPART' ||
      schemaId === 'FILEPLAN_DOCUMENT') {
      return true;
    }
    return false;
  }

  public ignoreColumnCountAtLast(schemaCols): number {
    if (Util.RestAPI.IsFavoritesEnabled() && this.getFavoriteColumnIndex(schemaCols) !== -1) {
      return 2; // expand and is_favorite columns
    }
    return 1;
  }

  public clearCache(): void {
    this.bLoadedCustomSchemas = false;
    this.customSchemas = {};
    this.lookupSchemas = {};
    this.serverSchemas = {};
  }

  public getSchema(id: string, lib: string, form: string, desc: BaseDesc): SchemaDef {
    if (!this.bLoadedCustomSchemas) {
      this.loadCustomSchemas();
    }
    let bBasicSearch = false;
    const key: string = this.makeCustomKey(id, lib, form || '');
    let def: SchemaDef = this.customSchemas[key];
    if (!def) {
      def = kSchemas[key];
      if (!def) {
        if (id==='SEARCHES') { // allow for a custom schema for searches
          id = 'BASIC_LIST';  // but if one does not exist fall back to basic list
          bBasicSearch = true;
        }
        def = kSchemas[id];
      }
    }
    if (!def && id.startsWith(Util.kCustomFormPrefix)) {
      let schemaName = Util.removeCustomFormPrefix(id);
      const firstUS: number = schemaName.indexOf('_');
      const vendor: string = schemaName.substring(0, firstUS);
      schemaName = schemaName.substr(firstUS+1);
      def = this.getVendorSchema(vendor, schemaName);
    }
    return this.finishGetSchema(id, def, desc, bBasicSearch);
  }

  private getLookupSchemaKey(id: string, library: string, form: string, formType: string, lookupKey: string): string {
    let theId = id;
    if (!!lookupKey) {
      theId = this.makeLookupID(id, formType, lookupKey);
    }
    const key: string = this.makeCustomKey(theId, library, form || '');
    return key;
  }

  public getLookupSchema(id: string, library: string, form: string, formType: string, lookupKey: string): SchemaDef {
    const key: string = this.getLookupSchemaKey(id, library, form, formType, lookupKey);
    return this.finishGetSchema(key, this.lookupSchemas[key], null, false);
  }

  public saveCustomSchema(def: SchemaDef, id: string, library: string, form: string, formType: string, lookupKey: string): void {
    const key: string = this.getLookupSchemaKey(id, library, form, formType, lookupKey);
    this.customSchemas[key] = def;
    localStorage.setItem('custom_schemas', JSON.stringify(this.customSchemas));
  }

  public hasCustomSchema(id: string, lib: string, form: string=''): boolean {
    const key: string = this.makeCustomKey(id, lib, form);
    return !!this.customSchemas[key];
  }

  public buildLookupSchema(id: string, library: string, form: string, data: any, leadingColums: number[], formType: string, lookupKey: string): SchemaDef {
    const def: SchemaDef = { sortControl: SortControl.LOCAL, name: data.LOOKUPID, columns:[] };
    const items: any[] = data.list && data.list.length ? data.list[0].item : null;
    const nList: number = items ? items.length : 0;
    let i: number;
    for (i=0; i<nList; i++) {
      const item: any = items[i];
      if (item.VISIBLE && !!item.TITLE) {
        const desc: ColumnDesc = {
          label: item.TITLE,
          property: Util.FieldMappings.mapLookupColumnProperty(item.PROPNAME),
          format: ColFormat.STRING,
          width: item.PROPNAME.indexOf('ID')>=0 ? 40 : 80
        };
        def.columns.push(desc);
      }
    }
    if (leadingColums && leadingColums.length) {
      def.leadingCols = leadingColums;
      for (i=leadingColums.length-1; i>=0; i--) {
        if (leadingColums[i]===ColFormat.SELECTOR) {
          def.columns.unshift({
            label: '',
            property: 'select',
            format: ColFormat.SELECTOR,
            width: 3,
            nonsortable: true
          });
        } else if (leadingColums[i]===ColFormat.OBJTYPE) {
          def.columns.unshift({
            label: '',
            property: 'APP_ID',
            format: ColFormat.OBJTYPE,
            width: 4,
            nonsortable: true
          });
        }
      }
    }
    let keyCol = def.columns.find(c => c.property===lookupKey);
    if (!keyCol) {
      const nCols = def.columns.length;
      for (i=0; i<nCols; i++) {
        keyCol = def.columns[i];
        const alias = Util.FieldMappings.aliasToField(keyCol.property);
        if (alias === lookupKey) {
          keyCol.property = lookupKey;
          break;
        }
      }
    }
    const key: string = this.makeCustomKey(this.makeLookupID(id,formType,lookupKey),library,form);
    this.lookupSchemas[key] = def;
    return def;
  }

  private buildAllColumns(data: any): ColumnDesc[] {
    const cols: ColumnDesc[] = [];
    const keys: string[] = Object.keys(data);
    for (const key of keys) {
      const col: any = data[key];
      const type: number = parseInt(col['type']);
      if (type === 1 || type === 0 || type === 4 || type === 8 || type === ColFormat.CHECKBOX || type === ColFormat.RADIO) {
        let label: string = col['label'];
        if (label.startsWith('IDS_') || label.startsWith('COLUMN_HEADINGS')) {
          label = this.localizer.getTranslation(label);
          label = label.split(';')[0];
        }
        cols.push({
          label,
          property: key,
          format: type === 8 ? ColFormat.DATETIME : type === 4 ? key === 'STATUS' ? ColFormat.STATUS : key === 'relevance' ? ColFormat.PROGRESS : ColFormat.NUMBER : type === 1 ? ColFormat.DATE : ColFormat.STRING,
          width: type === 8 ? 12 : (type === 4 && key !== 'STATUS') ? 4 : 10,
          minwidth: (key === 'DOCNAME' || key === 'DESCRIPTION') ? kDocNameMinWidth : -1,
          displayMap: Util.Transforms.splitFormatOptions(col.format, key)
        });
      }
    }
    return cols;
  }

  public getAllColumns(formName: string, library: string): Promise<ColumnDesc[]> {
    library = library ? library.toUpperCase() : Util.RestAPI.getPrimaryLibrary();
    return Util.RestAPI.getColumns(formName, library).toPromise().then((data: any) => {
      let cols: ColumnDesc[];
      if (data.cols) {
        cols = data.cols;
      } else {
        cols = this.buildAllColumns(data);
        Util.RestAPI.cacheColumns(formName, library, JSON.stringify({cols}));
      }
      for (const col of cols.filter(c => c.property === 'PARENTMAIL_ID' || (c.property === 'ITEM_TYPE' && c.label === 'ITEM_TYPE'))) {
        if (col) {
          col.label = this.localizer.getTranslation('COLUMN_HEADINGS.DEFAULT.' + col.property);
        }
      }
      return cols;
    }).catch( error => null);
  }

  public getSchemaFromServer(desc: any, form: string=''): Promise<SchemaDef> {
    const key: string = this.makeCustomKey(desc.id,desc.lib,form);
    let def: SchemaDef = this.serverSchemas[key];
    if (def) {
      return Promise.resolve(def);
    }
    return Util.RestAPI.getSchema(desc, form).toPromise().then((data: any) => {
      def = data;
      this.serverSchemas[key] = def;
      return def;
    }, err => null);
  }

  public addVendorSchemas(vendor: string, schemas: any): void {
    this.vendorSchemas[vendor] = schemas;
  }
}
