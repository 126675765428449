
import { OnChanges, Input, Component, ElementRef } from '@angular/core';
import { FormField, RadioGroupInterface, RadioFieldInterface } from '../models/form-field';
import { FormGroup } from '@angular/forms';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';

@Component({
  selector: 'edx-radio-field',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
    <div [formGroup]="form" class="controlcontainer columnview fullwidth" [title]="label">
      <div class="checkboxlabel" [ngClass]="{readonly:viewReadonly, indialog:inDialog}">
        <input [formControlName]="field.name" value="{{value}}" type="field.isRGCheckboxes?'checkbox':'radio'" [ngClass]="{checkbox:field.isRGCheckboxes, radio:!field.isRGCheckboxes, checked:isChecked}" tabindex="-1">
        <span class="checklabel" [id]="radioId" [tabindex]="viewReadonly?-1:tabIndex" [attr.role]="field.isRGCheckboxes?'checkbox':'radio'" [attr.aria-label]="isChrome?(isChecked?label:label+' '):null" [attr.aria-checked]="isChecked" (click)="rbClicked($event)" (keydown.space)="rbClicked($event)" (keyup.ArrowUp)="radioGroup.shiftRadioFocus($event,itemIndex-1)" (keyup.ArrowDown)="radioGroup.shiftRadioFocus($event,itemIndex+1)">{{label}}</span>
      </div>
    </div>
  `
})
export class RadioFieldComponent implements OnChanges, RadioFieldInterface {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() value: string;
  @Input() label: string;
  @Input() radioGroup: RadioGroupInterface;
  @Input() parent?: DynamicFormFieldComponent;
  @Input() inDialog?: boolean = false;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() itemIndex: number;
  @Input() radioId: string;
  @Input() tabIndex = 0;
  public isChecked = false;
  public viewReadonly = false;
  public isChrome = false;

  constructor(private localizer: LocalizeService) { }

  ngOnChanges() {
    let value: string = this.form.controls[this.field.name].value;
    value = value ? value.toString() : null;
    this.isChecked = (this.value === value) || (this.value === 'null' && value == null);
    this.viewReadonly = this.field.isReadonly || this.formReadonly;
    this.isChrome = Util.Device.bIsChrome;
  }

  public rbClicked(event: Event): boolean {
    if (!!event && event.type === 'keydown') {
      event.preventDefault();
    }
    if (this.parent && !this.viewReadonly && !this.isChecked) {
      this.isChecked = true;
      this.radioGroup.radioButtonOn(this, this.value);
    }
    return true;
  }

}
