import { ListItem } from './list-item';

export type tFilePickCB = (files: File[], paths: string[], success: boolean) => void;
export type tListPickCB = (list: any[], success: boolean) => void;
export type tListItemCB = (item: ListItem) => void;

export class FileFormInfo {
  public forms: string[];
  public profile_defaults: any[];
  public trustees: any[];
  public appID: string;
  public defForm: string;
}

export class AppIDInfo {
  public profile_defaults: any;
  public trustees: any[];
}

export class DataFile {
  public name;
  public size?: number;
  public lastModified?: string;
  public excludeAttachments?: boolean;
  public fromSaveAsDesc?: any;    // the desc this save as was from
  public attachNum?: number;      // 1st attachment is 1, 0 or undefined not an attachment
  public b64Data?: string;        // either a file encoded b64
  public url?: string;            // or
  public headers?: any;           // an url with headers for the RestAPI to fetch
  public officeDoc?: any;         // or an Office.context.document to get an Office.File that can be queried for bytes
  public templateDesc?: any;      // the user is trying to use a template, we need a profile first and then the PFTA does the upload
  public externalName?: any;      // file name on external application
  public listItemCB?: tListItemCB;// when a save as completes, this is the new DM Item
  public mailID?: string;
}

