<div *ngIf="ui<2" class="window-modal-admin window-modal" [ngClass]="{web:ui==0, desktop:ui==1}" (keyup.enter)="rightHdrBtnClick()" (keyup.escape)="cancelHdrBtnClick()">
  <edx-window-modal-header *ngIf="ui<2" [title]="getName()" [desc]="desc" [rightBtn]="showSave?saveStr:showReset?resetStr:null" [showCancel]="showSave" (rightHdrBtnClick)="rightHdrBtnClick()" (cancelHdrBtnClick)="cancelHdrBtnClick()"></edx-window-modal-header>
  <ng-template [ngIf]="!showSpinner">
    <div class="tabcontainer">
      <edx-tab-selector #tabSelector [receiver]="this" [tabdefs]="tabList" [disable]="showSave"></edx-tab-selector>
    </div>
    <div [ngSwitch]="tabId" class="contentpanel admincontainer">
      <div *ngSwitchCase="'lookuptables'" class="adminlistcontainer">
        <ng-template [ngIf]="ui<2">
          <div class="left">
            <div *ngIf="!showFilter">
              <ng-container *ngTemplateOutlet="libraryPane"></ng-container>
            </div>
            <edx-filelist-sidebar *ngIf="!showFilter" [receiver]="this" [list]="leftPaneList" [selectIndex]="currentListIndex" [desc]="desc" [disabled]="disabledNav" [tabIndex]="0"></edx-filelist-sidebar>
            <edx-filter-sidebar  [target] = "this"></edx-filter-sidebar>
          </div>
          <div class="right">
            <edx-action-bar #actionbar [menuID]="actionId" [target]="this"></edx-action-bar>
            <div class="listcontainer" id="edx_admin_list_container" tabindex="0">
              <edx-list-table #lookupTable [parent]="this" [desc]="lookupDesc" [lookupForm]="lookupForm" [lookupKey]="lookupKey"
                [formType]="lookupFormType" [leadingColums]="lookupLeadingColums" [inlineActionMenuId]="6"
                [inlineActionTarget]="this"></edx-list-table>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngSwitchCase="'grouptiles'" class="adminlistcontainer">
        <ng-template [ngIf]="ui<2">
          <div class="left">
            <ng-container *ngTemplateOutlet="libraryPane"></ng-container>
            <edx-filelist-sidebar [receiver]="this" [list]="leftPaneList" [selectIndex]="currentListIndex" [desc]="desc" [disabled]="disabledNav" [tabIndex]="0"></edx-filelist-sidebar>
          </div>
          <div class="right" *ngIf="rightPaneDataLoaded">
            <div class="tileSettingWrapper">
              <div class="headerWrapper">
                <div class="tileSettingDiv">
                  <div class="tileSettingColumn" *ngFor="let setting of tileSettingsConstants">
                    <span>{{setting}}</span>
                  </div>
                </div>
              </div>
              <edx-form-wrapper #formWrapper [kind]="'__local_group_tile_settings'" [desc]="desc" [formData]="formData" (okEnabled)="okEnabled($event)" (formDataChanged)="updateFormData($event)"></edx-form-wrapper> 
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngSwitchCase="'footerdefaults'" class="adminlistcontainer">
        <ng-template [ngIf]="ui<2">
          <div class="left">
            <ng-container *ngTemplateOutlet="libraryPane"></ng-container>
            <edx-filelist-sidebar [receiver]="this" [list]="leftPaneList" [selectIndex]="currentListIndex" [desc]="desc" [disabled]="disabledNav" [tabIndex]="0"></edx-filelist-sidebar>
          </div>
          <div class="right" *ngIf="rightPaneDataLoaded">
            <div class="footerSettingWrapper">
              <div class="footerHeaderWrapper">
                <div class="footerSettingDiv">
                  <div class="footerSettingColumn" *ngFor="let setting of footerDefaultsConstants">
                    <span>{{setting}}</span>
                  </div>
                </div>
              </div>
              <edx-form-wrapper #formWrapper [kind]="'__local_group_footer_defaults'" [desc]="desc" [formData]="formData" (okEnabled)="okEnabled($event)" (formDataChanged)="updateFormData($event)"></edx-form-wrapper> 
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <edx-spinner *ngIf="showSpinner"></edx-spinner>
</div>
<ng-template #libraryPane>
  <div class="selwrapper">
    <div class="sellabel">{{forLibStr}}</div>
    <edx-select [id]="'edx_libsel_item'" [ariaLabel]="forLibStr" [items]="librarySelectItems" [value]="!!desc?desc.lib:''" (change)="libraryChanged($event)"></edx-select>
  </div>
</ng-template>
