import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { LocalizeService } from './services/localize.service';
import { Util } from './utils/utils.module';
import { AppComponent } from './app.component';
import { FormWrapperComponent, FieldChangeListener } from './forms/form-wrapper.component';
import { SearchOfficeComponent } from './widgets/search-office.component';
import { FormField } from './models/form-field';

const kOfficeHeaderCollapsedKey = 'edx_office_header_collapsed';

@Component({
  selector:'edx-home',
  styleUrls: [ 'app-home.component.scss' ],
  template: `
    <div *ngIf="showOfficePanel" class="office-panel-wrapper" [ngClass]="{headercollapsed:headerCollapsed}">
      <edx-search-office #searchOffice [docName]="docName" [addEnabled]="searchFooterWrapper && !searchFooterWrapper.okDisabled" [headerCollapsed]="headerCollapsed" (addToDMClicked)="searchFooterWrapper ? searchFooterWrapper.popupOK() : null" (gotOfficeDataFiles)="gotOfficeDataFiles($event)" (descDataFilesChanging)="descDataFilesChanging($event)"></edx-search-office>
      <div *ngIf="searchFooterWrapper && !searchFooterWrapper.showSaveAsPicker()" class="collapse-section" (keyup.space)="toggleHeader()" (click)="toggleHeader()">
        <div class="collapser" [tabindex]="tabIndex" [title]="collapserTooltip"></div>
        <span class="collapse-title">{{collapserTooltip}}</span>
      </div>
      <div *ngIf="hasOfficeDataFiles" class="office-form-section" [ngClass]="{hide:!showBody}">
        <div *ngIf="!headerCollapsed" class="office-permissions-form-content">
          <edx-form-wrapper #headerWrapper [desc]="searchOffice.desc" [tabIndex]="0" [createType]="'documents'" [inDialog]="true" [kind]="'__local_permissions_selector'" [layout]="'column'" [mainWrapper]="searchFooterWrapper" [formData]="searchOffice.formData"></edx-form-wrapper>
        </div>
        <div class="office-form-content">
          <edx-form-wrapper #searchFooterWrapper [desc]="searchOffice.desc" [headerWrapper]="headerWrapper" [tabIndex]="0" [createType]="'documents'" [inDialog]="true" [kind]="'profile_office_home'" [disableFirstFocus]="true" [dataFiles]="searchOffice.dataFiles" [formData]="searchOffice.formData" [trustees]="searchOffice.trustees" [fileFormInfo]="searchOffice.fileFormInfo" [emailHasAttachments]="searchOffice.emailHasAttachments()" [fileDropTarget]="searchOffice" [filesChanging]="officeDataFilesChanging" [changeListener]="this" [hideChoosers]="headerCollapsed" (extrasShownByChild)="extrasShownByChild($event)" (notifyFormChanged)="formChanged($event)" (applyAllChanged)="onApplyAllChanged($event)"></edx-form-wrapper>
        </div>
        <div class="bottom-controls">
          <div class="extras" (keyup.space)="toggleExtras()" (click)="toggleExtras()">
            <div class="extras-caret" [tabindex]="tabIndex" [ngClass]="{up:extrasShown}"></div>
            <span *ngIf="!extrasShown || !extrasAlt" class="extras-title">{{extras}}</span>
            <span *ngIf="extrasShown && extrasAlt" class="extras-title">{{extrasAlt}}</span>
          </div>
          <button *ngIf="autoFill" class="autofill" (click)="thirdButtonClick()">{{autoProfile}}</button>
        </div>
      </div>
    </div>
    <edx-tiles-container *ngIf="!isOfficeAddin"></edx-tiles-container>
    `
})
export class AppHomeComponent implements OnInit, OnDestroy, FieldChangeListener {
  public showOfficePanel: boolean = Util.Device.bIsOfficeAddin;
  public isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  private routerSubscription: Subscription;
  private app: AppComponent = Util.RestAPI.getAppComponent();
  private hasOfficeDataFiles = false;
  private officeDataFilesChanging = false;
  private collapserTooltip: string;
  private extrasShown = false;
  private showBody = true;
  private extras: string;
  private extrasAlt: string;
  private autoProfile: string;
  private docName: string = null;
  private headerCollapsed = false;
  private autoFill = false;
  @Input() tabIndex?: number = 0;
  @ViewChild(SearchOfficeComponent) searchOffice: SearchOfficeComponent;
  @ViewChild('searchFooterWrapper') searchFooterWrapper: FormWrapperComponent;
  @ViewChild('headerWrapper') private headerWrapper: FormWrapperComponent;

  constructor(private router: Router, private localizer: LocalizeService) {
    this.extras = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_ALL');
    this.extrasAlt = this.localizer.getTranslation('FORMS.BUTTONS.SHOW_REQUIRED_ONLY');
    this.autoProfile = this.localizer.getTranslation('FORMS.BUTTONS.AUTO_FILL');
    this.headerCollapsed = localStorage.getItem(kOfficeHeaderCollapsedKey) === 'true';
    this.collapserTooltip = this.localizer.getTranslation('FORMS.BUTTONS.' + (this.headerCollapsed ? 'MORE' : 'LESS'));
    if (this.isOfficeAddin) {
      this.routerSubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const url = this.router.url;
          const homeUrl = Util.RestAPI.getHomeURL();
          this.showOfficePanel = url === homeUrl || url.startsWith(homeUrl + '#ch_');
          if (this.showOfficePanel) {
            const checkLoggedIn = () => {
              const connected = !!Util.RestAPI.getLoginReply() && Object.keys(Util.RestAPI.getLoginReply()).length > 0;
              if (connected) {
                this.autoFill = Util.RestAPI.canAutoProfile();
              } else {
                setTimeout(checkLoggedIn, 100);
              }
            };
            checkLoggedIn();
          } else {
            this.autoFill = false;
          }
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.isOfficeAddin) {
      this.app.starting = false;
    }
  }

  ngOnDestroy(): void {
    if (!!this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private gotOfficeDataFiles(set: boolean): void {
    this.hasOfficeDataFiles = set;
  }

  private descDataFilesChanging(changing: boolean): void {
    if (changing) {
      this.officeDataFilesChanging = true;
      setTimeout(() => {
        this.officeDataFilesChanging = false;
      }, 500);
    }
  }

  private toggleExtras(): void {
    this.extrasShown = !this.extrasShown;
    this.showBody = false;
    setTimeout(() => {
      this.showBody = true;
      setTimeout(() => {
        this.searchFooterWrapper.extrasToggled(this.extrasShown);
      }, 1);
    }, 100);
  }

  private thirdButtonClick(): void {
    this.searchFooterWrapper.popupThirdBtn();
  }

  private toggleHeader(): void {
    this.headerCollapsed = !this.headerCollapsed;
    localStorage.setItem(kOfficeHeaderCollapsedKey, '' + this.headerCollapsed);
    this.collapserTooltip = this.localizer.getTranslation('FORMS.BUTTONS.' + (this.headerCollapsed ? 'MORE' : 'LESS'));
  }

  public extrasShownByChild(show: boolean): void {
    this.extrasShown = show;
  }

  public fieldChanged(field: FormField, control: AbstractControl, formWrapper: FormWrapperComponent): void {
    if (field.name==='DOCNAME') {
      this.docName = control.value;
    }
  }

  public formChanged(formName: string): void {
    if (!this.headerCollapsed) {
      this.headerCollapsed = true;
      setTimeout(() => {
        this.headerCollapsed = false;
      }, 300);
    }
  }

  public onApplyAllChanged(checked: boolean): void {
    this.app.onApplyAllChanged(checked);
  }
}
