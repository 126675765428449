import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, NgZone } from '@angular/core';

import { SelectItem } from '../models/form-field';
import { DataFile } from '../models/file-form-info';
import { ListData } from '../models/base';
import { ListItem } from '../models/list-item';
import { FileFormInfo } from '../models/file-form-info';
import { SecurityControl, AccessLevel } from '../models/security-control';
import { LocalizeService } from '../services/localize.service';
import { OOxmlService } from '../services/ooxml.service';
import { FormService } from './../services/form.service';
import { Util } from '../utils/utils.module';
import { SegmentComponent } from './segment.component';
import { AppComponent } from '../app.component';

declare let Office: any;
declare let Word: any;
declare let Excel: any;
const searchForTestDoc = false;

@Component({
  selector: 'edx-search-office',
  styleUrls: ['search-mobile.component.scss', 'search-office.component.scss'],
  template: `
    <div class="contentpanel oai" [ngClass]="{ieedge:ieEdge}" [edx-file-drop-target]="this">
      <div *ngIf="!isOfficeAddinOutlookMessageEditable" class="officerow" id="edx_search_outlook">
        <div class="officebar" [ngClass]="{alignmentfix:addEnabled}">
          <span class="icon-wrapper">
            <img class="headericon" src="{{officeItemIcon()}}" />
          </span>
          <div class="text wrap" title="{{officeItemTitle}}">{{officeItemName()}}</div>
          <div class="addbtn">
            <button *ngIf="showSaveButton()" [ngClass]="{readonly:!addEnabled && !officeItem}" tabindex="0" (click)="saveButtonClicked($event)"
                    (keyup.enter)="saveButtonClicked($event)" (keydown.space)="saveButtonClicked($event)">{{saveButtonTitle()}}
            </button>
          </div>
        </div>
        <div class="finding" [ngClass]="{edx_hidden:headerCollapsed}">
          <ng-template [ngIf]="!findingOfficeItem && ((!!officeItemOptionItems || !isOfficeAddinOutlook) && !officeItem)">
            <div class="text">{{this.localizer.getTranslation((!!officeItemAttachments || isOfficeAddinOutlook) ? 'FORMS.LOCAL.OAI_ADD.ADD_MSG' : 'FORMS.LOCAL.OAI_ADD.ADD_DOC_AS')}}</div>
            <edx-select *ngIf="(!!officeItemOptionItems || !isOfficeAddinOutlook)" id="edx_message_options" [items]="officeItemOptionItems" [value]="officeItemOption" [inDialog]="true" (change)="officeItemOptionChanged($event)" [tabIndex]="0"></edx-select>
            <edx-select *ngIf="showAttachmentsSelectionList" [items]="msgAndAttachmentsList" id="edx_message_attachments_selections" [value]="msgAndAttachmentsSelection" [inDialog]="true" [isMultiSelect]="true" [showIcon]="true" (change)="attachmentSelectionChanged($event)"></edx-select>
          </ng-template>
          <div *ngIf="findingOfficeItem" class="spincontainer">
            <edx-spinner [mini]="true"></edx-spinner>
          </div>
        </div>
        <ng-template [ngIf]="!findingOfficeItem && !!officeItem">
          <div class="officerow list" [id]="getRowId(0)" tabindex="0" (click)="profileClick('profile')" (keyup)="onKeyUp($event,'profile',0)">
            <div class="icon profile"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.PROFILE')}}</div>
          </div>
          <div *ngIf="canShowPreview()" class="officerow list" [id]="getRowId(1)" tabindex="0" (click)="profileClick('preview')" (keyup)="onKeyUp($event,'preview',1)">
            <div class="icon preview"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.PREVIEW')}}</div>
          </div>
          <div *ngIf="bravaEnabled" class="officerow list" [id]="getRowId(2)" tabindex="0" (click)="profileClick('viewer')" (keyup)="onKeyUp($event,'viewer',2)">
            <div class="icon preview"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.VIEWER')}}</div>
          </div>
          <div class="officerow list" tabindex="0" [id]="getRowId(3)" (click)="profileClick('versions')" (keyup)="onKeyUp($event,'versions',3)">
           <div class="icon versions"></div>
           <div class="text">{{this.localizer.getTranslation('METADATA.TABS.VERSIONS')}}</div>
          </div>
          <div class="officerow list" tabindex="0" [id]="getRowId(4)" (click)="profileClick('history')" (keyup)="onKeyUp($event,'history',4)">
            <div class="icon history"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.HISTORY')}}</div>
          </div>
          <div class="officerow list" tabindex="0" [id]="getRowId(5)" (click)="profileClick('whereused')" (keyup)="onKeyUp($event,'whereused',5)">
            <div class="icon whereused"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.WHERE_USED')}}</div>
          </div>
          <div class="officerow list" tabindex="0" [id]="getRowId(6)" (click)="profileClick('related')" (keyup)="onKeyUp($event,'related',6)">
            <div class="icon whereused"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.RELATED')}}</div>
          </div>
          <div class="officerow list" tabindex="0" [id]="getRowId(7)" (click)="profileClick('attachments')" (keyup)="onKeyUp($event,'attachments',7)">
            <div class="icon whereused"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.ATTACHMENTS')}}</div>
          </div>
          <div *ngIf="canShowSecurity()" class="officerow list" tabindex="0" [id]="getRowId(8)" (click)="profileClick('security')" (keyup)="onKeyUp($event,'security',8)">
            <div class="icon security"></div>
            <div class="text">{{this.localizer.getTranslation('METADATA.TABS.SECURITY')}}</div>
          </div>
        </ng-template>
      </div>
    </div>
  `
})
export class SearchOfficeComponent implements OnInit, OnDestroy {
  public isOfficeAddinOutlookMessageEditable: boolean;
  public dataFiles: DataFile[] = null;
  public desc: any = null;
  public formData: any = null;
  public trustees: any[] = null;
  public fileFormInfo: FileFormInfo = null;
  public ieEdge: boolean;
  public msgAndAttachmentsList: any[] = null;
  private app: AppComponent = Util.RestAPI.getAppComponent();
  private bravaEnabled: boolean;
  private isOfficeAddinOutlook: boolean;
  private findingOfficeItem = false;
  private officeItem: ListItem = null;
  private officeItemFileName: string;
  private officeItemFilePath: string;
  private officeItemTitle: string;
  private officeItemVersionLabel: string;
  private officeItemExtension: string;
  private officeItemAttachments: any[] = null;
  private officeItemOption: string = null;
  private previousSelectedOfficeItemOption: string = null;
  private officeItemOptionItems: SelectItem[] = null;
  private officeItemChangedEventID = 0;
  private officePropertiesError: any = null;
  private isSaveButtonClicked = false;
  private showAttachmentsSelectionList = false;
  private msgAndAttachmentsSelection: string;
  @Output() addToDMClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() gotOfficeDataFiles: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() descDataFilesChanging: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() addEnabled?: boolean = false;
  @Input() headerCollapsed?: boolean = false;
  @Input('docName') set setter(value: string) {
    if (!!value) {
      this.officeItemTitle = value;
      if (!!this.dataFiles && (Util.Device.bIsOfficeAddinExcel || Util.Device.bIsOfficeAddinPowerPoint)) {
        const fileNameParts = this.dataFiles[0].name.split('.');
        fileNameParts[0] = fileNameParts[0] || value;
        this.dataFiles[0].name = fileNameParts.join('.');
      }
    }
  }

  constructor(public localizer: LocalizeService, protected zone: NgZone, private ooxmlService: OOxmlService, private formService: FormService) {
  }

  ngOnInit(): void {
    this.bravaEnabled = Util.RestAPI.isBravaEnabled();
    this.isOfficeAddinOutlook = Util.Device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.mailbox;
    this.isOfficeAddinOutlookMessageEditable = Util.Device.bIsOfficeAddinOutlookMessageEditable;
    this.ieEdge = Util.Device.bIsIE || Util.Device.bIsEdge;
    this.officeItemChangedEventID = Util.RestAPI.registerOfficeItemChangedEvent(this.startFindOfficeItem.bind(this));
    this.officeItemExtension = this.isOfficeAddinOutlook ? 'msg' : Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : '';
    setTimeout(() => {
      this.startFindOfficeItem();
    }, 500);
  }

  ngOnDestroy(): void {
    this.gotOfficeDataFiles.emit(false);
    Util.RestAPI.deRegisterOfficeItemChangedEvent(this.officeItemChangedEventID);
  }

  private canShowPreview(): boolean {
    return Util.RestAPI.canShowPreview();
  }

  private canShowSecurity(): boolean {
    if (!!this.officeItem) {
      const rights: SecurityControl = new SecurityControl(AccessLevel.ACCESS_LEVEL_FULL);
      if (!!this.officeItem['%SECURITY']) {
        const itemAccess = +this.officeItem['%SECURITY'];
        if (itemAccess > 0) {
          rights.access &= itemAccess;
        }
      }
      return rights.canControlAccess;
    }
    return false;
  }

  private isDocumentInIcOrDmTempLocation(temp?: string): boolean {
    temp = !!temp ? temp.toUpperCase() : 'ICTEMP';
    const sep = Util.Device.bIsWindows ? '\\' : '/';
    const tempDirectorySuffix = 'OPENTEXT' + sep + 'DM' + sep + temp + sep;
    if (!!this.officeItemFilePath && this.officeItemFilePath.toUpperCase().indexOf(tempDirectorySuffix) !== -1) {
      return true;
    }
    return false;
  }

  private showSaveButton(): boolean {
    if (!this.findingOfficeItem && (!this.officeItem && this.addEnabled) || !!this.officeItem) {
      return !this.isDocumentInIcOrDmTempLocation('TEMP');
    }
    return false;
  }

  private saveButtonTitle(): string {
    return this.localizer.getTranslation('FORMS.BUTTONS.' + (!!this.officeItem ? 'SAVE_AS' : 'SAVE'));
  }

  private extractTrusteesFromProfileData(profileData: any): any[] {
    let trustees: any[] = null;
    if (!!profileData['edx_trustees']) {
      trustees = profileData['edx_trustees'];
      delete profileData['edx_trustees'];
    } else {
      this.trustees = null;
    }
    return trustees;
  }

  private extractFileFormInfoFromProfileData(profileData: any): FileFormInfo {
    let ffi: FileFormInfo = null;
    if (!!profileData['edx_fileFormInfo']) {
      ffi = profileData['edx_fileFormInfo'];
      delete profileData['edx_fileFormInfo'];
    } else {
      this.fileFormInfo = null;
    }
    return ffi;
  }

  private startFindOutlookItem(): void {
    const outlookMailbox = Util.Device.bIsOfficeAddin && Office && Office.context ? Office.context.mailbox : null;
    const outlookItem = outlookMailbox ? outlookMailbox.item : null;
    if (!!outlookItem?.getSharedPropertiesAsync) {
      outlookItem.getSharedPropertiesAsync((result) => {
        outlookMailbox['sharedProperties'] = result['value'];
        Util.RestAPI.setOutlookMailbox(outlookMailbox);
      });
    } else {
      Util.RestAPI.setOutlookMailbox(outlookMailbox);
    }
    const notFound = () => {
      Util.RestAPI.getOutlookItemProfileData(outlookMailbox, null, true).then(profileData => {
        const nAttach: number = outlookItem.attachments ? outlookItem.attachments.length : 0;
        const fileName: string = this.officeItemTitle+'.msg';
        this.officeItemAttachments = outlookItem.attachments && outlookItem.attachments.length > 0 ? outlookItem.attachments: null;
        this.dataFiles = [{name:fileName, url:'promise_msg', attachNum:null}];
        if (this.officeItemAttachments) {
          for (let i=0; i < nAttach; i++) {
            if (this.officeItemAttachments[i].isInline === false) {
              this.dataFiles[0]['attachNum'] = -1;
              break;
            }
          }
        }
        this.desc = {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:fileName};
        this.trustees = this.extractTrusteesFromProfileData(profileData);
        this.fileFormInfo = this.extractFileFormInfoFromProfileData(profileData);
        this.formData = profileData;
        if (this.officeItemAttachments)  {
          this.officeItemOptionItems = [];
          this.officeItemOptionItems.push({value: 'M', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.MSG_ATTACHMENTS')});
          if (!!Office.context.mailbox.restUrl) {
            this.officeItemOptionItems.push({value: 'E', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCLUDE_ATTACHMENTS')});
          }
          this.officeItemOptionItems.push({value: 'S', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.MSG_ATTACHMENTS_SEPARATELY')});
          this.officeItemOptionItems.push({value: 'ALL', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.ALL')});
          this.officeItemOption = 'M';
        } else if (!!Office.context.mailbox.restUrl) {
          this.officeItemOptionItems = [{ value: 'E', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCLUDE_ATTACHMENTS') }];
          this.officeItemOption = 'E';
        }
        this.gotOfficeDataFiles.emit(true);
        this.findingOfficeItem = false;
      });
    };
    const findNewItem = () => {
      this.formData = null;
      this.dataFiles = null;
      this.officeItemOption = null;
      this.gotOfficeDataFiles.emit(false);
      if (outlookItem && outlookItem.internetMessageId) {
        this.findingOfficeItem = true;
        this.officeItem = null;
        Util.Device.officeDMItem = null;
        this.officeItemTitle = outlookItem.subject;
        //DMIC-7460 Using regex expression to replace the emoticons in the subject with ''
        this.officeItemTitle= this.officeItemTitle.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\ufe0f)/g,'');
        Util.RestAPI.getCurrentOutlookExistingListItem(outlookItem).then(listItem => {
          this.officeItem = listItem;
          Util.Device.officeDMItem = this.officeItem;
          this.findingOfficeItem = false;
        }, notFound);
      } else {
        notFound();
      }
    };
    if (!!outlookMailbox) {
      this.zone.run(findNewItem);
    }
  }

  private startFindOfficeItem(): void {
    const notFound = () => {
      this.officeItemTitle = this.officeItemTitle || '';
      let nameParts: string[] = this.officeItemTitle.split('.');
      if (nameParts.length > 1) {
        nameParts.splice(nameParts.length-1);
      }
      this.officeItemTitle = nameParts.join('.');
      if (this.officeItemTitle.startsWith('http')) {
        nameParts = this.officeItemTitle.split('/');
        this.officeItemTitle = nameParts[nameParts.length-1];
      }
      this.findingOfficeItem = false;
      this.officeItemExtension = this.getFileExtension();
      if (!!Office.context.document) {
        this.dataFiles = [{name:this.officeItemTitle + '.' + this.officeItemExtension, officeDoc:Office.context.document, url:this.officeItemFilePath}];
      } else {
        this.dataFiles = [{name:this.officeItemTitle + '.docx', b64Data:btoa('A fake document')}];
      }
      this.desc = {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:this.dataFiles[0].name};
      this.officeItemOptionItems = [{ value: 'D', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.DEFAULT') }];
      if (!this.officePropertiesError && (Util.Device.bIsOfficeAddinDesktop || Util.Device.bIsOfficeAddinWord)) {
        this.officeItemOptionItems.push({ value: 'P', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.PDF') });
      }
      if (Util.Device.bIsOfficeAddinWord) {
        const appID: string = Util.RestAPI.getAppIDForFile('tmp.txt', null);
        if (!!appID) {
          this.officeItemOptionItems.push({ value: 'T', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.TEXT') });
        }
      }
      this.officeItemOption = 'D';
      Util.RestAPI.getFormInfoForFile(this.dataFiles[0].name, Util.RestAPI.getPrimaryLibrary()).then((ffi: FileFormInfo) => {
        const formName = !!ffi.defForm ? ffi.defForm : Util.RestAPI.getDefaultProfileForm().id;
        const formIndex: number = ffi.forms.indexOf(formName);
        if (formIndex >= 0) {
          this.formData = Util.deepCopy(ffi.profile_defaults[formIndex]);
          this.formData['FORMNAME'] = formName;
          this.trustees = ffi.trustees[formIndex];
          this.fileFormInfo = Util.deepCopy(ffi);
        }
        this.gotOfficeDataFiles.emit(true);
      });
    };
    const documentRecognized = (document) => {
      this.officeItem = document;
      Util.RestAPI.get(this.officeItem, 'profile').subscribe(profileData => {
        this.officeItem['MATTER_NAME'] = profileData['MATTER_NAME'] || profileData['X8809'] || '';
        this.officeItem['CLIENT_NAME'] = profileData['CLIENT_NAME'] || profileData['X7316'] || '';
        if (!!this.officeItem) {
          if (!this.officeItem['vers']) {
            this.officeItem['vers'] = this.officeItemVersionLabel;
          }
          Util.RestAPI.get(this.officeItem, 'versions').subscribe(result => {
            const currentVersion = result?.['list'].find(version => version['VERSION_LABEL'] === this.officeItem['vers']);
            this.officeItem['VERSION_COMMENTS'] = currentVersion?.['COMMENTS'];
            this.officeItem['VERSION_STATUS'] = currentVersion?.['STATUS'];
            Util.RestAPI.setOfficeItem(this.officeItem);
            Util.Device.officeDMItem = this.officeItem;
            // Forcing office to update footer when it opens it up
            if (this.formService.formUpdatedSuccessfully === undefined && this.ooxmlService.isAlwaysUpdateFooterEnabled()) {
              // Get profile data for footer update to have more metadata
              if (!!this.officeItem['checkout']) {
                this.ooxmlService.addFooter(this.officeItem, true).then(() => {
                  this.formService.formUpdatedSuccessfully = false;
                }, footerError => {
                  console.log(footerError);
                });
              }
            } else if (Util.Device.bIsOfficeAddinWord) {
              if (this.ooxmlService.isAlwaysUpdateFooterEnabled()) {
                this.ooxmlService.addFooter(this.officeItem, true).then(() => {
                }, footerError => {
                  console.log(footerError);
                });
              } else {
                this.ooxmlService.addFooter(this.officeItem, false, true).then(() => {
                }, footerError => {
                  console.log(footerError);
                });
              }
            }
          });
        }
      });
      this.findingOfficeItem = false;
      this.desc = null;
    };
    const searchForDoc = (docNum: string, lib: string): void => {
      const searchCriteria: any = {};
      const criteria: any = {};
      const searchForms: any[] = Util.RestAPI.getSearchForms();
      if (searchForms && searchForms.length) {
        searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
      }
      searchCriteria['DESCRIPTION'] = docNum;
      criteria['DOCNUM'] = docNum;
      searchCriteria['criteria'] = criteria;
      this.officeItem = null;
      Util.Device.officeDMItem = null;
      this.findingOfficeItem = true;
      const shouldRecognizeTaggedDocuments = (Util.RestAPI.getSystemDefaultSetting('RECOGNIZE_TAGGED_DOCUMENTS_AS_INTEGRATED') || 'Y').toUpperCase() === 'Y';
      const isInTempLocation = this.isDocumentInIcOrDmTempLocation();
      Util.RestAPI.post({ id: 'evaluation', lib, type: 'searches' }, searchCriteria, '', 'libs=' + lib + '&start=0&max=1&ascending=SYSTEM_ID').subscribe((listData: ListData) => {
        if (!!listData?.list?.length) {
          const docItem = listData.list[0];
          const isCheckedout = !!docItem.checkout;
          const launchInfo = Util.RestAPI.getCachedOfficeLaunchInfo(this.officeItemFilePath);
          if (shouldRecognizeTaggedDocuments || isInTempLocation || isCheckedout || (launchInfo?.launched === true)) {
            documentRecognized(docItem);
          } else if (!launchInfo) {
            Util.RestAPI.getPFTALaunchInfo(this.officeItemFilePath).then(pftaLaunchInfo => {
              Util.RestAPI.setCachedOfficeLaunchInfo(pftaLaunchInfo);
              if (pftaLaunchInfo?.launched) {
                documentRecognized(docItem);
              } else {
                notFound();
              }
            });
          } else {
            notFound();
          }
        } else {
          notFound();
        }
      }, err => {
        notFound();
      });

      /* DMIC-9883 - After saving document as a new version, sub version, new doc and replacement
         of the current version, document settings changes and word considers the document dirty */
      if (Util.Device.bIsOfficeAddinWord) {
        Util.RestAPI.getOfficeDocProperties().then(properties => {
          if (!properties.isReadOnly && this.isSaveButtonClicked) {
            Word.run(async (context) => {
              context.load(context.document, 'saved');
              await context.sync();
              if (context.document.saved === false) {
                context.document.save();
                await context.sync();
              }
            });
          }
        });
      }
    };
    const getFileNameDocProperty = async (done) => {
      try {
        const getDiskName = async () => {
          await Office.context.document.getFilePropertiesAsync(async asyncResult => {
            let officeFilename: string;
            let edxFileName: string = null;
            if (!!Office.context.document.settings) {
              edxFileName = Office.context.document.settings.get('edx_filename');
            }
            if (!!asyncResult && !!asyncResult.value && !!asyncResult.value.url) {
              const sep: string = (Util.Device.bIsWindows && !asyncResult.value.url.startsWith('http')) ? '\\' : '/';
              const components: string[] = asyncResult.value.url.split(sep);
              officeFilename = components[components.length-1];
              this.officeItemFilePath = asyncResult.value.url;
              done(officeFilename, edxFileName);
            } else if (!!asyncResult && !!asyncResult.error) {
              this.officePropertiesError = asyncResult.error;
              if (Util.Device.bIsOfficeAddinExcel && asyncResult.error.code === 5004) {
                const waitForCellExit = () => {
                  Util.Notify.confirm(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCEL_5004'), null, null, false, true, true).then(response => {
                    if (response && response.confirm) {
                      getDiskName();
                    } else {
                      setTimeout(waitForCellExit, 1000);
                    }
                  });
                };
                waitForCellExit();
              } else {
                done(officeFilename, edxFileName);
              }
            } else {
              done(officeFilename, edxFileName);
            }
          });
        };
        getDiskName();
      } catch (e) {
        done();
      }
    };
    const afterGetFileURL = async () => {
      await getFileNameDocProperty(async (officeFilename: string, edxFileName: string) => {
        const metatDataNameIsManagedFile = Util.Transforms.isDMManagedFile(edxFileName);
        const diskFileNameIsManagedFile = Util.Transforms.isDMManagedFile(officeFilename);
        if (metatDataNameIsManagedFile && diskFileNameIsManagedFile) {
          const metaDataDocObj = Util.decodeFileName(edxFileName);
          const diskFileDocObj = Util.decodeFileName(officeFilename);
          const resetDocumentTag = () => {
            Util.RestAPI.setOfficeDocCustomProperty('edx_filename', officeFilename);
            edxFileName = null;
          }
          if (!!metaDataDocObj && !!diskFileDocObj) {
            if (metaDataDocObj.lib === diskFileDocObj.lib && metaDataDocObj.docNum === diskFileDocObj.docNum && metaDataDocObj.vers !== diskFileDocObj.vers) {
              const metaDataFloatVers = Util.versionFloatFromLabel(metaDataDocObj.vers);
              const diskFileFloatVers = Util.versionFloatFromLabel(diskFileDocObj.vers);
              if (diskFileFloatVers > metaDataFloatVers) {
                resetDocumentTag();
              }
            }
            if ((metaDataDocObj.lib !== diskFileDocObj.lib) || ((metaDataDocObj.docNum >> 0) < (diskFileDocObj.docNum >> 0))) {
              resetDocumentTag();
            }
          }
        }
        const fileName = edxFileName ? edxFileName : officeFilename;
        this.zone.run(() => {
          this.officeItemTitle = this.officeItemFileName = fileName;
          this.officeItemVersionLabel = null;
          if (metatDataNameIsManagedFile || diskFileNameIsManagedFile) {
            const docObj = Util.decodeFileName(fileName);
            if (!!docObj) {
              this.officeItemTitle = docObj.name;
              this.officeItemExtension = docObj.ext || this.officeItemExtension;
              this.officeItemVersionLabel = docObj.vers;
              searchForDoc(docObj.docNum, docObj.lib);
            } else {
              notFound();
            }
          } else {
            notFound();
          }
        });
      });
    };
    const getFileURL = async (done) => {
      try {
        await Office.context.document.getFilePropertiesAsync(async asyncResult => {
          if (!!asyncResult && !!asyncResult.value && !!asyncResult.value.url) {
            done(asyncResult.value.url);
          } else {
            done(null);
          }
        });
      } catch (e) {
        done(null);
      }
    };
    const startLooking = async () => {
      if (!!Office.context.mailbox) {
        this.startFindOutlookItem();
      } else if (!!Office.context.document) {
        await getFileURL(async (anUrl: string) => {
          if (Util.Device.officeDocDelete === undefined) {
            Util.Device.officeDocDelete = !anUrl;
          }
          afterGetFileURL();
        });
      } else {
        // test code
        if (searchForTestDoc) {
          searchForDoc('1210', 'RHQA167-Legal');
        } else {
          this.officeItemTitle = 'Fake';
          this.officeItemFileName = 'fake.docx';
          notFound();
        }
      }
    };
    const waitForCache = () => {
      if (Util.RestAPI.cacheIsLoaded() && !!Util.RestAPI.getTiles() && Util.RestAPI.getTiles().length > 0) {
        startLooking();
      } else {
        setTimeout(() => {
          waitForCache();
        }, 500);
      }
    };
    if (Util.Device.bIsOfficeAddin && !!Office && !!Office.context) {
      this.officeItemTitle = null;
      this.officeItemFileName = null;
      this.officeItemAttachments = null;
      this.msgAndAttachmentsList = null;
      this.showAttachmentsSelectionList = false;
      this.dataFiles = null;
      this.findingOfficeItem = true;
      this.officePropertiesError = null;
      this.gotOfficeDataFiles.emit(false);
      waitForCache();
    }
  }

  private officeItemName(): string {
    return this.findingOfficeItem ? '...' : this.officeItem ? this.officeItem['DOCNAME'] : this.officeItemTitle;
  }

  private officeItemIcon(): string {
    const iconPath = 'assets/images/';
    let icon: string;
    if (window.hasOwnProperty('Word')) {
      icon = 'mime_word.svg';
    } else if (window.hasOwnProperty('Excel')) {
      icon = 'mime_excel.svg';
    } else if (window.hasOwnProperty('PowerPoint')) {
      icon = 'mime_powerpoint.svg';
    } else {
      icon = 'mime_outlook.svg';
    }
    return !this.findingOfficeItem && this.officeItem ? Util.Transforms.iconUrlFromDesc(this.officeItem) : (iconPath + icon);
  }

  private officeItemIconAdd(): boolean {
    return !this.findingOfficeItem && !this.officeItem && !!this.officeItemTitle;
  }

  private officeItemIconProfile(): boolean {
    return !this.findingOfficeItem && !!this.officeItem;
  }

  private profileClick(tab: string): boolean {
    if (!this.findingOfficeItem) {
      if (this.officeItem) {
        this.app.doCommand('profile_outlook_item', {list:this.officeItem,tab});
      } else if (!!Office &&!!Office.context) {
        if (!!Office.context.mailbox) {
          this.app.doCommand('uploadofficeitem');
        } else if (Office.context.document) {
          Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 }, result => {
            if (result.status === 'succeeded') {
              this.zone.run(() => {
                const file = result.value;
                const fileSize: number = file.size * 2; // this is only for progress and we are base64 encoding so double the size
                file.closeAsync();
                Util.RestAPI.uploadFilesWithUI(null, null, [{name: this.officeItemTitle, size: fileSize, officeDoc: Office.context.document}]);
              });
            }
          });
        }
      }
    }
    return true;
  }

  private attachmentSelectionChanged(event: Event) {
    const  dataFiles = [];
    for (const attData of event['selectedList']) {
      dataFiles.push({name:attData.display, url:attData['url'], attachNum:attData['attachNum'], mailID: attData['mailID']});
    }
    const firstAttachmentInTheList = dataFiles.length > 0 ? dataFiles[0] : null;
    let appID = null;
    if (!!firstAttachmentInTheList) {
      appID = Util.RestAPI.getAppIDForFile(firstAttachmentInTheList.name || firstAttachmentInTheList.Name, this.desc?.lib);
    }
    Util.RestAPI.getOutlookItemProfileData(Office.context.mailbox, null, true, null, appID).then(profileData => {
      if (dataFiles?.length === 1) {
        profileData['DOCNAME'] = '';
      }
      this.updateDataFiles(dataFiles, {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:''}, profileData);
    });
  }

  public updateDataFilesFromAttachments(): any {
    const dataFiles = [];
    for (let i=0; i<this.officeItemAttachments.length; i++) {
      const attachmentData = this.officeItemAttachments[i];
      dataFiles.push({name:attachmentData.Name||attachmentData.name, url:'promise_'+i, attachNum:i, mailID : this.formData['MAIL_ID']});
    }
    return dataFiles;
  }

  public updateDataFiles(newDataFiles: DataFile[], newDesc: any, newFormData: any) {
    this.descDataFilesChanging.emit(true);
    setTimeout(() => {
      this.dataFiles = newDataFiles;
      this.desc = newDesc;
      if (newFormData) {
        this.trustees = this.extractTrusteesFromProfileData(newFormData);
        this.formData = newFormData;
        this.gotOfficeDataFiles.emit(false);
        setTimeout(() => {
          // destroy the profile form then reload it with the new data
          this.gotOfficeDataFiles.emit(true);
        }, 300);
      } else {
        this.gotOfficeDataFiles.emit(true);
      }
    }, 1);
  }

  private officeItemOptionChanged(seg: SegmentComponent): void {
    if (this.isOfficeAddinOutlook) {
        this.previousSelectedOfficeItemOption = this.officeItemOption;
        this.officeItemOption = seg.value;
        let attIndex: number = null;
        let descName: string = this.officeItemTitle;
        this.showAttachmentsSelectionList = false;
        const populateAttachmentsListBox = () => {
          attIndex = -1;
          this.desc = { id: '', lib: Util.RestAPI.getPrimaryLibrary(), type: 'documents', name: descName };
          this.msgAndAttachmentsList = this.dataFiles.concat(this.updateDataFilesFromAttachments());
          for (const item of this.msgAndAttachmentsList) {
            item['src'] = Util.Transforms.iconUrlFromDesc({ APP_ID: Util.RestAPI.getAppIDForFile(item['name']) });
            item['display'] = item['name'];
            item['value'] = item['name'];
          }
          this.msgAndAttachmentsSelection = this.msgAndAttachmentsList.map(i => i.name).join(',');
          this.showAttachmentsSelectionList = true;
        }
      switch (this.officeItemOption) {
        case 'M':
        case 'E':
          this.dataFiles = [{ name: this.officeItemTitle + '.msg', url: 'promise_msg', attachNum: this.officeItemOption === 'E' ? null : -1, excludeAttachments: this.officeItemOption === 'E' }];
          this.desc = { id: '', lib: Util.RestAPI.getPrimaryLibrary(), type: 'documents', name: descName };
          break;
        case 'ALL':
          this.dataFiles = [];
          populateAttachmentsListBox();
          break;
        case 'S':
          this.dataFiles = [{ name: this.officeItemTitle + '.msg', url: 'promise_msg', attachNum: -1, mailID: this.formData['MAIL_ID'], excludeAttachments: false }];
          populateAttachmentsListBox();
          break;
        default:
          attIndex = parseInt(this.officeItemOption);
          break;
      }
        if (attIndex !== null && this.officeItemAttachments) {
          let attData: any;
          let dataFiles: DataFile[] = [];
          if (this.officeItemOption === 'S') {
            dataFiles = dataFiles.concat(this.dataFiles);
          }
          attData = attIndex>=0 ? this.officeItemAttachments[attIndex] : this.officeItemAttachments[0];
          const appID = this.officeItemOption === 'S' ? 'MS Outlook' : Util.RestAPI.getAppIDForFile(attData.Name||attData.name, !!this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary());
          if (attIndex>=0) { //TODO: Check and remove this code as having attachment separately (attIndex>=0 condition) in THIS dropdown is removed.
            descName = attData.Name || attData.name;
            dataFiles.push({name:descName, url:'promise_'+attIndex, attachNum:attIndex});
          } else {
            dataFiles = dataFiles.concat(this.updateDataFilesFromAttachments());
          }
          Util.RestAPI.getOutlookItemProfileData(Office.context.mailbox, null, true, null, appID).then(profileData => {
            if (dataFiles?.length === 1) {
              profileData['DOCNAME'] = descName;
            }
            this.updateDataFiles(dataFiles, {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:descName}, profileData);
          });
        } else {
          Util.RestAPI.getOutlookItemProfileData(Office.context.mailbox, null, true, null).then((profileData) => {
            if (this.previousSelectedOfficeItemOption !== 'M' && this.previousSelectedOfficeItemOption !== 'E') {
              this.updateDataFiles(this.dataFiles, this.desc, profileData);
            }
          });
        }
    } else {
      this.officeItemOption = seg.value;
      switch (this.officeItemOption) {
      case 'D':
        this.officeItemExtension = this.getFileExtension();
        break;
      case 'T':
        this.officeItemExtension = Util.Device.bIsOfficeAddinWord ? 'txt' : '';
        break;
      case 'P':
        this.officeItemExtension = 'pdf';
        break;
      }
      this.updateDataFiles([{name:this.officeItemTitle+'.' + this.officeItemExtension, officeDoc: Office.context.document}], {id:'',lib:Util.RestAPI.getPrimaryLibrary(),type:'documents',name:this.officeItemExtension}, null);
    }
  }

  private saveButtonClicked(event: Event): void {
    const fileName: string = this.officeItemTitle + (this.officeItemTitle.endsWith(this.officeItemExtension) ? '' : ('.' + this.officeItemExtension));
    this.formService.formUpdatedSuccessfully = false;
    if (!this.officeItem) {
      this.addToDMClicked.emit(true);
    } else {
      const uploadNewDocument = (dataFile) => {
        const docObj: any = Util.decodeFileName(dataFile.name);
        if (!!docObj) {
          dataFile.name = docObj.name + (!!docObj.ext ? '.' + docObj.ext : '');
        }
        if (this.isOfficeAddinOutlook) {
          Util.RestAPI.getOutlookItemProfileData(Office.context.mailbox, null, true).then((data: any) => {
            this.extractTrusteesFromProfileData(data);
            Util.RestAPI.uploadFilesWithUI(null, null, [dataFile], null, data);
          }, e => {
            Util.RestAPI.uploadFilesWithUI(null, null, [dataFile]);
          });
        } else {
          dataFile.fromSaveAsDesc = Util.deepCopy(this.officeItem);
          const addNewDoc = () => {
            dataFile.fromSaveAsDesc.STATUS = '0';
            dataFile.fromSaveAsDesc.vers = docObj?.vers || this.officeItem.checkout?.VERSION_LABEL || '1';
            dataFile.listItemCB = listItemCB;
            Util.RestAPI.get(this.officeItem, 'profile').subscribe((data: any) => {
              Util.RestAPI.uploadFilesWithUI(null, null, [dataFile], null, data);
            }, e => {
              Util.RestAPI.uploadFilesWithUI(null, null, [dataFile]);
            });
          };
          const unlockOldDoc = () => {
            Util.RestAPI.put(this.officeItem, { '%STATUS': '%UNLOCK' }, 'profile', null, { observe: 'response' }).subscribe(addNewDoc, addNewDoc);
          };
          Util.RestAPI.cancelMonitorFile(this.officeItemFileName, this.officeItemFilePath).then(unlockOldDoc, unlockOldDoc);
        }
      };

      const isCheckedOutByMe: boolean = !!this.officeItem && this.officeItem['STATUS'] === '3' && !!this.officeItem['checkout'] && this.officeItem['checkout']['TYPIST_ID'].toUpperCase() === Util.RestAPI.getUserID();
      const isSelectedVersionSameAsCheckedOutVersionByMe = isCheckedOutByMe && this.officeItem['vers'] === this.officeItem['checkout'].VERSION_LABEL;
      const rights: SecurityControl = Util.RestAPI.getRightsForItem(this.officeItem);
      const dataFile: DataFile = this.isOfficeAddinOutlook ? { name: fileName, url: 'promise_msg' } : { name: (this.officeItemFileName || fileName), officeDoc: Office.context.document, url: this.officeItemFilePath };
      const listItemCB = (item: ListItem) => {
        this.officeItem = item;
        Util.Device.officeDMItem = item;
        this.findingOfficeItem = false;
      };
      if (['19', '20'].indexOf(this.officeItem['STATUS']) !== -1 || this.officeItem['READONLY'] === 'Y' || (this.officeItem['STATUS'] === '3' && (!isCheckedOutByMe || !isSelectedVersionSameAsCheckedOutVersionByMe)) || !rights.canEditContent) {
        Util.Notify.success(this.localizer.getTranslation('FORMS.LOCAL.SAVE_AS.NEW_DOCUMENT'), this.localizer.getTranslation('FORMS.LOCAL.SAVE_AS.NEW_DOCUMENT_ALERT'));
        uploadNewDocument(dataFile);
      } else {
        const title = this.localizer.getTranslation('FORMS.BUTTONS.SAVE_AS');
        Util.Notify.confirm(title, '__local_save_as', null, { list: [this.officeItem], versionLabel: this.officeItemVersionLabel }, true, true, true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            const radioVal: string = confirmed.data['$edx_save_as_radio'];
            const comment: string = confirmed.data['COMMENT'];
            const vers: string = this.officeItemVersionLabel || 'C';
            const formData: any = !!comment ? { COMMENT: comment } : {};

            const doUploadNewVers = (aVers: string): void => {
              const isReadOnlyVersion = ['19', '20'].indexOf(this.officeItem['VERSION_STATUS']) !== -1;
              const queryArgs = !isReadOnlyVersion ? 'keeplock' : '';
              if (isReadOnlyVersion) {
                dataFile.fromSaveAsDesc = Util.deepCopy(this.officeItem);
              }
              formData['_restapi'] = { src: { ver_label: vers, id: this.officeItem.id, type: this.officeItem.type, lib: this.officeItem.lib } };
              dataFile.listItemCB = listItemCB;
              Util.RestAPI.uploadDataFiles(this.officeItem, formData, [dataFile], false, aVers, queryArgs);
            };
            const cancelPFTAThenUpload = (aVers: string): void => {
              if (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinExcel || Util.Device.bIsOfficeAddinPowerPoint) {
                Util.RestAPI.cancelMonitorFile(this.officeItemFileName, this.officeItemFilePath).then((res) => {
                  if (!!aVers) {
                    this.officeItemVersionLabel = aVers;
                  }
                  doUploadNewVers(aVers);
                }, () => {
                  doUploadNewVers(aVers);
                });
              } else {
                doUploadNewVers(aVers);
              }
            };
            //We need to reset the filename property to be read later from Office VSTO
            Util.RestAPI.setOfficeDocCustomProperty('edx_filename', '');

            switch (radioVal) {
              case 'RV':
                Util.RestAPI.uploadDataFiles(this.officeItem, formData, [dataFile], true, vers, 'keeplock');
                break;
              case 'NV':
                cancelPFTAThenUpload(null);
                break;
              case 'NSV':
                cancelPFTAThenUpload(vers);
                break;
              case 'ND':
                uploadNewDocument(dataFile);
                break;
            }
            this.isSaveButtonClicked = true;
          }
        });
      }
    }
    if (!!event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private getRowId(index: number) {
    return 'edx_officerow_list_' + index;
  }

  private getNextElementId(currentIndex: number, inc: number): string {
    let nextIndex = currentIndex + inc;
    if (nextIndex === 2) {
      nextIndex = this.bravaEnabled ? 2 : nextIndex + inc;
    }
    return this.getRowId(nextIndex);
  }

  private onKeyUp(event: KeyboardEvent, tab: string, index: number) {
    let elementId;
    switch (event.key) {
      case 'Enter':
      case ' ':
        this.profileClick(tab);
        break;
      case 'ArrowDown':
        elementId = this.getNextElementId(index, 1);
        break;
      case 'ArrowUp':
        elementId = this.getNextElementId(index, -1);
        break;
    }
    if (!!elementId) {
      const element = document.getElementById(elementId);
      if (!!element) {
        element.focus();
      }
    }
  }

  private getFileExtension(): string {
    if (!!this.officeItemFileName) {
      const fileNameParts = this.officeItemFileName.split('.');
      if (!!fileNameParts && fileNameParts.length>=2) {
        return fileNameParts.pop();
      }
    }
    return Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : '';
  }

  public emailHasAttachments(): boolean {
    return this.isOfficeAddinOutlook && this.officeItemOption !== 'E' && this.officeItemAttachments?.length > 0;
  }
}
