import { from as observableFrom, of as observableOf, Observable } from 'rxjs';
import { NgZone } from '@angular/core';
import { HttpClient, HttpResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AWAuth, AWFileTransfer, AWFileSystem, AWMobileFileSystem, AWFinder, AWPage, AWComponent, AWSecureStorage } from 'appworks-js';
import * as microsoftTeams from '@microsoft/teams-js';

import { LocalizeService } from '../services/localize.service';
import { DataService } from '../services/data.service';
import { FormService } from '../services/form.service';
import { SchemaService } from '../services/schema.service';
import { ProfileService } from '../services/profile.service';
import { oAuth2Service } from '../services/oauth2.service';
import { OOxmlService } from '../services/ooxml.service';
import { SettingsService } from '../services/settings.service';
import { BaseDesc, ListData } from '../models/base';
import { FileFormInfo, AppIDInfo, DataFile, tFilePickCB, tListPickCB, tListItemCB } from '../models/file-form-info';
import { Tile } from '../models/tile';
import { SecurityControl, RightsIndex, AccessRights, AccessLevel, AccessType } from '../models/security-control';
import { FilePartDesc } from '../models/filepart';
import { ListItem } from '../models/list-item';
import { FormResult } from '../models/command-handler';
import { _Device } from './device';
import { _Notify } from './notify';
import { _Transforms } from './transforms';
import { _Help } from './help';
import { ListBaseComponent } from '../lists/list-base.component';
import { AppComponent } from '../app.component';
import { WindowModalComponent } from '../windows/window-modal.component';
import { TilesContainerComponent } from '../tiles/tiles-container.component';
import { RecentLocationService } from '../services/recent-location.service';
import { EventEmitter } from 'events';
import { FavoriteService } from '../services/favorite.service';

declare let Office;
declare let Word;
declare let Excel;
declare let PowerPoint;
declare let ICC;
declare let cordova;
const kEmailFields = ['MAIL_ID', 'PARENTMAIL_ID', 'EMAIL_FROM', 'EMAIL_TO', 'EMAIL_CC', 'EMAIL_BCC', 'EMAIL_SENT', 'EMAIL_RECEIVED', 'MSG_ITEM', 'ATTACH_NUM'];
const kContactsLookup = '$edx_contacts';
const kDefaultAddressBook = 'MyContacts';
const kNetworkIDKey = 'NETWORK_ID';
const kLocalHost = 'localhost';
const kLocalHostHTTPS: string = 'https://'+kLocalHost;
const kPFTADefaultPortSSL = 9443;
const kDefaultMaxItems = 25;
const kAPIVersion = '1.0';
const kInitialOpenCheckMS = 15000;  // 15 seconds time enough for the app to launch
const kOpenCheckIntervalMS = 5000;  // 5 seconds
const kRefreshInterval: number = (10 * 60 * 1000);
const parentMailIdLength = 44;  // To do - Need to revalidate to remove addition characters from parental email id for reply mail
const kInitialBatchSize = 4;
const kNoOp = (x) => {};
enum APIVerb { kGet=0,kPost=1,kPut=2,kDel=3,kUpload=4,kDownloadBase64=5 }
enum DownloadOption { kDownload=0, kOpenIn=1, kViewInternal=2, kOpenInOffice=3 }
enum PFTAErrorCode { ecNone=0, ecSys=0x8001, ecDM=0x8002, ecWrongProto=0x8003, ecNoHandler=0x8004, ecUnreg=0x8005, ecBadJSON=0x8006, ecBadPath=0x8007, ecUserCancel=0x8008 }
//Rest API sends below email tokens with form appropriate field names during profile call
export const enum EmailHeaders{
   From ='From',
   To ='To',
   Bcc ='Bcc',
   Cc = 'Cc',
   EmailDate = 'EmailDate',
   DateReceived = 'DateReceived',
   DateSent = 'DateSent'
};

class _AuthPopupWindow {
  private bOfficeAuth: boolean;
  private bTeamsAuth: boolean;
  private authWindow: Window;
  private authOfficeDialog: any;
  private bTeamsAuthStarted: boolean;

  static GetQueryArgs(device: _Device, rootSiteUrl: string): string {
    return (device.bIsTeamsAddIn?('redirect_uri='+encodeURIComponent(rootSiteUrl+'/assets/signin-teams-redirect-callback.html')):null);
  }

  constructor(device: _Device, private help: _Help, private name: string) {
    this.bOfficeAuth = device.bIsOfficeAddin && !device.bIsOfficeAddinWeb && !!Office && !!Office.context;
    this.bTeamsAuth = device.bIsTeamsAddIn;
  }

  public startAuth(url: string, rootSiteUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.bOfficeAuth) {
        Office.context.ui.displayDialogAsync(url, {height: 30, width: 20}, asyncResult => {
          if (!!asyncResult && !!asyncResult.value) {
            this.authOfficeDialog = asyncResult.value;
            resolve();
          } else {
            reject('no dialog');
          }
        });
      } else if (this.bTeamsAuth) {
        this.bTeamsAuthStarted = true;
        microsoftTeams.authentication.authenticate({
          url: rootSiteUrl + '/assets/signin-teams-start.html?' + 'authstart='+encodeURIComponent(url),
          width: 600,
          height: 535,
          successCallback:(result: any) => {
          },
          failureCallback:(error: any) => {
            console.error(error);
          }
        });
        resolve();
      } else {
        const DefaultPopupFeatures = 'location=no,toolbar=no,width=500,height=500,left=100,top=100;';
        this.authWindow = this.help.openURL(url, this.name, null, DefaultPopupFeatures);
        resolve();
      }
    });
  }

  public close(): void {
    if (this.bTeamsAuthStarted) {
      this.bTeamsAuthStarted = false;
    } else if (!!this.authOfficeDialog) {
      this.authOfficeDialog.close();
      this.authOfficeDialog = null;
    } else if (!!this.authWindow) {
      this.help.closeURL(this.authWindow);
      this.authWindow = null;
    }
  }
}

class _ListInfo {
  public desc: any = null;
  public list: ListItem[] = null;
  public set: any = null;
  public item: ListItem = null;
  public listComponent: ListBaseComponent = null;
  public lastClosedList: any = null;
}

class _OfficeItemChangedListener {
  public callback: () => void;
  public id: number;
  constructor(cb: () => void) {
    this.callback = cb;
    this.id = new Date().getTime();
  }
}

class SessionCache {
  private static key = '$edx_sess_cache';
  private bCanStore: boolean;
  public loginReply: any;
  public teamsCache: any;
  // all library keyed
  public forms: any;
  public lookups: any;
  public appIds: any;
  public defaultProfileForm: any;
  // all only primary library
  public searchForms: any[];
  public defaultForms: any;
  // all keyed with encoded library
  public lookupColumnsCache: any;
  public formCache: any;
  public columnsCache: any;
  public officeLaunchedItems: any[];

  public static Load(bCanStore: boolean): SessionCache {
    const cacheStr: string = bCanStore ? localStorage.getItem(SessionCache.key) : null;
    const sessionCache: SessionCache = new SessionCache(bCanStore);
    if (!!cacheStr) {
      try {
        const sessionFromCache: SessionCache = JSON.parse(cacheStr);
        if (!!sessionFromCache) {
          sessionCache.set(sessionFromCache);
          return sessionCache;
        }
      } catch (e) { }
    }
    localStorage.removeItem(SessionCache.key);
    return sessionCache;
  }
  constructor(bCanStore: boolean) {
    this.bCanStore = bCanStore;
    this.set(null);
  }
  private set(sessionFromCache: SessionCache): void {
    if (!!sessionFromCache) {
      this.loginReply = sessionFromCache.loginReply;
      this.teamsCache = sessionFromCache.teamsCache;
      this.forms = sessionFromCache.forms;
      this.lookups = sessionFromCache.lookups;
      this.appIds = sessionFromCache.appIds;
      this.defaultProfileForm = sessionFromCache.defaultProfileForm;
      this.searchForms = sessionFromCache.searchForms;
      this.defaultForms = sessionFromCache.defaultForms;
      this.lookupColumnsCache = sessionFromCache.lookupColumnsCache;
      this.formCache = sessionFromCache.formCache;
      this.columnsCache = sessionFromCache.columnsCache;
      this.officeLaunchedItems = sessionFromCache.officeLaunchedItems;
    } else {
      this.loginReply = null;
      this.teamsCache = {};
      this.forms = {};
      this.lookups = {};
      this.appIds = {};
      this.defaultProfileForm = {};
      this.searchForms = null;
      this.defaultForms = {};
      this.lookupColumnsCache = null;
      this.formCache = null;
      this.columnsCache = null;
      this.officeLaunchedItems = [];
    }
  }
  public clear(): void {
    localStorage.removeItem(SessionCache.key);
    this.set(null);
  }
  public save(): void {
    if (this.bCanStore) {
      localStorage.setItem(SessionCache.key, JSON.stringify(this));
    }
  }
}

class _AutoCheckinFile {
  private fs: AWFileSystem;
  private mfs: AWMobileFileSystem;
  private downloadTime: number;
  private afterFileClosed: (acf: _AutoCheckinFile, aFile: any, modified: boolean) => void;
  public filePath: string;
  public deferedDesc: any;
  constructor(bIsElectron: boolean, deferedDesc: any, aPath: string, afterFileClosedCB: (acf: _AutoCheckinFile, aFile: any, modified: boolean) => void) {
    this.fs = bIsElectron ? new AWFileSystem() : null;
    this.mfs = !bIsElectron ? new AWMobileFileSystem(kNoOp, kNoOp) : null;
    this.deferedDesc = deferedDesc;
    this.filePath = aPath;
    this.downloadTime = new Date().getTime();
    this.afterFileClosed = afterFileClosedCB;
    setTimeout(() => {
      this.checkForOpen();
    }, kInitialOpenCheckMS);
  }

  private isOpen(): Promise<boolean> {
    let waitingForAppWorks = false;
    let rc = false;
    if (this.fs) {
      waitingForAppWorks = true;
      this.fs.isOpen(this.filePath, open => {
        waitingForAppWorks = false;
        rc = open;
      }, err => {
        waitingForAppWorks = false;
        rc = false;
      });
    } else if (this.mfs) {
      waitingForAppWorks = true;
      this.mfs.isOpen(this.filePath, false, open => {
        waitingForAppWorks = false;
        return open;
      }, err => {
        waitingForAppWorks = false;
        rc = false;
      });
    }
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        if (waitingForAppWorks) {
          setTimeout(waitFunc,100);
        } else {
          resolve(rc);
        }
      };
      setTimeout(waitFunc,100);
    });
  }

  private getDetails(): Promise<any> {
    let waitingForAppWorks = false;
    let details: any = null;
    if (this.fs) {
      waitingForAppWorks = true;
      this.fs.getDetails(this.filePath, aFile => {
        waitingForAppWorks = false;
        details = aFile;
      }, err => {
        waitingForAppWorks = false;
      });
    } else if (this.mfs) {
      waitingForAppWorks = true;
      this.mfs.list('/', false, list => {
        waitingForAppWorks = false;
        if (list) {
          details = list.find(f => f.type==='file' && f.path===this.filePath);
        }
      }, err => {
        waitingForAppWorks = false;
      });
    }
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        if (waitingForAppWorks) {
          setTimeout(waitFunc,100);
        } else {
          resolve(details);
        }
      };
      setTimeout(waitFunc,100);
    });
  }

  private checkForOpen(): void {
    setTimeout(() => {
      this.isOpen().then(open => {
        if (open) {
          this.checkForOpen();
        } else {
          this.getDetails().then(aFile => {
            const modified = aFile ? (aFile.modified || aFile.lastmodified) : 0;
            this.afterFileClosed(this, aFile, modified > this.downloadTime);
          }, err1 => {
            this.afterFileClosed(this, null, false);
        });
        }
      }, err => {
        this.afterFileClosed(this, null, false);
      });
    }, kOpenCheckIntervalMS);
  }

  public remove(): Promise<boolean> {
    let waitingForAppWorks = false;
    let rc: boolean;
    if (this.fs) {
      rc = false;
      waitingForAppWorks = true;
      this.fs.remove(this.filePath, details => {
        waitingForAppWorks = false;
        rc = true;
      }, err => {
        rc = false;
        waitingForAppWorks = false;
      });
    } else if (this.mfs) {
      waitingForAppWorks = true;
      this.mfs.remove(this.filePath, false, success => {
        rc = success;
        waitingForAppWorks = false;
      }, err => {
        rc = false;
        waitingForAppWorks = false;
      });
    }
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        if (waitingForAppWorks) {
          setTimeout(waitFunc,100);
        } else {
          resolve(rc);
        }
      };
      setTimeout(waitFunc,100);
    });
  }
}

class SiteConfigurations {
  public readonly isLoadedSuccessfully: boolean;
  public readonly restApi: string;
  public readonly portServer: string;
  public readonly showServer: boolean;
  public readonly showLibraries: boolean;
  public readonly showInterfaces: boolean;
  public readonly showTestUpload: boolean;
  public readonly allowAnonymous: boolean;
  public readonly forceSingleSignOn: boolean;
  public readonly allowPftaInstall: boolean;
  public readonly multiPfta: boolean;
  public readonly pftaOpenDelay: number;
  public readonly showPftaNotification: boolean;
  public readonly disableUserHelp: boolean;
  public readonly helpUrl: string;
  public readonly redirectSignin: boolean;
  constructor(data: any) {
    this.isLoadedSuccessfully = !!data;
    this.restApi = data?.restapi;
    this.portServer = data?.portserver;
    this.showServer = !!data?.showserver;
    this.showLibraries = !!data?.showlibraries;
    this.showInterfaces = !!data?.showInterfaces;
    this.showTestUpload = !!data?.showTestUpload;
    this.allowAnonymous = !!data?.allowAnonymous;
    this.forceSingleSignOn = !!data?.forcesinglesignon;
    this.allowPftaInstall = !!data?.allowpftainstall;
    this.multiPfta = data?.multipfta;
    this.pftaOpenDelay = data?.pftaopendelay;
    this.showPftaNotification = !!data?.showpftanotification;
    this.disableUserHelp = !!data?.disableuserhelp;
    this.helpUrl = data?.helpurl;
    this.redirectSignin = !!data?.redirectSignin;
  }
}

export class _RestAPI {
  private http: HttpClient;
  private router: Router;
  private zone: NgZone;
  private app: AppComponent;
  private tilesContainer: TilesContainerComponent;
  private localizer: LocalizeService;
  private dataService: DataService;
  private formService: FormService;
  private schemaService: SchemaService;
  private profileService: ProfileService;
  private oauth2Service: oAuth2Service;
  private ooxmlService: OOxmlService;
  private recentLocService: RecentLocationService;
  private favoriteService: FavoriteService;
  public siteConfigurations = new SiteConfigurations(null);
  private pftaPort: number = kPFTADefaultPortSSL;
  private bGotPFTAPort = false;
  private wsPFTA: WebSocket = null;
  private keyPFTA: string = null;  // Personal File Transfer Agent key
  private versPFTA: string = null;
  private bWasOffline = false;
  private bWarmingCache = false;
  private bDoneFinishLoadHome = false;
  private baseURL = '';
  private sharedDocumentURL: string = localStorage.getItem('sharedDocumentUrl');
  private apiPathVersion: string = '/edocsapi/v'+kAPIVersion;
  private filePathSeparator = '/';
  private tempPath = '';
  private downloadsPath = '/';
  private connectInfo: any = {};
  private libraries: string[] = null;
  private sessionCache: SessionCache;
  private listsToRefresh: ListBaseComponent[] = [];
  private curList: _ListInfo = new _ListInfo();
  private curWindow: WindowModalComponent = null;
  private searchScope: string = null;
  private loginReply: any = {};
  private sessionHeaders: any = {};
  private restAPIHeaders: any = {};
  private authData: any = {};
  private ssoAuthErr: any = null;
  private maxItems: number = kDefaultMaxItems;
  private selectedLibraries: any[] = [];
  private autoCheckinFiles: _AutoCheckinFile[] = [];
  private officeItemChangedListeners: _OfficeItemChangedListener[] = [];
  private customCommands: any = {};
  public nConnectErrors = 0;
  public kMultiFileSeparator = ',; ';
  public kMultiAppIdSeparator = '|';
  public kFullTextTypes: string[] = ['FULLTEXT_CONTENT', 'FULLTEXT_PROFILE', 'FULLTEXT_CONTENT_PROFILE', 'FULLTEXT_EASY_SEARCH'];
  public officeItem: any;
  public pftaNotificationEmitter = new EventEmitter();
  public outlookMailbox: any = null;
  public dragDesc: any;
  public kshareTypes = {documents: '30', documentszip:'30', linkonly:'32',linkonlyzip:'32',all:'32',allzip:'32',url:'120'};
  private folderUploadFiles: any = [];
  private folderUploadFolders: any = [];
  private filesToUpload: any = [];
  private nFolderUploadBatchSize : number = 0;
  private folderUploadResponse: any;
  private nFolderUploadSuccessFiles = 0;
  private nFolderUploadSuccessFolders = 0;
  private nFilesSuccessWithUncheck = 0;
  private nFilesProcessedWithUncheck = 0;
  public nFolderUploadTotalProcessedFiles = 0;
  private nFolderUploadTotalProcessedFolders = 0;
  private folderDropDone: boolean = false;
  private bFilesDragDrop : boolean = false;
  private showFooterOptions = true;
  private refreshTimer: any;

  constructor(private device: _Device,private notify: _Notify,private transforms: _Transforms,private help: _Help) {
    const closeAppWorks = (): boolean => {
      const canClose = (!this.autoCheckinFiles || this.autoCheckinFiles.length === 0);
      if (canClose) {
        appWorksComponent.closeApp();
      }
      return canClose;
    };

    const onClose = () => {
      if (!closeAppWorks()) {
        const title = this.localizer.getTranslation('APP_TITLE');
        const message = this.localizer.getTranslation('GENERIC_ERRORS.APPWORKS_EXIT_WAIT');
        notify.warning(title, message, null, null, null, null, true);
        setInterval(() => {
          closeAppWorks();
        }, 200);
      }
    };

    const onAWComponentError = (error) => {
      const title = this.localizer?.getTranslation('APP_TITLE') || 'eDOCS InfoCenter';
      this.notify.error(title, error);
    };

    const appWorksComponent = new AWComponent(null, onAWComponentError);

    appWorksComponent.registerAppClose(onClose);

    window.onbeforeunload = (e) => {
      this.logOff();
    };
    this.getSiteConfig().then(data => {
      this.siteConfigurations = new SiteConfigurations(data);
    });
  }

  private parseUrlOptions: any = {
    strictMode: true,
    key: ['source', 'protocol', 'authority', 'userInfo', 'user', 'password', 'host', 'port', 'relative', 'path', 'directory', 'file', 'query', 'anchor'],
    q: {
      name: 'queryKey',
      parser: /(?:^|&)([^&=]*)=?([^&]*)/g
    },
    parser: {
      strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
      loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
    }
  };

  public init(router: Router, http: HttpClient, zone: NgZone, app: AppComponent, localizer: LocalizeService, dataService: DataService, formService: FormService, schemaService: SchemaService, profileService: ProfileService, oauth2Service: oAuth2Service, ooxmlService: OOxmlService,
              recentLocService: RecentLocationService,
              favoriteService: FavoriteService): void {
    this.router = router;
    this.http = http;
    this.zone = zone;
    this.app = app;
    this.localizer = localizer;
    this.dataService = dataService;
    this.formService = formService;
    this.schemaService = schemaService;
    this.profileService = profileService;
    this.oauth2Service = oauth2Service;
    this.ooxmlService = ooxmlService;
    this.recentLocService = recentLocService;
    this.favoriteService = favoriteService;
    this.nConnectErrors = 0;
    this.sessionCache = SessionCache.Load(!this.device.bIsElectron && !this.device.bIsCordova);
    this.filePathSeparator = this.transforms.filePathSeparator();
    if (this.device.bIsElectron || this.device.bIsCordova) {
      const checkAuth = () => {
        setTimeout(() => {
          if (this.device.bIsCordova && !cordova.exec) {
            checkAuth();
          } else {
            let bAskingForAuthResponse = false;
            const auth: AWAuth = new AWAuth(data => {
              if (data && data.authData && data.authData.authorizationHeader) {
                this.authData = data.authData;
                this.sessionHeaders['Authorization'] = data.authData.authorizationHeader['Authorization'];
                this.sharedDocumentURL = data.authData.sharedDocumentUrl;
                localStorage.setItem('sharedDocumentUrl',this.sharedDocumentURL);
                this.useAPIServerUrl(data.authData.gatewayUrl);
                this.idleAuth();
              } else {
                alert('AWAuth response authData is null');
                this.notify.error('Error:', 'AWAuth response authData is null');
              }
            },
            error => {
              if (bAskingForAuthResponse) {
                auth.authenticate();
              }
              bAskingForAuthResponse = false;
              this.notify.error('Error:', error);
            });
            auth.online(status => {
              if (status) {
                auth.getAuthResponse();
                bAskingForAuthResponse = true;
              } else {
                // offline
                if (this.device.bIsCordova) {
                  this.libraries = ['edx_offline'];
                  setTimeout(() => {
                    this.loadHome();
                    this.idleAuth(3000);
                    this.bWasOffline = true;
                  }, 1);
                }
              }
            }, error => {
              auth.authenticate();
              console.log(error);
            });
            if (this.device.bIsElectron) {
              const fs: AWFileSystem = new AWFileSystem();
              fs.getPath('appData', path => {
                this.tempPath = path+this.filePathSeparator+'OpenText'+this.filePathSeparator+'DM'+this.filePathSeparator+'ICTemp';
              }, err => {
                this.tempPath = '/';
              });
              fs.getPath('downloads', path => {
                this.downloadsPath = path;
              }, err => {
              });
            } else if (this.device.bIsCordova) {
              this.tempPath = '/';
            }
          }
        }, 250);
      };
      checkAuth();
      if (this.device.bIsAndroid) {
        document.addEventListener('backbutton', e => {
          e.preventDefault();
          e.stopPropagation();
          if (!this.app.isHeaderMasked()) {
            this.app.cordovaLeft();
          }
        }, false);
      }
    } else {
      const postGetSiteConfig = (restUrl: string): void => {
        try {
          const rs = this.device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.platform && !!Office.context.roamingSettings ? Office.context.roamingSettings : null;
          const hdrsStr: string = localStorage.getItem('edx_session_headers');
          const hdrs = rs?.get('edx_session_headers') || hdrsStr ? JSON.parse(hdrsStr) : null;
          const auth = rs?.get('edx_session_auth') || localStorage.getItem('edx_session_auth');
          if (!restUrl) {
            restUrl = rs?.get('edx_session_url') || localStorage.getItem('edx_session_url');
          }
          if (!!restUrl) {
            if (!!hdrs) {
              hdrs['X-DM-LOGIN'] = 'reply';
              this.sessionHeaders = hdrs;
            }
            this.useAPIServerUrl(restUrl, auth, success => {
              if (!success) {
                this.killSessionData();
                this.oauth2Service.reset();
                setTimeout(() => {
                  this.navLogin();
                } , 300);
              }
            });
          } else {
            this.killSessionData();
            this.oauth2Service.reset();
            setTimeout(() => {
              this.navLogin();
            } , 300);
          }
        } catch (e) {
          this.device.bUseWebLogin = true;
          this.sessionHeaders = {};
        }
        setTimeout(()=> {
          this.checkPFTAVersion();
        }, 1000);
      };
      if (this.siteConfigurations.restApi && !this.siteConfigurations.showServer && !this.siteConfigurations.allowAnonymous) {
        postGetSiteConfig(this.siteConfigurations.restApi);
      } else {
        postGetSiteConfig(null);
      }
    }
    const maxItemsStr: string = localStorage.getItem('list_max_items');
    if (maxItemsStr) {
      const maxItems: number = parseInt(maxItemsStr);
      if (!isNaN(maxItems)) {
        this.maxItems = maxItems;
      }
    }
    const xReqWith: string = localStorage.getItem('X-Requested-With');
    if (xReqWith) {
      this.sessionHeaders['X-Requested-With'] = xReqWith;
    }
    if (this.device.bIsOfficeAddin && Office && Office.context && Office.context.mailbox && Office.context.mailbox.addHandlerAsync) {
      Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, item => {
        const isHome: boolean = this.router.url===this.getHomeURL();
        const isLogin: boolean = this.router.url===this.getLoginURL();
        const outlookItem = !!item.initialData && item.initialData.internetMessageId ? item.initialData : item;
        if (!isHome && !isLogin && outlookItem && outlookItem.internetMessageId) {
          this.getCurrentOutlookExistingListItem(outlookItem).then(listItem => {
            this.device.officeDMItem = listItem;
          }, kNoOp);
        }
        for (const listener of this.officeItemChangedListeners) {
          listener.callback();
        }
      }, result => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          console.log('Office.AsyncResultStatus.Failed');
        }
      });
    }
  }

  private wentOffline(set: boolean): void {
    this.app.offlineStateChange(set);
    if (this.tilesContainer) {
      this.tilesContainer.clearTiles();
      this.app.cordovaReloadTiles();
    }
  }

  private idleAuth(authRecheckMS: number=30000): void { // every 30 seconds, 300000; // every five minutes
    const auth: AWAuth = new AWAuth(data => {
      if (data && data.authData && data.authData.authorizationHeader) {
        const reloadUI: boolean = !this.authData || !this.authData.authorizationHeader || this.authData.authorizationHeader.length;
        this.authData = data.authData;
        this.sessionHeaders['Authorization'] = data.authData.authorizationHeader['Authorization'];
        this.idleAuth();
        if (this.bWasOffline) {
          this.wentOffline(false);
        }
        if (reloadUI) {
          setTimeout(() => {
            this.clearCache();
            this.sharedDocumentURL = data.authData.sharedDocumentUrl;
            localStorage.setItem('sharedDocumentUrl',this.sharedDocumentURL);
            this.useAPIServerUrl(data.authData.gatewayUrl);
          }, 3000);
        }
      } else {
        this.idleAuth(3000);
      }
    }, error => {
      auth.authenticate();
      alert(error);
    });
    const checkOnline = () => {
      auth.online(status => {
        const wasOffline: boolean = !this.authData || Object.keys(this.authData).length===0;
        if (status) {
          this.bWasOffline = wasOffline;
          auth.getAuthResponse();
        } else {
          // offline
          this.authData = {};
          if (!wasOffline) {
            this.wentOffline(true);
          }
          this.idleAuth(3000);
        }
      }, error => {
        auth.authenticate();
        alert(error);
      });
    };
    setTimeout(() => {
      checkOnline();
    }, authRecheckMS);
  }

  private reAuth(error: any): void {
    if (!this.bWasOffline) {
      if (this.device.bIsOfficeAddin) {
        this.device.bUseWebLogin = true;
        this.handleLoginError(error);
      } else {
        this.handleError(error);
      }
    }
    setTimeout(() => {
      if (this.device.bIsElectron || this.device.bIsCordova) {
        const auth: AWAuth = new AWAuth(data => {
          if (data && data.authData && data.authData.authorizationHeader) {
            this.authData = data.authData;
            this.sessionHeaders['Authorization'] = data.authData.authorizationHeader['Authorization'];
            this.useAPIServerUrl(data.authData.gatewayUrl);
            this.idleAuth();
          } else {
            this.notify.error('Error:', 'AWAuth response authData is null');
          }
        }, err => {
          this.handleError(error);
        });
        // force user to login again in OTDS
        auth.authenticate(true);
      }
    }, 2500);
  }

  private refreshSession(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const sessionInfo: any = {};
      sessionInfo['SESSION_AUTHORIZATION'] = localStorage.getItem('edx_session_auth') || this.loginReply['SESSION_AUTHORIZATION'];
      setTimeout(() => {
        this.post('/refresh', sessionInfo).subscribe((reply: any) => {
          this.loginReply['SESSION_AUTHORIZATION'] = reply['SESSION_AUTHORIZATION'];
          this.loginReply['SESSION_DURATION'] = reply['SESSION_DURATION'];
          if (reply['X-DM-DST']) {
            this.sessionHeaders['X-DM-DST'] = reply['X-DM-DST'];
          }
          if (!this.device.bIsElectron && !this.device.bIsCordova) {
            localStorage.setItem('edx_session_auth', this.loginReply.SESSION_AUTHORIZATION);
            localStorage.setItem('edx_session_headers', JSON.stringify(this.loginReply.HEADERS));
            this.sessionCache.save();
            if (this.device.bIsOfficeAddin && !!Office.context && !!Office.context.roamingSettings) {
              Office.context.roamingSettings.set('edx_session_headers', this.loginReply.HEADERS);
              Office.context.roamingSettings.set('edx_session_url', this.baseURL);
              Office.context.roamingSettings.set('edx_session_auth', this.loginReply.SESSION_AUTHORIZATION);
              Office.context.roamingSettings.saveAsync();
            }
          }
          if (this.hasPFTA()) {
            const headers: string = encodeURIComponent(this.transforms.b64EncodeUnicode(JSON.stringify(this.sessionHeaders)));
            this.postJSONP('&setheaders=' + headers, '').then(kNoOp, kNoOp);
          }
          resolve(true);
        }, error => {
          reject(error);
        });
      }, 1);
    });
  }

  public setSSOAccessToken(value: string): void {
    this.sessionHeaders['X-DM-AUTH'] = value;
    if (this.isLoggedIn()) {
      this.refreshSession().then(kNoOp, err2 => {
        this.restAPIHeaders = {};
        this.navLogin().then(kNoOp, (err3) => {
          this.handleError(err3);
        });
      });
    }
  }

  public postGetLibs(auth: string, doneCB: (success: boolean) => void): void {
    const postConnect = (loginReply: any) => {
      this.setLoginReply(loginReply);
      setTimeout(() => {
        this.loadHome();
      }, 1);
      if (!!doneCB) {
        doneCB(true);
      }
    };
    const reConnect = () => {
      const data: any = {};
      if (!!auth) {
        data['SESSION_AUTHORIZATION'] = auth;
      }
      data['library'] = this.getPrimaryLibrary();
      data['remoteLibraries'] = '?';
      this.post('/connect', this.addTZInfoToLogin(data)).subscribe(postConnect, error => {
        if (!!doneCB) {
          doneCB(false);
        } else if (++this.nConnectErrors <= 3) {
          this.handleLoginError(error);
          this.reAuth(error);
        } else {
          this.notify.error(this.localizer.getTranslation('RAPI_ERRORS.5'));
        }
      });
    };
    if (!this.sessionCache.loginReply) {
      reConnect();
    } else {
      // On a 'cookie' request, If the client sends a valid DST in the header and X-DM-DST does not exist in the Rest Api cookie,
      // Rest Api will set the X-DM-DST cookie otherwiseÂ it will ignore the request.
      // If Client sends an invalid DST in the header, the rest api responds with a 401 error.
      this.get('/cookie').subscribe((data: any) => {
        postConnect(this.sessionCache.loginReply);
      }, (err) => {
        reConnect();
      });
    }
  }

  private useAPIServerUrl(url: string, auth?: string, doneCB?: (success: boolean) => void): void {
    const errHandler = (err) => {
      if (!!doneCB) {
        doneCB(false);
      } else if (++this.nConnectErrors <= 3) {
        this.reAuth(err);
      } else {
        this.notify.error(this.localizer.getTranslation('RAPI_ERRORS.5'));
      }
    };
    const queryArgs = this.device.bIsElectron || this.device.bIsCordova ? null : 'configuration';
    this.setBaseURL(url);
    this.createMobileImportsFolder();
    this.get('libraries', null, queryArgs).subscribe((data: any) => {
      const libraries: string[] = !!data && !!data['libraries'] ? data['libraries'] : data;
      const ssoData: any = !!data && !!data['authentication'] ? data['authentication'] : null;
      if (!!libraries) {
        this.setLibraries(libraries, !!this.sessionCache.loginReply);
        const authUP = localStorage.getItem('edx_authup');
        if (!!ssoData && ssoData.oidc_enabled && (authUP !== 'true' || !auth) && !(this.device.bIsElectron || this.device.bIsCordova)) {
          this.oauth2Service.login(ssoData).then((token: string) => {
            this.ssoAuthErr = null;
            this.sessionHeaders['X-DM-AUTH'] = token;
            this.postGetLibs(auth, doneCB);
          }, err => {
            this.ssoAuthErr = err;
            delete this.sessionHeaders['X-DM-AUTH'];
            this.sessionCache.clear();
            errHandler(err);
          });
        } else if ((this.device.bIsElectron || this.device.bIsCordova) || (!!this.sessionHeaders && this.sessionHeaders['X-DM-LOGIN'] === 'reply')) {
          setTimeout(() => {
            this.postGetLibs(auth, doneCB);
          }, 1);
        } else if (!!doneCB) {
          doneCB(false);
        }
      } else {
        errHandler({ error: { rapi_code: 1 } });
      }
    }, errHandler);
  }

  //Creating an 'imports' folder by storing a dummy file in the path.
  private createMobileImportsFolder() {
    if (this.device.bIsCordova) {
      const importPath = 'imports';
      const dummyFile = `${importPath}/dummyfile.txt`;
      const setFile = (file) => {
        storage.remove(dummyFile); //Now the 'imports' folder is created and we can delete the dummy file.
        console.log(`${importPath} folder created.`);
      };
      const stopLoading = () => {
      };
      const storage = new AWSecureStorage(setFile, stopLoading);
      const mobileFileSystem: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
      mobileFileSystem.exists(importPath, true, success => {
        console.log(`${importPath} folder exists.`);
      }, error => {
        //We need any content to save in storage as a dummy file. This will create the path and the file. List of libraries are easy to access.
        const resourcePath = this.getServerURLOrigin() + `/libraries?library=primary&configuration`;
        storage.store(resourcePath, dummyFile);
      });
    }
  }

  private clearCache(keepConnectInfo?: boolean): void {
    if (!keepConnectInfo) {
      this.libraries = null;
      this.connectInfo = {};
      this.sessionCache.clear();
    }
    this.curList = new _ListInfo();
    this.curWindow = null;
    this.sessionCache.searchForms = null;
    this.searchScope = null;
    this.loginReply = {};
    this.bWarmingCache = false;
    this.listsToRefresh = [];
    const dlifs = document.body.getElementsByClassName('download-iframe');
    const ndlifs = dlifs ? dlifs.length : 0;
    if (ndlifs) {
      for (let i=ndlifs-1; i>=0; i--) {
        document.body.removeChild(dlifs[i]);
      }
    }
    this.dataService.clearAll();
    this.schemaService.clearCache();
    this.selectedLibraries = [];
  }

  private createHeaders(withHeaders: any={}): HttpHeaders {
    let headers = new HttpHeaders(withHeaders);
    const keys = Object.keys(this.sessionHeaders);
    for (const key of keys) {
      headers = headers.append(key, this.sessionHeaders[key]);
    }
    return headers;
  }

  private getParams(uri: any): any {
    let uriQuery: string = uri['query'];
    if (uriQuery.endsWith('=)')) {
      uriQuery = uriQuery.substr(0,uriQuery.length - 2);
    }
    const ret: any = {};
    const seg: string[] = uriQuery.replace(/^\?/, '').split('&');
    const len: number = seg.length;

    for (let i=0; i < len; i++) {
      if (!seg[i]) {
       continue;
      }
      const singleSplit = (segment: string) => {
        const equalPos = segment.indexOf('=');
        const key = segment.substring(0,equalPos);
        const value = segment.substring(equalPos+1);
        ret[key] = value;
      };
      singleSplit(seg[i]);
    }
    return ret;
  }

  private parseURL(str: string): any {
    const o = this.parseUrlOptions;
    const	m = o.parser[o.strictMode ? 'strict' : 'loose'].exec(str);
    const	uri = {};
    let	i = 14;

    while (i--) {
      uri[o.key[i]] = m[i] || '';
    }
    uri[o.q.name] = {};
    uri[o.key[12]].replace(o.q.parser, ($0, $1, $2) => {
      if ($1) {
        uri[o.q.name][$1] = $2;
      }
    });
    uri['params'] = this.getParams(uri);
    uri['path'] = uri['path'].split('/');
    return uri;
  }

  private findStrInArgs(str: string, args: string[]): string {
    for (const arg of args) {
      const strLen = str.length;
      const index: number = arg.indexOf(str+'=');
      if (index>=0) {
        return arg.slice(strLen+1);
      }
    }
    return null;
  }

  private canGetContainerProfileData(desc: any): boolean {
    if (desc && (desc.type==='folders' || desc.type==='workspaces') && desc.id!=='recentedits' && desc.id!=='downloads' && desc.id!=='imports') {
      return true;
    }
    return false;
  }

  private appendExternalHeaders(headers: any, library: string): void {
    let externalHeaders: any;
    if (!this.sessionHeaders['X-Requested-With']) {
      externalHeaders = {};
      this.sessionHeaders['X-Requested-With'] = '';
    } else {
      externalHeaders = JSON.parse(this.sessionHeaders['X-Requested-With'])['external'];
    }
    const libHeaders = {};
    libHeaders[library] = headers;
    externalHeaders = Object.assign(externalHeaders, libHeaders);
    this.sessionHeaders['X-Requested-With'] = JSON.stringify({external:externalHeaders});
    localStorage.setItem('X-Requested-With', this.sessionHeaders['X-Requested-With']);
  }

  private disconnectPFTA(): void {
    if (this.keyPFTA) {
      this.getJSONP('&disconnect=true').then(res => {
        this.setPFTAParams(null, null);
      }, err => {
        this.setPFTAParams(null, null);
      });
    }
    this.bGotPFTAPort = false;
  }

  private getAutoCheckinFile(aFilePath: string): _AutoCheckinFile {
    return this.autoCheckinFiles.find(f => f.filePath === aFilePath);
  }

  private removeAutoCheckinFile(aFilePath: string): void {
    const acf: _AutoCheckinFile = this.autoCheckinFiles.find(f => f.filePath === aFilePath);
    if (acf) {
      const index: number = this.autoCheckinFiles.indexOf(acf);
      if (index !== -1) {
        this.autoCheckinFiles.splice(index, 1);
      }
    }
  }

  public handleFileClosed(acf: _AutoCheckinFile, aFile: any, modified: boolean): void {
    const done = (item?: any) => {
      if (!!item) {
        this.handleItemChanged(JSON.stringify({data:{set:{},list:[item]}}));
      }
      acf.remove().then(success => {
        this.removeAutoCheckinFile(acf.filePath);
      });
    };
    const replaceVersionWithFile = (fileName: string, filePath: string) => {
      const isManagedFile: boolean = this.transforms.isDMManagedFile(fileName);
      if (isManagedFile) {
        const docObj: any = this.decodeFileName(fileName);
        // check in
        if (!!docObj) {
          const item: any = {type:'documents',id:docObj.docNum,lib:docObj.lib,DOCNAME:docObj.name,DOCNUM:docObj.docNum};
          const serverData: any = this.device.bIsElectron ? {'%STATUS':'%UNLOCK', '%CHECKIN_LOCATION':'', _restapi:{}} : {'%CHECKIN_LOCATION':'', _restapi:{}};
          const doPut = (aDesc: BaseDesc): void => {
            if (modified) {
              this.uploadFilesWithAppWorks([filePath], serverData, true, true, undefined, [aDesc], null, done);
            } else {
              this.put(aDesc, {'%STATUS':'%UNLOCK'}, 'profile', null, {observe:'response'}).subscribe(res => {
                setTimeout(() => {
                  done(item);
                }, 2000);  // we need a refresh
              }, err => {
                done(item);
              });
            }
          };
          if (!!acf.deferedDesc) {
            doPut(acf.deferedDesc);
          } else {
            this.post('searches',{ criteria: {DOCNUM:docObj.docNum}, DESCRIPTION:docObj.docNum },'evaluation').subscribe((listData: ListData) => {
              if (!!listData && listData.list && listData.list.length && listData.list[0]['STATUS']==='3') {
                doPut(listData.list[0]);
              } else {
                done(item);
              }
            }, error => {
              done(item);
            });
          }
        } else {
          done();
        }
      } else if (this.device.isMobile() && fileName && !fileName.startsWith('.')) {
        // create as long it is not a systme file such as .DS_Store on macs
        this.uploadFilesWithUI(null, [filePath]);
        setTimeout(() => {
          done(); // we need to do this in a call back from upload when it is done
        },10000);
      }
    };
    if (aFile && aFile.name && aFile.path) {
      replaceVersionWithFile(aFile.name, aFile.path);
    } else {
      done();
    }
  }

  private checkTempPathOverride(): void {
    if (this.device.bIsElectron && this.loginReply['SYSTEM_DEFAULTS']) {
      let downloadsPathOverride: string = null;
      if (this.device.bIsWindows) {
        downloadsPathOverride = this.loginReply['SYSTEM_DEFAULTS']['USERDOWNLOADS'];
      } else if (this.device.bIsMacintosh) {
        downloadsPathOverride = this.loginReply['SYSTEM_DEFAULTS']['USERDOWNLOADS_MAC'];
      }
      const checkPath = (kind: string, path: string) => {
        if (path) {
          const fs: AWFileSystem = new AWFileSystem();
          const parts: string[] = path.split(this.filePathSeparator);
          const firstComp: string = parts[0];
          const pathSansFirstComp: string = parts.slice(1).join(this.filePathSeparator);
          let isRelative = false;
          switch (firstComp) {
            case 'home':
            case 'appData':
            case 'userData':
            case 'temp':
            case 'desktop':
            case 'documents':
            case 'downloads':
            case 'music':
            case 'pictures':
            case 'videos':
              isRelative = true;
              break;
          }
          if (isRelative) {
            fs.getPath(firstComp, responsePath => {
              path = responsePath+this.filePathSeparator+pathSansFirstComp;
              fs.exists(path, exists => {
                if (exists) {
                  if (kind === 'temp') {
                    this.tempPath = path;
                  } else {
                    this.downloadsPath = path;
                  }
                }
              }, kNoOp);
            }, kNoOp);
          } else {
            fs.exists(path, exists => {
              if (exists) {
                if (kind === 'temp') {
                  this.tempPath = path;
                } else {
                  this.downloadsPath = path;
                }
              }
            }, kNoOp);
          }
        }
      };
      checkPath('downloads', downloadsPathOverride);
    }
  }

  private uniqueDLName(path: string, fileName: string): Promise<string> {
    const nameExt: string[] = fileName.split('.');
    let name: string;
    let ext: string;
    if (nameExt.length>2) {
      ext = nameExt[nameExt.length-1];
      nameExt.length = nameExt.length - 1;
      name = nameExt.join('.');
    } else if (nameExt.length<2) {
      ext = '';
      name = nameExt[0];
    } else {
      name = nameExt[0];
      ext = nameExt[1];
    }
    return new Promise<string>((resolve, reject) => {
      if (this.device.bIsElectron) {
        const fs: AWFileSystem = new AWFileSystem();
        let nAttempts = 0;
        const check = () => {
          const uniqueName = name + (nAttempts>0 ? (' ('+nAttempts+')') : '') + '.' + ext;
          fs.exists(path + uniqueName, exists => {
            if (exists) {
              ++nAttempts;
              check();
            } else {
              resolve(uniqueName);
            }
          }, err => {
            resolve(uniqueName);
          });
        };
        check();
      } else {
        reject('electron only');
      }
    });
  }

  public restAPIVersion(): number {
    let rc = 0x00160301;  // of the form 00 major minor dot
    if (this.loginReply && this.loginReply.SERVER_INFO && this.loginReply.SERVER_INFO.VERSION) {
      rc = this.numerateVersion(this.loginReply.SERVER_INFO.VERSION);
    }
    return rc;
  }

  public clientVersion(): number {
    let rc = 0x00160400;  // of the form 00 major minor dot
    rc = this.numerateVersion(this.device.version);
    return rc;
  }

  private numerateVersion(versionString: string): number {
    let rc = 0x00000000;  // of the form 00 major minor dot
    const parts: string[] = versionString.split('.');
    let nParts = parts.length;
    if (nParts > 3) {
      nParts = 3;
    } // Use only first 3 version values
    if (nParts===3) {
      let value = '0x00';
      for (let i=0; i<nParts; i++) {
        if (parts[i].length===1) {
          value += '0';
        }
        value += parts[i];
      }
      rc = parseInt(value);
    }
    return rc;
  }

  public addTZInfoToLogin(data: any): any {
    const now: Date = new Date();
    const jan: Date = new Date(now.getFullYear(), 0, 1);
    const jul: Date = new Date(now.getFullYear(), 6, 1);
    const tzOffset: number = now.getTimezoneOffset();
    const stdTZOffset: number = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    data['tzOffset'] = tzOffset;
    data['tzDST'] = tzOffset < stdTZOffset ? true : false;
    return data;
  }

  public offline(): boolean {
    return this.device.bIsCordova && (!this.authData || !this.authData.authorizationHeader);
  }

  public registerOfficeItemChangedEvent(cb: any): number {
    const oicl: _OfficeItemChangedListener = new _OfficeItemChangedListener(cb);
    this.officeItemChangedListeners.push(oicl);
    return oicl.id;
  }

  public deRegisterOfficeItemChangedEvent(id: number): void {
    const oicl: _OfficeItemChangedListener = this.officeItemChangedListeners.find(l => l.id === id);
    const index = !!oicl ? this.officeItemChangedListeners.indexOf(oicl) : -1;
    if (index !== -1) {
      this.officeItemChangedListeners.splice(index, 1);
    }
  }

  public hasPFTA(): boolean {
    return !!this.keyPFTA;
  }

  public pftaVersion(): number {
    let rc = 0;
    if (this.versPFTA) {
      rc = this.numerateVersion(this.versPFTA);
    }
    return rc;
  }

  public pftaAboutVersion(): string {
    return this.versPFTA;
  }

  public probePFTA(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      let vers = this.pftaVersion();
      if (!!vers) {
        resolve(vers);
      } else {
        this.getJSONP('&version').then(res => {
          if (!!res.data && !!res.data.version) {
            vers = this.numerateVersion(res.data.version);
          }
          resolve(vers);
        }, err1 => {
          resolve(0);
        });
      }
    });
  }

  private checkPFTAVersion(): void {
    if (!this.device.bIsElectron && !this.device.bIsCordova && (!this.device.isMobile() || this.device.bIsOfficeAddin)) {
      const dontCheckPFTA = this.getPreference('dont_ask_pfta_download');
      if (dontCheckPFTA !== '1' && !!this.siteConfigurations.allowPftaInstall) {
        this.probePFTA().then(version => {
          if (version < this.clientVersion()) {
            const path: string = location.pathname;
            const pathParts: string[] = path.split('/');
            pathParts.splice(pathParts.length - 1, 1);
            const pftaName: string = 'eDOCS Personal File Transfer Agent.' + (this.device.bIsMacintosh ? 'dmg' : 'msi');
            const url: string = location.origin + pathParts.join('/') + '/installers/' + encodeURIComponent(pftaName);
            const formData: any = {
              dont_ask_pfta_download: '0',
              version
            };
            const title: string = this.localizer.getTranslation('FORMS.LOCAL.PFTA.PFTA');
            const ok: string = this.localizer.getTranslation(this.device.bIsOfficeAddinDesktop ? 'FORMS.BUTTONS.OK' : 'FORMS.LOCAL.CHECK_OUT.DOWNLOAD');
            this.notify.confirm(title, '__local_pfta_check', ok, formData, true, true, false).then(confirmed => {
              if (!!confirmed) {
                if (confirmed.data && confirmed.data.dont_ask_pfta_download) {
                  this.setPreference('dont_ask_pfta_download', confirmed.data.dont_ask_pfta_download);
                }
                if (confirmed.confirm) {
                  if (this.device.bIsOfficeAddinDesktop && !!Office.context && !!Office.context.ui && !!Office.context.ui.displayDialogAsync) {
                    const width = Math.ceil(80000 / window.screen.width);
                    const height = Math.ceil(60000 / window.screen.height);
                    Office.context.ui.displayDialogAsync(location.origin + pathParts.join('/') + '/installers/installers.html', { height, width });
                  } else if (this.device.bIsTeamsAddIn) {
                    microsoftTeams.authentication.authenticate({
                      url: location.origin + pathParts.join('/') + '/installers/installers.html',
                      width: 600,
                      height: 535,
                      successCallback: kNoOp,
                      failureCallback: (error: any) => {
                        console.error(error);
                      }
                    });
                  } else {
                    this.downloadSiteFile(url, pftaName);
                  }
                }
              }
            });
          }
        });
      }
    }
  }

  private setPFTAParams(key, vers) {
    this.keyPFTA = key;
    this.versPFTA = vers;
    this.pftaNotificationEmitter.emit('update');
  }

  private checkForPFTA(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.setPFTAParams(null, null);
      if (!this.device.bIsElectron && !this.device.bIsCordova) {
        let downloadsPathOverride: string = null;
        const noWS = (error) => {
          this.wsPFTA = null;
          this.startLongPollPFTA();
        };
        const connectResponse = (resConn) => {
          this.setPFTAParams(resConn.data.key, resConn.data.pftaversion);
          this.setAutocheckinForPFTA().then(kNoOp, () => {
            this.setPFTAParams(null, null);
          });
          if (this.loginReply['SYSTEM_DEFAULTS']) {
            if (this.device.bIsWindows) {
              downloadsPathOverride = this.loginReply['SYSTEM_DEFAULTS']['USERDOWNLOADS'];
            } else if (this.device.bIsMacintosh) {
              downloadsPathOverride = this.loginReply['SYSTEM_DEFAULTS']['USERDOWNLOADS_MAC'];
            }
          }
          const handlePath = (kind: string, resPath: any) => {
            if (!!resPath && !!resPath.data && !!resPath.data.path) {
              if (kind === 'temp') {
                this.tempPath = resPath.data.path;
              } else {
                this.downloadsPath = resPath.data.path;
              }
            }
          };
          const getPath = (kind: string): void => {
            this.getJSONP('&get' + kind + 'path=true').then(res2 => {
              handlePath(kind, res2);
            }, kNoOp);
          };
          const setPath = (kind: string, pathOverride: string): void => {
            this.getJSONP('&set' + kind + 'path=' + encodeURIComponent(this.transforms.b64EncodeUnicode(pathOverride))).then(res3 => {
              handlePath(kind, res3);
            }, err3 => {
              getPath(kind);
            });
          };
          getPath('temp');
          if (!!downloadsPathOverride) {
            setPath('downloads', downloadsPathOverride);
          } else {
            getPath('downloads');
          }
          try {
            this.wsPFTA = new WebSocket('wss://' + kLocalHost + ':' + this.pftaPort + '?key=' + this.keyPFTA);
            this.wsPFTA.onerror = noWS;
            this.wsPFTA.onmessage = (message) => {
              this.handleItemChanged(message.data);
            };
          } catch (ews) {
            noWS(ews);
          }
          resolve(true);
        };
        const headers: string = encodeURIComponent(this.transforms.b64EncodeUnicode(JSON.stringify(this.restAPIHeaders)));
        const restUrl: string = encodeURIComponent(this.transforms.b64EncodeUnicode(this.getServerURLOrigin()));
        const userID: string = encodeURIComponent(this.transforms.b64EncodeUnicode(this.getUserID()));
        const userAgent: string = encodeURIComponent(this.transforms.b64EncodeUnicode(navigator.userAgent));
        const connReq: string = '&connect=' + this.device.version + '&restheaders=' + headers + '&resturl=' + restUrl + '&userid=' + userID + '&useragent=' + userAgent;
        this.getJSONP(connReq).then(connectResponse, () => {
          this.setPFTAParams(null, null);
          reject(false);
        });
      } else {
        reject(false);
      }
    });
  }

  private startLongPollPFTA(): void {
    this.getJSONP('&longpoll=true').then(message => {
      if (message && message.data) {
        this.handleItemChanged(message.data);
      } else {
        this.startLongPollPFTA();
      }
    }, err => {
    });
  }

  private handleItemChanged(data: string): void {
    try {
      const json = JSON.parse(data);
      if (!!json && !!json.data) {
        if (!!json && !!json.data && !!json.data.set) {
          if (this.curList.listComponent) {
            this.curList.listComponent.listItemChanged(json.data);
          }
          if (this.tilesContainer) {
            this.tilesContainer.listItemChanged(json.data);
          }
          if (this.curWindow) {
            this.curWindow.refresh();
          }
        } else if (!!json.data.cmd) {
          switch (json.data.cmd) {
            case 'open':
              const listItem = !!json.data.list && json.data.list.length ? json.data.list[0] : null;
              if (!!listItem) {
                this.setCurDesc(listItem);
                const url: string = this.makeChildRouteURL('home', 'tcc_outlet', listItem.type, listItem, null, '&max='+kDefaultMaxItems+'&name='+this.encodeChildRouteName(listItem.DOCNAME));
                this.navToURL(url);
              }
              break;
          }
        } else if (!!json.data.error) {
          this.handleError({pfta_err: json.data.error});
        }
      }
    } catch (e) {
    }
  }

  private setAutocheckinForPFTA(): Promise<boolean> {
    if (!this.device.bIsElectron && !this.device.bIsCordova) {
      let url = '&autocheckin='+this.hasAutoCheckoutCheckin();
      if (this.hasInstantSave()) {
        url += '&instantsave=true';
      }
      return this.getJSONP(url).then(data => true, err => false);
    }
    return Promise.resolve(false);
  }

  public prefsChanged(): void {
    if (!!this.hasPFTA) {
      this.setAutocheckinForPFTA().then(kNoOp, kNoOp);
    }
  }

  public showDirSelectorWithPFTA(options?: any): Promise<string> {
    if (!this.device.bIsElectron && !this.device.bIsCordova) {
      options = options || {};
      const optionsStr: string = encodeURIComponent(this.transforms.b64EncodeUnicode(JSON.stringify(options)));
      return this.getJSONP('&pickfolder='+optionsStr).then(response => response.data ? response.data.paths : null, err => null);
    }
    return Promise.resolve(null);
  }

  public rand(min: number, max: number): number {
    const winCrypto = window.crypto || (window as any).msCrypto;
    const randomBuffer = new Uint32Array(1);
    winCrypto.getRandomValues(randomBuffer);
    const randomNumber = randomBuffer[0] / 0x100000000;
    if (min > max) {
      const tmp = min;
      min = max;
      max = tmp;
    }
    min = Math.ceil(min);
    max = Math.floor(max);
    if (min === max) {
      max = min + 1;
    }
    return (randomNumber * (max - min)) + min;
  }

  public deepCopy(data: any): any {
    return JSON.parse(JSON.stringify(data));
  }

  public isExternalLib(lib: string): boolean {
    if (!!lib) {
      if (lib.toUpperCase().startsWith(this.getExternalLibraryPrefix().toUpperCase())) {
        return true;
       }
    }
    return false;
  }
  public getExternalLibraryPrefix(): string {
    return this.loginReply['EXTERNAL_LIB_PREFIX'] || 'ex_';
  }

  public isExtAppOutlookForSendMail(): boolean {
    let app: any = null;
    app = this.findExternalAppType('outlook');
    if (!!app) {
      if (app.access.indexOf('contacts') !== -1 || app.access.indexOf('sendmail') !== -1) {
        return true;
      }
    }
    return false;
  }

  public IsAppOutlookAddin(): boolean {
    if (this.device.bIsOfficeAddin && Office && Office.context && Office.context.mailbox) {
      return true;
    }
    return false;
  }

  public isExtAppEnabledForSaveToeDOCS(desc: any): boolean {
    let app: any = null;
    app = this.findExternalAppType(desc);
    if (!!app) {
      if (app.access.indexOf('savetoedocs') !== -1) {
        return true;
      }
    }
    return false;
  }

  public AuthenticateOutlook(): Promise<string> {
    let fromAddress = '';
    const app = this.findExternalAppType('outlook');
    return new Promise<string>((resolve, reject) => {
      if (!!app) {
        this.authenticateExternal(app).then(success => {
          if (success) {
            if (app.access.indexOf('contacts') !== -1 || app.access.indexOf('sendmail') !== -1) {
                this.getOutlookEmailFrom().then(result => {
                  const response = {};
                  response['data'] = !!result && !!result.hasOwnProperty('data') ? result['data'] : result;
                  const list: ListItem[] = !!response['data'] ? response['data'].list : '';
                  for (const item of list) {
                    if (item[2]['PROPNAME'] ==='$edx_outlook_email_from') {
                      fromAddress = item[2]['DATA'];
                      this.setOutlookPreferences('whoamI', fromAddress);
                      break;
                    }
                  }
                //get addressbook entries
                this.getOutlookAddressBookEntry().then(data => {
                  if (!!data && data.list) {
                    const list = data.list[0];
                    this.setOutlookPreferences('addressbookentry', JSON.stringify(list));
                 }
                });
                resolve(fromAddress);
              }, error => {
                reject(error);
              });
            }
          }
        }, error => {
          reject(error);
        });
      }
    });
 }

  public isExtAppTeams(lib: string): boolean {
    const app = this.findExternalApp(lib);
    if (!!app && app['apptype'] === 'teams') {
      return true;
    }
    return false;
  }

  public isTeamsAppUploadTarget(desc: any, set?: any): boolean {
    const isTeamsApp = this.isExtAppTeams(desc.lib);
    if (isTeamsApp) {
      if (desc['DOCNAME'] === 'Files' && desc['app_metadata']) {
        return ( desc['app_metadata']['rights'].length ? desc['app_metadata']['rights'].includes('uploadfile') : false );
      } else if (!!set && !!set['application']['rights']) {
        return ( set['application']['rights'].length ? set['application']['rights'].includes('uploadfile') : false );
      }
    }
    return false;
  }

  public isContainer(type: string): boolean {
    if (type === 'searches' || type === 'folders' || type === 'flexfolders' || type === 'workspaces' || type === 'fileplans' || type === 'boxes' || type === 'requests' || type === 'activities') {
      return true;
    }
    return false;
  }

  public isContainerWithId(desc: any): boolean {
    if (this.isContainer(desc.type) && (!isNaN(parseInt(desc.id)) || this.isExternalLib(desc.lib))) {
      return true;
    }
    return false;
  }

  public isAContainerOfGivenType(desc: any, type: string): boolean {
    return desc && desc['id'] === '' && desc['type'] === type;
  }

  public handleFavoritesDataRefresh(): void {
    this.favoriteService.getFavoriteIdsForAllLibraries();
    this.refreshTimer = setInterval(() => {
      this.favoriteService.getFavoriteIdsForAllLibraries();
    }, kRefreshInterval);
  }

  public isFavoriteItemSelected(): boolean {
    let isFavorite = false;
    const selections: any = this.curList.listComponent?.getSelections();
    if (!!selections && selections.length > 0) {
      isFavorite = !!selections.find(item => item.is_favorite);
    } else {
      const curItem = this.curList?.item;
      if (!!curItem['is_favorite']) {
        isFavorite = curItem['is_favorite'];
      } else {
        isFavorite = this.favoriteService.isFavoriteItem(curItem);
      }
    }
    return isFavorite;
  }

  public isFlexfolderLevelNode(desc: any): boolean {
    if (desc.type === 'flexfolders' && !!desc.id && (desc.id.indexOf('DV_LEVEL_NODE')>=0 || desc.id.indexOf('DV_ENUMERATION_NODE')>=0 || desc.id.indexOf('DV_GROUP_NODE')>=0)) {
      return true;
    }
    return false;
  }

  public isSharedDownloads(desc: any): boolean {
    if (desc && desc.id==='downloads' && desc.type==='folders') {
      return true;
    }
    return false;
  }

  public isSharedImports(desc: any): boolean {
    if (desc && desc.id==='imports' && desc.type==='folders') {
      return true;
    }
    return false;
  }
  public isUnmangedFile(desc: any): boolean {
    return (desc && desc.type==='documents' && desc.id==='0' && desc.DOCNUM==='-' && desc.lib==='-');
  }

  public isEmailField(name: string): boolean {
    return kEmailFields.indexOf(name)!==-1;
  }

  public dragHasFiles(dt: DataTransfer): boolean {
    const types = dt.types;
    const items = dt.items;
    if (items && items.length) {
      // eslint-disable-next-line
      for (let i=0; i<items.length; i++) {
        const item = items[i];
        if (item.kind==='file') {
          return true;
        }
      }
    } else if (this.device.bIsOfficeAddin && types) {
      if (this.device.bIsMacintosh) {
        if (types.indexOf('Files') !== -1) {
          return true;
        }
      } else if (this.device.bIsWindows) {
        return true;  // ass-you-me we do
      }
    }
    return false;
  }

  private dragHasTextType(dt: DataTransfer, type: string): boolean {
    const items = dt.items;
    if (!!items) {
      const nItems = items.length;
      // eslint-disable-next-line
      for (let i=0; i<nItems; i++) {
        const item = items[i];
        if (item.kind==='string' && item.type===('text/'+type)) {
          return true;
        }
      }
    }
    return false;
  }

  public dragHasJSON(dt: DataTransfer): boolean {
    return this.dragHasTextType(dt,'json');
  }

  public dragHasPromisedFile(dt: DataTransfer): boolean {
    return this.dragHasTextType(dt,'promised-file');
  }

  // will return a string array of file paths or a File array
  public getDropFiles(dt: DataTransfer, dropDesc?): Promise<any[]> {
    const files: File[] = [];
    const filePaths: string[] = [];
    let gettingFolderContents = false;
    const fs: AWFileSystem = new AWFileSystem();
    const fieldNameToCheck = this.device.bIsOfficeAddin ? 'name' : 'type';  // outlook has an empty string type so fallback to name but we need type for folders on non outlook
    const scanFiles = (item) => {
      item.file(aFile => {
        if (!aFile.name.startsWith('.')) {
          files.push(aFile);
        }
      });
    };
    if (dt.files) {
      // eslint-disable-next-line
      for (let i=0; i<dt.files.length; i++) {
        const dtFile = dt.files[i];
        const lcName = !!dtFile.name ? dtFile.name.toLowerCase() : '';
        if (!!dtFile[fieldNameToCheck] || (lcName.endsWith('.msg') || lcName.endsWith('.drf'))) {
          files.push(dtFile);
        } else if (this.device.bIsElectron && (dtFile as any).path) {
          // could be a directory
          gettingFolderContents = true;
          fs.isDir((dtFile as any).path, (bIsDir) => {
            if (bIsDir) {
              fs.listDirContents((dtFile as any).path, (results: any[]) => {
                for (const file of results) {
                  if (!file.name.startsWith('.') && !file.isDirectory) {
                    filePaths.push(file.path);
                  }
                }
                gettingFolderContents = false;
              });
            } else {
              gettingFolderContents = false;
              if (!dtFile.name.startsWith('.')) {
                files.push(dtFile);
              }
            }
          }, error => {
            gettingFolderContents = false;
            //error is thrown if it is not a directory and handling made similar to IC Web
            if (!dtFile.name.startsWith('.')) {
              files.push(dtFile);
            }
          });
        } else if (!!dt.items[i].webkitGetAsEntry) {
          const item = dt.items[i].webkitGetAsEntry();
          if (item.isDirectory) {
            if (!!dropDesc && !this.isExternalLib(dropDesc.lib)) {
              if (this.canUserCreateFolders() && this.getRightsForItem(dropDesc).canEditContent) {
                this.showFolderUploadSpinner(true);
                this.folderUploadFolders.push({ name: item.name, path: item.name });
                this.parseDirectoryEntry(item, item.name);
                this.setFolderDropDone(true);
                this.getAppComponent().commandsComponent.createFromCmd('newfolder', dropDesc, null, true);
              } else {
                const title = this.localizer.getTranslation('GENERIC_ERRORS.RESTRICTED');
                const body = this.localizer.getTranslation('GENERIC_ERRORS.NOT_AUTHORIZED');
                this.notify.warning(title, body);
              }
            }
          } else {
            scanFiles(item);
          }
        }
      }
      this.setLastCmdName(null);
      this.bFilesDragDrop = !this.isFolderDropDone();
    }
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        if (gettingFolderContents) {
          setTimeout(waitFunc,100);
        } else {
          resolve(filePaths.length ? filePaths : files.length ? files : null);
        }
      };
      setTimeout(waitFunc,100);
    });
  }

  public escapeFileName(fileName: string): string {
    if (this.device.bIsAndroid) {
      // (" []=|,^<>\t\r\n\\.*?:\"/\'") we add % because of double decoding in AW Android
      return fileName.replace(/[ \[\]=|\,;^<>\t\r\n\\\.*?:\"/'!%$&#@~\`\{\}\(\)\+]/g,'_');
    }
    return fileName.replace(/[ \[\]=|\,;^<>\t\r\n\\\.*?:\"/'+]/g,'_'); // (" []=|,^<>\t\r\n\\.*?:\"/\'") all other platforms
  }

  private deleteFileFromDownloads(path: string): Promise<boolean> {
    let bDeleted = false;
    if (this.device.bIsCordova && !!path) {
      const parts: string[] = path.split('/');
      const fileName: string = parts[parts.length-1];
      let gettingDownloads = true;
      const awPath = '/' + (parts.indexOf('imports') >= 0 ? 'imports/' : '');
      const mfs: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
      mfs.remove(awPath + fileName, false, success => {
        bDeleted = true;
        gettingDownloads = false;
      }, error => {
        gettingDownloads = false;
      });
      return new Promise<any>((resolve, reject) => {
        const waitFunc = () => {
          if (gettingDownloads) {
            setTimeout(waitFunc,100);
          } else {
            resolve(bDeleted);
          }
        };
        setTimeout(waitFunc,100);
      });
    }
    return Promise.resolve(bDeleted);
  }

  public deleteFromDownloads(item: ListItem): Promise<boolean> {
    return this.deleteFileFromDownloads((item as any).fullPath);
  }

  public fileNameFromDownloads(listItem: ListItem, version?: string): Promise<string> { // null if it does not exist
    return this.fileNameFromDirectory('/', listItem, version);
  }

  private fileItemsToListItems(list: any[]): ListItem[] {
    const items: ListItem[] = [];
    for (const item of list) {
      if (item.type==='file' && item.filename && item.filename.length && !item.filename.startsWith('.')) {
        const dateStr: string = (new Date(item.lastmodified*1000)).toUTCString();
        const isManagedFile: boolean = !item.filename.endsWith('.drf') && !item.filename.endsWith('.DRF') && this.transforms.isDMManagedFile(item.filename);
        const fullName: string = isManagedFile ? decodeURIComponent(item.filename) : item.filename;
        let appID: string  = this.getAppIDForFile(fullName, null);
        // Try to guess since DEFAULT may be a result of off-line state
        if (appID === 'DEFAULT') {
          const guess: string = this.transforms.fileNameToAppID(fullName);
          appID = guess==='' ? 'DEFAULT' : guess;
        }
        let listItem: ListItem = null;
        if (isManagedFile) {
          const docObj: any = this.decodeFileName(fullName);
          if (!!docObj) {
            listItem = new ListItem({
              type: 'documents',
              id: docObj.docNum,
              DOCNUM: docObj.docNum,
              lib: docObj.lib,
              DOCNAME: docObj.name,
              LAST_EDIT_DATE: dateStr,
              APP_ID: appID
            });
            listItem['ver'] = docObj.vers;
          }
        } else {
          // not an edocs file, put here by another app
          listItem = new ListItem({
            type: 'documents',
            id: '0',
            DOCNUM: '-',
            lib: '-',
            DOCNAME: fullName,
            LAST_EDIT_DATE: dateStr,
            APP_ID: appID
          });
        }
        if (listItem) {
          listItem['fullPath'] = item.path ? item.path : this.sharedDocumentURL+this.filePathSeparator+fullName;
          items.push(listItem);
        }
      }
    }
    return items;
  }

  public getDownloadsList(imports: boolean): Promise<ListItem[]> {
    let gettingDownloads = false;
    let items: ListItem[] = [];
    const getDLItems = () => {
      if (this.device.bIsCordova) {
        gettingDownloads = true;
        const mfs: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
        const handleError = (error) => {
          console.log(error);
          gettingDownloads = false;
        };
        const handleSuccess = (list) => {
          items = this.fileItemsToListItems(list);
          gettingDownloads = false;
        };
        if (imports) {
          mfs.listImports(handleSuccess, handleError);
        } else {
          mfs.list('/', false, handleSuccess, handleError);
        }
      } else if (this.device.isMobile() && !this.device.bIsOfficeAddin) {
        const nProbedPFTAWaits = 0;
        gettingDownloads = true;
        const getPFTADL = () => {
          this.getJSONP('listdirectory=' + encodeURIComponent(this.transforms.b64EncodeUnicode(this.downloadsPath))).then(res => {
            if (!!res.data && !!res.data.list) {
              items = this.fileItemsToListItems(res.data.list);
            }
            gettingDownloads = false;
          }, error2 => {
            console.log(error2);
            gettingDownloads = false;
          });
        };
        const waitPFTA = () => {
          if (this.hasPFTA()) {
            getPFTADL();
          } else {
            if (nProbedPFTAWaits === 0) {
              this.probePFTA().then(version => {
                if (version >= 0x00160702) {
                  setTimeout(waitPFTA, 100);
                } else {
                  gettingDownloads = false;
                }
              });
            } else {
              if (nProbedPFTAWaits > 10) {
                gettingDownloads = false;
              } else {
                setTimeout(waitPFTA, 100);
              }
            }
          }
        };
        waitPFTA();
      }
    };
    return new Promise<any>((resolve, reject) => {
      const waitForAWFunc = () => {
        if (gettingDownloads) {
          setTimeout(waitForAWFunc, 100);
        } else {
          resolve(items);
        }
      };
      const waitForCache = () => {
        if (this.offline() || this.cacheIsLoaded()) {
          getDLItems();
          waitForAWFunc();
        } else {
          setTimeout(() => {
            waitForCache();
          }, 100);
        }
      };
      waitForCache();
    });
  }

  public decodeFileName(fileName: string): any {
    if (!!fileName) {
      let fn = String(fileName);
      const docNumIndex = fn.indexOf(this.transforms.docIDSeparator);
      if (docNumIndex !== -1) {
        const lib: string = fn.substring(0, docNumIndex);
        fn = fn.substring(docNumIndex+2);
        const versIndex = fn.indexOf(this.transforms.versionLabelSeparator);
        if (versIndex !== -1) {
          const docNum = fn.substring(0, versIndex);
          fn = fn.substring(versIndex+2);
          const nameStartIndex: number = fn.indexOf(this.transforms.hyphenSeparator);
          if (nameStartIndex !== -1) {
            const vers: string = fn.substring(0,nameStartIndex);
            const fullName: string = fn.substring(nameStartIndex+1);
            const nameExt: string[] = fullName.split('.');
            const ucLibs: string[] = !!this.libraries ? this.libraries.map(l => l.toLocaleUpperCase()) : [];
            let name: string;
            let ext: string;
            if (nameExt.length>2) {
              ext = nameExt[nameExt.length-1];
              nameExt.length = nameExt.length - 1;
              name = nameExt.join('.');
            } else if (nameExt.length<2) {
              ext = '';
              name = nameExt[0];
            } else {
              name = nameExt[0];
              ext = nameExt[1];
            }
            return {
              lib,
              docNum,
              vers,
              name,
              ext,
              libExists: ucLibs.indexOf(lib.toUpperCase()) >=0
            };
          }
        }
      }
    }
    return null;
  }

  public fileNameFromDirectory(dirPath: string, listItem: ListItem, versionId?: string): Promise<string> { // null if it does not exist
    let rc: string = null;
    let waitingForAppWorks = false;
    const fileNameMatches = (name: string): boolean => {
      let matches = false;
      const docObj: any = this.decodeFileName(name);
      if (!!docObj) {
        if (listItem.DOCNUM === docObj.docNum && listItem.lib === docObj.lib && (!versionId || !versionId.length || versionId === 'C' || versionId === '0' || versionId === docObj.vers)) {
          matches = true;
        }
      }
      return matches;
    };
    if (this.device.bIsCordova) {
      if (listItem.id==='0' && listItem.DOCNUM==='-' && listItem.lib==='-') {
        rc = listItem.DOCNAME;
      } else if (listItem['fullPath']) {
        // from downloads we have the file on disk use the last component
        const fullPath: string = listItem['fullPath'];
        const parts: string[] = fullPath.split(this.filePathSeparator);
        rc = parts[parts.length-1];
      } else {
        waitingForAppWorks = true;
        const mfs: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
        mfs.list(dirPath, false, list => {
          for (const item of list) {
            if (item.type==='file' && item.filename && item.filename && !item.filename.toUpperCase().endsWith('.DRF')) {
              if (fileNameMatches(item.filename)) {
                rc = item.filename;
                break;
              }
            }
          }
          waitingForAppWorks = false;
        }, error => {
          console.log(error);
          waitingForAppWorks = false;
        });
      }
    } else if (this.device.bIsElectron) {
      const fs: AWFileSystem = new AWFileSystem();
      fs.listDirContents(dirPath, (results: any[]) => {
        for (const file of results) {
          if (!file.isDirectory && file.name && file.name.length && !file.name.startsWith('.') && !file.name.startsWith('~$') && !file.name.toUpperCase().endsWith('.DRF')) {
            if (fileNameMatches(file.name)) {
              rc = file.name;
              break;
            }
          }
        }
        waitingForAppWorks = false;
      }, err => {
        waitingForAppWorks = false;
      });
    }
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        if (waitingForAppWorks) {
          setTimeout(waitFunc,100);
        } else {
          resolve(rc);
        }
      };
      setTimeout(waitFunc,100);
    });
  }

  public getFileNameFromPath(path: string): string {
    const comps: string[] = path.split(this.filePathSeparator);
    return comps[comps.length-1];
  }

  public findParamValueInURL(url: string, key: string): string {
    const urlParts = url.split('?');
    if (urlParts.length>1) {
      const queryargStr: string = urlParts[1];
      const queryArgs: string[] = queryargStr.split('&');
      return this.findStrInArgs(key, queryArgs);
    }
    return null;
  }

  public handleError(error: any): void {
    if (error) {
      const title = this.localizer.getTranslation('GENERIC_ERRORS.ERROR');
      this.notify.error(title, error);
    }
  }

  private killSessionData(): void {
    if (!this.device.bIsElectron && !this.device.bIsCordova) {
      if (this.device.bIsOfficeAddin && Office.context && Office.context.roamingSettings) {
        Office.context.roamingSettings.set('edx_session_headers', null);
        Office.context.roamingSettings.set('edx_session_url', null);
        Office.context.roamingSettings.set('edx_session_auth', null);
        Office.context.roamingSettings.saveAsync();
      }
      localStorage.removeItem('edx_session_headers');
      localStorage.removeItem('edx_session_url');
      localStorage.removeItem('edx_session_auth');
      localStorage.removeItem('edx_authup');
      localStorage.removeItem('edx_favorites');
      this.device.bUseWebLogin = true;
      this.sessionHeaders = {};
    }
    this.sessionCache.clear();
    clearInterval(this.refreshTimer);
  }

  public canAutoConnect(): boolean {
    return !!this.sessionHeaders && Object.keys(this.sessionHeaders).length > 0;
  }

  public handleLoginError(error: any): boolean {
    const prefs: string = localStorage.getItem('$edx_preferences');
    const formData: any = prefs ? JSON.parse(prefs) : null;
    if (formData) {
      let changed = false;
      if (formData['old_primary']) {
        formData['primary'] = formData['old_primary'];
        delete formData['old_primary'];
        changed = true;
      } else if (formData['primary']) {
        delete formData['primary'];
        changed = true;
      }
      if (changed) {
        localStorage.setItem('$edx_preferences', JSON.stringify(formData));
        this.clearCache();
        setTimeout(() => {
          this.notify.warning(this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.PRIMARY_LIB'), this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.SWITCHING_BACK'));
        }, 1500);
        return true;
      }
    }
    this.killSessionData();
    if (this.sessionHeaders['X-DM-LOGIN']) {
      delete this.sessionHeaders['X-DM-LOGIN'];
    }
    this.restAPIHeaders = {};
    return false;
  }

  public logOff(bDMLogout?: boolean, bFullLogout?: boolean): void {
    const doLogOff = () => {
      const finishLogOff = () => {
        if (bFullLogout || bDMLogout) {
          this.killSessionData();
          if (bFullLogout) {
            this.oauth2Service.logout().then(kNoOp, kNoOp);
          }
        }
        this.disconnectPFTA();
        this.restAPIHeaders = {};
        this.navLogin();
      };
      if (this.restAPIVersion() >= 0x00160702 && (bFullLogout || bDMLogout)) {
        this.get('disconnect').subscribe(data => {
          finishLogOff();
        }, err => {
          finishLogOff();
        });
      } else {
        finishLogOff();
      }
      ICC.globals = {};
      if (!!this.profileService) {
        this.profileService.destroy().then(kNoOp, kNoOp);
      }
    };
    if (bDMLogout && bFullLogout && this.hasPFTA() && this.pftaVersion()>=0x00210400) {
      const library = this.getPrimaryLibrary();
      this.getJSONP(`&getmonitorfiles=true&library=${library}`).then(res => {
        if (!!res.data && !!res.data.paths && !!res.data.paths.length) {
          const names = res.data.paths.map(p => {
            const parts = p.split(this.filePathSeparator);
            return parts[parts.length-1];
          }).join(', ');
          const title = this.localizer.getTranslation('SETTINGS.LOGOFF');
          const body = this.localizer.getTranslation('SIGN_OUT.CONFIRM', [names]);
          this.notify.confirm(title,body,null,null,true,true,true).then(confirmed => {
            if (confirmed && confirmed.confirm) {
              doLogOff();
            }
          });
        } else {
          doLogOff();
        }
      }, err => {
        doLogOff();
      });
    } else {
      doLogOff();
    }
  }

  public isLoggedIn(): boolean {
    return this.restAPIHeaders && Object.keys(this.restAPIHeaders).length!==0;
  }

  public reloadTiles(): void {
    if (this.tilesContainer) {
      this.tilesContainer.reset();
    } else {
      this.app.resetTiles();
    }
    this.bWasOffline = false;
  }

  public tilesChanged(tiles: Tile[]): void {
    this.put('/settings/tiles', tiles).subscribe(response => {
    }, error => {
      this.handleError(error);
    });
    this.app.tilesChanged();
  }

  public isPermaTile(tile: Tile): boolean {
    return this.app.isPermaTile(tile);
  }

  public remoteLibrariesChanged(): void {
    this.app.tilesChanged();
  }

  public openDRF(drf: string, name: string): void {
    try {
      const parts: string[] = drf.split(';');
      const lib: string = parts[1];
      const docID: string = parts[2];
      let vers: string = parts[3];
      if (vers === 'R') {
        vers = 'C';
      }
      const listItem: ListItem = new ListItem({id:docID,DOCNUM:docID,lib,DOCNAME:name,type:''});
      if (this.offline()) {
        this.viewFile(listItem, vers, null, null, false);
      } else {
        this.post('searches',{ criteria: {DOCNUM:docID}, DESCRIPTION: docID },'evaluation','?library='+lib).subscribe((data: ListData) => {
          let foundContainer = false;
          let foundFile = false;
          if (!!data && data.list && data.list.length) {
            const searchItem: ListItem = new ListItem(data.list[0]);
            if (this.isContainer(searchItem.type)) {
              let queries: string = 'name='+this.encodeChildRouteName(searchItem.DOCNAME)+'&max=25';
              if (searchItem['imgPath']) {
                queries += '&imgPath='+this.encodeChildRouteName(searchItem['imgPath']);
              }
              const url: string = this.makeChildRouteURL('home', 'tcc_outlet', searchItem.type, searchItem, null, queries);
              this.setCurDesc(searchItem);
              this.app.cordovaNavToUrl(url);
              foundContainer = true;
            } else {
              foundFile = true;
              if (vers !== 'C') {
                this.get(searchItem,'versions','?library='+lib).subscribe((data2: ListData) => {
                  const versionItem = data2 && data2.list ? data2.list.find(v => v.VERSION_LABEL===vers) : null;
                  if (versionItem && versionItem.VERSION_ID) {
                    vers = versionItem.VERSION_ID;
                  }
                  this.viewFile(searchItem, vers, null, null, false);
                }, err1 => {
                  this.handleError(err1);
                });
              } else {
                this.viewFile(searchItem, vers, null, null, false);
              }
            }
          }
          if (!foundContainer && !foundFile) {
            this.handleError({error:{rapi_code:15}});
          }
        }, error => {
          this.handleError(error);
        });
      }
    } catch (e) {}
  }

  public openDRFItem(listItem: ListItem): void {
    const fullPath: string = listItem['fullPath'];
    if (fullPath && fullPath.toUpperCase().endsWith('.DRF')) {
      if (this.device.bIsCordova) {
        const fs: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
        fs.readFile(listItem.DOCNAME, false, (data: string): void => {
          if (data && data.length) {
            this.openDRF(data, listItem.DOCNAME);
          }
        }, error => {
          this.handleError(error);
        });
      } else if (this.device.bIsElectron) {
        const fs: AWFileSystem = new AWFileSystem();
        fs.readFile(listItem.DOCNAME, (data: string): void => {
          if (data && data.length) {
            this.openDRF(data, listItem.DOCNAME);
          }
        }, error => {
          this.handleError(error);
        });
      } else if (this.hasPFTA) {
        this.getJSONP('&readfile='+encodeURIComponent(this.transforms.b64EncodeUnicode(fullPath))).then(res => {
          if (res && res.data && res.data.b64Data) {
            this.openDRF(atob(res.data.b64Data), listItem.DOCNAME);
          }
        }, err => {
          this.handleError(err);
         });
      }
    }
  }

  public openDRFFile(file: File, filePath: string): boolean {
    const filePathParts: string[] = filePath ? filePath.split(this.filePathSeparator) : null;
    const fileName: string = file ? file.name : filePathParts ? filePathParts[filePathParts.length-1] : null;
    if (fileName && fileName.toUpperCase().endsWith('.DRF')) {
      if (file) {
        const reader: FileReader = new FileReader();
        reader.onload = (e) => {
          const fileStr: string = !!reader.result ? ((typeof reader.result === 'string') ? (reader.result as string) : String.fromCharCode.apply(null, new Uint16Array(reader.result))) : null;
          if (fileStr && fileStr.length) {
            this.openDRF(fileStr, fileName);
          }
        };
        reader.readAsBinaryString(file);
      } else {
        if (this.device.bIsCordova) {
          const fs: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
          fs.readFile(fileName, false, (data: string): void => {
            if (data && data.length) {
              this.openDRF(data, fileName);
            }
          }, error => {
            this.handleError(error);
          });
        } else if (this.device.bIsElectron) {
          const fs: AWFileSystem = new AWFileSystem();
          fs.readFile(filePath, (data: string): void => {
            if (data && data.length) {
              this.openDRF(data, fileName);
            }
          }, error => {
            this.handleError(error);
          });
        }
      }
      return true;
    }
    return false;
  }

  private finishLoadHome(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.bWasOffline && this.tilesContainer) {
        this.tilesContainer.clearTiles();
      }
      if (this.device.bIsCordova) {
        this.app.cordovaNavHome();
      } else {
        this.navHome().then(success => {
          if (!success && this.tilesContainer) {
            this.tilesContainer.reset();
          }
        });
      }
      if (this.bWasOffline) {
        setTimeout(() => {
          if (this.device.bIsCordova) {
            this.app.cordovaReloadTiles();
          }
        }, 1000);
      }
      this.bDoneFinishLoadHome = true;
      resolve(true);
    });
  }

  public showSystemNotification(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const notif = this.loginReply.SYSTEM_NOTIFICATIONS[0];
      this.notify.confirm(notif.title, notif.message, notif.button_ok, null, !!notif.button_cancel, !!notif.button_ok, true).then(res => {
        if (!!res && res.confirm) {
          const navLocation = window.location.href;
          if (navLocation.indexOf('tcc_outlet') <= 0) {
            this.finishLoadHome().then(success => {
              resolve(success);
            });
          } else {
            resolve(true);
          }
        } else {
          this.logOff(true);
          resolve(false);
        }
      });
    });
  }

  public loadHome(): Promise<boolean> {
    this.bDoneFinishLoadHome = false;
    if (this.offline()) {
      return this.finishLoadHome();
    } else if (!!this.loginReply.SYSTEM_NOTIFICATIONS && this.loginReply.SYSTEM_NOTIFICATIONS.length) {
      return new Promise<boolean>((resolve, reject) => {
        setTimeout(() => {
          if (this.device.bIsCordova) {
            const waitForFinish = () => {
              if (this.bDoneFinishLoadHome) {
                resolve(true);
              } else {
                setTimeout(waitForFinish, 100);
              }
            };
            this.app.cordovaShowSystemNotification();
            waitForFinish();
          } else {
            this.showSystemNotification().then(success=> {
              resolve(success);
            });
          }
        }, 1000);
      });
    } else {
      return this.finishLoadHome();
    }
  }

  private finishNavUrl(url: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const doNav = () => {
        if (!!this.router) {
          this.router.navigateByUrl(url).then(success => {
            if (success) {
              setTimeout(() => {
              resolve(true);
              }, 300);
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      };
      if (!!this.zone) {
        this.zone.run(doNav);
      } else {
        doNav();
      }
    });
  }

  public navToURL(url: string): Promise<boolean> {
    if (this.offline() && url.indexOf('%2Ffolders%2Fdownloads')===-1 && url !== this.getHomeURL()) {  // child url encoded
      this.notify.warning(this.localizer.getTranslation('GENERIC_ERRORS.NOT_AVILABLE_OFFLINE'));
      return new Promise<boolean>((resolve, reject) => {
        resolve(false);
      });
    }
    if (url.indexOf('tcc_outlet:splitview')>=0 && location.href.indexOf('tcc_outlet:splitview')>=0) {
      this.curWindow.useURL(url);
      history.replaceState(null,null,url);
      return new Promise<boolean>((resolve, reject) => {
        resolve(true);
      });
    }
    return this.finishNavUrl(url);
  }

  public navHome(): Promise<boolean> {
    this.dataService.clearAll();
    return this.finishNavUrl(this.getHomeURL());
  }

  public navLogin(): Promise<boolean> {
    return this.finishNavUrl(this.getLoginURL());
  }

  public navPreferences(): void {
    this.navToURL(this.getPrefsURL());
  }

  public navAdmin(): void {
    this.navToURL(this.getAdminMaintenanceURL());
  }

  public isAdminPage(): boolean {
    return this.router.url === this.getAdminMaintenanceURL();
  }

  public navBack(): void {
    this.app.navBack();
  }

  public navToSearchURL(childRouteName: string, searchCriteria: any): void {
    const ticks: number = new Date().getTime();
    const extraQuery: string = !!searchCriteria.$edx_extra_query ? searchCriteria.$edx_extra_query : '';
    const queryString: string = 'name=' + this.encodeChildRouteName(this.localizer.getTranslation('HEADER.SEARCH_RESULTS')) + '&max=' + this.getDefualtMaxItems() + extraQuery;
    const desc: any = { id: 'evaluation' + ticks, lib: this.getSearchLibQuery(), type: 'searches' };
    const url: string = this.makeChildRouteURL('home', 'tcc_outlet', childRouteName, desc, null, queryString);

    //store the search criteria
    if (searchCriteria.$edx_in_folder) {
      delete searchCriteria.$edx_in_folder;
    }
    this.dataService.setSearchData(desc, searchCriteria);
    this.navToURL(url);
  }

  private postSearchData(desc: any, searchCriteria: any, filterString: string): Observable<ListData> {
    let queryString: string = 'descending=LAST_EDIT_DATE&start=0&max=' + this.getDefualtMaxItems();
    if (filterString) {
      queryString += '&filter=' + filterString;
      if (desc && desc.id && desc.id.startsWith('evaluation')) {
        this.dataService.setSearchData(desc, searchCriteria);
      }
    } else {
      //store the search criteria
      this.dataService.setSearchData(desc, searchCriteria);
    }
    return this.post(desc, searchCriteria, null, queryString);
  }

  public stringifyFilters(filters: any): string {
    let filterString = '';
    if (!!filters && Object.keys(filters).length > 0) {
      filterString = Object.keys(filters).map(key => key + '=' + encodeURIComponent(filters[key])).join(' and ');
    }
    return filterString;
  }

  public stringifyFiltersFromDesc(desc: BaseDesc): string {
    return this.stringifyFilters(this.dataService.getFilters(desc));
  }

  public doFilterSearch(desc: any, params: string, searchCriteria: any, filters: any, sortKey: string): Observable<ListData> {
    const filterString: string = this.stringifyFilters(filters);
    this.dataService.setFilters(desc, filters);
    if (desc && desc.id && desc.id.startsWith('evaluation')) {
      this.dataService.setSearchData(desc, searchCriteria);
      return this.postSearchData(desc, searchCriteria, filterString);
    }
    return this.get(desc, params, 'max=' + this.getDefualtMaxItems() + '&' + (sortKey || 'descending=LAST_EDIT_DATE') + (!!filterString ? ('&filter=' + filterString) : ''));
  }

  public conformFulltextCriteria(criteria: any): void {
    if (!!criteria) {
      this.kFullTextTypes.forEach((ft) => {
        if (!!criteria[ft]) {
          // No need to pass FULLTEXT_EDIT when full text search is empty.
          if (criteria[ft].length > 0) {
            criteria['FULLTEXT_EDIT'] = criteria[ft];
          }
          criteria['SEARCH_IN'] = String(this.kFullTextTypes.indexOf(ft));
          delete criteria[ft];
        }
      });
      const keys: string[] = Object.keys(criteria);
      for (const key of keys) {
        if (!!criteria[key]) {
          criteria[key] = criteria[key].replace(/[|]/g, ',');
        }
      }
    }
  }

  public loggedOut(): void {
    this.clearCache(true);
  }

  public getHomeURL(): string {
    return '/home';
  }

  public getLoginURL(): string {
    return '/login';
  }

  public getPrefsURL(): string {
    const realUrl: string = this.encodeChildRouteName('preferences?name='+this.localizer.getTranslation('SETTINGS.PREFERENCES'));
    return '/home/(tcc_outlet:preferences;'+realUrl+'=)';
  }

  public getAdminMaintenanceURL(): string {
    const realUrl: string = this.encodeChildRouteName('admin?name='+this.localizer.getTranslation('ADMIN.ADMINISTRATION'));
    return '/home/(tcc_outlet:admin;'+realUrl+'=)';
  }

  public setBaseURL(baseURL: string): void {
    if (this.baseURL !== baseURL) {
      this.baseURL = baseURL;
      this.ssoAuthErr = null;
    }
  }

  public getBaseURL(): string {
    return this.baseURL;
  }

  public getSharedDocumentURL(): string {
    return this.sharedDocumentURL;
  }

  public getSsoAuthErr(): any {
    return this.ssoAuthErr;
  }

  public setSsoAuthErr(err: string): any {
    this.ssoAuthErr = err;
  }


  public getDMHeaders(): any {
    return this.loginReply.HEADERS;
  }

  public canShowPreview(): boolean {
    return this.loginReply && this.loginReply.EFFECTIVE_RIGHTS && this.loginReply.EFFECTIVE_RIGHTS.ALLOW_PREVIEW !== 'N';
  }

  public hasAutoCheckoutCheckin(): boolean {
    return (this.device.bIsElectron || !!this.keyPFTA) && this.getPreference('def_checkout_open') !=='0';
  }

  public hasInstantSave(): boolean {
    return this.getPreference('instant_save') !== '0';
  }

  public getPreference(key: string, preferencesKey = '$edx_preferences'): string {
    const formDataStr: string = localStorage.getItem(preferencesKey);
    if (formDataStr && key) {
      try {
        return JSON.parse(formDataStr)[key];
      } catch (e) { }
    }
    return null;
  }

  public setPreference(key: string, value: string, preferencesKey = '$edx_preferences'): void {
    const formDataStr: string = localStorage.getItem(preferencesKey);
    try {
      const formData: any = formDataStr && formDataStr.length ? JSON.parse(formDataStr) : {};
      if (!!formData[key] && !value) {
        delete formData[key];
      } else if (value !== null || value !== undefined) {
        formData[key] = value;
      }
      localStorage.setItem(preferencesKey, JSON.stringify(formData));
    } catch (e) { }
  }

  public getNewTileIndex(heroTile: Tile, redTile: Tile): number {
    if (this.canUserAddTileAfterRed()) {
      if (this.getPreference('add_tile_after_red', '$edx_preferences_tiles') === '1') {
        const heroIndex = heroTile?.index;
        const redIndex = redTile?.index;
        if ((heroIndex >= 0 && redIndex >= 0 && heroIndex <= 1 && redIndex <= 1) || heroIndex === 1 || redIndex === 1) {
          return 2;
        } else if (heroIndex === 0 || redIndex === 0) {
          return 1;
        } else {
          return 0;
        }
      }
    }

    return -1;
  }

  public getLibraries(all?: boolean): any[] {
    let libs: any[] = [];
    let disabledState = 'N';
    if (this.loginReply && this.loginReply.LIBRARIES && this.loginReply.LIBRARIES.length) {
      libs = this.deepCopy(this.loginReply.LIBRARIES);
      disabledState = 'Y';
    }
    if ((this.libraries && this.libraries.length) && (all || !libs)) {
      for (const lib of this.libraries) {
        const existingLib: any = libs.find(l => l.LIBRARY_NAME.toUpperCase() === lib.toUpperCase());
        if (!existingLib) {
          libs.push({DISABLED:disabledState,LIBRARY_NAME:lib,LIBRARY_DESC:lib,only_root:disabledState});
        }
      }
    }
    return libs;
  }

  public getLibrariesList(): string[] {
    const libs = this.getLibraries();
    return !!libs ? libs.map(l => l.LIBRARY_NAME) : [this.getPrimaryLibrary()];
  }

  public getLibraryNamesListInLowerCase(): string[] {
    const libs = this.getLibraries();
    return !!libs ? libs.map(l => l.LIBRARY_NAME.toLocaleLowerCase()) : [this.getPrimaryLibrary().toLocaleLowerCase()];
  }

  public setLibraries(libraries: string[], keepConnectInfo?: boolean) {
    this.clearCache(keepConnectInfo);
    this.libraries = libraries;
    if (this.libraries) {
      const lib: string = this.getPreference('primary');
      if (typeof lib === 'string' && lib.length) {
        const index: number = this.libraries.indexOf(lib);
        if (index!==0) {
          if (index!==-1) {
            this.libraries.splice(index,1);
          }
          this.libraries.splice(0,0,lib);
        }
      }
    } else {
      this.app.starting = false;
    }
  }

  public getPrimaryLibrary(): string {
    return this.libraries && this.libraries.length ? this.libraries[0] : '';
  }

  public setPrimaryLibrary(lib: string, reconnect: boolean=true): void {
    if (this.libraries && this.libraries.length) {
      const index: number = this.libraries.indexOf(lib);
      if (index!==0) {
        if (index!==-1) {
          this.libraries.splice(index,1);
        }
        this.libraries.splice(0,0,lib);
        if (reconnect) {
          this.reconnect();
        }
      }
    } else {
      this.libraries = [lib];
    }
  }

  public changePrimaryLibrary(lib?: string): void {
    // will call back to setPrimaryLibrary or clear tiles if lib is null
    this.app.changePrimaryLibrary(lib);
  }

  public setTilesContainer(tilesContainer: TilesContainerComponent): void {
    this.tilesContainer = tilesContainer;
    this.app.starting = false;
  }

  public getTiles(): Tile[] {
    return this.tilesContainer ? this.tilesContainer.getTiles() : this.app.getTiles();
  }

  public saveGroupTileSettings(groupName: string, tiles: Tile[], params: string, queryArgs: string): Promise<Tile[]> {
    return this.app.saveTilesForGroup(groupName, tiles, params, queryArgs);
  }

  public getDefaultTiles(): Tile[] {
    if (!!this.loginReply && !!this.loginReply['DEFAULT_TILES']) {
      return this.loginReply['DEFAULT_TILES'];
    }
    return this.tilesContainer ? this.tilesContainer.getDefaultTiles() : this.app.getDefaultTiles();
  }

  public setTiles(tiles: Tile[]): void {
    if (this.tilesContainer) {
      this.tilesContainer.setTiles(tiles);
    } else {
      this.app.setTiles(tiles);
    }
  }

  public getTileMaxRows(): number {
    return !!this.loginReply && !!this.loginReply['TILE_MAX_ROWS'] ? this.loginReply['TILE_MAX_ROWS'] : 12;
  }

  public IsFavoritesEnabled(): boolean {
    return !!this.loginReply && !!this.loginReply['FAVORITES_ENABLED'] ? this.loginReply['FAVORITES_ENABLED'] === 'Y' : false;
  }

  public shareChanged(item: any): void {
    if (this.tilesContainer) {
      this.tilesContainer.shareChanged(item);
    }
  }

  public reconnect(): void {
    const primary = this.getPrimaryLibrary();
    const libraries = this.getLibrariesList();
    if (this.device.bIsOfficeAddin && libraries.indexOf(primary) === -1) {
      this.logOff(true);  // SESSION_AUTHORIZATION does not work on non remote libs
    } else {
      this.connectInfo.library = primary;  // incase it changed and we are logging into another library the user just chose
      const connectInfo = this.device.bIsOfficeAddin ? Object.assign({}, this.connectInfo, {SESSION_AUTHORIZATION:localStorage.getItem('edx_session_auth')||this.loginReply['SESSION_AUTHORIZATION']}) : this.connectInfo;
      this.loggedOut();
      if (this.tilesContainer) {
        this.tilesContainer.reconnect();
      } else if (this.app) {
        this.app.resetTiles();
      }
      if (this.device.bIsOfficeAddin) {
        this.sessionHeaders['X-DM-LOGIN'] = 'reply';  // incase it has not been set, we do not have a user id or passord stored
      }
      this.post('/connect',connectInfo).subscribe((loginReply: any) => {
        this.setLoginReply(loginReply);
        this.bWarmingCache = false;
        this.warmCache();
        // let the tiles be reset after the loginreply is set
        if (this.tilesContainer) {
          this.tilesContainer.reset();
        }
        this.app.reconnected();
        setTimeout(() => {
          this.navHome();
        }, 1);
      }, error => {
        if (this.device.bIsOfficeAddin) {
          this.logOff(true);  // office add ins cannot reconnect if the primary groups do not match
        } else {
          this.handleError(error);
        }
      });
    }
  }

  public getCurList(): ListItem[] {
    return this.curList.list;
  }

  public getCurSet(): any {
    return this.curList.set;
  }

  public setCurList(list: ListItem[], set: any): void {
    this.curList.set = set;
    this.curList.list = list;
  }

  public getCurWindow(): WindowModalComponent {
    return this.curWindow;
  }

  public getLastCmdName(): string {
    return this.curWindow ? this.curWindow['viewFolders']?.lastCmd : null;
  }

  public setLastCmdName(cmdName): void {
    if(!!this.curWindow && !! this.curWindow['viewFolders']) {
      this.curWindow['viewFolders'].lastCmd = cmdName; 
    }
  }

  public updateFilesDragDrop(value) : void {
    this.bFilesDragDrop = value;
  }
  
  public isFilesDragDrop() : boolean {
    return this.bFilesDragDrop;
  }

  public getFilesToUpload() : any {
    return this.filesToUpload;
  }

  public setCurWindow(curWindow: WindowModalComponent): void {
    this.curWindow = curWindow;
  }

  public getCurWindowName(): string {
    return this.curWindow ? this.curWindow.getName() : null;
  }

  public getCurItem(): ListItem {
    return this.curList.item;
  }

  public setCurItem(item: ListItem): void {
    this.curList.item = item;
  }

  public refreshCurWindow(cmd?: string, value?: any): void {
    if (!!this.curWindow) {
      this.curWindow.refresh(cmd, value);
    }
  }

  public getLastCopyContainer(): any {
    const desc: any = this.curList.listComponent && this.curList.listComponent.desc ? (this.curList.listComponent.desc.type==='searches' && this.getPreference('edx_search_where')!=='0')  ? this.curList.desc : this.curList.listComponent.desc : this.curList.lastClosedList;
    if (desc && desc.id!=='recentedits' && desc.id!=='checkedout' && (desc.type==='folders' || desc.type==='workspaces')) {
      return desc;
    }
    return null;
  }

  public getCurListComponent(): ListBaseComponent {
    return this.curList.listComponent;
  }

  public setCurListComponent(listComponent: ListBaseComponent): void {
    this.curList.lastClosedList = this.curList.listComponent ? this.curList.listComponent.desc : null;
    this.curList.listComponent = listComponent;
  }

  public setCurDesc(desc: any): void {
    this.curList.desc = desc;
  }

  public setCurDescForFlexFolders(url: string): void {
    if (url.includes('flexfolder')) {
      const listComponent: any = this.getCurListComponent();
      if (!!listComponent && !!listComponent.set && !!listComponent.set.flexinfo && !!listComponent.set.flexinfo.levels) {
        const urlDesc = this.getDescFromURL(this.decodeChildRouteURL(url));
        if (!!urlDesc) {
          const levelDesc = listComponent.set.flexinfo.levels.find(level => level.id === urlDesc.id);
          if (!!levelDesc) {
            this.setCurDesc(levelDesc);
          }
        }
      }
    }
  }

  public getCurDesc(): any {
    return this.curList.desc;
  }

  public getAppDesc(): any {
    return this.app.desc;
  }

  public setShowFooterOptions(): void {
    this.getGroupFooterDefaults(this.loginReply.GROUP, this.loginReply.LIB).then((data)=>{
      this.showFooterOptions = !data  || !!parseInt(data?.['showfooteroptions']);
    });
  }

  public canShowFooterOptions(): boolean {
    return this.showFooterOptions;
  }

  public getGroupFooterDefaults(groupName: string, lib: string): Promise<any> {
    if (!groupName) {
      return Promise.resolve([]);
    }
    return new Promise<any[]>((resolve, reject) => {
      let queryArgs = '';
      const filters = {
        name: 'GroupFooterDefaults'
      };
      const filterString = this.stringifyFilters(filters);
      queryArgs = '?library=' + (lib ? lib :this.getPrimaryLibrary());
      queryArgs += '&filter=' + filterString;
      this.get('settings', 'multiple/group/' + groupName, queryArgs).toPromise().then((footerdefaults: any[]) => {
        resolve(footerdefaults);
      });
    });

  }

  public setFooterOptions(settingValue: string, addInApplication: string): Observable<any> {
    if (!!settingValue) {
      return this.put('settings/footeroptions/' + addInApplication, settingValue);
    } else {
      return this.delete('settings/footeroptions/' + addInApplication);
    }
  }

  public getFooterOptions(addInApplication: string, settingName: string): any {
    return this.get('settings/' + settingName + '/' + addInApplication).toPromise();
  }

  public getOfficeItem(): any {
    return this.officeItem;
  }

  public setOfficeItem(officeItem: any): void {
    this.officeItem = officeItem;
  }

  private setFormsList(list: any[], library?: string): void {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    this.sessionCache.forms[library] = list;
    this.sessionCache.save();
  }

  private setSearchFormsList(list: any[]): void {
    this.sessionCache.searchForms = list.sort((a: any, b: any) => {
      const strA = (a['%FORM_NAME'] || ' ').toLocaleUpperCase();
      const strB = (b['%FORM_NAME'] || ' ').toLocaleUpperCase();
      return strA.localeCompare(strB);
    });
    this.sessionCache.save();
  }

  public setSelectedLibraries(library: any): void {
    if (library) {
      const indx = this.selectedLibraries.findIndex(l => l.LIBRARY_NAME === library.LIBRARY_NAME);
      if (library.checked) {
        // if library is not already present in the list, then add it.
        if (indx === -1) {
          this.selectedLibraries.push(library);
        }
      } else {
        if (indx >= 0) {
          this.selectedLibraries.splice(indx, 1);
        }
      }
      this.setPrimaryLibraryIndex();
      let libs = '';
      for (const lib of this.selectedLibraries) {
        libs += lib.LIBRARY_NAME + ',';
      }
      if (libs.length) {
        libs = libs.substr(0, libs.length-1);
        this.setPreference('$edx_selected_libraries', libs);
      }
    }
  }

  //set the primary library index to 0
  public setPrimaryLibraryIndex(): void {
    if (this.selectedLibraries) {
      const primaryLib = this.selectedLibraries.find(lib => lib.isPrimary);
      if (primaryLib) {
        const primaryLibIndex = this.selectedLibraries.indexOf(primaryLib);
        if (primaryLibIndex > 0) {
          this.selectedLibraries.splice(0, 0, this.selectedLibraries.splice(primaryLibIndex, 1)[0]);
        }
      }
    }
  }

  public getSelectedLibraries(): any[] {
    return this.selectedLibraries;
  }

  public getSelectedLibrariesList(): string[] {
    return !this.device.bIsOfficeAddin && this.selectedLibraries ? this.getSelectedLibraryNames() : [this.getPrimaryLibrary()];
  }

  public getSelectedLibraryNames(): string[] {
    if (!!this.selectedLibraries) {
      return this.selectedLibraries.map((lib) => lib.LIBRARY_NAME);
    }
    return null;
  }

  private getSelectedLibrariesString(library?: string): string {
    let libraries: string[];
    if (this.selectedLibraries) {
      libraries = this.getSelectedLibraryNames();

      //when saved search is available in a remote or primary library, that library should sent first
      if (library) {
        library = library.split(',')[0];
        const libraryIndex = libraries.indexOf(library);
        //when the library of a saved search is in the selected libraries list, remove it
        if (libraryIndex >= 0) {
          libraries.splice(libraryIndex, 1);
        }
        // add the search library at first poistion
        libraries.splice(0, 0, library);
      }
    }
    return libraries ? libraries.join(',') : this.getPrimaryLibrary();
  }

  public getSearchLibQuery(library?: string): string {
    return this.restAPIVersion() >= 0x00160700 ? (library || this.getPrimaryLibrary()) : this.getSelectedLibrariesString(library);
  }

  public canHaveFilters(desc: any, params?: string): boolean {
    // tiles do not allow filters, so if you filter a list we do not want the tile to have the same filter. tile always has a lower case size field in desc
    return !!desc && (!!desc.id && (this.isContainerWithId(desc) ||  desc.type === 'searches' || params === 'history')) && !this.isSharedDownloads(desc) && !this.isSharedImports(desc) && !this.isExternalLib(desc.lib);
  }

  public getLookups(library: string): any {
    library = library || this.getPrimaryLibrary();
    return !!this.sessionCache.lookups ? this.sessionCache.lookups[library] : null;
  }

  private verifyLookupTable(key: string, library: string): string {
    const lookupsForLib: any = this.getLookups(library);
    if (lookupsForLib) {
      if (!lookupsForLib[key]) {
        let lookupKey;
        const keys: string[] = Object.keys(lookupsForLib);
        // work around multi language such as FRA_GROUPS_ENA
        for (lookupKey of keys) {
          if (lookupKey.endsWith(key) && lookupKey.split(key).length===2) {
            return lookupKey;
          }
        }
        if (key.startsWith('PD_')) {
          const altKey = key.substr(3);
          for (lookupKey of keys) {
            if (lookupKey.endsWith(altKey) && lookupKey.split(altKey).length===2) {
              return lookupKey;
            }
          }
        }
        const indexEnabled: number = key.indexOf('_ENABLED');
        if (indexEnabled>0) {
          const primaryKeyPart = key.substr(0,indexEnabled)+'_ENA';
          for (lookupKey of keys) {
            if (lookupKey.indexOf(primaryKeyPart)>=0) {
              return lookupKey;
            }
          }
        }
      }
    }
    return key;
  }

  public getLookupTable(id: string, library: string, profile: string): any {
    let rc: any = null;
    const lookupsForLib: any = this.getLookups(library);
    const key: string = id/* + "__" + profile*/;// TODO: RON: add back in when setLookupsList can capture the form id
    if (lookupsForLib) {
      rc = lookupsForLib[this.verifyLookupTable(key, library)];
    }
    return rc;
  }

  public setLookupsList(list: any[], library: string): void {
    this.sessionCache.lookups[library] = {};
    for (const item of list) {
      if (item.LOOKUPID && item.TABLE_NAME) {
        this.sessionCache.lookups[library][item.LOOKUPID] = item.TABLE_NAME;
      }
    }
    this.sessionCache.save();
  }

  private getFormsList(filter: string, library?: string): Promise<any[]> {
    const filters: any[] = ['TYPE=PROFILE', 'TYPE=SEARCH'];
    let list: any[] = [];
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    return this.get('/forms' + (library ? '?library=' + library : ''), undefined, 'filter='+filter).toPromise().then((formInfo: any) => {
      try {
        //create a default profile form from login reply
        const defaultFormName: string = this.loginReply['DEFAULT_FORM'] || 'DEFAULT';
        const defaultForm: any = {
          lib: library,
          FORM_TITLE: defaultFormName,
          FORM_TYPE: 'P',
          id: defaultFormName
        };
        if (!!formInfo && formInfo.list) {
          list = formInfo.list;
          // update the form id's in data
          if (!filter.startsWith('APP_ID')) {
            list.forEach((form) => {
              form.id = form['%FORM_NAME'];
              form.FORM_TITLE = form['%FORM_TITLE'] || form.id;
            });
          }
          // assign default profile form when profile forms are requested
          if (filters.indexOf(filter) === 0) {
            this.sessionCache.defaultProfileForm[library] = list.filter(form => form['%FORM_APPLICATION'] === '%PRIMARY_FORM')[0] || defaultForm;
          } else if (filters.indexOf(filter) === 1) {
            this.sessionCache.defaultForms['TYPE=SEARCH'] = list.filter(form => form['%FORM_DEFAULT_PRIMARY'] === 'Y')[0] || list[0];
          }
        } else {
          list = [];
          if (filters.indexOf(filter) === 0) {
            //map the list created using default form in login reply
            this.sessionCache.defaultProfileForm[library] = defaultForm;
            list = [defaultForm];
          }
        }
      } catch (e) {
        list = [];
      }
      return list;
    }, error => list);
  }

  public getProfileFormsForApp(appId?: string, library?: string): any[] {
    if (!library || this.isExternalLib(library)) {
      library = this.getPrimaryLibrary();
    }
    library = library.toUpperCase();
    let forms: any[];
    if (appId && appId.length > 0 && !!this.sessionCache.forms && this.sessionCache.forms[library]) {
      forms = this.sessionCache.forms[library].filter(form => form['%FORM_APPLICATION'] === appId);
    }
    if (!(forms && forms.length > 0)) {
      forms = this.getDefaultProfileForm(library) ? [this.getDefaultProfileForm(library)] : [];
    }
    return forms;
  }

  public getProfileFormsForFileExt(name: string, library: string): any[] {
    if (!library || this.isExternalLib(library)) {
      library = this.getPrimaryLibrary();
    }
    library = library.toUpperCase();
    let forms: any[];
    if (!!name && !!this.sessionCache.forms && this.sessionCache.forms[library]) {
      const fileExtForms: string[] = this.getFormInfoForFileFromAppIDList(name, library, this.sessionCache.appIds[library]).forms;
      forms = this.sessionCache.forms[library].filter(form => fileExtForms.indexOf(form['%FORM_NAME']) !== -1);
    }
    if (!forms || forms.length === 0) {
      forms = this.getDefaultProfileForm(library) ? [this.getDefaultProfileForm(library)] : [];
    }
    return forms;
  }

  public getProfileForms(library?: string): any[] {
    if (!library || this.isExternalLib(library)) {
      library = this.getPrimaryLibrary();
    }
    library = library.toUpperCase();
    return this.sessionCache.forms[library];
  }

  public getSearchForms(): any[] {
    return this.sessionCache.searchForms;
  }

  public isSearchForm(name: string, library: string): boolean {
    if (!library || this.isExternalLib(library)) {
      library = this.getPrimaryLibrary();
    }
    library = library.toUpperCase();
    const forms = this.getSearchForms();
    if (!!forms && !!library && !!name) {
      return forms.findIndex(f => f['%FORM_NAME'] === name) !== -1;
    }
    return false;
  }
  public getAppComponent(): AppComponent {
    return this.app;
  }

  private defAppIDFromList(appIDList: any[]): any {
    return appIDList.find(a => a.APP_ID.toUpperCase() === 'DEFAULT');
  }

  private getFormInfoForAppIDFromAppIDList(appID: string, library: string, appIDList: any[]): FileFormInfo {
    const defaultProfileForm: any = this.getDefaultProfileForm(library);
    const defaultProfileForm_id = defaultProfileForm ? defaultProfileForm.id : '';
    const found = (appId: any) => {
      const forms: string[] = appId.forms && appId.forms.length ? appId.forms : [defaultProfileForm_id];
      const profileDefaults: any[] = appId.profile_defaults || [{}];
      return {forms, profile_defaults: profileDefaults, trustees: appId.trustees, appID: appId.APP_ID, defForm: ''};
    };
    appID = appID && appID.length ? appID.toUpperCase() : null;
    // search for an extension exact match first such as pdf or JPG in the appid list from the server
    // if that fails, such as the case of doc when the file is docx, fall back to the local table
    if (appIDList && !!appID) {
      for (const curAppId of appIDList) {
        if (curAppId.APP_ID && curAppId.APP_ID.toUpperCase() === appID) {
          return found(curAppId);
        }
      }
    }
    return {forms: [defaultProfileForm_id], profile_defaults: [{}], trustees:[], appID: 'DEFAULT', defForm: ''};
  }

  private getFormInfoForFileFromAppIDList(name: string, library: string, appIDList: any[]): FileFormInfo {
    const defaultProfileForm: any = this.getDefaultProfileForm(library);
    const defaultProfileForm_id = defaultProfileForm ? defaultProfileForm.id : '';
    let fileExt: string = null;
    let appID: string = this.transforms.fileNameToAppID(name);
    const parts: string[] = name ? name.split('.') : [];
    if (parts.length > 1) {
      fileExt = parts.splice(parts.length-1,1)[0];
      fileExt = fileExt && fileExt.length ? fileExt.toUpperCase() : null;
    }
    const found = (appId: any) => {
      const forms: string[] = appId.forms && appId.forms.length ? appId.forms : [defaultProfileForm_id];
      const profileDefaults: any[] = appId.profile_defaults || [{}];
      return {forms, profile_defaults: profileDefaults, trustees: appId.trustees, appID: appId.APP_ID, defForm: ''};
    };
    appID = appID && appID.length ? appID.toUpperCase() :  'DEFAULT';
    const appIdExtension = this.getMultipleAppIdsForFileExtension(fileExt, appIDList);
    // search for an extension exact match first such as pdf or JPG in the appid list from the server
    // if that fails, such as the case of doc when the file is docx, fall back to the local table
    if (appIDList) {
      if (appIdExtension.APP_ID.indexOf(this.kMultiAppIdSeparator) > -1) {
        return found(appIdExtension);
      } else {
        let curAppId: any;
        if (fileExt) {
          for (curAppId of appIDList) {
            if ((curAppId.ext && curAppId.ext.toUpperCase() === fileExt) || (!!curAppId.fileExtensions && curAppId.fileExtensions.indexOf(fileExt) !== -1)) {
              return found(curAppId);
            }
          }
        }
        if (appID) {
          for (curAppId of appIDList) {
            if (curAppId.APP_ID && curAppId.APP_ID.toUpperCase() === appID) {
              return found(curAppId);
            }
          }
        }
      }
    }
    return {forms: [defaultProfileForm_id], profile_defaults: [{}], trustees:[], appID: 'DEFAULT', defForm: ''};
  }

  private getMultipleAppIdsForFileExtension(fileExt: string, appIDList: any[]): any {
    const uniqueForms: string[] = [];
    const appIds: string[] = [];
    if (!!appIDList && !!fileExt) {
      for (const appId of appIDList) {
        if ((appId.ext && appId.ext.toUpperCase() === fileExt) || (!!appId.fileExtensions && appId.fileExtensions.indexOf(fileExt) !== -1)) {
          for (const form of appId.forms) {
            if (uniqueForms.indexOf(form) === -1) {
              uniqueForms.push(form);
            }
          }
          if (appIds.indexOf(appId.APP_ID) === -1) {
            appIds.push(appId.APP_ID);
          }
        }
      }
    }
    return {forms: uniqueForms, profile_defaults: [{}], trustees: [], APP_ID: appIds.join(this.kMultiAppIdSeparator), defForm: ''};
  }

  public getAppIDLibAndDesc(inThisDescOrLibrary: BaseDesc | string): {lib: string; desc: BaseDesc} {
    let library: string;
    let desc: BaseDesc = null;
    if (typeof inThisDescOrLibrary === 'string') {
      library = inThisDescOrLibrary || this.getPrimaryLibrary();
    } else if (!!inThisDescOrLibrary) {
      /* The library is being passed as the parent when retrieving
         the profile defaults for a new item in a workspace */
      if (
        ((!inThisDescOrLibrary.type ||
          inThisDescOrLibrary.type === 'folders') &&
          (!inThisDescOrLibrary.id ||
            inThisDescOrLibrary.id === 'recentedits')) ||
        inThisDescOrLibrary.type === 'workspaces'
      ) {
        library = inThisDescOrLibrary.lib || this.getPrimaryLibrary();
      } else {
        inThisDescOrLibrary.lib = inThisDescOrLibrary.lib || this.getPrimaryLibrary();
        desc = inThisDescOrLibrary;
        library = desc.lib;
      }
    } else {
      library = this.getPrimaryLibrary();
    }
    return {lib: library.toUpperCase(), desc};
  }

  private serverToClientTrustees(trustees: any[]): any[] {
    const trusteeDefaults: any[] = [];
    if (!!trustees) {
      trustees.forEach((at: any) => {
        trusteeDefaults.push({ flag: parseInt(at['%TRUSTEE_TYPE']), USER_ID: at['%TRUSTEE_ID'].toUpperCase(), rights: parseInt(at['%TRUSTEE_RIGHTS']) });
      });
      trustees = trusteeDefaults;
    }
    return trusteeDefaults;
  }

  private sessionCacheTrustees(trustees: any[]): any[] {
    const trusteeDefaults: any[] = [];
    if (!!trustees) {
      trustees.forEach((at: any) => {
        trusteeDefaults.push(at.map((t: any) => ({ flag: parseInt(t['%TRUSTEE_TYPE']), USER_ID: t['%TRUSTEE_ID'].toUpperCase(), rights: parseInt(t['%TRUSTEE_RIGHTS']) })));
      });
      trustees = trusteeDefaults;
    }
    return trusteeDefaults;
  }

  public getAppIDDescription(appID: string): string {
    let description: string = appID;
    if (description==='%PRIMARY_FORM') {
      description = this.localizer.getTranslation('PROFILE_DEFAULTS.PRIMARY');
    } else if (description==='%FORM_PAPER_APPLICATION') {
      description = this.localizer.getTranslation('FORMS.BUTTONS.PAPER');
    }
    return description;
  }

  public getAppIDForFile(name: string, library?: string): string {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    return this.getFormInfoForFileFromAppIDList(name, library, this.sessionCache.appIds[library]).appID;
  }

  public getAppIDs(library: string): any {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    return !!this.sessionCache.appIds ? this.sessionCache.appIds[library] : null;
  }

  public getIconForFile(name: string): string {
    const library: string = this.getPrimaryLibrary().toUpperCase();
    const appID = this.getFormInfoForFileFromAppIDList(name, library, this.sessionCache.appIds[library]).appID;
    if (this.transforms.hasIconForAppID(appID)) {
      return this.transforms.iconUrlFromDesc({id:'', type:'documents', lib:library, APP_ID:appID});
    }
    return null;
  }

  public updateFileFormInfoForContainer(ffi: FileFormInfo, formName: string, desc: BaseDesc): Promise<FileFormInfo> {
    return new Promise(resolve => {
      let query: string = '?library='+desc.lib+'&reflib='+desc.lib+'&refappid='+ffi.appID+'&reftype='+desc.type;
      if (!!desc.id) {
        let id: string = desc.id;
        if (desc.type === 'fileplans' && desc.id.startsWith('FP-FilePart')) {
          id = desc.id.split('FP-FilePart-')[1];
        }
        query += '&refid='+id;
      }
      if (!!desc['SYSTEM_ID']) {
        query += '&refsysid='+desc['SYSTEM_ID'];
      }
      this.get('forms/'+formName+'/defaults',null,query).toPromise().then((data: any) => {
        try {
          const mergedFFI: FileFormInfo = this.deepCopy(ffi);
          mergedFFI.defForm = formName;
          const formIndex: number = mergedFFI.forms.indexOf(formName);
          mergedFFI.profile_defaults[formIndex] = data.profile_defaults[0];
          mergedFFI.trustees[formIndex] = this.serverToClientTrustees(data.trustees);
          resolve(mergedFFI);
        } catch (e2) {
          resolve(ffi);
        }
      }).catch(e => {
        resolve(ffi);
      });
    });
  }

  private getFormInfoForFileOrAppID(name: string, appID: string, inThisDescOrLibrary: BaseDesc | string, preferredFormName: string): Promise<FileFormInfo> {
    return new Promise(resolve => {
      const ld = this.getAppIDLibAndDesc(inThisDescOrLibrary);
      let nChecks = 0;
      const waitForAppids = () => {
        const appIDList = this.sessionCache.appIds[ld.lib];
        let ffi: FileFormInfo;
        if (!!appIDList || nChecks>60) {
          if (!!name) {
            ffi = this.getFormInfoForFileFromAppIDList(name, ld.lib, appIDList);
          } else {
            ffi = this.getFormInfoForAppIDFromAppIDList(appID, ld.lib, appIDList);
          }
          const defForm: any = this.getDefaultProfileForm(ld.lib);
          let formName: string = preferredFormName || ((!!defForm && ffi.forms.indexOf(defForm.id )!== -1) ? defForm.id : ffi.forms[0]);
          const gotDefForm = () => {
            if (!!ld.desc && (ld.desc.id==='downloads' || ld.desc.id==='imports')) {
              const mergedFFI: FileFormInfo = this.deepCopy(ffi);
              mergedFFI.defForm = formName;
              resolve(mergedFFI);
            } else {
              if (!ld.desc) {
                ld.desc = {type:'documents', id:'0', lib:ld.lib};
              }
              this.updateFileFormInfoForContainer(ffi, formName, ld.desc).then(resolve).catch(() => {
               resolve(ffi);
              });
            }
          };
          if (this.restAPIVersion() < 0x00160700 || !!preferredFormName) {
            resolve(ffi);
          } else {
            this.get('settings/icclient/preferences/formdefaults', null, null, {observe:'response'}).subscribe(res => {
              const data = !!res.body ? res.body.data : null;
              const libAppIDForm = !!data && !!data[ld.lib] ? data[ld.lib][ffi.appID] : null;
              if (!!libAppIDForm && ffi.forms.indexOf(libAppIDForm)!==-1) {
                formName = libAppIDForm;
              }
              gotDefForm();
            }, err => {
              resolve(ffi);
            });
          }
        } else {
          ++nChecks;
          setTimeout(waitForAppids, 1000);
        }
      };
      waitForAppids();
    });
  }

  public getFormInfoForAppID(appID: string, inThisDescOrLibrary: BaseDesc | string, preferredFormName?: string): Promise<FileFormInfo> {
    return this.getFormInfoForFileOrAppID(null, appID, inThisDescOrLibrary, preferredFormName);
  }

  public getFormInfoForFile(name: string, inThisDescOrLibrary: BaseDesc | string, preferredFormName?: string): Promise<FileFormInfo> {
    return this.getFormInfoForFileOrAppID(name, null, inThisDescOrLibrary, preferredFormName);
  }

  public getDefsForAppID(formName: string, appID: string, library?: string): AppIDInfo {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    const ucAppID = appID==='%PRIMARY_FORM'?'DEFAULT':appID==='%FORM_PAPER_APPLICATION'?'PAPER':appID.toUpperCase();
    const appIDItem = this.sessionCache.appIds[library].find(a => a.APP_ID.toUpperCase() === ucAppID);
    const appIDInfo: AppIDInfo = {trustees: [], profile_defaults: {}};
    if (!!appIDItem) {
      const indx: number = appIDItem.forms.indexOf(formName);
      if (appIDItem.trustees && indx >= 0 && indx < appIDItem.trustees.length) {
        appIDInfo.trustees = this.deepCopy(appIDItem.trustees[indx]);
      }
      if (appIDItem.profile_defaults && indx >= 0 && indx < appIDItem.profile_defaults.length) {
        appIDInfo.profile_defaults = this.deepCopy(appIDItem.profile_defaults[indx]);
      }
    }
    return appIDInfo;
  }

  private getProfileCommonPriv(formName: string, appID: string, inThisDescOrLibrary: BaseDesc | string): Promise<any> {
    return new Promise(resolve => {
      const rc = {formData:{}, trustees:[]};
      const ucAppID = appID.toUpperCase();
      const ld = this.getAppIDLibAndDesc(inThisDescOrLibrary);
      const load = (defaults: any, fromServer: boolean): any => {
        if (!!defaults && defaults.APP_ID && defaults.APP_ID.toUpperCase() === ucAppID) {
          const indx: number = defaults.forms.indexOf(formName);
          if (!!defaults.trustees && indx >= 0 && indx < defaults.trustees.length) {
            if (defaults.forms.length === 1) {
              rc.trustees = fromServer ? this.serverToClientTrustees(defaults.trustees) : defaults.trustees[0];
            } else {
              rc.trustees = fromServer ? this.serverToClientTrustees(defaults.trustees)[indx] : defaults.trustees[indx];
            }
          }
          if (!!defaults.profile_defaults && indx >= 0 && indx < defaults.profile_defaults.length) {
            rc.formData = defaults.profile_defaults[indx];
          }
        }
        return this.deepCopy(rc);
      };
      if (!ld.desc || !ld.desc.type || this.restAPIVersion() < 0x00160700) {
        const appIDItem = this.sessionCache.appIds[ld.lib].find(a => a.APP_ID.toUpperCase() === ucAppID);
        resolve(load(appIDItem,  false));
      } else {
        let query: string = '?library='+ld.lib+'&reflib='+ld.desc.lib+'&refappid='+ucAppID+'&reftype='+ld.desc.type;
        if (!!ld.desc.id) {
          let id: string = ld.desc.id;
          if (ld.desc.type === 'fileplans' && ld.desc.id.startsWith('FP-FilePart')) {
            id = ld.desc.id.split('FP-FilePart-')[1];
          }
          query += '&refid='+id;
        }
        if (!!ld.desc['SYSTEM_ID']) {
          query += '&refsysid='+ld.desc['SYSTEM_ID'];
        }
        this.get('forms/'+formName+'/defaults',null,query).toPromise().then((data: any) => {
          try {
            resolve(load(data, true));
          } catch (e2) {
            resolve(rc);
          }
        }).catch(e => {
          resolve(rc);
        });
      }
    });
  }

  public setProfileDefaults(formName: string, appID: string, defaults: any, library?: string): Promise<any> {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    const appIDToPut = appID==='PAPER'?'%FORM_PAPER_APPLICATION':appID;
    const appIDToUpdate = appID;
    const appID2ToUpdate = appID==='%PRIMARY_FORM'?'DEFAULT':appID==='%FORM_PAPER_APPLICATION'?'PAPER':appID;
    return new Promise<any>((resolve, reject) => {
      this.put('forms/'+formName,defaults,'defaults','?library='+library+'&app_id='+appIDToPut,{observe:'response'}).subscribe(data => {
        if (appID==='%PRIMARY_FORM') {
          this.repopulateAppIDsInSessionCache(library);
        } else {
          const appIDList = this.sessionCache.appIds[library];
          if (!!appIDList) {
            let trustees: any[];
            if (!!defaults._restapi) {
              trustees = defaults._restapi.security;
              delete defaults._restapi;
            }
            for (const curAppId of appIDList) {
              const curAppIDUC = !!curAppId.APP_ID ? curAppId.APP_ID.toUpperCase() : '';
              if (curAppIDUC === appIDToUpdate || curAppIDUC === appID2ToUpdate) {
                let index: number;
                const nForms: number = curAppId.forms.length;
                for (index=0; index<nForms; index++) {
                  if (curAppId.forms[index] === formName) {
                    curAppId.profile_defaults[index] = defaults;
                    if (trustees !== undefined) {
                      curAppId.trustees[index] = trustees;
                    }
                    break;
                  }
                }
              }
            }
          }
        }
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  private repopulateAppIDsInSessionCache(library: string) {
    this.get('/forms/app_ids?library=' + library).subscribe((listData: ListData) => {
      if (!!listData && listData.list) {
        let iForm;
        const anAppID: any[] = this.sessionCache.appIds[library] = listData.list;
        this.checkAddTypeInAppIDs('EML', 'MSG', anAppID);
        this.checkAddTypeInAppIDs('JPG', 'JPEG', anAppID);
        this.checkAddTypeInAppIDs('ODT', 'DOCX', anAppID);
        this.checkAddTypeInAppIDs('ODS', 'XLSX', anAppID);
        this.checkAddTypeInAppIDs('ODP', 'PPTX', anAppID);
        anAppID.forEach(a => {
          const nForms: number = a.forms.length;
          const nDefaults: number = a.profile_defaults.length;
          const nTrustees: number = a.trustees.length;
          if (nForms > nDefaults) {
            for (iForm = nDefaults - 1; iForm < nForms; iForm++) {
              a.profile_defaults.push({});
            }
          } else {
            a.editable = true;
          }
          if (nForms > nTrustees) {
            for (iForm = nTrustees - 1; iForm < nForms; iForm++) {
              a.trustees.push([]);
            }
          } else {
            a.editable = true;
          }
          a.trustees = this.sessionCacheTrustees(a.trustees);
        });
        this.sessionCache.save();
      }
    }, error => {
    });
  }

  public getProfileDefaultsAndTrustees(formName: string, appID: string, inThisDescOrLibrary: BaseDesc | string): Promise<any> {
    return this.getProfileCommonPriv(formName, appID, inThisDescOrLibrary);
  }

  public getProfileDefaults(formName: string, appID: string, inThisDescOrLibrary: BaseDesc | string): Promise<any> {
    return new Promise(resolve => {
      this.getProfileCommonPriv(formName, appID, inThisDescOrLibrary).then(data => {
        resolve(data.formData);
      });
    });
  }

  public getProfileDefaultTrustees(formName: string, appID: string, inThisDescOrLibrary: BaseDesc | string): Promise<any[]> {
    return new Promise(resolve => {
      this.getProfileCommonPriv(formName, appID, inThisDescOrLibrary).then(data => {
        resolve(data.trustees);
      });
    });
  }

  public setLoginReply(loginReply: any): void {
    let primaryLibName: string = this.getPreference('primary');
    if (typeof primaryLibName !== 'string' || !primaryLibName.length) {
      primaryLibName = this.getPrimaryLibrary();
    }
    loginReply.NETWORK_ID = loginReply.NETWORK_ID || localStorage.getItem(kNetworkIDKey);
    localStorage.setItem(kNetworkIDKey, loginReply.NETWORK_ID);
    this.loginReply = loginReply;
    this.pftaPort = loginReply.PFTA_PORT || this.pftaPort;
    const restAPIVers: number = this.restAPIVersion();
    this.transforms.restAPIVers = restAPIVers;
    this.transforms.myUserID = this.getUserID();
    const externalPrefix: string = !!this.loginReply['EXTERNAL_LIB_PREFIX'] ? this.loginReply['EXTERNAL_LIB_PREFIX'] : 'ex_';
    this.transforms.setExternalApps(loginReply.APPLICATIONS, externalPrefix);
    this.restAPIHeaders = loginReply.HEADERS;
    if (!this.device.bIsElectron && !this.device.bIsCordova) {
      localStorage.setItem('edx_session_auth', loginReply.SESSION_AUTHORIZATION);
      localStorage.setItem('edx_session_headers', JSON.stringify(loginReply.HEADERS));
      localStorage.setItem('edx_session_url', this.baseURL);
      if (this.device.bIsOfficeAddin && !!Office.context && !!Office.context.roamingSettings) {
        Office.context.roamingSettings.set('edx_session_headers', loginReply.HEADERS);
        Office.context.roamingSettings.set('edx_session_url', this.baseURL);
        Office.context.roamingSettings.set('edx_session_auth', loginReply.SESSION_AUTHORIZATION);
        Office.context.roamingSettings.saveAsync();
      }
      const authToken = !!this.sessionHeaders ? this.sessionHeaders['X-DM-AUTH'] : null;
      this.sessionHeaders = loginReply.HEADERS;
      if (!!authToken) {
        this.sessionHeaders['X-DM-AUTH'] = authToken;
      }
    }
    if (this.loginReply.USER_ID) {
      this.loginReply.USER_ID = this.loginReply.USER_ID.toUpperCase();
    }
    if (this.loginReply.LIB) {
      this.loginReply.LIB = this.loginReply.LIB.toUpperCase();
    }
    if (this.loginReply.LIBRARIES && this.loginReply.LIBRARIES.length) {
      const primaryLib: any = this.loginReply.LIBRARIES.find(aLib => aLib['LIBRARY_NAME'].toUpperCase() === primaryLibName.toUpperCase());
      const index: number = primaryLib ? this.loginReply.LIBRARIES.indexOf(primaryLib) : -1;
      if (index>0) {
        this.loginReply.LIBRARIES.splice(index,1);
        this.loginReply.LIBRARIES.splice(0,0,primaryLib);
      }
    } else {
      if (primaryLibName) {
        this.loginReply.LIBRARIES = [{DISABLED:'N',LIBRARY_NAME:primaryLibName,LIBRARY_DESC:primaryLibName}];
      } else {
        this.loginReply.LIBRARIES = [{DISABLED:'N',LIBRARY_NAME:'',LIBRARY_DESC:''}];
      }
    }
    if (this.loginReply.LIBRARIES) {
      if (this.device.bIsOfficeAddinWord || this.device.bIsOfficeAddinExcel || this.device.bIsOfficeAddinPowerPoint) {
        const addInApplication = this.device.bIsOfficeAddinWord ? 'MS WORD' : (this.device.bIsOfficeAddinPowerPoint ? 'MS POWERPOINT' : 'MS EXCEL');
        let footerOptionsStr = this.getPreference('edx_footer_options');
        const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
        this.getFooterOptions(addInApplication, 'footeroptions').then((res) => {
          const response = {};
          response['data'] = !!res && !!res.hasOwnProperty('data') ? res['data'] : res;
          footerOptions['xml'] = !!response['data'] ? response['data'] : '';
          this.getFooterOptions(addInApplication, 'footerautoupdate').then((result) => {
            response['data'] = !!result && !!result.hasOwnProperty('data') ? result['data'] : result;
            footerOptions['auto'] = !!response['data'] ? (response['data'] === 'Y' ? '1' : '0') : '0';
            this.getFooterOptions(addInApplication, 'footershowoptionsdialog').then((data) => {
              response['data'] = !!data && !!data.hasOwnProperty('data') ? data['data'] : data;
              footerOptions['dont_show'] = !!response['data'] ? (response['data'] === 'Y' ? '0' : '1') : '1';
              footerOptionsStr = JSON.stringify(footerOptions);
              this.setPreference('edx_footer_options', footerOptionsStr);
            });
          });
        });
      }
      let selectedLibs: string[] = null;
      const libs: string = this.getPreference('$edx_selected_libraries');
      if (libs) {
        selectedLibs = libs.split(',');
      }
      for (const key in this.loginReply.LIBRARIES) {
        const lib = this.loginReply.LIBRARIES[key];
        if (!!lib && lib.DISABLED !== 'Y') {
          lib.isPrimary = lib['LIBRARY_NAME'].toUpperCase() === primaryLibName.toUpperCase();
          if (((!selectedLibs || selectedLibs.length === 0) && lib.DEFAULT_SEARCH === 'Y') || (!!selectedLibs && selectedLibs.indexOf(lib.LIBRARY_NAME) >= 0)) {
            lib.checked = true;
            this.setSelectedLibraries(lib);
          }
        }
      }
      this.setShowFooterOptions();
      const settingsService = new SettingsService();
      settingsService.read('General').then(() => {});
      if (this.canUserAddTileAfterRed()) {
        settingsService.read('Tiles').then(() => {});
      }
      this.setPrimaryLibraryIndex();
    }
    if (restAPIVers < 0x00160500) {
      this.loginReply.RM_ENABLED = false;
    }
    this.sessionCache.loginReply = this.loginReply;
    this.sessionCache.save();
    this.checkTempPathOverride();
    this.checkCustomVendors();
    this.checkForPFTA().then(kNoOp, kNoOp);
    if (this.device.bIsOfficeAddinWord || this.device.bIsOfficeAddinOutlook) {
      this.profileService.init().then(kNoOp, kNoOp);
    }
    this.loadBrava();
    if (!this.canUserCheckoutDocuments()) {
      this.setPreference('def_checkout_open', '0');
    }
    //Here we are going to remove local storage of Outlook from address
    localStorage.removeItem('edx_outlook.whoamI');
    this.recentLocService.getRecentlyUsedContainers();
    this.handleFavoritesDataRefresh();
  }

  public getLoginReply(): any {
    return this.loginReply;
  }

  public getLoginReplySetting(setting: string): boolean {
    return this.loginReply[setting];
  }

  public getSystemDefaultSetting(setting: string): any {
    return !!this.loginReply['SYSTEM_DEFAULTS'] ? this.loginReply['SYSTEM_DEFAULTS'][setting] : null;
  }

  public findExternalApp(lib: string): any {
    let extApp: any = null;
    if (this.loginReply && !!this.loginReply.APPLICATIONS) {
      for (const app of this.loginReply.APPLICATIONS) {
        if (app.lib === lib) {
          extApp = app;
          break;
        }
      }
    }
    return extApp;
  }

  public findExternalAppType(apptype: string): any {
    let extApp: any = null;
    if (this.loginReply && !!this.loginReply.APPLICATIONS) {
      for (const app of this.loginReply.APPLICATIONS) {
        if (app.apptype === apptype) {
          extApp = app;
          break;
        }
      }
    }
    return extApp;
  }

  public getLibraryEffectiveRights(library?: string): any {
    if (library && library !== this.getPrimaryLibrary()) {
      if (this.loginReply && this.loginReply['LIBRARIES']) {
        const libraryInfo: any = this.loginReply['LIBRARIES'].find(lib => lib['LIBRARY_NAME'] === library);
        if (libraryInfo) {
          return libraryInfo.EFFECTIVE_RIGHTS || {};
        }
      }
    }
    return this.loginReply.EFFECTIVE_RIGHTS || {};
  }

  public canAutoProfile(): boolean {
    return !!this.loginReply && !!this.loginReply['EFFECTIVE_RIGHTS'] && this.loginReply['EFFECTIVE_RIGHTS']['ALLOW_SMART_PROFILING'] === 'Y' && (this.device.bIsOfficeAddinWord || this.device.bIsOfficeAddinOutlook);
  }

  public isUserInGroup(groupID: string): boolean {
    if (this.loginReply) {
      if (this.loginReply.GROUPS && this.loginReply.GROUPS.length) {
        return !!this.loginReply.GROUPS.find(g => g.GROUP_ID===groupID);
      } else if ((this.loginReply['HEADERS'] && this.loginReply['HEADERS']['X-DM-GROUPID']===groupID) || groupID === 'DOCS_USERS') {
        return true;
      }
    }
    return false;
  }

  public isAdmin(): boolean {
    return this.loginReply && this.loginReply.HEADERS && this.loginReply.HEADERS['X-DM-GROUPID'] === 'DOCS_SUPERVISORS' ? true : false;
  }

  public isAdminByGroup(): boolean {
    return this.isAdmin() || this.loginReply?.GROUP === 'DOCS_SUPERVISORS';
  }

  public isGuestUser(): boolean {
    return this.loginReply && !!this.loginReply['GUEST_USER'];
  }

  public canUserCreateDocument(): boolean {
    return this.getUserRightFor('ALLOW_DOC_CREATE');
  }

  public canUserChangeProfileFormOnEdit(): boolean {
    return this.getUserRightFor('SU_CHNGE_PROF_EDIT');
  }

  public canUserChangeProfileFormOnSave(): boolean {
    return this.getUserRightFor('SU_CHNGE_PROF_SAVE');
  }

  public canPublishMultiple(): boolean {
    return this.getUserRightFor('MULT_PUB_VER');
  }

  public canUserDeleteVersions(): boolean {
    return this.getUserRightFor('DELETE_VERSIONS');
  }

  public canUserCreateRelations(): boolean {
    return this.getUserRightFor('CREATE_RELATION');
  }

  public canUserCheckoutDocuments(): boolean {
    return this.getUserRightFor('CHECKOUT');
  }

  public canUserCreateFolders(): boolean {
    return this.getUserRightFor('CREATE_FOLDER');
  }

  public canUserCreatePublicFolders(): boolean {
    return this.getUserRightFor('ROOT_FOLDER');
  }

  public canUserSaveToRemoteLibrary(): boolean {
    return this.getUserRightFor('SAVE_TO_REM_LIB');
  }

  public canUserShowRelations(): boolean {
    return this.getUserRightFor('SHOW_RELATED');
  }

  public canUserManageTemplates(): boolean {
    return this.getUserRightFor('TEMPLATE_MANAGER');
  }

  public canUserEditPreviousVersions(): boolean {
    return this.getUserRightFor('EDIT_PREVIOUS_VER');
  }

  public canUserMassUpdateToProfiles(): boolean {
    return this.getUserRightFor('MASS_UPD_PROFILES');
  }

  public canUserManageLookups(): boolean {
    return this.isAdminFeatureEnabled('LOOKUPS') && this.getUserRightFor('EDIT_VTS');
  }

  public canUserManageTiles(): boolean {
    return this.isAdminFeatureEnabled('TILES') && this.isAdminByGroup();
  }

  public canUserManageFooter(): boolean {
    return this.isAdminFeatureEnabled('FOOTER_DEFAULTS') && this.isAdminByGroup();
  }

  public canUserAccessAdminMaintenance(): boolean {
    return this.device.isWebLook() && (this.canUserManageTiles() || this.canUserManageLookups());
  }

  public isAdminFeatureEnabled(feature: string): boolean {
    return !!this.loginReply && !!this.loginReply['ADMIN_MAINTENANCE_FEATURES'] && this.loginReply['ADMIN_MAINTENANCE_FEATURES'].indexOf(feature) !== -1;
  }

  public canUserEditVersion(list: any[], curDocVersion: string): boolean {
    const lastVersionItem = this.lastVersionItem(list);
    return this.canUserEditPreviousVersions() ? true : lastVersionItem['VERSION'] === curDocVersion ? true : false;
  }

  public canUserEmail(): boolean {
    return this.device.bIsOfficeAddinOutlook || !this.loginReply || !this.loginReply['SYSTEM_DEFAULTS'] || this.loginReply['SYSTEM_DEFAULTS']['ALLOW_SEND_MAIL'] !== 'N';
  }

  public canUserAddTileAfterRed(): boolean {
    return this.getSystemDefaultSetting('ADD_TILE_AFTER_RED') === 'Y';
  }
  public canCompareVersions(): boolean {
    return this.getSystemDefaultSetting('CAN_COMPARE_VERSIONS') === 'Y';
  }

  public getRecentLocationsMaxCount(): number {
    const count = parseInt(this.getUserRight('SU_RECENT_COUNT'));
    return isNaN(count) ? 0 : count;
  }

  private getUserRightFor(right: string): boolean {
    return this.getUserRight(right) === 'Y';
  }

  // Check EFFECTIVE RIGHTS first because they have presedence over system defaults and check if property exists
  // since older versions of REST API may not have included all rights properties that are found in LIBRARY DEFAULTS.
  private getUserRight(right: string): string {
    let permission = '';
    if (this.loginReply) {
      if (this.loginReply.EFFECTIVE_RIGHTS && !!this.loginReply.EFFECTIVE_RIGHTS[right]) {
        permission = this.loginReply.EFFECTIVE_RIGHTS[right];
      } else if (this.loginReply.LIB_DEFAULTS && !!this.loginReply.LIB_DEFAULTS[right]) {
        permission = this.loginReply.LIB_DEFAULTS[right];
      }
    }
    return permission;
  }

  public isBravaEnabled(): boolean {
    return this.loginReply && this.loginReply.BRAVA_ENABLED;
  }

  public lastVersionItem(list: any[]): any[] {
    return list.reduce(
      (max, current) =>
        parseInt(current['VERSION']) > parseInt(max['VERSION']) ||
        current['VERSION_LABEL'] > max['VERSION_LABEL']
          ? current
          : max,
      list[0]
    );
  }

  public maxVersions(key = 'MAX_VERSIONS', docType?: string): number {
    let max = key === 'MAX_VERSIONS' ? 9999 : 26;
    if (!!this.loginReply) {
      const libMax: number = !!this.loginReply.LIB_DEFAULTS && !!this.loginReply.LIB_DEFAULTS[key] ? parseInt(this.loginReply.LIB_DEFAULTS[key]) : max;
      const effectiveMax: number = !!this.loginReply.EFFECTIVE_RIGHTS && !!this.loginReply.EFFECTIVE_RIGHTS[key] ? parseInt(this.loginReply.EFFECTIVE_RIGHTS[key]) : max;
      if (!!docType) {
        max = Math.min(libMax, effectiveMax, this.maxVersionsForDocType(docType, key));
      } else {
        max = Math.min(libMax, effectiveMax);
      }
    }
    return max;
  }

  public maxVersionsForDocTypeArray(list: string[], maxKey = 'MAX_VERSIONS'): number {
    let max = maxKey === 'MAX_VERSIONS' ? 9999 : 26;
    if (!!this.loginReply && !!this.loginReply.EFFECTIVE_RIGHTS && !!this.loginReply.EFFECTIVE_RIGHTS.DOCUMENTTYPES) {
      let docTypeMax = max;
      for (const docType of list) {
        docTypeMax = this.maxVersionsForDocType(docType, maxKey);
        if (docTypeMax < max) {
          max = docTypeMax;
        }
      }
    }
    return Math.min(max, this.maxVersions(maxKey));
  }

  public maxVersionsForDocType(docType: string, maxKey = 'MAX_VERSIONS'): number {
    let max = maxKey === 'MAX_VERSIONS' ? 9999 : 26;
    if (!!this.loginReply && !!this.loginReply.EFFECTIVE_RIGHTS && !!this.loginReply.EFFECTIVE_RIGHTS.DOCUMENTTYPES && !!this.loginReply.EFFECTIVE_RIGHTS.DOCUMENTTYPES[docType]) {
      max = this.loginReply.EFFECTIVE_RIGHTS.DOCUMENTTYPES[docType][maxKey];
    }

    return max;
  }

  public canAddNewVersion(list: any[], docType?: string): boolean {
    // DM Server treats the highest version as total number of versions created, even when we delete a lower version
    // e.g A user has version limit as 10 and he created versions 1 to 10 and deleted versions 3,4,5. But versions limit is reached as the highest version is at 10.
    const highestVersion: number = list.length > 0 ? Math.max.apply(null, list.map(ver => ver.VERSION)) : 0;
    return highestVersion < this.maxVersions('MAX_VERSIONS', docType);
  }

  public canAddNewSubversion(list: any[], version?: string, docType?: string): boolean {
    version = !!version ? version : list[0].VERSION;
    const subVersionsCount = list.reduce((count, item) => (item.VERSION === version && item.VERSION_LABEL !== item.VERSION) ? ++count : count, 0);
    return subVersionsCount < this.maxVersions('MAX_SUBVERSIONS', docType);
  }

  public getServerURLOrigin(): string {
    return this.baseURL+this.apiPathVersion;
  }

  public getAPIVersion(): string {
    return kAPIVersion;
  }

  public getUserID(): string {
    if (this.loginReply && this.loginReply.USER_ID && this.loginReply.USER_ID.length) {
      return this.loginReply.USER_ID;
    }
    return this.connectInfo.userid;
  }

  public getUserFullName(): string {
    if (this.loginReply && this.loginReply.FULL_NAME && this.loginReply.FULL_NAME.length) {
      return this.loginReply.FULL_NAME;
    }
    return this.connectInfo.userid;
  }

  public getEmail(): string {
    let host: string;
    let urlParts: any;
    let email: string = !!this.loginReply ? this.loginReply.EMAIL_ADDRESS : null;
    if (!email) {
      email = this.authData && this.authData.email ? this.authData.email : null;
      if (!email && this.authData.gatewayUrl) {
        urlParts = this.parseURL(this.authData.gatewayUrl);
        host = urlParts ? urlParts['host'] : null;
      }
    }
    if (!email && !!this.loginReply && !!this.loginReply['SYSTEM_DEFAULTS']) {
      email = this.loginReply['SYSTEM_DEFAULTS']['DEFAULT_FROM_EMAIL'];
    }
    if (!email) {
      if (!host) {
        urlParts = this.parseURL(this.baseURL);
        host = !!urlParts ? urlParts['host'] : null;
      }
      host = host || location.hostname;
      if (host) {
        email = this.getUserID().toLowerCase() + '@' + host;
      }
    }
    return email;
  }

  public getSearchScope(): string {
    return this.searchScope;
  }

  public setSearchScope(searchScope: string): void {
    this.searchScope = searchScope;
  }

  public isItemInList(item1: any, list: any[]): boolean {
    if (list && item1) {
      for (const item2 of list) {
        if (this.isSameItem(item1, item2)) {
          return true;
        }
      }
    }
    return false;
  }

  public isSameItem(item1: any, item2: any): boolean {
    if (!!item1 && !!item2 && !!item1.desc && !!item2.desc) {
      return this.isSameDesc(item1.desc, item2.desc);
    }
    return false;
  }

  public isSameDesc(desc1: BaseDesc, desc2: BaseDesc): boolean {
    const primaryLib: string = this.getPrimaryLibrary().toUpperCase();
    let same: boolean = (!!desc1 && !!desc2 && !!desc1.type && !!desc2.type && desc1.type === desc2.type && desc1.id === desc2.id);
    if (same) {
      const lib1 = !!desc1.lib ? desc1.lib.toUpperCase() : null;
      const lib2 = !!desc2.lib ? desc2.lib.toUpperCase() : null;
      same = ((lib1 === lib2) || (!lib1 && lib2 === primaryLib) || (lib1 === primaryLib && !lib2));
    }
    return same;
  }

  public isItemInListsToRefresh(item: any): boolean {
    return this.isItemInList(item,this.listsToRefresh);
  }

  public addToRefreshList(aList: ListBaseComponent): void {
    if (this.listsToRefresh.indexOf(aList)===-1) {
      this.listsToRefresh.push(aList);
    }
  }

  public removeFromRefreshList(aList: ListBaseComponent): void {
    const index = this.listsToRefresh.indexOf(aList);
    if (index !== -1) {
      this.listsToRefresh.splice(index, 1);
    }
  }

  public getListsToRefresh():  ListBaseComponent[] {
    return this.listsToRefresh;
  }

  public refreshLists(itemUpdating?: BaseDesc): void {
    let tileToRefresh: Tile = null;
    if (this.curList.listComponent && this.curList.listComponent.desc) {
      this.curList.listComponent.reloadList(this.device.bIsOfficeAddin);
    }
    setTimeout(() => {
      if (!itemUpdating) {
        for (const list of this.listsToRefresh) {
          list.reloadList();
        }
      }
      if (this.tilesContainer) {
        this.tilesContainer.refreshHero();
        if (this.router.url===this.getHomeURL()) {
          if (this.curList.lastClosedList) {
            tileToRefresh = this.getTiles().find(t => t.id === this.curList.lastClosedList.id && t.type === this.curList.lastClosedList.type);
            if (tileToRefresh) {
              this.tilesContainer.refreshTile(tileToRefresh);
            }
          }
        }
        if (itemUpdating) {
          let tile: Tile;
          for (tile of this.getTiles()) {
            let itemFound = false;
            const listComp: ListBaseComponent = this.tilesContainer.getTileListComponent(tile);
            const list: ListItem[] = listComp ? listComp.getList() : null;
            if (list && list.length) {
              let item: ListItem = list.find(t => t.id===itemUpdating.id && t.type===itemUpdating.type && t.lib===itemUpdating.lib);
              if (!item && this.isExternalLib(tile.lib)) {
                item = list.find(t => !!t['correlator'] && !!itemUpdating['correlator'] && t['correlator']===itemUpdating['correlator']);
              }
              if (item) {
                itemFound = true;
                if (tile!==tileToRefresh) {
                  this.tilesContainer.refreshTile(tile);
                }
              }
            }
            if (!itemFound && (tile.id === 'deleted' || tile.id === 'checkedout' || tile.type === 'requests')) {
              if (tile!==tileToRefresh) {
                this.tilesContainer.refreshTile(tile);
              }
            }
          }
        }
      }
    }, 1000);
  }

  public refreshTile(tile) {
    if (tile && !this.device.bIsOfficeAddin) {
      this.tilesContainer.refreshTile(tile);
    }
  }

  public refresh(all?: boolean): void {
    if (all) {
      this.refreshLists();
    }
    const curWindow: WindowModalComponent = this.getCurWindow();
    if (curWindow) {
       curWindow.refresh();
    }
  }

  public refreshDownloads(imports: boolean): void {
    if (this.device.bIsCordova && this.tilesContainer) {
      this.tilesContainer.refreshDownloads(imports);
    }
  }

  public refreshSavedSearches(): void {
    const tiles: Tile[] = this.getTiles();
    if (tiles && tiles.length && this.tilesContainer) {
      const tile: Tile = tiles.find(t => t.id === '' && t.type === 'searches');
      if (tile) {
        this.tilesContainer.refreshTile(tile);
      }
    }
  }

  public refreshRequests(): void {
    const tiles: Tile[] = this.getTiles();
    if (tiles && tiles.length && this.tilesContainer) {
      const tile: Tile = tiles.find(t => t.id === '' && t.type === 'requests');
      if (tile) {
        this.tilesContainer.refreshTile(tile);
      }
    }
  }

  public refreshRecentEdits(): void {
    const tiles: Tile[] = this.getTiles();
    if (tiles && tiles.length && this.tilesContainer) {
      const tile: Tile = tiles.find(t => t.id === 'recentedits' && t.type === 'folders');
      if (!!tile) {
        this.tilesContainer.refreshTile(tile);
      }
    }
  }

  public makeURL(desc: BaseDesc, params?: string, queryargs?: string, encodeID: boolean=true): string {
    let url: string;
    let argsFileParts: string;
    if (desc) {
      let urlType: string;
      if ((this.isContainer(desc.type) || desc.type === 'forms' || desc.type === 'urls' || desc.type === 'requests' || desc.type === 'lookups') && params !== 'records') {
        urlType = desc.type;
      } else {
        urlType = 'documents';
      }
      if (desc.type === 'fileplans') {
        const filePartId: string[] = desc.id.split('-');
        if (filePartId[1] === 'FilePart') {
          const docNumber: string = desc['DOCNUM'] || desc['docNumber'] || '';
          const filePartNo: string = desc['PD_FILEPT_NO'] || desc['filePartNo'] || '';
          const docName: string = desc['name'] || desc['DOCNAME'] || '';
          argsFileParts = '&docNumber=' + this.encodeChildRouteName(docNumber) + '&filePartNo=' + this.encodeChildRouteName(filePartNo) + '&docName=' + this.encodeChildRouteName(docName);
          if ((params === 'history' || params === 'profile' || params === 'records' || params === 'references' || params === 'requests' || params === 'boxoperations' || params === 'closeorreopen')) {
            url = '/' + urlType + (docNumber === '' ? '' : ('/' + this.encodeChildRouteName(docNumber)));
            argsFileParts += '&filePartNo=' + this.encodeChildRouteName(filePartId[2]) + '&docName=' + this.encodeChildRouteName(docName);
          } else {
            url = '/' + urlType + (desc.id === '' ? '' : ('/' + this.encodeChildRouteName(this.encodeChildRouteName(desc.id))));
          }
          queryargs = queryargs ? queryargs + argsFileParts : argsFileParts;
        } else {
          url = '/' + urlType + (desc.id === '' ? '' : ('/' + this.encodeChildRouteName(desc.id)));
        }
      } else {
        url = '/' + urlType + (desc.id === '' ? '' : ('/' + (encodeID ? encodeURIComponent(desc.id) : desc.id)));
      }
      if (params) {
        url += '/' + params;
        if (desc.type === 'lookups') {
          url += '/' + (desc['DOCNUM'] || desc['docNumber'] || '');
        }
      }
      url += this.addLibQueryToUrl(desc, params, queryargs);
      if (!!queryargs) {
        if (url.indexOf('?library=') !== -1 && queryargs.indexOf('?library=') !== -1) {
          const queryargParts = queryargs.split('&');
          if (queryargParts.length>1) {
            queryargParts.splice(0,1);
            queryargs = queryargParts.join('&');
          } else {
            queryargs = '';
          }
        }
        url += (queryargs.startsWith('&') ? '' : '&') + queryargs;
      }
    }
    return url;
  }

  public makeDragURL(desc: BaseDesc, version: string='0', queryargs?: string): string {
    queryargs = queryargs ? (queryargs + '&download') : 'download';
    return this.makeURL(desc,version===null?'':'versions/'+version,queryargs);
  }

  public makeDragName(desc: any): string {
    let name: string = desc.DOCNAME;
    const lib: string = !!desc && !!desc.lib ? desc.lib.toUpperCase() : this.getPrimaryLibrary().toUpperCase();
    if (desc.ext) {
      name = name + '.' + desc.ext;
    } else if (desc.FILE_EXTENSION) {
      name = name + '.' + desc.FILE_EXTENSION;
    } else if (desc.APP_ID && this.sessionCache.appIds && this.sessionCache.appIds[lib] && this.sessionCache.appIds[lib].length) {
      for (const curAppId of this.sessionCache.appIds[lib]) {
        if (curAppId.APP_ID === desc.APP_ID) {
          name = name + '.' + curAppId.ext.toLowerCase();
          break;
        }
      }
    }
    return name;
  }

  private getTeamsAppIDForChannel(channelID: string): string {
    const data = this.sessionCache.teamsCache;
    if (!!data && !!data['channels']) {
      return data['channels'][channelID];
    }
    return null;
  }

  public makeTeamsBaseDeepLink(): string {
    let teamsAppID: string;
    if (!!this.device.teamsContext && !!(teamsAppID = this.getTeamsAppIDForChannel(this.device.teamsContext.channelId))) {
      const context = {
        subEntityId: null,
        channelId: this.device.teamsContext.channelId
      };
      const contextStr: string = JSON.stringify(context);
      const encodedContext: string = encodeURIComponent(contextStr);
      return 'https://teams.microsoft.com/l/entity/' + teamsAppID + '/' + this.device.teamsContext.entityId + '?context=' + encodedContext;
    }
    return null;
  }

  public makeDeepLink(desc: any, action?: string, bTeamsLink?: boolean,documentUrl?: boolean): string {
    let url: string = this.getRootSiteUrl();
    let teamsAppID: string;
    const name = encodeURIComponent(desc['DOCNAME'] || desc['name'] || 'untitled');
    const appID = encodeURIComponent(desc.APP_ID);
    let versionStr = '';
    const version = (documentUrl ? desc.ver : desc.vers) || 'C';
    if (action === 'download') {
      versionStr = '&vers='+(version === 'all'? (documentUrl? 'zipurl' : 'zip'): version);
    } else if (action === 'open' && documentUrl) {
      versionStr = '&vers='+ version;
    }
    const icUrl = url + '/#/home/iclink?type='+desc.type+'&id='+desc.id+'&lib='+desc.lib+'&appid='+appID+'&name='+name+(!!action?'&action='+action:'')+ versionStr;

    if (bTeamsLink && !!this.device.teamsContext && !!(teamsAppID = this.getTeamsAppIDForChannel(this.device.teamsContext.channelId))) {
      const subEntity = {
        id: desc.id,
        type: desc.type,
        lib: desc.lib,
        DOCNAME: encodeURIComponent(desc.DOCNAME),
        action: action || '',
        version: desc.vers || 'C'
      };
      const context = {
        subEntityId: JSON.stringify(subEntity),
        channelId: this.device.teamsContext.channelId
      };
      const contextStr: string = JSON.stringify(context);
      const encodedContext: string = encodeURIComponent(contextStr);
      const encodedWebUrl: string = encodeURIComponent(url);
      const encodedLabel: string = encodeURIComponent(desc.DOCNAME);
      url = 'https://teams.microsoft.com/l/entity/' + teamsAppID + '/' + this.device.teamsContext.entityId.replace('%', '%25') + '?webUrl=' + encodedWebUrl + '&label=' + encodedLabel + '&context=' + encodedContext;
    } else {
      url = icUrl;
    }
    return url;
  }

  public makeFileInfoURL(desc: BaseDesc, version: string='0', token?: boolean): string {
    if (!desc.id && desc['DOCNUMBER']) {
      desc.id = desc['DOCNUMBER'];
    }
    return this.makeURL(desc,version ===null?'':'versions/'+version,'fileinfo' + (token ? '&token' : ''));
  }

  public makeChildRouteURL(parent: string, childOutlet: string, childRouteName: string, desc: BaseDesc, params?: string, queryargs?: string): string {
    const realUrl: string = this.encodeChildRouteName(this.makeURL(desc, params, queryargs, false));
    const url: string = '/' + parent + '/(' + childOutlet + ':' + childRouteName + ';' + realUrl + '=)';  // match angular router
    return url;
  }

  public encodeChildRouteName(name: string): string {
    let str: string = encodeURIComponent(name);
    str = str.replace(/[(]/g,'%28');
    str = str.replace(/[)]/g,'%29');
    return str;
  }

  public decodeChildRouteURL(encodedURL: string): string {
    let url: string = encodedURL.split('#ch_')[0];
    if (encodedURL.indexOf('(')>0 && encodedURL.indexOf(')')===encodedURL.length-1) {
      url = decodeURIComponent(encodedURL);
      const parts: string[] = url.split(';');
      parts.shift();
      url = parts.join(';');
      if (url[url.length-1]==='=') {
        url = url.slice(0,url.length-1);
      }
    }
    return url;
  }

  public getNameFromURL(url: string): string {
    const urlParts = this.parseURL(url);
    let str: string = urlParts.params['name'];
    if (!!str) {
      str = decodeURIComponent(str);
    } else {
      const desc = this.getDescFromURL(url);
      str = desc.id;
    }
    return str;
  }

  public getDescFromURL(url: string): BaseDesc {
    const urlParts = this.parseURL(url);
    const nParts = urlParts.path.length;
    let desc: BaseDesc;
    if (urlParts.path[1] === 'fileplans') {
      const descFilePart: FilePartDesc = {
        type: urlParts.path[1],
        id: nParts > 2 ? urlParts.path[2] : '',
        lib: urlParts.params['library'],
        filePartNo: urlParts.params['filePartNo'],
        docNumber: urlParts.params['docNumber'],
        name: decodeURIComponent(urlParts.params['docName'])
      };
      desc = descFilePart;
    } else {
      desc = {
        type: urlParts.path[1] || '',
        id: nParts > 2 ? urlParts.path[2] : '',
        lib: urlParts.params['library'] || ''
      };
    }
    if (urlParts.params['imgPath']) {
      desc['imgPath'] = urlParts.params['imgPath'];
    }
    if (desc.id.startsWith('FP-FilePart')) {
      desc.id = decodeURIComponent(decodeURIComponent(desc.id));
    }
    return desc;
  }

  public getQueryFromURL(url: string, key: string): string {
    const urlParts = this.parseURL(url);
    return urlParts.params[key];
  }

  public getQueriesFromURL(url: string): any {
    const urlParts = this.parseURL(url);
    return urlParts.params;
  }

  public getDefualtMaxItems(): number {
    return this.maxItems;
  }

  public setDefualtMaxItems(maxItems: number): void {
    localStorage.setItem('list_max_items', maxItems.toString());
    this.maxItems = maxItems;
  }

  public getDefaultProfileForm(library?: string): any {
    library = library || this.getPrimaryLibrary();
    if (!!library) {
      library = library.toUpperCase();
      if (!!this.sessionCache.defaultProfileForm && !!this.sessionCache.defaultProfileForm[library]) {
        return this.sessionCache.defaultProfileForm[library];
      }
    }
    return null;
  }

  public getDefaultSearchForm(): any {
    return this.sessionCache.defaultForms['TYPE=SEARCH'];
  }

  public getDefaultWorkspaceForm(): any {
    return this.sessionCache.defaultForms['APP_ID=WORKSPACE'];
  }

  public getDefaultFilepartForm(): any {
    return this.sessionCache.defaultForms['APP_ID=FILE_PART'];
  }

  public getDefaultBoxForm(): any {
    return this.sessionCache.defaultForms['APP_ID=BOX'];
  }

  public getFormDescription(formName: string, library?: string): string {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    let formDescription: string;
    if (formName && this.sessionCache.forms[library]) {
      const form: any = this.sessionCache.forms[library].find(aForm => aForm.id === formName);
      formDescription = form ? form.FORM_TITLE : '';
    }
    return formDescription || '';
  }

  public getProfileFormID(name: string, library?: string): string {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    if (this.sessionCache.forms[library]) {
      for (const form of this.sessionCache.forms[library]) {
        if (form.id===name) {
          return form.DOCNUM;
        }
      }
    }
    return null;
  }

  public getProfileFormName(id: string, library?: string): string {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    if (id && this.sessionCache.forms[library]) {
      for (const form of this.sessionCache.forms[library]) {
        if (form.DOCNUM===id) {
          return form.id;
        }
      }
    }
    return null;
  }

  public cacheForm(formName: string, library: string, jsonStr: string): void {
    library = library || this.getPrimaryLibrary();
    library = library ? library.toUpperCase() : library;
    if (!this.sessionCache.formCache) {
      this.sessionCache.formCache = {};
    }
    const key: string = formName + '_' + library;
    if (!(key in this.sessionCache.formCache)) {
      this.sessionCache.formCache[key] = jsonStr;
    }
  }

  public getSmartProfilingData(library: string, formName: string): Observable<any> {
    library = library || this.getPrimaryLibrary();
    library = library ? library.toUpperCase() : library;
    const defProfForm: any = this.getDefaultProfileForm(library);
    formName = formName || (defProfForm ? defProfForm.id : null);
    if ( (formName) && (library) ) {
      //TODO caching - maybe
      const url = '/lookups/sampling?library=' + library + '&profile=' + formName;
      return this.get(url);
    }
    return observableOf(null);
  }

  public getFormTemplate(formName: string, library: string): Observable<any> {
    library = library || this.getPrimaryLibrary();
    library = library ? library.toUpperCase() : library;
    const defProfForm: any = this.getDefaultProfileForm(library);
    formName = formName || (defProfForm ? defProfForm.id : null);
    if (formName && library) {
      const key: string = formName + '_' + library;
      const jsonStr: string = this.sessionCache.formCache ? this.sessionCache.formCache[key] : null;
      if (!!jsonStr) {
        return observableOf(JSON.parse(jsonStr));
      }
      return this.get('/forms/' + formName + '/profile?library='+library);
    }
    return observableOf(null);
  }

  public getFormData(desc: BaseDesc, kind: string, formName?: string): Observable<any> {
    const url: string = this.makeURL(desc, kind, formName?'form='+formName:null);
    return this.get(url);
  }

  public getLocalFormTemplate(formName: string): any {
    const form = this.formService.getLocalFormTemplate(formName);
    return form?.data ?? {};
  }

  public getFormServiceTemplate(formName: string, library: string): Promise<any> {
    return this.formService.getFormTemplate(formName, library, false);
  }

  public cacheColumns(formName: string, library: string, jsonStr: string): void {
    if (!this.sessionCache.columnsCache) {
      this.sessionCache.columnsCache = {};
    }
    const key: string = formName + '_' + library;
    if (!(key in this.sessionCache.columnsCache)) {
      this.sessionCache.columnsCache[key] = jsonStr;
    }
    this.sessionCache.save();
  }

  public getColumns(formName: string, library: string): Observable<any> {
    const key: string = formName + '_' + library;
    const jsonStr: string = this.sessionCache.columnsCache?.[key] || null;
    const data = JSON.parse(jsonStr);
    if (!!data && !!data.cols && !!data.cols[0] && !!data.cols[0].displayMap) {
      return observableOf(data);
    }
    return this.get('/forms/' + formName + '/columns?library='+library);
  }

  public getSchema(desc: any, form: string=''): Observable<any> {
    const query: string = !!form ? ('form='+form) : null;
    return this.get(desc, 'schema', query);
  }

  public fillInProfile(formTemplate: any, defData: any, library?: string): any {
    const profile: any = { _restapi: {} };
    const addItIn = (field: any) => {
      if (field.name) {
        if (defData && defData[field.name]) {
          profile[field.name] = defData[field.name];
        }
      }
    };
    const parseFields = (fields: any[]) => {
      fields.forEach(field => {
        if (field.fields && field.fields.length) {
          parseFields(field.fields);
        } else {
          addItIn(field);
        }
      });
    };
    const defaultProfileForm: any = this.getDefaultProfileForm(library);
    if (!formTemplate && defaultProfileForm && defaultProfileForm.id) {
      const key: string = defaultProfileForm.id + '_' + defaultProfileForm.lib;
      const jsonStr: string = this.sessionCache.formCache ? this.sessionCache.formCache[key] : null;
      formTemplate = jsonStr ? JSON.parse(jsonStr).data : null;
    }
    if (!defData) {
      defData = {};
    }
    if (!defData.AUTHOR_ID) {
      defData.AUTHOR_ID = this.loginReply.USER_ID;
      defData.AUTHOR_FULL_NAME = this.loginReply.FULL_NAME;
    }
    if (formTemplate && formTemplate.defs && formTemplate.defs.length) {
      parseFields(formTemplate.defs);
    }
    if (formTemplate && formTemplate.eMailFields) {
      const emailFieldKeys = Object.keys(formTemplate.eMailFields);
      for (const key of emailFieldKeys) {
        const columnNames = formTemplate.eMailFields[key];
        columnNames.forEach(col => {
          profile[col] = defData[key];
        });
      }
    }
    return profile;
  }

  public getFormEmailFields(form: string, library: string): any[] {
    let emailFieldMappings: any[];
    if (!!form && !!library) {
      const key: string = form + '_' + library;
      const jsonStr: string = this.sessionCache.formCache ? this.sessionCache.formCache[key] : null;
      const cacheform = jsonStr ? JSON.parse(jsonStr) : null;
      if (!!cacheform && !!cacheform.eMailFields) {
        emailFieldMappings = cacheform.eMailFields;
      }
    }
    return emailFieldMappings;
  }

  public IsFieldAnEmailFieldInForm(curField: string, emailFieldMappings: any[]): any {
    let bFound: any = false;
    if (!!curField && !!emailFieldMappings) {
      for (const key of Object.keys(emailFieldMappings)) {
        emailFieldMappings[key].forEach(emailField => {
            if (emailField === curField) {
              bFound = true;
            }
        });
        if (bFound) {
          return key;
        }
      }
    }
    return null;
  }

  public cacheLookupColumns(id: string, library, profile: string, jsonStr: string): void {
    if (!this.sessionCache.lookupColumnsCache) {
      this.sessionCache.lookupColumnsCache = {};
    }
    const key: string = id + '_' + library + '_' + profile;
    if (!(key in this.sessionCache.lookupColumnsCache)) {
      this.sessionCache.lookupColumnsCache[key] = jsonStr;
    }
    this.sessionCache.save();
  }

  public copyDescChanged(desc: any): void {
    this.app.copyDescChanged(desc);
  }

  public pickGroupsUsers(callback: any, disableList?: ListItem[], title?: string): void {
    this.app.pickGroupsUsers(callback, disableList, title);
  }

  public clickPickFiles(extensions?: string): void {
    this.app.clickPickFiles(extensions);
  }

  public pickFiles(extensions: string, callback: tFilePickCB): void {
    this.app.pickFiles(extensions, callback);
  }

  public pickFolders(desc: any, multiple: boolean, allowLibrary: boolean, locations: boolean, callback: any): void {
    this.app.pickFolders(desc, multiple, allowLibrary, locations, callback);
  }

  public pickFilepart(callback): void {
    this.app.pickFilepart(callback);
  }

  public pickFromLookups(id: string, key: string, title: string, callback: any): void {
    this.app.pickFromLookups(id, key, title, callback);
  }

  public pickFromDownloads(callbackList: tListPickCB, callbackFiles: tFilePickCB, title?: string): void {
    this.app.pickFromDownloads(callbackList, callbackFiles, title);
  }

  public createContainer(type: string, desc: any): Promise<FormResult> {
    return this.app.createContainer(type, desc);
  }

  public runLocalForm(formData: any, formName: string, title: string, okTitle: string, showExtras: boolean): Promise<FormResult> {
    return this.app.runLocalForm(formData, formName, title, okTitle, showExtras);
  }

  private getLookupColumnsOrList(id: string, library: string, profile: string, param: string, query: string): Observable<any> {
    if (id === '$edx_contacts') {
      return this.get(`/lookups/Contacts${param}?library=${this.getExternalLibraryPrefix()}outlook` + query);
    } else {
      const key: string = id + '_' + library + '_' + profile;
      const jsonStr: string = param==='/profile' ? (this.sessionCache.lookupColumnsCache ? this.sessionCache.lookupColumnsCache[key] : null) : null;
      if (!!jsonStr) {
        return observableOf(JSON.parse(jsonStr));
      }
      return this.get('/lookups/' + id + param + '?library=' + library + '&profile=' + profile + query);
    }
  }

  public getLookupList(id: string, library: string, profile: string, key: string, filter?: string, queryIn?: string): Observable<ListData> {
    if (!profile) {
      if (id.endsWith('_GROUP_MANAGER') || id.endsWith('_GROUPS_ENABLED')) {
        profile = 'v_groups';
      } else {
        const defaultProfileForm: any = this.getDefaultProfileForm(library);
        profile = (defaultProfileForm ? defaultProfileForm.id : 'DEFAULT');
      }
    }
    const table: string = id.endsWith('APPS') ? id : id.endsWith('_GROUP_MANAGER') ? this.getLookupTable(id, library, profile) : this.verifyLookupTable(id, library);
    const keyVal = id.endsWith('APPS') ? 'APP_ID' : key;
    let subKey : string = '';
    if (id === '$edx_contacts') {
      subKey =  this.formatContactsURL(profile);
      profile = '';
    }
    const query: string = '&key=' + keyVal + (filter?('&filter='+filter):'') +(queryIn?('&'+queryIn):'');
    return this.getLookupColumnsOrList(table, library, profile, subKey, query);
  }

  public getLookupColumns(id: string, library: string, profile: string, key: string): Observable<any> {
    if (!profile) {
      const defaultProfileForm: any = this.getDefaultProfileForm(library);
      profile = (defaultProfileForm ? defaultProfileForm.id : 'DEFAULT');
    }
    const table: string = id.endsWith('APPS') ? id : this.verifyLookupTable(id, library);
    const keyVal: string = id.endsWith('APPS') ? 'APP_ID' : key;
    const query: string = '&max=1&key=' + keyVal;
    let subKey: string = (id === '$edx_contacts')? this.formatContactsURL(kDefaultAddressBook): '' ;
    return this.getLookupColumnsOrList(table, library, profile, subKey, query);
  }

  public getCurrentOutlookUser(): Promise<string> {
    let fromAddress = '';
    return new Promise<any>((resolve, reject) => {
     this.getOutlookEmailFrom().then((result) => {
      const response = {};
      response['data'] = !!result && !!result.hasOwnProperty('data') ? result['data'] : result;
      const list: ListItem[] = !!response['data'] ? response['data'].list : '';
      for (const item of list) {
        if (item[2]['PROPNAME'] ==='$edx_outlook_email_from') {
          fromAddress = item[2]['DATA'];
          this.setOutlookPreferences('whoamI', fromAddress);
          break;
        }
      }
        resolve(fromAddress);
        },error =>{
            reject(error);
      });
   });
  }

  public getOutlookAddressBookEntry(): any {
    return this.get(`/lookups/Contacts/AddressBookEntry?library=${this.getExternalLibraryPrefix()}outlook` + '&key=AddressBookEntrym').toPromise();
  }
  public getOutlookEmailFrom(): any {
    return this.get(`/lookups/Contacts/Me?library=${this.getExternalLibraryPrefix()}outlook` + '&key=$edx_outlook_email_from').toPromise();
  }
  private downloadFileWithOptions(option: DownloadOption, desc: BaseDesc, version: string, queryargs: string, path: string, okToAutoCheckin: boolean, forceAutoCheckin: boolean, shortName: boolean, launch: boolean, cb: (success) => void, useTempFile?: boolean, versionInfoData?: any, fileName?: string): void {
    queryargs = queryargs ? (queryargs + '&downloadoption=' + option) : 'downloadoption=' + option;
    if (this.device.bIsElectron || this.device.bIsCordova) {
      this.downloadFilesWithAppWorks([desc], path, undefined, version, queryargs, option===DownloadOption.kOpenIn, option===DownloadOption.kViewInternal, okToAutoCheckin, forceAutoCheckin, shortName);
    } else if (option === DownloadOption.kOpenInOffice) {
      this.downloadFileIntoOffice(desc, version, queryargs, okToAutoCheckin, shortName);
    } else if (!!this.keyPFTA) {
      this.downloadFileWithPFTA(option, desc, version, queryargs, path, okToAutoCheckin || forceAutoCheckin, null, shortName, launch, cb, useTempFile, versionInfoData, fileName);
    } else if (this.device.bIsOfficeAddinDesktop || this.device.bIsTeamsAddIn) {
      this.downloadFileWithBrowserAnchor(desc, version, queryargs);
    } else {
      let url: string = this.getServerURLOrigin() + this.makeURL(desc, !!version ? 'versions/' + version : '', 'download');
      url += (queryargs ? ('&' + queryargs) : '') + (shortName ? '&export' : '');
      const dAnchor: HTMLAnchorElement = document.createElement('a');
      if (option === DownloadOption.kDownload) {
        dAnchor['download'] = '';
      }
      dAnchor.target = '_blank';
      dAnchor.classList.add('download-iframe');
      dAnchor.style.display = 'none';
      dAnchor.href = url;
      document.body.appendChild(dAnchor);
      dAnchor.click();
    }
    if (!!cb) {
      cb(true);
    }
  }

  private downloadFileIntoOffice(desc: BaseDesc, version: string, queryargs: string, okToAutoCheckin: boolean, shortName: boolean): void {
    this.getFileDownloadInfo(desc, version, shortName).then(infoData => {
      this.downloadAsBase64(desc, 'versions/'+ version, queryargs).toPromise().then(b64Data => {
        if (this.device.bIsOfficeAddinWord) {
          Word.run(context => {
            const docCreated = context.application.createDocument(b64Data);
            return context.sync().then(() => {
              if (this.device.bIsOfficeAddinDesktop) {
                docCreated.save();
              }
              docCreated.open();
              return context.sync();
            });
          }).catch(wordError => {
            this.handleError(wordError);
          });
        } else if (this.device.bIsOfficeAddinExcel) {
          Excel.run(context => {
            // cannot set props in excel yet or save just open (undocumented)
            context.application.createWorkbook(b64Data).open();
            return context.sync();
          }).catch(excelError => {
            this.handleError(excelError);
          });
        } else if (this.device.bIsOfficeAddinPowerPoint) {
          PowerPoint.createPresentation(b64Data).then(() => {
          }).catch(powerPointError => {
            this.handleError(powerPointError);
          });
        }
      }, err => {
        this.handleError(err);
      });
    });
  }
  private formatContactsURL(header: string): string {
    if (header.toUpperCase()==='ADDRESSBOOKENTRY') {
      return '/' + header;
    }
    const addressbookobject = this.getOutlookPreferences('addressbookentry');
    if (!!addressbookobject) {
      const addressBookEntries =  JSON.parse(addressbookobject);
      if(!!addressBookEntries && !!header) {
        return '/' + addressBookEntries[header.toUpperCase()];
      }
    }
    return '/' + kDefaultAddressBook;
  }
  public downloadFileWithBrowserAnchor(desc: BaseDesc, version: string, queryargs: string): void {
    const formData: any = { DOCUMENTS: [desc] };
    const title: string = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING');
    this.notify.progress(title,'__local_filetransfer',null,formData,false,false,false).then(kNoOp);
    desc['$edx_progress'] = 0;
    this.getFileToken(desc, version).then(tokenData => {
      desc['$edx_progress'] = -1;
      const newFormData: any = { DOCUMENTS: [desc] };
      this.notify.updateFormData(newFormData);
      if (tokenData && tokenData.url && tokenData.name) {
        let fileUrl: string = tokenData.url;
        if (!!queryargs) {
          if (queryargs.startsWith('&')) {
            fileUrl += queryargs + '&share';
          } else if (fileUrl.indexOf('?') !== -1) {
            fileUrl += '&share&' + queryargs;
          } else {
            fileUrl += '?' + queryargs + '&share';
          }
        } else {
          if (fileUrl.indexOf('?') !== -1) {
          fileUrl += '&share';
          } else {
            fileUrl += '?share';
          }
        }
        const dAnchor: HTMLAnchorElement = document.createElement('a');
        dAnchor['download'] = 'download';
        dAnchor.target = '_blank';
        dAnchor.classList.add('download-iframe');
        dAnchor.style.display = 'none';
        dAnchor.href = fileUrl;
        document.body.appendChild(dAnchor);
        dAnchor.click();
        setTimeout(() => {
          this.notify.success(this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_DONE'));
        }, 1000);
      } else {
        this.notify.warning(title, {rapi_code:0,status:0});
      }
    }, err => {
      this.notify.warning(title, err);
    });
  }

  private downloadFileWithPFTA(option: DownloadOption, desc: BaseDesc, version: string, queryargs: string, path: string, okToAutoCheckin: boolean, deferedDesc: any, shortName: boolean, launch: boolean, cb: (success) => void, useTempFile?: boolean, versionInfoData?: any, fileName?: string): void {
    const formData: any = { DOCUMENTS: [desc] };
    const upperPath: string = !!path ? path.toUpperCase() : '';
    const title: string = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING');
    this.notify.progress(title,'__local_filetransfer',null,formData,false,false,false).then(kNoOp);
    desc['$edx_progress'] = 0;
    const downloadIt = (infoData) => {
      desc['$edx_progress'] = -1;
      const newFormData: any = { DOCUMENTS: [desc] };
      this.notify.updateFormData(newFormData);
      if (!!infoData && !!infoData.url && !!infoData.name) {
        if (!!deferedDesc) {
          const docObj: any = this.decodeFileName(infoData.name);
          const documentName = this.escapeFileName(deferedDesc.DOCNAME) + '.' + (!!docObj ? docObj.ext : deferedDesc.ext);
          infoData.name = this.transforms.getDMDocName(deferedDesc.lib, deferedDesc.id, documentName, '1');
        }
        if (!!fileName) {
          infoData.name = fileName;
        }
        const urlB64: string = encodeURIComponent(this.transforms.b64EncodeUnicode(infoData.url + (queryargs ? ((queryargs.startsWith('&') ? '' : '&')+queryargs) : '')));
        const nameB64: string = encodeURIComponent(this.transforms.b64EncodeUnicode(infoData.name + (queryargs && queryargs.indexOf('linkonly')!==-1 ? '.drf' : '')));
        const isTemp: boolean = (upperPath==='TEMP' || upperPath.endsWith('TEMP'));
        const location: string = isTemp ? '&location=temp' : upperPath==='DOWNLOADS' ? '&location=downloads' : (!!path && path.length) ? '&location=absolute_'+encodeURIComponent(this.transforms.b64EncodeUnicode(path)) : '';
        const lanuch: string = option===DownloadOption.kOpenIn || option===DownloadOption.kViewInternal || isTemp || launch ? '&launch=launch' : '';
        const autocheckin: string = okToAutoCheckin ? '&autocheckin=true' : '';
        const readonly: string = option===DownloadOption.kViewInternal || (desc['STATUS']==='19' || desc['READONLY']==='Y' || (desc['checkout'] && desc['checkout']['TYPIST_ID'].toUpperCase() !== this.getUserID())) ? '&readonly=true' : '';
        const defered: string = !!deferedDesc ? ('&defereddesc=' + encodeURIComponent(this.transforms.b64EncodeUnicode(JSON.stringify(deferedDesc)))) : '';
        const useTempFileQS: string = '&usetempfile=' + (useTempFile ?? false).toString();
        const lastEditDateQS: string = '&lasteditdate=' + encodeURIComponent(this.transforms.b64EncodeUnicode(useTempFile || !version ? '' : infoData.lastEditDate));
        this.getJSONP('&fileurl='+urlB64+'&filename='+nameB64+lanuch+location+autocheckin+readonly+defered+useTempFileQS+lastEditDateQS).then(data => {
          this.notify.success(this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_DONE'), !!data && !!data.data ? data.data : '');
          if (!!cb) {
            cb(true);
          }
        }, err => {
          this.checkForPFTA().then(success => {
            if (!!cb) {
              cb(false);
            } else {
              this.notify.warning(title, err);
            }
          }, error => {
            err = {
              message: this.localizer.getTranslation('FORMS.LOCAL.PFTA.NOT_RUNNING')
            };
            this.notify.warning(title, err);
            if (!!cb) {
              cb(false);
            }
          });
        });
      }
    };
    if (!!queryargs && queryargs.indexOf('&exportfields=') !== -1) {
      if (desc.type === 'activities' && !isNaN(parseInt(desc['ACTIVITY_TYPE']))) {
        desc['DOCNAME'] = this.localizer.getTranslation('HISTORY_ACTIONS.' + parseInt(desc['ACTIVITY_TYPE']));
      }
      downloadIt({ name: this.escapeFileName(desc['DOCNAME'] || 'download'), url: this.getServerURLOrigin() + this.makeDragURL(desc, null) });
    } else {
      this.getFileDownloadInfo(desc, version, shortName).then(infoData => {
        downloadIt(infoData);
      });
    }
  }

  public getBlobUrlForFile(desc: BaseDesc, params?: string, queryargs?: string): Promise<string> {
    return this.get(desc, params, queryargs, { responseType: 'blob'}).toPromise().then(blob => window.URL.createObjectURL(blob),
    error => {
      this.handleError(error);
      return null;
    });
  }

  public getFileToken(item: any, itemVers: string='0', shortName: boolean=true, ignoreVersion: boolean=false): Promise<any> {
    return this.getFileDownloadInfo(item, itemVers, shortName, true, ignoreVersion);
  }

  public getFileDownloadInfo(item: any, itemVers: string = '0', shortName?: boolean, asToken?: boolean, ignoreVersion?: boolean): Promise<any> {
    let fileInfoUrl = this.makeFileInfoURL(item, itemVers, asToken);
    if (item.link && ['P', 'C', 'R'].indexOf(item.ver?.toLocaleUpperCase()) !== -1) {
      fileInfoUrl += (fileInfoUrl.indexOf('?') === -1 ? '?' : '&') + 'originVersion';
    }
    return this.get(fileInfoUrl).toPromise().then((data: any) => {
      const vers_id: string = data.versionId || itemVers;
      const vers_lbl: string = data.version_label ? data.version_label : (data.versionLabel ? data.versionLabel : vers_id);
      const fileExtension: string = data.fileExtension ?? data.FILE_EXTENSION;
      const tokenId: string = data.tokenId ?? data.tokenid;
      const url: string = this.getServerURLOrigin() + (asToken ? ('/documents/' + tokenId) : this.makeDragURL(item, (itemVers === 'zip' ? 'zip' : vers_id)));
      let fileName: string = this.escapeFileName(item.DOCNAME || '');
      const lastEditDate: string = data.lastEditDate || item['LAST_EDIT_DATE'];
      const documentNumber: string = item['DOCNUM'];
      const templateMap = {'%LibraryName%' : item.lib, '%DocumentNumber%' : documentNumber, '%VersionLabel%': vers_lbl ,'%DocumentName%': fileName};
      if (itemVers === 'zip' || itemVers === 'zipurl') {
        fileName += '.zip';
      } else if (shortName || this.transforms.isDMManagedFile(item.DOCNAME)) {
        let template = this.getSystemDefaultSetting('ShortFileName_Template');
        if (!!template) {
          for (const key of Object.keys(templateMap)) {
            template = template.replace(key, templateMap[key]);
          }
          fileName = template;
        }
        fileName = fileName + '.' + fileExtension;
      } else if (this.isExternalLib(item.lib)) {
        fileName = item.DOCNAME;
      } else {
        fileName = this.transforms.getDMDocName(item.lib, item.id, fileName + '.' + fileExtension, (ignoreVersion ? '' : vers_lbl));
      }
      return {
        documentNumber,
        name: fileName,
        url, size: data.fileSize,
        versionLabel: vers_lbl,
        FILE_EXTENSION: fileExtension,
        lastEditDate
      };
    }).catch(error => ({ docNumber: null, name: null, url: null, size: 0, lastEditDate: null }));
  }

  public renameFiles(sourcePaths: string[], renamePaths: string[]): void {
    if (this.device.bIsCordova) {
      let i = 0;
      const mfs: AWMobileFileSystem = new AWMobileFileSystem(kNoOp, kNoOp);
      for (const source of sourcePaths) {
        mfs.rename(source, renamePaths[i++], false, success => {
        }, error => {
          console.log('rename error: ' + error);
        });
      }
    }
  }

  //Creating local full path of physical file of document from 'checkout' data.
  public makeFilePath(item: any, root: string): string {
    item['$edx_progress'] = 0;
    let filePath = this.transforms.trimEnd(root, this.filePathSeparator);
    const userFileName = filePath.split(this.filePathSeparator).pop();
    const checkoutInfo = item['checkout'];
    if ((userFileName.indexOf('.') <= 0) && !!checkoutInfo) {
      const versionLabel = checkoutInfo.VERSION_LABEL;
      const fileExtension = checkoutInfo.FILE_EXTENSION;
      const documentName = this.escapeFileName(item.DOCNAME) + '.' + fileExtension;
      const fileName = this.transforms.getDMDocName(item.lib, item.id, documentName, versionLabel);
      if (!filePath) {
        filePath = this.getTempPath();
      }
      filePath = [filePath, fileName].join(this.filePathSeparator);
    }
    return filePath;
  }

  private localPathInfo(localPath: string): Promise<any> {
    if (this.device.bIsElectron) {
      return new Promise<any>((resolve) => {
        const fileSystem: AWFileSystem = new AWFileSystem();
        fileSystem.getDetails(localPath,
          (fileInfo) => {
            const result = {
              isDirectory: fileInfo.isDirectory,
              isFile: !!fileInfo.checksum
            };
            resolve(result);
          },
          (error) => {
            const result = {
              isDirectory: false,
              isFile: false
            };
            resolve(result);
          });
      });
    } else {
      return this.getPFTALocalPathInfo('', localPath, null);
    }
  }

  public isValidPath(path: string, fieldLookupType: string, eDocsFileName: string): Promise<any> {
    const validation = {
      isValid: true,
      errorCode: ''
    };
    const isFilePicker: boolean = (fieldLookupType === '$edx_file_picker');
    const isFolderPicker: boolean = (fieldLookupType === '$edx_folder_picker');
    path = this.transforms.trimEnd(path, this.filePathSeparator);
    if (this.transforms.isValidateUrl(path)) {
      validation.isValid = isFilePicker ? path.includes('?_rest_api_:lib=') : false;
      validation.errorCode = !validation.isValid ? (isFilePicker ? 'INVALID_FILE' : 'FOLDER_PICKER') : '';
      return Promise.resolve(validation);
    } else {
      validation.isValid = false;
      return new Promise<any>((resolve, reject) => {
        this.localPathInfo(path).then(localFileInfo => {
          if (localFileInfo) {
            validation.isValid = (isFilePicker && localFileInfo.isFile) || (isFolderPicker && localFileInfo.isDirectory);
            if (isFilePicker && !validation.isValid && localFileInfo.isDirectory) {
              const fullPath = [path, eDocsFileName].join(this.filePathSeparator);
              this.localPathInfo(fullPath).then(localFolderFileInfo => {
                if (localFolderFileInfo) {
                  validation.isValid = localFolderFileInfo.isFile;
                  if (!localFolderFileInfo.isFile) {
                    validation.errorCode = 'FILE_NOTFOUND';
                  }
                } else {
                  validation.errorCode = 'PFTA_NOT_RUNNING';
                }
                resolve(validation);
              });
            } else {
              if (isFilePicker) {
                validation.errorCode = 'INVALID_FILE';
              } else if (isFolderPicker) {
                validation.errorCode = 'FOLDER_PICKER';
              }
              resolve(validation);
            }
          } else {
            validation.errorCode = 'PFTA_NOT_RUNNING';
            resolve(validation);
          }
        },
          error => reject(error));
      });
    }
  }

  public downloadFilesWithAppWorks(list: any[], path?: string, title?: string, version?: string, queryargs?: string, view?: boolean, viewInternal?: boolean, okToAutoCheckin?: boolean, forceAutoCheckin?: boolean, shortName?: boolean, deferedDesc?: any, successCB?: any): void {
    if (this.device.bIsElectron || this.device.bIsCordova) {
      const effectiveRights: any = this.loginReply && this.loginReply.EFFECTIVE_RIGHTS ? this.loginReply.EFFECTIVE_RIGHTS : {};
      const fs: AWFileSystem = new AWFileSystem();
      const options = {headers: this.getDMHeaders()};
      const canOnlyView: boolean = effectiveRights.ALLOW_MOBILE_DOWNLOAD!=='Y' && viewInternal;
      let doUI: boolean = version!=='zip' && !canOnlyView;
      let uiShown = false;
      const paths: string[] = [];
      const formData: any = { DOCUMENTS: list };
      title = title || this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING');

      const doExit = (result) => {
        if (successCB) {
          successCB(result);
        }
      };
      const successFunc = (result: any, fileName: string, showSuccess: boolean) => {
        paths.push(result.fullPath ? result.fullPath : result.url);
        if (paths.length===list.length && uiShown) {
          setTimeout(() => {
            if (showSuccess) {
              this.notify.success(this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_DONE'));
            } else {
              this.notify.close();
            }
          }, 1000);
          this.refreshDownloads(false);
        }
        if (view || viewInternal) {
          setTimeout(() => {
            if (this.device.bIsElectron) {
              const filePath = result.fullPath;
              fs.open(filePath, openResult => {
                if (okToAutoCheckin && (this.hasAutoCheckoutCheckin() || forceAutoCheckin)) {
                  fs.getDetails(filePath, aFile => {
                    if (!aFile.isDirectory && aFile.name && aFile.name.length && !aFile.name.startsWith('.') && !aFile.name.startsWith('~$') && !this.getAutoCheckinFile(aFile.path)) {
                      this.autoCheckinFiles.push(new _AutoCheckinFile(true, deferedDesc, aFile.path, this.handleFileClosed.bind(this)));
                    }
                  }, err => {
                    this.notify.warning(title, err);
                  });
                }
              }, error => {
                this.notify.warning(title, error);
              });
            } else {
              if (view) {
                const finder: AWFinder = new AWFinder(finderList => {
                }, error => {
                  this.notify.warning(title, error);
                });
                let nativeURL: string = result.nativeURL ? result.nativeURL : null;
                if (!nativeURL) {
                  nativeURL = encodeURI('file:///' + result.fullPath);
                }
                finder.openIn(nativeURL);
              } else {
                const awPage: AWPage = new AWPage(pageResult => {
                }, error => {
                  this.notify.warning(title, error);
                });
                const headerOptions = result.fullPath ? null : options;
                const resultURL: string = result.fullPath ? encodeURI('file://' + result.fullPath) : result.url;
                awPage.openModalExternalWebView(resultURL, fileName, this.localizer.getTranslation('FORMS.BUTTONS.CANCEL'),headerOptions);
              }
            }
          }, 300);
        }
        doExit(true);
      };
      const makeReadOnly = (result: any, fileName: string) => {
        const aFullPath: string = result.fullPath ? result.fullPath : result.url;
        fs.setReadOnly(aFullPath, success => {
          successFunc(result, fileName, doUI && !(this.device.bIsCordova && view));
        });
      };
      const goodNameDoDL = (item: any, versionId: string, fileName: string, url: string, filePath: string, bReadOnly: boolean) => {
        const progressFunc = (progress) => {
          let value = -1;
          const total: number = progress.total ? progress.total : item.fileSize ? item.fileSize : 0;
          if (total) {
            value = (progress.loaded / total);
          }
          item['$edx_progress'] = value;
          const newFormData: any = { DOCUMENTS: list };
          this.notify.updateFormData(newFormData);
        };
        this.fileNameFromDirectory(filePath, item, versionId).then(downloadedName => {
          if ((!!queryargs && queryargs.indexOf('linkonly')!==-1) || this.device.bIsElectron) {
            downloadedName = null;
          }
          if (downloadedName) {
            // already downloaded
            doUI = false;
            const fullPath: string = '' + (this.device.bIsCordova ? this.sharedDocumentURL : filePath) + this.filePathSeparator + downloadedName;
            successFunc({fullPath}, downloadedName, false);
          } else if (url && fileName) {
            // is not yet downloaded so download it
            const ft: AWFileTransfer = new AWFileTransfer(result => {
              this.zone.run(() => {
                if (this.device.bIsElectron && bReadOnly) {
                  makeReadOnly(result, fileName);
                } else {
                  successFunc(result, fileName, doUI && !(this.device.bIsCordova && view));
                }
              });
            }, error => {
              this.zone.run(() => {
                const errorMessage = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.MOBILE_DOWNLOAD_ERROR');
                this.notify.warning(title, errorMessage);
                doExit(false);
              });
            });
            ft.progressHandler = progressFunc;
            if (this.device.bIsElectron) {
              ft.download(url,filePath+fileName,options);
            } else {
              if (canOnlyView) {
                // we are not allowed to download, but we just want to view so use AppWorks external viewer
                successFunc({url}, fileName, false);
              } else {
                ft.download(url,fileName,options,true);
              }
            }
          } else {
            doExit(false);
          }
        });
      };
      const doDownload = (item: any, versionId: string, fileName: string, url: string) => {
        const bReadOnly = (view && viewInternal) || item['STATUS']==='19' || item['READONLY']==='Y' || !!item['checkout'] || ['19', '20'].indexOf(item['versionStatus']) !== -1;
        let filePath: string = path || (bReadOnly ? this.getDownloadsPath() : this.getTempPath());
        if (filePath[filePath.length-1] !== this.filePathSeparator) {
          filePath += this.filePathSeparator;
        }
        if (this.device.bIsElectron && filePath.indexOf(this.getDownloadsPath()) !== -1) {
          this.uniqueDLName(filePath, fileName).then(uniqueName => {
            goodNameDoDL(item, versionId, uniqueName, url, filePath, bReadOnly);
          }, err => {
            goodNameDoDL(item, versionId, fileName, url, filePath, bReadOnly);
          });
        } else {
          const remove = (): Promise<boolean> => {
            let waitingForAppWorks = false;
            let rc: boolean;
            if (this.device.bIsElectron) {
              rc = false;
              waitingForAppWorks = true;
              fs.remove(filePath + fileName, details => {
                waitingForAppWorks = false;
                rc = true;
              }, err => {
                rc = false;
                waitingForAppWorks = false;
              });
            }
            return new Promise<any>((resolve, reject) => {
              const waitFunc = () => {
                if (waitingForAppWorks) {
                  setTimeout(waitFunc,100);
                } else {
                  resolve(rc);
                }
              };
              setTimeout(waitFunc,100);
            });
          };
          remove().then(() => {
            goodNameDoDL(item, versionId, fileName, url, filePath, bReadOnly);
          });
        }
      };
      const getFileInfo = (item: any, itemVers: string) => {
        if (itemVers && itemVers.startsWith('version_label') && item['fullPath']) {
          // from downloads we have the file on disk
          const fullPath: string = item['fullPath'];
          const parts: string[] = fullPath.split(this.filePathSeparator);
          const downloadedName: string = parts[parts.length - 1];
          successFunc({ fullPath }, downloadedName, false);
        } else {
          itemVers = !!deferedDesc ? 'C' : itemVers;
          this.get(this.makeFileInfoURL(item, itemVers === 'zip' ? 'C' : itemVers)).toPromise().then((data: any) => {
            const versionId: string = data.versionId || itemVers;
            let vers_lbl: string = data.version_label ? data.version_label : versionId;
            const url: string = this.getServerURLOrigin() + this.makeDragURL(item, (itemVers === 'zip' ? 'zip' : versionId), queryargs);
            let fileName: string = this.isExternalLib(item.lib) ? item.DOCNAME : this.escapeFileName(item.DOCNAME);
            if (!!deferedDesc) {
              vers_lbl = '1';
              fileName = this.transforms.getDMDocName(deferedDesc.lib, deferedDesc.id, fileName, '1');
            } else if (itemVers === 'zip') {
              fileName += '.zip';
            } else if (!shortName) {
              fileName = this.transforms.getDMDocName(item.lib, item.id, fileName, vers_lbl);
            }
            if (!!data.FILE_EXTENSION && itemVers !== 'zip') {
              fileName += '.' + data.FILE_EXTENSION;
            }
            if (queryargs && queryargs.indexOf('linkonly') !== -1) {
              fileName += '.drf';
            }
            item.fileSize = data.filesize;
            doDownload(item, versionId, fileName, url);
          }).catch(error => {
            this.notify.warning(title, error);
            doExit(false);
          });
        }
      };
      const startDownloads = () => {
        const nItems: number = list.length;
        for (let i=0; i<nItems; i++) {
          const item: any = list[i];
          if (doUI) {
            if (!uiShown) {
              uiShown = true;
              this.notify.progress(title,'__local_filetransfer',null,formData,false,false,false).then(kNoOp);
            }
            item['$edx_progress'] = 0;
          }
          let singleVers: string = version;
          if (singleVers && singleVers.indexOf(';')>=0) {
            singleVers = singleVers.split(';')[i];
          }
          if (singleVers !== undefined && singleVers !== null && singleVers.length === 0) {
            singleVers = undefined;
          }
          if (this.offline() || this.isUnmangedFile(item)) {
            doDownload(item, singleVers, null, null);
          } else {
            getFileInfo(item, singleVers);
          }
        }
      };
      if (this.device.bIsElectron) {
        let pathToCreate = path || this.getTempPath();
        if (pathToCreate[pathToCreate.length-1] !== this.filePathSeparator) {
          pathToCreate += this.filePathSeparator;
        }
        fs.exists(pathToCreate, exists => {
          if (exists) {
            startDownloads();
          } else {
            fs.createDirectory(pathToCreate, success => {
              startDownloads();
            }, error => {
              this.notify.warning(title, error);
              if (successCB) {
                successCB(false);
              }
            });
          }
        }, error => {
          fs.createDirectory(pathToCreate, success => {
            startDownloads();
          }, error2 => {
            this.notify.warning(title, error2);
            doExit(false);
          });
        });
      } else {
        startDownloads();
      }
    }
  }

  private findCheckinDocument(diskFileName: any, documentList: any[]): any {
    let i = 0;
    let item: any = null;
    let found = false;
    if (documentList.length > 1) {
      const docObj: any = this.decodeFileName(diskFileName);
      if (!!docObj) {
        for (i; i<documentList.length; i++) {
          if (documentList[i].lib === docObj.lib && documentList[i].id === docObj.docNum && (!documentList[i]['VERSION_LABEL'] || documentList[i]['VERSION_LABEL']===docObj.vers)) {
            found = true;
            break;
          }
        }
      }
    } else if (documentList.length === 1) {
      found = true;
    }
    if (found) {
      item = documentList[i];
    }
    return item;
  }

  public uploadFilesWithAppWorks(paths: string[], profileData: any, put?: boolean, specifyVersion?: boolean, versionIn?: string, listItems?: any[], title?: string, success?: any, lib?: string, partialUrl?: string): void {
    if (this.device.bIsElectron || this.device.bIsCordova) {
      const successPaths: string[] = [];
      const list: any[] = [];
      let i = 0;
      const nPaths: number = paths.length;
      const bDeleteAfterUpload: boolean = this.getPreference('delete_upload')==='1';
      let nErrors = 0;

      title = title || this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.UPLOADING');
      for (i=0; i<nPaths; i++) {
        const path: string = paths[i];
        let item: any;
        let url: string;
        let params: string;
        const fileName: string = this.getFileNameFromPath(path);
        if (listItems) {
          item = this.findCheckinDocument(fileName,listItems);
        } else {
          item = {type:'documents',id:'',lib:lib || (!!profileData._restapi && !!profileData._restapi.ref ? profileData._restapi.ref.lib : this.getPrimaryLibrary())};
        }
        const docObj: any = this.decodeFileName(fileName);
        const version = versionIn || (!!docObj && !!docObj.vers ? docObj.vers : 'C');
        if (specifyVersion && version && version.length) {
          params = 'versions/' + version;
        }
        const profileDataCopy: any = this.deepCopy(profileData);
        const docname: string = profileDataCopy.DOCNAME;
        if (docname && docname.length) {
          const docnames: string[] = docname.split(this.kMultiFileSeparator);
          if (docnames.length===nPaths) {
            profileDataCopy.DOCNAME = docnames[i];
          }
        }
        const appid: string = profileDataCopy.APP_ID;
        if (appid && appid.length) {
          const appids: string[] = appid.split(this.kMultiFileSeparator);
          if (appids.length===nPaths) {
            profileDataCopy.APP_ID = appids[i];
          }
        }
        const profStr: string = JSON.stringify(profileDataCopy);
        const b64Data: string = this.transforms.b64EncodeUnicode(profStr);
        if (!!partialUrl) {
          url = this.getServerURLOrigin() + this.formatDefaultURL(partialUrl, params, 'data='+encodeURIComponent(b64Data));
        } else {
          url = this.getServerURLOrigin() + this.makeURL(item, params, 'data='+encodeURIComponent(b64Data));
        }
        list.push({APP_ID:this.getAppIDForFile(fileName, item.lib), DOCNAME: fileName, $edx_progress: 0});
        const ft: AWFileTransfer = new AWFileTransfer(result => {
          this.zone.run(() => {
            if (result.responseCode===206) {
              ++nErrors;
              paths[successPaths.length-1] = null;
              this.notify.error(title, result);
            }
            successPaths.push(result.fullPath);
            if (successPaths.length===nPaths) {
              if (bDeleteAfterUpload) {
                for (let delIndex=0; delIndex<nPaths; delIndex++) {
                  if (paths[delIndex]) {
                    this.deleteFileFromDownloads(paths[delIndex]);
                  }
                }
              }
              if (typeof success === 'function') {
                success(list, paths);
              }
              this.refreshLists();
              this.uploadDone();
              if (nErrors===0) {
                this.notify.success(nPaths===1 ? this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE',[fileName]) : this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_MANY',[nPaths.toString()]));
              }
            }
          });
        }, error => {
          this.zone.run(() => {
            const errorList: any[] = (error.data && error.data['error-list']) ? error.data['error-list'] : (error.data && error.data.error_list) ? error.data.error_list : null;
            const failedSingle: string = errorList && errorList.length===1 ? errorList[0].object : fileName;
            const nFailedFiles: number = errorList ? errorList.length : nPaths;
            const bodyFail: string = nFailedFiles>1 ? this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_MANY',[nFailedFiles.toString()]) : this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_SINGLE',[failedSingle]);
            if (nFailedFiles!==nPaths) {
              this.refreshLists();
            }
            this.uploadDone(error);
            this.notify.warning(title, bodyFail);
          });
        });
        ft.progressHandler(progress => {
          this.zone.run(() => {
            if (progress.total) {
              const value: number = (progress.loaded / progress.total);
              item['$edx_progress'] = value;
              const newFormData: any = { DOCUMENTS: list };
              this.notify.updateFormData(newFormData);
            }
          });
        });
        ft.upload(path, url, {headers: this.getDMHeaders(), httpMethod: (put ? 'PUT' : 'POST'), fileName, mimeType:'*/*'}, this.device.bIsCordova);
      }
      const formData: any = { DOCUMENTS: list };
      this.notify.progress(title,'__local_filetransfer',null,formData,false,false,false).then(confirmed => {
      });
    }
  }

  public setOutlookMailbox(outlookMailbox: any): void {
    this.outlookMailbox = outlookMailbox;
  }

  public getOutlookMailbox(): any {
    return this.outlookMailbox;
  }

  public uploadDataFiles(existingDesc: BaseDesc, serverData: any, dataFiles: DataFile[], isPut?: boolean, existingVersion?: string, queryArgs?: string): void {
    const outlookMailbox = this.getOutlookMailbox();
    const settings = this.device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.document ? Office.context.document.settings : null;
    let dataFile: DataFile;
    let body: string;
    let title: string;
    const formData: any = new FormData();
    const items: any[] = [];
    const ref: any = !!serverData._restapi ? serverData._restapi.ref : null;
    const lib: string = !!ref ? ref.lib : null;
    let byteIndex = 0;
    let promiseUrls = false;
    let binaryFileData = null;
    let docName: string;
    const done = (doneTitle: string , doneMsg: string, doneError?: any): void => {
      this.refreshLists();
      this.uploadDone(doneError);
      if (doneError) {
        this.notify.warning(doneTitle, doneMsg);
      } else {
        this.notify.success(doneTitle, doneMsg);
        for (const listener of this.officeItemChangedListeners) {
          listener.callback();
        }
      }
    };
    const readChunk = (file: any, sliceIndex: number, putUrl: string, raw: boolean, newItem: any, vers: string): void => {
      const fileSize: number = file.size;
      let errMsg: string;
      file.getSliceAsync(sliceIndex, result => {
        if (result.value) {
          const len = result.value.size;
          if (raw) {
            if (binaryFileData) {
              binaryFileData += result.value.data;
            } else {
              binaryFileData = result.value.data;
            }
          } else {
            const chunk: Uint8Array = new Uint8Array(result.value.data);
            if (binaryFileData) {
              const newBinaryFileData = new Uint8Array(len + byteIndex);
              newBinaryFileData.set(binaryFileData);
              newBinaryFileData.set(chunk, byteIndex);
              binaryFileData = newBinaryFileData;
            } else {
              binaryFileData = chunk;
            }
          }
          byteIndex += len;
          const item = items[0];
          let prg: number = byteIndex / fileSize;
          if (prg > 1) {
            prg = 1;
          } else if (prg < 0.0) {
            prg = 0.0;
          }
          item['$edx_progress'] = prg;
          this.notify.updateFormData({ DOCUMENTS: items, $edx_progress: prg });
          if (byteIndex < fileSize) {
            readChunk(file, result.value.index + 1, putUrl, raw, newItem, vers);
          } else {
            const fileFormData: FormData = new FormData();
            fileFormData.append('filename', dataFile.name);
            fileFormData.append('mode', 'defered');
            fileFormData.append('blob', new Blob([binaryFileData]));
            this.notify.updateTitle(this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.PROCESSING'));
            file.closeAsync();
            setTimeout(() => {
              this.put(putUrl, fileFormData, null, queryArgs, { sendRaw: true }).toPromise().then(response => {
                if (this.device.bIsOfficeAddinWord || this.device.bIsOfficeAddinExcel || this.device.bIsOfficeAddinPowerPoint) {
                  // new file added to DM so monitor it for changes
                  if (!!dataFile.fromSaveAsDesc || !existingDesc) {
                    const canCheckout = !dataFile.name.endsWith('.pdf') && !dataFile.name.endsWith('.txt');
                    let documentName: string;
                    if (!!dataFile.fromSaveAsDesc) {
                      documentName = docName;
                      this.post(newItem, {
                        REF_DOCUMENT: dataFile.fromSaveAsDesc.id,
                        REF_LIB: dataFile.fromSaveAsDesc.lib,
                        VERSION_LABEL: dataFile.fromSaveAsDesc['VERSION_LABEL'] || dataFile.fromSaveAsDesc.vers,
                        TYPE: '18'
                      }, 'history').subscribe(kNoOp, kNoOp);
                      this.post(dataFile.fromSaveAsDesc, {
                        REF_DOCUMENT: newItem.id,
                        REF_LIB: newItem.lib,
                        VERSION_LABEL: '1',
                        TYPE: '17'
                      }, 'history').subscribe(kNoOp, kNoOp);
                    } else {
                      documentName = (canCheckout && !!dataFile.url) ? dataFile.url : dataFile.name;
                    }
                    body = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE', [documentName]);
                    if (canCheckout) {
                      this.checkoutItem(newItem, dataFile.url, null).then(() => {
                        done(title, body);
                        this.getOfficeDocProperties(dataFile.fromSaveAsDesc).then(properties => {
                          newItem['STATUS'] = '3';
                          if (properties.isReadOnly) {
                            settings.set('edx_filename', '');
                            this.downloadFileWithPFTA(DownloadOption.kOpenIn, newItem, 'C', null, 'TEMP', true, null, false, true, null);
                          } else {
                            if (this.device.bIsOfficeAddinPowerPoint) {
                              this.getPowerPointFilePath().then(tagPropertyVal => {
                                dataFile.url = tagPropertyVal.fileNameWithPath;
                                if (!!dataFile.url) {
                                  this.monitorFile(dataFile.name, dataFile.url, vers, newItem);
                                }
                              });
                            } else {
                              this.monitorFile(dataFile.name, dataFile.url, vers, newItem);
                              if (!!dataFile.listItemCB) {
                                dataFile.listItemCB(newItem);
                              }
                            }
                          }
                        });
                      }, error2 => {
                        postErr(error2);
                      });
                    } else {
                      done(title, body);
                    }
                  } else {
                    done(title, body);
                    if (this.device.bIsOfficeAddinPowerPoint) {
                      this.getPowerPointFilePath().then(tagPropertyVal => {
                        dataFile.url = tagPropertyVal.fileNameWithPath;
                        if (!!dataFile.url) {
                          this.monitorFile(dataFile.name, dataFile.url, vers, newItem);
                        }
                      });
                    } else {
                      this.monitorFile(dataFile.name, dataFile.url, vers, newItem);
                    }
                    newItem.vers = vers;
                    if (!!dataFile.listItemCB) {
                      dataFile.listItemCB(newItem);
                    }
                    if (!!ref && !!this.curList && !!this.curList.listComponent) {
                      setTimeout(() => {
                        this.curList.listComponent.reloadList();
                      }, 2000);
                    }
                  }
                } else {
                  done(title, body);
                }
              }, error1 => {
                postErr(error1);
              });
            }, 800);
          }
        } else {
          file.closeAsync();
        }
      });
    };
    const postErr = (error): void => {
      const errorList: any[] = error.data && (error.data['error-list'] || error.data.error_list);
      const failedSingle: string = errorList && errorList.length===1 ? errorList[0].object : dataFile.name;
      const bodyFail: string = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_SINGLE',[failedSingle]);
      done(title, bodyFail, error);
    };
    const officeDocSuccess = (newItem: any, vers: string, doneTitle: string, doneMsg: string) => {
      if (!!newItem) {
        const afterGetFileUrl = () => {
          const nameParts: string[] = dataFile.name.split('.');
          const ext: string = nameParts.length ? nameParts[nameParts.length-1].toUpperCase() : '';
          const fmt = ext === 'PDF' ? Office.FileType.Pdf : ext === 'TXT' ? Office.FileType.Text : Office.FileType.Compressed;
          if (!newItem['vers']) {
            newItem['vers'] = vers;
          }
          const getFile = () => {
            dataFile.officeDoc.getFileAsync(fmt, { sliceSize: 65536 }, result2 => {
              if (result2.status === 'succeeded') {
                const docNum = newItem.id || newItem.DOCNUM || newItem.DOCNUMBER;
                let putUrl: string = 'documents/'+docNum;
                if (!!vers) {
                  putUrl += '/versions/' + vers;
                }
                if (!queryArgs || queryArgs.indexOf('?library=') === -1) {
                  putUrl += '?library='+newItem['lib'];
                }
                readChunk(result2.value, 0, putUrl, fmt===Office.FileType.Text, newItem, vers);
              } else {
                if (this.device.bIsOfficeAddinExcel && result2.error.code === 5004) {
                  this.notify.confirm(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.EXCEL_5004'), null, null, false, true, true).then(response => {
                    if (response && response.confirm) {
                      getFile();
                    } else {
                      done(title, this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_SINGLE',[newItem['DOCNAME']]), result2.error.message);
                    }
                  });
                } else {
                  done(title, this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_SINGLE',[newItem['DOCNAME']]), result2.error.message);
                }
              }
            });
          };
          if (fmt === Office.FileType.Compressed && serverData['STORAGE'] !== 'T') { // if original format then add the edocs name, else don't so the real format can still be added
            const docObj = !!dataFile.name ? this.decodeFileName(dataFile.name) : null;
            let documentName;
            if (!!docObj) {
              documentName = docObj.name + '.' + docObj.ext;
            } else {
              const nameExt: string[] = dataFile.name.split('.');
              documentName = ((nameExt.length > 1) ? newItem['DOCNAME'] + '.' + nameExt.pop() : dataFile.name);
            }
            const diskName: string = this.transforms.getDMDocName(newItem.lib, newItem.id, documentName, vers);
            //Set the actual filename to custom props for Office VSTO to read later
            if (!this.device.bIsOfficeAddinPowerPoint || !dataFile.fromSaveAsDesc || dataFile.fromSaveAsDesc['READONLY'] !== 'Y') {
              this.setOfficeDocCustomProperty('edx_filename', diskName);
              settings.set('edx_filename', diskName);
            }
          }
          settings.set('Office.AutoShowTaskpaneWithDocument', true);
          settings.saveAsync(result => {
            if (result.status === 'succeeded') {
              if (this.ooxmlService.needsDialog()) {
                this.zone.run(() => {
                  this.notify.showHide(false);
                });
                setTimeout(() => {
                  this.zone.run(() => {
                    this.ooxmlService.addFooter(newItem).then(() => {
                      this.zone.run(() => {
                        this.notify.showHide(true);
                      });
                      setTimeout(() => {
                        getFile();
                      }, 500);
                    }, footerError => {
                      console.log(this.transforms.formatError(footerError));
                      this.zone.run(() => {
                        this.notify.showHide(true);
                      });
                      setTimeout(() => {
                        getFile();
                      }, 500);
                    });
                  });
                }, 500);
              } else {
                setTimeout(() => {
                  getFile();
                }, 500);
              }
            } else {
              postErr(result.status);
            }
          });
        };
        const afterSave = async () => {
          if (!!dataFile.url) {
            afterGetFileUrl();
          } else {
            await Office.context.document.getFilePropertiesAsync(async asyncResult => {
              if (!!asyncResult && !!asyncResult.value && !!asyncResult.value.url) {
                dataFile.url = asyncResult.value.url;
                afterGetFileUrl();
              } else {
                afterGetFileUrl();
              }
            });
          }
        };
        if (this.device.bIsOfficeAddinWord) {
          Word.run(async (context) => {
            const builtInProperties = context.document.properties;
            builtInProperties.load('security');
            await context.sync();
            // security 0 = File on disk is read/write or undefined is not set so save it
            if (builtInProperties.security === 0 || !builtInProperties.security) {
              context.document.save();
              await context.sync();
            }
            afterSave();
          });
        } else if (Office.context.requirements.isSetSupported('ExcelApi', '1.11')) {
          Excel.run(async (context) => {
            const builtInProperties = context.workbook;
            builtInProperties.load('readOnly');
            await context.sync();
            if (!builtInProperties.readOnly) {
              context.workbook.save(Excel.SaveBehavior.save);
              await context.sync();
            }
            afterSave();
          }).catch(runError => {
            afterSave();
          });
        } else if (this.device.bIsOfficeAddinPowerPoint) {
          this.getPowerPointFilePath().then(tagPropertyVal => {
            dataFile.url = tagPropertyVal.fileNameWithPath;
            afterSave();
          }).catch(runError => {
            afterSave();
          });
        } else {
          afterSave();
        }
      } else {
        done(doneTitle, doneMsg);
      }
    };
    const start = () => {
      formData.append('data', JSON.stringify(serverData));
      body = dataFiles.length === 1 ? this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE',[dataFile.name]) : this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_MANY',[dataFiles.length + '']);
      title = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.UPLOADING');
      this.notify.progress(title,'__local_filetransfer',null,{ DOCUMENTS:items },false,false,false).then(kNoOp);
      if (isPut) {
        if (!!outlookMailbox) {
          this.put(existingDesc, serverData, 'versions/' + existingVersion, queryArgs, { sendRaw: false, observe: 'response' }).subscribe((response: HttpResponse<any>) => {
            if (response.status===206) {
              postErr(response.body);
            } else {
              if (!!response.body && !!response.body.data && !!response.body.data.list && response.body.data.list.length) {
                this.device.officeDMItem = response.body.data.list[0];
              }
              done(title, body);
            }
          }, postErr);
        } else {
          officeDocSuccess(existingDesc, existingVersion, title, body);
        }
      } else {
        const params: string = existingVersion ? ('versions/' + existingVersion) : null;
        this.post(existingDesc || 'documents', !existingDesc ? formData : serverData, params, queryArgs, { sendRaw: !existingDesc, observe: 'response' }).subscribe((response: HttpResponse<any>) => {
          if (response.status===206) {
            postErr(response.body);
          } else {
            if (!!dataFile.officeDoc || !!dataFile.templateDesc) {
              if (!!response.body && !!response.body.data && !!response.body.data.list && response.body.data.list.length) {
                let item = response.body.data.list[0];
                if (!!dataFile.officeDoc) {
                  const afterGetProfile = (theData: any) => {
                    const fatItem = this.deepCopy(theData);
                    fatItem.id = item.id;
                    fatItem.type = item.type;
                    fatItem.lib = item.lib;
                    fatItem['VERSION_LABEL'] = item['VERSION_LABEL'] || '1';
                    fatItem.vers = fatItem['VERSION_LABEL'];
                    item = fatItem;
                    officeDocSuccess(item, item['VERSION_LABEL'], title, body);
                  };
                  this.checkoutItem(item, dataFile.url, null).then(() => {
                    this.get(item, 'profile').subscribe(afterGetProfile, () => {
                      afterGetProfile(serverData);
                    });
                  });
                } else if (!!dataFile.templateDesc) {
                  if (!item.DOCNAME) {
                    let name = dataFile.name || dataFile.templateDesc.DOCNAME;
                    const fatItem = this.deepCopy(item);
                    const nameParts = !!name ? name.split('.') : [];
                    if (nameParts.length > 1) {
                      name = nameParts.slice(0, nameParts.length-1).join('.');
                    }
                    fatItem.DOCNAME = name;
                    fatItem.vers = '1';
                    item = fatItem;
                  } else if (!item.vers) {
                    item.vers = '1';
                  }
                  this.checkoutItem(item, dataFile.url, null).then(() => {
                    if (this.device.bIsElectron) {
                      const queryargs: string = 'downloadoption=' + DownloadOption.kOpenIn;
                      this.downloadFilesWithAppWorks([dataFile.templateDesc], undefined, undefined, null, queryargs, true, true, true, true, false, item);
                      this.refresh(true);
                    } else {
                      this.downloadFileWithPFTA(DownloadOption.kOpenIn, dataFile.templateDesc, 'C', null, 'TEMP', true, item, false, true, null);
                      this.refresh(true);
                    }
                  }).catch(error => {
                    postErr(error);
                  });
                }
              } else {
                postErr(response.body);
              }
            } else {
              if (!!outlookMailbox && !!response.body && !!response.body.data && !!response.body.data.list && response.body.data.list.length) {
                this.device.officeDMItem = response.body.data.list[0];
                if (!!serverData['ATTACH_NUM']) {
                  const searchCriteria = { criteria: { MAIL_ID: serverData['MAIL_ID'] } };
                  this.post({ id: 'evaluation', lib: this.device.officeDMItem['lib'], type: 'searches' }, searchCriteria).subscribe((result) => {
                    const reqBody = result.list.filter(item => item['ATTACH_NUM'] > 0);
                    const msgItem = result.list.find(item => item['ATTACH_NUM'] === '0' ||  item['ATTACH_NUM'] === '-1');
                    if (!!msgItem) {
                      this.post(msgItem, reqBody, 'associations').subscribe();
                    }
                  });
                }
              }
              done(title, body);
            }
          }
        }, postErr);
      }
    };
    const startWithDataUrls = (resolvedDataFiles: DataFile[]) => {
      const isEWS: boolean = (outlookMailbox && !outlookMailbox.restUrl);
      const urls: string[] = [];
      for (dataFile of resolvedDataFiles) {
        urls.push(dataFile.url);
      }
      if (!serverData._restapi) {
        serverData['_restapi'] = {};
      }
      dataFile = resolvedDataFiles[0];
      serverData._restapi['exsrc'] = {urls, headers:dataFile.headers, excludeattachments:!!dataFile.excludeAttachments, ews:isEWS};
      start();
    };
    let dFile = null;
    const docNames = !!serverData.DOCNAME ? serverData.DOCNAME.split(this.kMultiFileSeparator) : [];
    for (let index = 0; index < dataFiles.length; index++) {
      dFile = dataFiles[index];
      docName = dFile.name;
      if (!!serverData.DOCNAME) {
        const nameParts = dFile.name.split('.');
        const ext = nameParts.length > 1 ? nameParts[nameParts.length - 1] : null;
        docName = docNames[index] + (!!ext ? ('.' + ext) : '');
      }
      const item: any = { APP_ID: this.getAppIDForFile(dFile.name, lib), type: 'documents', DOCNAME: docName, $edx_progress: 0.0, size: dFile.size };
      items.push(item);
      if (dFile.url && dFile.url.startsWith('promise_')) {
        promiseUrls = true;
      }
    }
    dataFile = dataFiles[0];
    if (!!dataFile.b64Data || !!dataFile.officeDoc || !!dataFile.templateDesc) {
      formData.append('filename', dataFile.name);
      if (!!dataFile.b64Data) {
        formData.append('b64data', dataFile.b64Data);
      } else if (!!dataFile.officeDoc || !!dataFile.templateDesc) {
        formData.append('mode', 'defered');
      }
      start();
    } else if (promiseUrls) {
      const validDataFiles: DataFile[] = [];
      let messageRestURL = '';
      let messageURL = '';
      if (!!outlookMailbox) {
        const isSharedMailbox = !!outlookMailbox['sharedProperties'];
        const sharedProperties = isSharedMailbox ? outlookMailbox['sharedProperties'] : null;
        const item = outlookMailbox.item;
        const restId = outlookMailbox.convertToRestId(item.itemId, Office.MailboxEnums.RestVersion.v2_0);
        if (isSharedMailbox && ((sharedProperties.delegatePermissions & Office.MailboxEnums.DelegatePermissions.Read) !== 0)) {
          messageRestURL = sharedProperties.targetRestUrl ? sharedProperties.targetRestUrl + '/v2.0/users/' + sharedProperties.targetMailbox + '/messages/' + restId : null;
        } else {
          messageRestURL = outlookMailbox.restUrl ? (outlookMailbox.restUrl + '/v2.0/me/messages/' + restId) : null;
        }
        const messageEwsURL: string = outlookMailbox.ewsUrl ? (outlookMailbox.ewsUrl + '/messages/' + encodeURIComponent(item.itemId)) : null;
        messageURL = messageRestURL || messageEwsURL || '';
        const options = {isRest:!!messageRestURL,asyncContext:{app:'edx'}};
        outlookMailbox.getCallbackTokenAsync(options, response => {
          if (response && response.value) {
            const headers: any = { Authorization: 'Bearer ' + response.value};
            const attachments: any = item.attachments;
            for (const curDataFile of dataFiles) {
              const kind: string = curDataFile.url.substring(8);
              if (kind==='msg') {
                validDataFiles.push({name:curDataFile.name,url:messageURL,headers,excludeAttachments:curDataFile.excludeAttachments});
              } else {
                const attData: any = attachments[parseInt(kind)];
                const attID = !!messageRestURL ? outlookMailbox.convertToRestId(attData.id, Office.MailboxEnums.RestVersion.v2_0) :  encodeURIComponent(attData.id);
                validDataFiles.push({name:curDataFile.name,size:attData.size,url:messageURL+'/attachments/'+attID,headers,attachNum:attID});
              }
            }
            this.zone.run(() => {
              startWithDataUrls(validDataFiles);
            });
          } else {
            this.handleError(response);
          }
        });
      }
    } else {
      startWithDataUrls(dataFiles);
    }
  }
  public setOfficeDocCustomProperty(propertyName: string, propertyVal: string): void {
      let properties = null;
      if (this.device.bIsOfficeAddinWord) {
        Word.run(async context => {
          properties = context.document.properties.customProperties;
          properties.add(propertyName, propertyVal);
          await context.sync();
          /*properties.load("key,type,value");
          context.sync();
          for (var i = 0; i < properties.items.length; i++)
          console.log("Property Name:" + properties.items[i].key + "; Type=" + properties.items[i].type + "; Property Value=" + properties.items[i].value);
          */
        });
      } else if (this.device.bIsOfficeAddinExcel && Office.context.requirements.isSetSupported('ExcelApi', '1.11')) {
          Excel.run(async context => {
          properties = context.workbook.properties.custom;
          properties.add(propertyName, propertyVal);
          await context.sync();
        });
      } else if (this.device.bIsOfficeAddinPowerPoint) {
        PowerPoint.run(async context => {
        properties = context.presentation.tags;
        properties.add(propertyName, propertyVal);
        await context.sync();
      });
    }
  }
  public async getOfficeDocProperties(fromSaveAsDesc?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let isReadOnly: boolean = null;
      if (this.device.bIsOfficeAddinWord) {
        Word.run(async (context) => {
          const builtInProperties = context.document.properties;
          builtInProperties.load('security');
          await context.sync();
          // security 0 = File on disk is read/write or undefined is not set so save it
          isReadOnly = !((builtInProperties.security === 0 || !builtInProperties.security));
          await context.sync();
          resolve({ isReadOnly });
        });
      } else if (Office.context.requirements.isSetSupported('ExcelApi', '1.11')) {
        Excel.run(async (context) => {
          const builtInProperties = context.workbook;
          builtInProperties.load('readOnly');
          await context.sync();
          isReadOnly = builtInProperties.readOnly;
          await context.sync();
          resolve({ isReadOnly });
        }).catch(runError => {
          reject(runError);
        });
      } else if (Office.context.requirements.isSetSupported('PowerPointApi', '1.1')) {
        isReadOnly = !!fromSaveAsDesc && fromSaveAsDesc['READONLY'] === 'Y';
        resolve({ isReadOnly });
      }
    });
  }
  public async getPowerPointFilePath(): Promise<any> {
    return new Promise((resolve, reject) => {
      let fileNameWithPath: string = null;
      PowerPoint.run(async (context) => {
        const curPresentation = context.presentation;
        curPresentation.load('tags/key, tags/value');
        await context.sync();
        for (const tag of curPresentation.tags.items) {
          if (tag.key === 'EDX_FILENAMEPATH') {
              fileNameWithPath = tag.value;
              break;
            }
        }
        await context.sync();
        resolve({ fileNameWithPath });
      }).catch(runError => {
        reject(runError);
      });
    });
  }

  public uploadFilesWithBrowser(url: string, serverData: any, fileList: File[], params?: string, queryArgs?: string): void {
    const option: any = {};
    option['message'] = {};
    option['message']['title']          = 'FORMS.LOCAL.UPLOAD.UPLOADING';
    option['message']['success_many']   = 'FORMS.LOCAL.UPLOAD.SUCCESS_MANY';
    option['message']['success_single'] = 'FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE';
    option['append_blob'] = true;
    this.uploadFilesToEDOCS(url, serverData, fileList, params, queryArgs,option);
  }

  public uploadFromOneDrive(desc: BaseDesc, serverData: any, dataFiles: DataFile[], params: string, queryArgs: string): void {
    const option: any = {};
    option['message'] = {};
    if (dataFiles[0].name === dataFiles[0].externalName) {
      // File names are same so this is a simple save-to-edocs command
      option['message']['title']          = 'FORMS.LOCAL.SAVETOEDOCS.TITLE';
      option['message']['success_many']   = 'FORMS.LOCAL.SAVETOEDOCS.MULTIPLE';
      option['message']['success_single'] = 'FORMS.LOCAL.SAVETOEDOCS.DONE';
    } else {
      // File names are not same so this is a checkin command
      option['message']['title']          = 'FORMS.LOCAL.UPLOAD.UPLOADING';
      option['message']['success_many']   = 'FORMS.LOCAL.UPLOAD.SUCCESS_MANY';
      option['message']['success_single'] = 'FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE';
    }
    this.uploadFilesToEDOCS(desc, serverData, dataFiles, params, queryArgs,option);
  }
  public uploadFilesToEDOCS(urlOrDesc: any, serverData: any, files: any[], params: string, queryArgs: string, option: any): void {
    let gotError = false;
    const items: any[] = [];
    const nFiles: number = files ? files.length : 0;
    const title: string = this.localizer.getTranslation(option['message']['title']);
    const formData: FormData = new FormData();
    const lib: string = serverData && serverData._restapi && serverData._restapi.ref ? serverData._restapi.ref.lib : null;
    const sourceLib: string = serverData && serverData._restapi && serverData._restapi.items && serverData._restapi.items.length ? serverData._restapi.items[0].lib : null;
    for (let i=0; i<nFiles; i++) {
      const file: File = files[i];
      const item: any = {APP_ID:this.getAppIDForFile(file.name, lib), type:'documents', DOCNAME:file.name, $edx_progress:0.0, size: file.size};
      items.push(item);
      if (!!option['append_blob'] && !(this.isExternalLib(sourceLib) && this.isExternalLib(lib))) {
        formData.append('file', file);
      }
    }
    const body: string = nFiles>1 ? this.localizer.getTranslation(option['message']['success_many'],[nFiles.toString()]) : this.localizer.getTranslation(option['message']['success_single'],[items[0]?.DOCNAME]);
    formData.append('data', JSON.stringify(serverData));
    this.notify.progress(title,'__local_filetransfer',null,{ DOCUMENTS:items },false,false,false).then(kNoOp);
    this.upload(urlOrDesc, formData, null, queryArgs, (progress) => {
      let curTotal = 0;
      for (let i=0; i<nFiles; i++) {
        const item = items[i];
        let prg: number = (progress.loaded-curTotal) / item.size;
        if (prg>1) {
          prg = 1;
        } else if (prg<0.0) {
          prg = 0.0;
        }
        item['$edx_progress'] = prg;
        curTotal += item.size;
      }
      const newFormData: any = { DOCUMENTS: items, $edx_progress: (progress.loaded/progress.total)};
      this.notify.updateFormData(newFormData);
      if (progress.loaded === progress.total) {
        setTimeout(() => {
          if (!gotError) {
            this.notify.updateTitle(this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.PROCESSING'));
          }
        }, 1000);
      }
    }).subscribe(response => {
      this.refreshLists();
      this.updateRecentLocinLocalStorage(serverData);
      this.uploadDone();
      this.notify.success(title, body);
    }, error => {
      gotError = true;
      const errorList: any[] = error.data && (error.data['error-list'] || error.data.error_list);
      const failedSingle: string = errorList && errorList.length===1 ? errorList[0].object : files[0] ? files[0].name : '';
      const nFailedFiles: number = errorList ? errorList.length : nFiles;
      const bodyFail: string = nFailedFiles>1 ? this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_MANY',[nFailedFiles.toString()]) :  this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILURE_SINGLE',[failedSingle]);
      if (nFailedFiles!==nFiles) {
        this.refreshLists();
      }
      this.uploadDone(error);
      if (nFailedFiles === 1 && (errorList[0].code === '0X80040490' || errorList[0].code === '0X8004031F')) {
        const fileType = errorList[0].object.split('.')[1];
        const errorMessage = errorList[0].code === '0X80040490' ? errorList[0].message : this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.DUPLICATE_FILE_TYPE', [fileType]);
        this.notify.error(title, bodyFail + ' ' + errorMessage);
      } else {
        this.notify.warning(title, bodyFail);
      }
    });
  }

    
  public uploadFolderFilesToeDOCS(urlOrDesc: any, serverData: any, files: any[], params: string, queryArgs: string, option: any, applyAllChecked: boolean): void {
    let gotError = false;
    const items: any[] = [];
    const nFiles: number = files ? files.length : 0;
    const title: string = this.localizer.getTranslation(option['message']['title']);
    const formData: FormData = new FormData();
    const lib: string = serverData && serverData._restapi && serverData._restapi.ref ? serverData._restapi.ref.lib : null;
    const sourceLib: string = serverData && serverData._restapi && serverData._restapi.items && serverData._restapi.items.length ? serverData._restapi.items[0].lib : null;
    for (let i=0; i<nFiles; i++) {
      const file: File = files[i];
      const item: any = {APP_ID:this.getAppIDForFile(file.name, lib), type:'documents', DOCNAME:file.name, $edx_progress:0.0, size: file.size};
      items.push(item);
      if (!!option['message']['append_blob'] && !(this.isExternalLib(sourceLib) && this.isExternalLib(lib))) {
        formData.append('file', file);
      }
    }
    const body: string = nFiles>1 ? this.localizer.getTranslation(option['message']['success_many'],[nFiles.toString()]) : this.localizer.getTranslation(option['message']['success_single'],[items[0]?.DOCNAME]);
    formData.append('data', JSON.stringify(serverData));
    this.upload(urlOrDesc, formData, null, queryArgs, (progress) => {
    }).subscribe(response => {
        this.updateDynamicProgress(applyAllChecked, option, response?.data?.list?.length, response?.data?.set?.total, serverData);
    }, error => {
      gotError = true;
      if (error?.data?.list?.length > 0) {
        this.updateDynamicProgress(applyAllChecked, option, error?.data?.list?.length, (error?.data?.list?.length + error?.data?.error_list?.length), serverData);
      } else {
        this.updateDynamicProgress(applyAllChecked, option, 0, (error?.data?.error_list?.length), serverData);
      }
    });
  }

  private updateDynamicProgress(applyAllChecked: boolean, option: any, successFiles, processedFiles, serverData? : any)
  {
    this.nFolderUploadSuccessFiles += successFiles;
    this.nFolderUploadTotalProcessedFiles += processedFiles;
    // To update the successfully processed files count in the progressbar body
    const messageData = [(this.nFolderUploadTotalProcessedFiles + this.nFilesProcessedWithUncheck + 1).toString(), applyAllChecked ? (this.filesToUpload?.length + this.nFilesProcessedWithUncheck) : (this.folderUploadFiles?.length)].map(String);
    const notifyBody: string = this.localizer.getTranslation(option['message']['upload_in_progress'], messageData); 
    if (!this.notify.updateBodyContent(notifyBody)) {
      const title: string = this.localizer.getTranslation(option['message']['title']);
      this.notify.progress(title, notifyBody, null,null,false,false,false).then(kNoOp);
    }

    if ((this.filesToUpload?.length === this.nFolderUploadTotalProcessedFiles && applyAllChecked)
    || (this.folderUploadFiles?.length === this.nFolderUploadTotalProcessedFiles && !applyAllChecked)) {
      this.showFolderUploadDescription(serverData);
    } else if (!applyAllChecked) {
      this.nFolderUploadBatchSize = 0;
      this.filesToUpload = [];
      this.uploadDone();
    } else if(this.nFolderUploadBatchSize < this.folderUploadResponse?.list?.length) {
    this.nFolderUploadBatchSize += 1;
    this.triggerBatchFolderUpload(this.nFolderUploadBatchSize - 1, option, serverData, applyAllChecked);
    }
  }

  public showFolderUploadDescription(serverData? : any) {
    const option: any = {
      'message' : {
        'success_folder' : 'FORMS.LOCAL.UPLOAD.SUCCESS_FOLDER_CREATION',
        'failed_folder' : 'FORMS.LOCAL.UPLOAD.FAILED_FOLDER_CREATION',
        'success_file' : 'FORMS.LOCAL.UPLOAD.SUCCESS_FILE_UPLOAD',
        'failed_file' : 'FORMS.LOCAL.UPLOAD.FAILED_FILE_UPLOAD',
        'upload_success_title' : 'FORMS.LOCAL.UPLOAD.UPLOADED'
      }
    };
    const failedFolderCount = (this.nFolderUploadTotalProcessedFolders - this.nFolderUploadSuccessFolders);
    const failedFilesCount = (this.folderUploadFiles?.length - (this.nFolderUploadSuccessFiles + this.nFilesSuccessWithUncheck));
    const successFolderString = (this.localizer.getTranslation(this.nFolderUploadSuccessFolders > 1 ? 'CONFIGURE_RED.FOLDERS' : 'FORMS.LOCAL.LINK.TYPE_FOLDERS')).toLowerCase();
    const failureFolderString = (this.localizer.getTranslation(failedFolderCount > 1 ? 'CONFIGURE_RED.FOLDERS' : 'FORMS.LOCAL.LINK.TYPE_FOLDERS')).toLowerCase();
    const successFileString = (this.localizer.getTranslation((this.nFolderUploadSuccessFiles + this.nFilesSuccessWithUncheck) > 1 ? 'FORMS.LOCAL.UPLOAD.FILES' : 'FORMS.LOCAL.UPLOAD.FILE')).toLowerCase();
    const failedFileString = (this.localizer.getTranslation(failedFilesCount > 1 ? 'FORMS.LOCAL.UPLOAD.FILES' : 'FORMS.LOCAL.UPLOAD.FILE')).toLowerCase();
    let notifySuccessBody: string = this.localizer.getTranslation(option['message']['success_folder'],[this.nFolderUploadSuccessFolders.toString(),successFolderString].map(String));
    if(failedFolderCount > 0) {
      notifySuccessBody += ", " + this.localizer.getTranslation(option['message']['failed_folder'],[failedFolderCount.toString(),failureFolderString].map(String));
    }
    notifySuccessBody += " \n";
    notifySuccessBody += this.localizer.getTranslation(option['message']['success_file'],[(this.nFolderUploadSuccessFiles + this.nFilesSuccessWithUncheck).toString(),successFileString].map(String));
    if (failedFilesCount > 0) {
      notifySuccessBody += ", " + this.localizer.getTranslation(option['message']['failed_file'],[(failedFilesCount).toString(), failedFileString].map(String));
    }
    this.refreshLists();
    if (!!serverData) {
      this.updateRecentLocinLocalStorage(serverData);
    }
    this.uploadDone();
    this.notify.success(this.localizer.getTranslation(option['message']['upload_success_title']), notifySuccessBody);
    this.resetUploadFolderFilesCount();
    this.resetFolderUploadFiles();
  }
  private resetUploadFolderFilesCount(): void {
    this.nFolderUploadSuccessFiles = 0;
    this.nFolderUploadTotalProcessedFiles = 0;
    this.nFilesProcessedWithUncheck = 0;
    this.nFilesSuccessWithUncheck = 0;
    this.nFolderUploadSuccessFolders = 0;
    this.nFolderUploadTotalProcessedFolders = 0;
    this.nFolderUploadBatchSize = 0;
  }

  public updateRecentLocinLocalStorage(serverData): void {
    if (!!serverData && !!serverData['%RECENTLY_USED_LOCATION']) {
      this.recentLocService.updateRecentLocationInLocalStorage(serverData['%RECENTLY_USED_LOCATION']);
    }
  }

  public checkinDocsWithPFTA(listItems: any[], profileData: any, put?: boolean, specifyVersion?: boolean): void {
    const list: any[] = [];
    const nItems: number = listItems.length;
    const title = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.UPLOADING');
    for (let i=0; i<nItems; i++) {
      const profileDataCopy: any = this.deepCopy(profileData);
      const docname: string = profileDataCopy.DOCNAME;
      if (docname && docname.length) {
        const docnames: string[] = docname.split(this.kMultiFileSeparator);
        if (docnames.length===nItems) {
          profileDataCopy.DOCNAME = docnames[i];
        }
      }
      const appid: string = profileDataCopy.APP_ID;
      if (appid && appid.length) {
        const appids: string[] = appid.split(this.kMultiFileSeparator);
        if (appids.length===nItems) {
          profileDataCopy.APP_ID = appids[i];
        }
      }
      list.push({APP_ID:profileDataCopy.APP_ID, DOCNAME: profileDataCopy.DOCNAME, $edx_progress: 0});
    }
    const formData: any = { DOCUMENTS: list };
    this.notify.progress(title, '__local_filetransfer', null, formData, false, false, false).then(confirmed => {
    });
    const specifyVers: string = '&specifyversion=' + (!!specifyVersion ? 'true' : 'false');
    const dataStr: string = JSON.stringify(listItems);
    const b64Data: string = this.transforms.b64EncodeUnicode(dataStr);
    this.putpostJSONP(put ? 'put' : 'post', '&checkin=true' + specifyVers + '&items=' + encodeURIComponent(b64Data), profileData).then(result => {
      const nUploadedItems = result.data?.data?.list.length || 0;
      if (nUploadedItems === nItems) {
        const successMessage = nUploadedItems === 1 ?
          this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE', [listItems[0].DOCNAME]) :
          this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_MANY', [nUploadedItems.toString()]);
          this.notify.success(successMessage);
      } else {
        const messageData = [nUploadedItems, nItems - nUploadedItems].map(String);
        const message = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_MANY_PARTIAL', messageData);
        this.notify.warning(title, message);
      }
      this.refreshLists();
      this.uploadDone();
    }, err => {
      this.notify.warning(title, err);
    });
  }

  public uploadFilesWithPFTA(filePaths: string[], profileData: any, lib?: string): void {
    if (this.pftaVersion()>=0x00160702) {
      const list: any[] = [];
      let firstDocName: string;
      const nItems: number = filePaths.length;
      const title = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.UPLOADING');
      for (let i=0; i<nItems; i++) {
        const profileDataCopy: any = this.deepCopy(profileData);
        const docname: string = profileDataCopy.DOCNAME;
        if (docname && docname.length) {
          const docnames: string[] = docname.split(this.kMultiFileSeparator);
          if (docnames.length===nItems) {
            profileDataCopy.DOCNAME = docnames[i];
          }
          if (i===0) {
            firstDocName = profileDataCopy.DOCNAME;
          }
        }
        const appid: string = profileDataCopy.APP_ID;
        if (appid && appid.length) {
          const appids: string[] = appid.split(this.kMultiFileSeparator);
          if (appids.length===nItems) {
            profileDataCopy.APP_ID = appids[i];
          }
        }
        const docNum: string = profileDataCopy.DOCNUM;
        if (docNum && docNum.length) {
          const docNums: string[] = docNum.split(this.kMultiFileSeparator);
          if (docNums.length===nItems) {
            profileDataCopy.DOCNUM = docNums[i];
          }
        }
        list.push({APP_ID:profileDataCopy.APP_ID, DOCNAME: profileDataCopy.DOCNAME, $edx_progress: 0});
      }
      const formData: any = { DOCUMENTS: list };
        this.notify.progress(title,'__local_filetransfer',null,formData,false,false,false).then(confirmed => {
      });
      const dataStr: string = JSON.stringify(filePaths);
      const b64Data: string = this.transforms.b64EncodeUnicode(dataStr);
      lib = lib || (!!profileData._restapi.ref ? profileData._restapi.ref.lib : this.getPrimaryLibrary());
      this.postJSONP('&uploadfiles=' + encodeURIComponent(b64Data) + '&lib=' + lib, profileData).then(data => {
        this.notify.success(nItems === 1 ? this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE', [firstDocName]) : this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_MANY', [nItems.toString()]));
        this.refreshLists();
        this.uploadDone();
      }, err => {
        this.notify.warning(title, err);
      });
    }
  }

  public getRightsForItem(listItem: ListItem): SecurityControl {
    const rights: SecurityControl = new SecurityControl(AccessLevel.ACCESS_LEVEL_FULL);
    if (listItem['%SECURITY']) {
      const itemAccess = +listItem['%SECURITY'];
      if (itemAccess > 0) {
        rights.access &= itemAccess;
      }
    }
    return rights;
  }

  public checkoutItem(item: any, location: string, version: any): Promise<boolean> {
    const checkoutData = { '%STATUS': '%LOCK_FOR_CHECKOUT' };
    if (!!location) {
      checkoutData['%CHECKIN_LOCATION'] = location;
    }
    if (version && version !== 'C') {
      checkoutData['%VERSION_ID'] = version;
    }
    return this.put(item, checkoutData, 'profile').toPromise();
  }

  public checkoutDocument(listItem: any, version?: string): Promise<boolean> {
    // rights to checkout
    const rights: SecurityControl = this.getRightsForItem(listItem);
    const canItemBeCheckout: boolean = rights.canEditContent && (listItem['type'] === 'documents') && listItem['STATUS'] === '0' && listItem['READONLY'] !== 'Y';
    const notifyTitle: string = this.localizer.getTranslation('DOC_STATUS.CHECKEDOUT');
    const notifyBody: string = listItem.DOCNAME;      // If user don't have rights to view
    if (canItemBeCheckout) {
      return this.checkoutItem(listItem, null, version).then(response => {
        this.refreshLists(listItem);
        const curWindow: WindowModalComponent = this.getCurWindow();
        if (curWindow) {
           curWindow.refresh();
        }
        if (notifyTitle) {
          this.notify.success(notifyTitle, notifyBody);
        }
        return Promise.resolve(true);
      }).catch(error => {
        this.notify.warning(notifyTitle, error);
        this.refreshLists();
        return Promise.resolve(false);
      });
    }
    return Promise.resolve(false);
  }

  public viewOrOpenFilesWithStatusCheck(list: ListItem[], versions: string[], view: boolean, cb: (success) => void): void {
    const selectedItem = list[0];
    const selectedVersionId = versions?.[0];
    const alwaysCheckoutDocumentOnOpen: boolean = this.hasAutoCheckoutCheckin() && selectedItem['STORAGE'] !== 'T' && !view;
    const rights: SecurityControl = this.getRightsForItem(selectedItem);
    const selectedItemStatus = parseInt(selectedItem['STATUS']);
    let selectedVersionStatus = null;
    const canItemBeCheckout: boolean = (selectedItem['type'] === 'documents') && selectedItemStatus === 0 && selectedItem['READONLY'] !== 'Y';
    const forceDL = alwaysCheckoutDocumentOnOpen && !canItemBeCheckout;
    const viewOrOpen = () => {
      if (forceDL
        || [3, 16, 19, 20].includes(selectedItemStatus)
        || [19, 20].includes(selectedVersionStatus)
        || selectedItem['READ_ONLY'] === 'Y'
        || (!rights.canEditContent && this.getSystemDefaultSetting('HideReadOnlyMessage') !== 'Y')) {
        const status = selectedItemStatus || selectedVersionStatus || 0;
        let bodyData = null;
        let bodyTranslationKey = '';
        let subForm: string = null;
        let subFormData: any = null;
        if (status === 3) {
          if (!!selectedItem.checkout) {
            subForm = '__local_checkout_open';
            subFormData = {
              DOCNAME_NOTE: this.localizer.getTranslation('FORMS.LOCAL.CHECK_OUT.DOCNAME_NOTE', [selectedItem.id, selectedItem['DOCNAME']]),
              CHECKED_OUT_BY: selectedItem.checkout['FULL_NAME'],
              CHECKOUT_DATE: this.transforms.formatDate(selectedItem.checkout['CHECKOUT_DATE']),
              CHECKIN_DATE: this.transforms.formatDate(selectedItem.checkout['CHECKIN_DATE'], true),
              COMMENTS: selectedItem.checkout['COMMENTS']
            };
          } else {
            bodyData = [selectedItem['DOCNAME'], selectedItem.checkout?.['FULL_NAME'], this.transforms.formatDate(selectedItem.checkout?.['CHECKOUT_DATE'])];
            bodyTranslationKey = 'FOLDER_ACTIONS.OPEN_CHECKED_OUT';
          }
        } else if (status === 16) {
          bodyData = [selectedItem['DOCNUM'], selectedItem['DOCNAME']];
          bodyTranslationKey = 'FORMS.LOCAL.OPEN.ERROR_OPEN_BEING_ARCHIVED';
        } else if (status === 19) {
          bodyTranslationKey = !!selectedVersionId ? 'FORMS.LOCAL.DOWNLOAD.OPEN_READONLY' :
            (!!selectedItemStatus ? 'FOLDER_ACTIONS.OPEN_READ_ONLY_DOCUMENT' : 'FOLDER_ACTIONS.OPEN_READ_ONLY');
        } else if (status === 20) {
          bodyTranslationKey = !!selectedVersionId ? 'FORMS.LOCAL.DOWNLOAD.OPEN_PUBLISHED' : 'FOLDER_ACTIONS.OPEN_PUBLISHED';
        } else if (!rights.canEditContent) {
          bodyTranslationKey = 'FOLDER_ACTIONS.OPEN_READ_ONLY_PERMISSION';
        }
        const title = this.localizer.getTranslation('FOLDER_ACTIONS.OPEN');
        const body = !!subForm ? subForm : this.localizer.getTranslation(bodyTranslationKey, bodyData);
        this.notify.confirm(title, body, null, subFormData, true, true, true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            this.viewOrOpenFiles(list, versions, view || forceDL, false, true, cb);
          }
        });
      } else {
        this.viewOrOpenFiles(list, versions, view, false, false, cb);
      }
    };
    const defaultOpen: string = this.getPreference('def_open');
    if (!defaultOpen || defaultOpen === 'L') {
      this.get(selectedItem, 'versions', 'descending=VERSION_ID').subscribe((data: ListData) => {
        const selectedVersion = data?.list?.find(item => !selectedVersionId || item.VERSION_ID === selectedVersionId);
        selectedVersionStatus = parseInt(selectedVersion?.['STATUS']);
        viewOrOpen();
      }, err => {
        this.handleError(err);
      });
    } else {
      viewOrOpen();
    }
  }

  public viewOrOpenFiles(list: ListItem[], versionsList: string[], view: boolean, bForceAutoCheckin: boolean, openFileAsReadOnly: boolean, cb?: (success) => void): void {
    const checkedOutList: ListItem[] = [];
    const versionList: string[] = [];
    let nReplies = 0;
    const nFiles: number = list.length;
    let bJustDownload = false;
    let title: string;
    let checkOutName1: string;
    let checkOutDate1: string;
    const doDownload = (autoCheckin: boolean, items: ListItem[], versions?: string[], useTempFile?: boolean, versionInfoData?: any) => {
      const path: string = (!this.device.bIsElectron && !this.device.bIsCordova && this.hasPFTA() && autoCheckin) ? 'temp' : null;
      const isOfficeOpen: boolean = !this.hasPFTA() && this.device.canDownloadOfficeDoc(items[0]);
      if (items && items.length) {
        if (this.device.bIsElectron || this.device.bIsCordova) {
          const version: string = !!versions ? versions.join(';') : undefined;
          const queryargs: string = 'downloadoption=' + DownloadOption.kOpenIn;
          this.downloadFilesWithAppWorks(items, undefined, undefined, version, queryargs, true, view, autoCheckin, bForceAutoCheckin, bJustDownload);
          if (!!cb) {
            cb(true);
          }
        } else {
          let bCalledCB = false;
          const nItems = items.length;
          for (let i = 0; i < nItems; i++) {
            const item = items[0];
            const version = !!versions ? versions[i] : undefined;
            if (isOfficeOpen) {
              this.openFileInOffice(item, version, undefined, autoCheckin, false);
            } else {
              this.viewFile(item, version, undefined, path, view, autoCheckin, bForceAutoCheckin, false, cb, useTempFile, versionInfoData);
              bCalledCB = true;
            }
          }
          if (!!cb && !bCalledCB) {
            cb(false);
          }
        }
      } else {
        let notifyBody: string;
        if (checkOutName1 && checkOutDate1 && list[0].DOCNUM && list[0].DOCNAME) {
          notifyBody = this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN', [list[0].DOCNUM, list[0].DOCNAME, checkOutName1, this.transforms.formatDate(checkOutDate1)]);
        } else {
          notifyBody = this.localizer.getTranslation('GENERIC_ERRORS.ERROR');
        }
        this.notify.warning(this.localizer.getTranslation('FOLDER_ACTIONS.OPEN'), notifyBody); // NOTE : this notification overwrites the catch notification in checkoutDocument
        if (!!cb) {
          cb(false);
        }
      }
    };

    const handleCheckedOut = (autoCheckin: boolean, success: boolean, item: ListItem, version?: string, useTempFile?: boolean, versionInfoData?: any) => {
      if (success) {
        checkedOutList.push(item);
        versionList.push(version);
      }
      if (++nReplies === nFiles && checkedOutList.length > 0) {
        // when all the repsonses have come back then start the downloads
        doDownload(autoCheckin, checkedOutList, versionList, useTempFile, versionInfoData);
      }
    };

    const versionCheckoutAndDownload = (listItem: ListItem, version: string, openAsReadOnly: boolean, alwaysCheckoutDocumentOnOpen: boolean, useTempFile: boolean, versionInfoData: any) => {
      const rights: SecurityControl = this.getRightsForItem(listItem);
      const isReadOnly: boolean = listItem['STATUS'] === 19 || listItem['READONLY'] === 'Y' || !rights.canEditContent;
      const isCheckedOut: boolean = listItem['STATUS'] === 3;
      const checkOutName: string = isCheckedOut && listItem['checkout'] ? listItem['checkout']['TYPIST_ID'] : '';
      if (!checkOutName1) {
        checkOutName1 = checkOutName;
      }
      if (!checkOutDate1) {
        checkOutDate1 = isCheckedOut && listItem['checkout'] ? listItem['checkout']['CHECKOUT_DATE'] : '';
      }
      if (isCheckedOut || isReadOnly || openAsReadOnly) {
        if (!isReadOnly || !rights.canEditContent) {
          listItem = this.deepCopy(listItem);
          listItem['READONLY'] = 'Y';
        }
        bJustDownload = true;
        handleCheckedOut(false, true, listItem, version, useTempFile, versionInfoData);
      } else {
        this.checkoutDocument(listItem, version).then(success => {
          handleCheckedOut(alwaysCheckoutDocumentOnOpen && success, success, listItem, version, useTempFile, versionInfoData);
        });
      }
    };

    const handleVersionPicked = (items: ListItem[], versions: string[], openAsReadOnly: boolean) => {
      const alwaysCheckoutDocumentOnOpen: boolean = (this.hasAutoCheckoutCheckin() || bForceAutoCheckin) && !bJustDownload && !view;
      // If 'Always checkout document on open' is set 'CHECKOUT' the document first then open
      if (alwaysCheckoutDocumentOnOpen) {
        for (let i = 0; i < nFiles; i++) {
          const listItem: ListItem = list[i];
          const version = !!versions ? versions[i] : undefined;
          if (this.hasPFTA()) {
            this.checkTempFile(listItem, null, version).then(result => {
              if (!result.ignoreSelection) {
                versionCheckoutAndDownload(listItem, version, openAsReadOnly, alwaysCheckoutDocumentOnOpen, result.useTempFile, result.versionInfoData);
              }
            });
          } else {
            versionCheckoutAndDownload(listItem, version, openAsReadOnly, alwaysCheckoutDocumentOnOpen, false, null);
          }
        }
      } else {
        const nItems = items.length;
        for (let iItem = 0; iItem < nItems; iItem++) {
          let anItem = items[iItem];
          const rights: SecurityControl = this.getRightsForItem(anItem);
          if (!(anItem['STATUS'] === 19 || anItem['READONLY'] === 'Y') && !rights.canEditContent) {
            anItem = this.deepCopy(anItem);
            anItem['READONLY'] = 'Y';
            items[iItem] = anItem;
          }
        }
        doDownload(false, items, versions);
      }
    };
    const pickVersion = (items: ListItem[], openAsReadOnly: boolean) => {
      if (!!versionsList) {
        handleVersionPicked(items, versionsList, false);
      } else if (nFiles === 1 && !view && this.getPreference('def_open') === 'S') {
        this.get(items[0], 'versions').subscribe((data: ListData) => {
          const confirmOpenReadOnlyVersion = (items1: ListItem[], versions: string[], readOnly: boolean, body: string) => {
            title = this.localizer.getTranslation('FOLDER_ACTIONS.OPEN');
            this.notify.confirm(title, body, null, null, true, true, true).then(confirmed2 => {
              if (!!confirmed2 && confirmed2.confirm) {
                handleVersionPicked(items1, versions, readOnly);
              }
            });
          };
          if (!!data && !!data.list && data.list.length === 1) {
            if (['19', '20'].indexOf(data.list[0]['STATUS']) !== -1) {
              const body = this.localizer.getTranslation(data.list[0]['STATUS'] === 19 ? 'FOLDER_ACTIONS.OPEN_READ_ONLY' : 'FOLDER_ACTIONS.OPEN_PUBLISHED');
              confirmOpenReadOnlyVersion(items, ['C'], true, body);
            } else {
              handleVersionPicked(items, ['C'], false);
            }
          } else {
            title = this.localizer.getTranslation('FORMS.LOCAL.PICK_VERSION.CHOOSE_VERSION');
            const formData: any = { version: 'C', desc: items[0] };
            this.notify.confirm(title, '__local_pick_version', null, formData, true, true, true).then(confirmed1 => {
              if (!!confirmed1 && confirmed1.confirm) {
                let version: string = confirmed1.data['$edx_version'];
                const curVersID = confirmed1.data['$edx_currentversion_id'];
                if (!version || !version.length) {
                  version = undefined;
                }
                const curDocVersion = data.list.find((item) => item.VERSION_ID === version)['VERSION'];
                const canUserEditPreviousVersions = this.canUserEditVersion(data.list, curDocVersion);
                const versionOpenAsReadOnly = localStorage.getItem('openAsReadOnly') === 'true' || !canUserEditPreviousVersions;
                localStorage.removeItem('openAsReadOnly');
                if (!!version && !(version === 'C' || version === curVersID) && !canUserEditPreviousVersions) {
                  const body = !canUserEditPreviousVersions ? this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN_PREVIOUS_VERSION')
                                                            : this.localizer.getTranslation('FOLDER_ACTIONS.OPEN_READ_ONLY');
                  confirmOpenReadOnlyVersion(items, !!version ? [version] : undefined, versionOpenAsReadOnly, body);
                } else {
                  handleVersionPicked(items, !!version ? [version] : undefined, versionOpenAsReadOnly);
                }
              }
            });
          }
        }, err1 => {
          this.handleError(err1);
        });
      } else {
        handleVersionPicked(items, undefined, openAsReadOnly);
      }
    };
    if (nFiles === 1 && !view && list[0]['STORAGE'] === 'T') {
      const rights: SecurityControl = this.getRightsForItem(list[0]);
      let bUseTemplate: boolean;
      const afterCheckForUse = () => {
        if (bUseTemplate && (this.hasPFTA() || this.device.bIsElectron)) {
          this.get(this.makeFileInfoURL(list[0])).toPromise().then((data: any) => {
            const templateDesc = this.deepCopy(list[0]);
            templateDesc.vers = '1';
            if (templateDesc['STATUS'] === '19') {
              templateDesc['STATUS'] = '0';
              templateDesc['READONLY'] = 'N';
            }
            this.uploadFilesWithUI(null, null, [{ name: list[0].DOCNAME + '.' + data.FILE_EXTENSION, templateDesc }]);
            if (!!cb) {
              cb(false);
            }
          }, err => {
            if (!!cb) {
              cb(false);
            }
          });
        } else {
          bJustDownload = bUseTemplate;
          bForceAutoCheckin = !bJustDownload;
          pickVersion(list, openFileAsReadOnly);
        }
      };
      if ((this.device.isMobile() && !this.device.bIsOfficeAddin) || list[0]['STATUS'] === '3') {
        bJustDownload = true;
        pickVersion(list, openFileAsReadOnly);
      } else if (!this.canUserManageTemplates() || !rights.canEditContent || ['18', '19'].indexOf(list[0]['STATUS']) > -1 || list[0]['READONLY'] === 'Y') {
        bUseTemplate = true;
        afterCheckForUse();
      } else {
        title = this.localizer.getTranslation('FORMS.LOCAL.TEMPLATE_OPTIONS.PROMPT');
        const formData: any = { use_edit: 'U', desc: list[0] };
        this.notify.confirm(title, '__local_template_options', null, formData, true, true, true).then(confirmed => {
          if (confirmed && confirmed.confirm) {
            bUseTemplate = confirmed.data['use_edit'] === 'U';
            if (bUseTemplate && this.app?.commandsComponent) {
              this.app.commandsComponent.dialogKind = '';
            }
            afterCheckForUse();
          } else {
            if (!!cb) {
              cb(false);
            }
          }
        });
      }
    } else {
      pickVersion(list, openFileAsReadOnly);
    }
  }

  public getCachedOfficeLaunchInfo(localFilePath: string): any {
    const launchedItems = this.sessionCache.officeLaunchedItems;
    const launchInfo = launchedItems.filter(item => item.localFilePath === localFilePath)[0];
    return launchInfo;
  }

  public setCachedOfficeLaunchInfo(launchInfo: any): void {
    const cachedLaunchInfo = this.getCachedOfficeLaunchInfo(launchInfo?.localFilePath);
    if (!cachedLaunchInfo) {
      this.sessionCache.officeLaunchedItems.push(launchInfo);
    }
  }

  public getPFTALaunchInfo(localFilePath: string): Promise<any> {
    if (this.hasPFTA()) {
      return new Promise((resolve, reject) => {
        const requestArgs: string = '&getlaunchinfo'
          + '&localfilepath=' + this.transforms.b64EncodeUnicode(localFilePath);
        this.getJSONP(requestArgs).then(pftaLaunchInfo => {
          const launchInfo = pftaLaunchInfo.data || {localFilePath};
          resolve(launchInfo);
        }, err => {
          resolve({localFilePath});
        });
      });
    } else {
      return Promise.resolve({localFilePath});
    }
  }

  public getPFTALocalPathInfo(rootPath: string, localPath: string, passAlong: string): Promise<any> {
    if (this.hasPFTA()) {
      return new Promise((resolve, reject) => {
        if (!!rootPath) {
          localPath = this.transforms.trimEnd(rootPath, this.filePathSeparator) + this.filePathSeparator + localPath;
        }
        const checkFileArgs: string = '&getlocalpathinfo'
          + '&localpath=' + this.transforms.b64EncodeUnicode(localPath)
          + '&passalong=' + this.transforms.b64EncodeUnicode(passAlong);
        this.getJSONP(checkFileArgs).then(localFileInfo => {
          localFileInfo.data.passAlong = this.transforms.b64DecodeUnicode(localFileInfo.data.passAlong);
          resolve(localFileInfo.data);
        }, err => {
          resolve(null);
        });
      });
    } else {
      return Promise.resolve(null);
    }
  }

  public canCheckoutSelectedDocuments(profileItems: Array<any>, downloadPath: string, version: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const allTempFilesInfo = [];
      for (const profileItem of profileItems) {
        const itemInfo1 = {
          loaded: false,
          documentNumber: profileItem['DOCNUM'],
          documentName: profileItem['DOCNAME']
        };
        allTempFilesInfo.push(itemInfo1);
        this.getFileDownloadInfo(profileItem, version ?? 'C', false).then(versionInfoData => {
          const documentNumber = versionInfoData.documentNumber;
          const itemInfo2 = allTempFilesInfo.filter(item => item.documentNumber === documentNumber)[0];
          itemInfo2['serverLastEditDate'] = versionInfoData.lastEditDate;
            this.getPFTALocalPathInfo(downloadPath, versionInfoData.name, documentNumber).then(localFileInfo => {
            const documentNumber3 = localFileInfo.passAlong;
            const itemInfo3 = allTempFilesInfo.filter(item => item.documentNumber === documentNumber3)[0];
            itemInfo3['tempLastEditDate'] = localFileInfo.lastModified;
            itemInfo3['loaded'] = true;
            if (allTempFilesInfo.filter(item => !item.loaded).length === 0) {
              const canCheckout = this.canCheckoutDocuments(allTempFilesInfo);
              resolve(canCheckout);
            }
          }, err => {
            reject(err);
          });
        }, err => {
          reject(err);
        });
      }
    });
  }

  private compareModifiedDate(tempLastEditDate: string, serverLastEditDate: string): number {
    let comapre = 0;
    if ((!!tempLastEditDate) && (!!serverLastEditDate)) {
      const tempFileLastModifiedDate: Date = new Date(tempLastEditDate);
      const serverFileLastModifiedDate: Date = new Date(serverLastEditDate);
      if (Math.abs(tempFileLastModifiedDate.getTime() - serverFileLastModifiedDate.getTime()) > 1000) {
        comapre = (tempFileLastModifiedDate > serverFileLastModifiedDate ? 1 : -1);
      }
    }
    return comapre;
  }

  private canCheckoutDocuments(allTempFilesInfo): boolean {
    const localTempFiles = allTempFilesInfo.filter(item => this.compareModifiedDate(item.tempLastEditDate, item.serverLastEditDate) !== 0);
    const canCheckout = ((localTempFiles.length < 1) || ((localTempFiles.length === 1) && (allTempFilesInfo.length <= 1)));
    if (!canCheckout) {
      const confirmTitle: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_TEMP_FILE.TITLE');
      const question: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_TEMP_FILE.CANNOT_CHECKOUT');
      const description: string = localTempFiles.map(item => `${item.documentName} (${item.documentNumber})`).join('\n');
      const formData: any = { question, description };
      this.notify.warning(confirmTitle, '__local_cannot_checkout', null, formData, true, false, false).then(confirmed => {
      });
    }
    return canCheckout;
  }

  public checkTempFile(profileItem: any, downloadPath: string, version: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFileDownloadInfo(profileItem, version ?? 'C', false).then(versionInfoData => {
        this.getPFTALocalPathInfo(downloadPath, versionInfoData.name, null).then(localFileInfo => {
          let ignoreSelection = false;
          let useTempFile = false;
          const tempFileLastModifiedStr = localFileInfo.lastModified;
          const compare = this.compareModifiedDate(tempFileLastModifiedStr, versionInfoData.lastEditDate);
          if (localFileInfo.isFile && compare !== 0) {
            const confirmTitle: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_TEMP_FILE.TITLE');
            const questionId = (compare === 1 ? 'ASK_NEW_FILE' : 'ASK_OLD_FILE');
            const question: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_TEMP_FILE.' + questionId, [profileItem.DOCNAME]);
            const filePath: string = localFileInfo.tempPath;
            const fileName: string = localFileInfo.fileName;
            const tempFileDate: string = this.transforms.formatDate(tempFileLastModifiedStr);
            const serverFileDate: string = this.transforms.formatDate(versionInfoData.lastEditDate);
            const descriptionData = [filePath, fileName, tempFileDate, serverFileDate];
            const description: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_TEMP_FILE.DESCRIPTION', descriptionData);
            const formData: any = { question, description };
            this.notify.warning(confirmTitle, '__local_check_temp_file', null, formData, true, true, true).then(confirmed => {
              let selection = '';
              if (confirmed && confirmed.confirm) {
                selection = confirmed.data.radio;
              }
              if (selection === 'UTF') {
                useTempFile = true;
              } else if (selection === 'USF') {
                useTempFile = false;
              } else {
                ignoreSelection = true;
              }
              resolve({ ignoreSelection, useTempFile, versionInfoData });
            });
          } else {
            resolve({ ignoreSelection, useTempFile, versionInfoData });
          }
        }, err => {
          reject(err);
        });
      });
    });
  }

  public monitorFile(name: string, path: string, vers: string='0', desc?: any): void {
    if ((this.device.bIsOfficeAddinWord || this.device.bIsOfficeAddinExcel || this.device.bIsOfficeAddinPowerPoint) && this.hasPFTA() && !!path ) {
      // new file so monitor it for changes
      const monitorDesc = !!desc ? {id:desc.id,type:desc.type,lib:desc.lib,vers,STATUS:(desc.STATUS || '0')} : {id:'',type:'',lib:'',vers,STATUS:''};
      const monitorDescStr = encodeURIComponent(this.transforms.b64EncodeUnicode(JSON.stringify(monitorDesc)));
      const monitorFile = encodeURIComponent(this.transforms.b64EncodeUnicode(name));
      const monitorPath = encodeURIComponent(this.transforms.b64EncodeUnicode(path));
      const pathParts = path.split(this.filePathSeparator);
      const nPathParts = pathParts.length;
      const fileNameFromPath = path[nPathParts-1];
      let versionOverrideStr = '';
      let deleteOnCloseStr = '';
      const docObj = this.decodeFileName(name);
      const docObjFromPath = this.decodeFileName(fileNameFromPath);
      if ((!docObj && vers!=='0'&& vers!=='C') || (!!docObj && !!docObj.vers && docObj.vers !== vers) || (!docObjFromPath && vers!=='0'&& vers!=='C') || (!!docObjFromPath && !!docObjFromPath.vers && docObjFromPath.vers !== vers)) {
        versionOverrideStr = '&versionoverride='+encodeURIComponent(this.transforms.b64EncodeUnicode(vers));
      }
      if (!!this.device.officeDocDelete) {
        deleteOnCloseStr = '&deleteonclose=true';
      }
      this.device.officeDocDelete = undefined;
      this.getJSONP('&monitorfile='+monitorFile+'&monitorpath='+monitorPath+'&desc='+monitorDescStr+versionOverrideStr+deleteOnCloseStr).then(kNoOp, err => {
        console.error(err);
      });
    }
  }

  public cancelMonitorFile(name: string, path: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.hasPFTA()) {
        // this cancels any monitoring and any auto-checkin
        const monitorFile = encodeURIComponent(this.transforms.b64EncodeUnicode(name));
        const monitorPath = encodeURIComponent(this.transforms.b64EncodeUnicode(path));
        this.getJSONP('&cancelmonitorfile='+monitorFile+'&monitorpath='+monitorPath).then(resolve, reject);
      } else {
        reject('no pfta or path or http path');
      }
    });
  }

  public getTempPath(): string {
    return this.tempPath;
  }

  public getDownloadsPath(): string {
    return this.downloadsPath;
  }

  public downloadFileUrl(url: string, path: string, name: string): void {
    const title = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING');
    const upperPath: string = !!path ? path.toUpperCase() : '';
    let tmpPath: string = path || this.getDownloadsPath();
    if (tmpPath[tmpPath.length - 1] !== this.filePathSeparator) {
      tmpPath += this.filePathSeparator;
    }
    if (this.device.bIsElectron) {
      const options = { headers: this.getDMHeaders() };
      const ft: AWFileTransfer = new AWFileTransfer(result => {
        this.zone.run(() => {
          setTimeout(() => {
            this.notify.success(this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_DONE'));
          }, 1000);
        });
      }, error => {
        this.zone.run(() => {
          this.notify.warning(title, error);
        });
      });
      ft.download(url, tmpPath + name, options);
    } else if (this.hasPFTA()) {
      const urlB64: string = encodeURIComponent(this.transforms.b64EncodeUnicode(url));
      const nameB64: string = encodeURIComponent(this.transforms.b64EncodeUnicode(name));
      const location: string = upperPath === 'DOWNLOADS' ? '&location=downloads' : (!!path && path.length) ? '&location=absolute_' + encodeURIComponent(this.transforms.b64EncodeUnicode(path)) : '';
      this.getJSONP('&fileurl=' + urlB64 + '&filename=' + nameB64 + location + '&noticks=true').then(data => {
        this.notify.success(this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_DONE'));
      }, err => {
        this.checkForPFTA().then(success => {
          this.notify.warning(title, err);
        }, error => {
          err = {
            message: this.localizer.getTranslation('FORMS.LOCAL.PFTA.NOT_RUNNING')
          };
          this.notify.warning(title, err);
        });
      });
    }
  }

  public downloadFile(desc: BaseDesc, version: string='C', queryargs?: string, path?: string, shortName?: boolean, lanuch?: boolean, cb?: (success) => void, useTempFile?: boolean, versionInfoData?: any, fileName?: string): void {
    this.downloadFileWithOptions(DownloadOption.kDownload, desc, version, queryargs, path, false, false, shortName, lanuch, cb, useTempFile, versionInfoData, fileName);
  }

  public viewFile(desc: BaseDesc, version: string='C', queryargs?: string, path?: string, internal?: boolean, okToAutoCheckin?: boolean, forceAutoCheckin?: boolean, shortName?: boolean, cb?: (success) => void, useTempFile?: boolean, versionInfoData?: any): void {
    this.downloadFileWithOptions(internal ? DownloadOption.kViewInternal : DownloadOption.kOpenIn, desc, version, queryargs, path, okToAutoCheckin, forceAutoCheckin, shortName, true, cb, useTempFile, versionInfoData);
  }

  public openFileInOffice(desc: BaseDesc, version: string='C', queryargs?: string, okToAutoCheckin?: boolean, shortName?: boolean): void {
    this.downloadFileWithOptions(DownloadOption.kOpenInOffice, desc, version, queryargs, null, okToAutoCheckin, false, shortName, false, null);
  }

  public getDocNameForUploadFolder(): string {
    return this.folderUploadFolders?.map(x => x.name)?.join(',;');
  }

  public uploadFilesWithUI(files: File[], filePaths?: string[], dataFiles?: DataFile[], intoThisFolder?: any, formData?: any, serverData?: any, doNotShowProfileForm?: boolean): void {
    if (!this.openDRFFile(files?files[0]:null,filePaths?filePaths[0]:null)) {
      this.app.uploadFilesWithUI(files, filePaths, dataFiles, intoThisFolder, formData, serverData, doNotShowProfileForm);
    }
  }

  public isFolderDropDone() {
    return this.folderDropDone;
  }

  public setFolderDropDone(isfolderDropDone: boolean) {
    this.folderDropDone = isfolderDropDone;
  }

  public isFolderFilesProfile(): boolean {
    const lastCmd = this.getLastCmdName();
    let isFolderFilesProfile = !((!!lastCmd && lastCmd != "uploadfolders") || (lastCmd == null && this.isFilesDragDrop()));
    if (this.device.bIsOfficeAddin && isFolderFilesProfile) {
      if ((['newdocument', 'uploadfiles'].indexOf(this.app.commandsComponent.getCurrentCommand()) !== -1) || this.app.commandsComponent.dialogKind === 'profile') {
        isFolderFilesProfile = false;
      }
    }
    return isFolderFilesProfile;
  }

  public uploadFolders(serverData, lib) {
    serverData['DOCNAME'] = this.folderUploadFolders.map(x => x.name + '|' + x.path).join(',;');
    this.post('folders/createtree?library=' + (!!lib ? lib : this.getPrimaryLibrary()), serverData).subscribe(response => {
      this.refresh();
      this.app.commandsComponent.dialogKind = 'profile_folderfiles'
      this.setLastCmdName('uploadfolders');
      if (!!this.folderUploadResponse || this.folderUploadResponse?.length > 0) {
        this.folderUploadResponse?.errorList.push(...response?.errorList);
        this.folderUploadResponse?.list.push(...response?.list);
      } else {
        this.folderUploadResponse = response;
      }
      if (this.folderUploadFiles.length > 0) {
        this.uploadFilesWithUI(this.folderUploadFiles.map(x => x.file), null, null, response?.list?.[0]);
      }
      this.showFolderUploadSpinner(false);
    }, error => {
      this.showFolderUploadSpinner(false);
    });
    this.resetFolderUploadFolders();
  }

  public uploadFolderPendingFiles(serverData, fileListToUpload, applyAllChecked) {
    this.filesToUpload.push(...(this.folderUploadFiles.filter(x => fileListToUpload.includes(x.file))));
    if (applyAllChecked) {
      this.nFolderUploadBatchSize = 0;
      if (this.nFolderUploadSuccessFiles > 0) {
        this.nFilesSuccessWithUncheck = this.nFolderUploadSuccessFiles;
        this.nFolderUploadSuccessFiles = 0;
      }
      if (this.nFolderUploadTotalProcessedFiles > 0) {
        this.nFilesProcessedWithUncheck = this.nFolderUploadTotalProcessedFiles;
        this.nFolderUploadTotalProcessedFiles = 0;
      }
    }
    const option: any = {
      'message' : {
        'title' : 'FORMS.LOCAL.UPLOAD.UPLOADING',
        'upload_many' : 'FORMS.LOCAL.UPLOAD.UPLOADING_FILES',
        'success_many' : 'FORMS.LOCAL.UPLOAD.SUCCESS_MANY',
        'success_single' : 'FORMS.LOCAL.UPLOAD.SUCCESS_SINGLE',
        'upload_success_title' : 'FORMS.LOCAL.UPLOAD.UPLOADED',
        'upload_in_progress' : 'FORMS.LOCAL.UPLOAD.UPLOAD_IN_PROGRESS',
        'append_blob' : true
      }
    };
    if (this.nFolderUploadBatchSize === 0) {
      if(applyAllChecked) {
        this.nFolderUploadBatchSize = (this.folderUploadResponse?.list?.length < kInitialBatchSize) ? (this.folderUploadResponse?.list?.length) : kInitialBatchSize;
      }
      else {
        this.nFolderUploadBatchSize = this.folderUploadResponse?.list?.length;
      }
      this.triggerBatchFolderUpload(0, option, serverData, applyAllChecked);
    }
  }

  private triggerBatchFolderUpload(startIndex, option, serverData, applyAllChecked) {
    const title: string = this.localizer.getTranslation(option['message']['title']);
    const libToUploadDocs = this.folderUploadResponse?.list?.[0]['lib'];
    const queryArgs = !!libToUploadDocs ? '?library=' + libToUploadDocs : null;
    if (this.nFolderUploadSuccessFolders === 0) {
      this.nFolderUploadTotalProcessedFolders += this.folderUploadResponse?.list?.length;
      this.nFolderUploadSuccessFolders += (this.folderUploadResponse?.list?.length - this.folderUploadResponse?.errorList?.length);
      const messageData = [this.nFolderUploadSuccessFiles + 1, this.folderUploadFiles?.length].map(String);
      const body: string = this.localizer.getTranslation(option['message']['upload_in_progress'], messageData);
      this.notify.progress(title, body, null, null, false, false, false).then(kNoOp);
    }
    let emptyFolders = 0;
    for (let i = startIndex; i < this.nFolderUploadBatchSize; i++) {
      const currentFolderFiles = this.filesToUpload.filter(x => x?.path?.replace('/' + x.file.name, '') === this.folderUploadResponse.list[i]?.DOCPATH).map(x => x.file);
      if (currentFolderFiles?.length > 0) {
        if (!serverData['_restapi']) {
          serverData['_restapi'] = {};
        }
        serverData['_restapi']['ref'] = this.folderUploadResponse.list[i];
        if (applyAllChecked) {
          serverData['APP_ID'] = currentFolderFiles.map(f => this.getAppIDForFile(f.name)).join(',; ');
          serverData['DOCNAME'] = currentFolderFiles.map(f => f.name).map(f => f.substring(0, f.includes('.') ? f.lastIndexOf('.') : f.length)).join(',; ');
        }
        this.uploadFolderFilesToeDOCS('documents', serverData, currentFolderFiles, null, queryArgs, option, applyAllChecked);
      } else {
        emptyFolders += 1;
      }
    }

    if ((this.nFolderUploadBatchSize < this.folderUploadResponse?.set?.total) && emptyFolders > 0) {
      const startIndex = this.nFolderUploadBatchSize;
      this.nFolderUploadBatchSize += 1;
      this.triggerBatchFolderUpload(startIndex, option, serverData, applyAllChecked);
    }
  }

  public parseDirectoryEntry(directoryEntry, path = '') {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        fileEntries => {
          for (const entry of fileEntries) {
            const entryPath = `${path}/${entry.name}`;
            if (entry.isDirectory) {
              this.folderUploadFolders.push({ name: entry.name, path: entryPath });
              this.parseDirectoryEntry(entry, entryPath);
            }
            else {
              entry.file(aFile => {
                if (!aFile.name.startsWith('.')) {
                  this.folderUploadFiles.push({ file: aFile, path: entryPath });
                }
              });
            }
          }
        },
        err => {
          console.log(err);
        }
      );
    });
  }

  public resetFolderUploadFolders(): void {
    this.folderUploadFolders = [];
    this.app.commandsComponent.folderEntries = [];
  }

  public resetFolderUploadFiles(): void {
    this.folderUploadFiles = [];
    this.app.commandsComponent.fileEntries = [];
    this.folderUploadResponse = null;
    this.filesToUpload = [];
  }

  public getFolderUploadFiles() {
    return this.folderUploadFiles;
  }

  public getFolderUploadFolders() {
    return this.folderUploadFolders;
  }

  public setFolderUploadFiles(folderUploaderFiles) {
    this.folderUploadFiles = folderUploaderFiles;
  }

  public setFolderUploadFolders(folderUploaderFolders) {
    this.folderUploadFolders = folderUploaderFolders;
  }

  public setFolderUploadFoldersAndFiles(folders, files) {
    this.folderUploadFolders = folders;
    this.folderUploadFiles = files;
  }

  public showFolderUploadSpinner(show: boolean): void {
    this.app.uploadingFolders = show;
  }

  public uploadDone(error?: string): void {
    this.app.uploadDone(error);
  }

  public maskHeader(mask: boolean): void {
    this.app.maskHeader(mask);
  }

  public setAppModal(modal: boolean): void {
    this.app.setAppModal(modal);
  }

  public showDropBanner(location: string, baseStr: string='HEADER.UPLOAD_FILES'): void {
    if (location) {
      location = this.localizer.getTranslation(baseStr, [this.localizer.getTranslation(location)]);
    }
    this.app.uploadMessage = location; // will show or hide on null
  }

  public dropBannerShown(): boolean {
    return !!this.app.uploadMessage;
  }

  public acceptFileDrag(): boolean {
    return this.app.acceptFileDrag();
  }

  public canSearchWhere(): boolean {
    return this.app.canSearchWhere();
  }


/*
      0 Available 	          5 Being Indexed       1 Document Being Edited 	    6 Archived
      2 Profile Being Edited 	16 Being Archived     3 Checked Out 	              18 Deleted
      4	Not Available 	      19 Read Only         20 Published
*/

  public getPropValue(item: ListItem, property: string): any {
    const key: string = property;
    let value: any = item[key];
    if (!value && key && key.indexOf('.')!==-1) {
      const keys: string[] = key.split('.');
      value = item;
      for (const aKey of keys) {
        value = value[aKey];
        if (!value) {
          break;
        }
      }
    }
    return value;
  }

  public getTrusteeAccess(trustee: ListItem, trusteeRightId: number): number {
    if (trusteeRightId === 9) {
      trusteeRightId = 10;
    }
    trusteeRightId = Math.pow (2, trusteeRightId -1 );
    if (!!trustee) {
      if (trustee['rights'] & (trusteeRightId<<16)) {
        return  AccessType.AT_DENY;
      } else if (trustee['rights'] & trusteeRightId) {
        return AccessType.AT_ALLOW;
      }
    }
    return AccessType.AT_NONE;
  }

  public setTrusteeAccess(desc: any, trustee: ListItem, rightsUIIndex: number, trusteeCurrentStatus: number): SecurityControl {
    let security: SecurityControl = null;
    if (!!trustee && !!desc) {
      const userId = trustee['USER_ID'] || '';
      const typistId = (desc['TYPIST_ID'] || '').toUpperCase();
      const authorId = (desc['AUTHOR_ID'] || '').toUpperCase();
      if (userId.toUpperCase() !== typistId && userId.toUpperCase() !== authorId) {
        const rightsIndex = rightsUIIndex < 9 ? rightsUIIndex - 1 : 9;
        let trusteeNextStatus;
        switch (trusteeCurrentStatus) {
          case AccessType.AT_NONE:
            trusteeNextStatus = (this.isUserInGroup(userId) || rightsIndex === RightsIndex.ALLOW_VIEW_PUBLISHED ? AccessType.AT_ALLOW : AccessType.AT_DENY);
            break;
          case AccessType.AT_ALLOW:
            trusteeNextStatus = (rightsIndex === RightsIndex.VIEW_PROFILE ? AccessType.AT_DENY : AccessType.AT_NONE);
            break;
          case AccessType.AT_DENY:
            trusteeNextStatus = AccessType.AT_ALLOW;
            break;
        }
        const accessRights = Math.pow(2, rightsIndex);
        security = new SecurityControl(accessRights);
        switch (trusteeNextStatus) {
          case AccessType.AT_NONE: {
            const rightsToUnSet = security.rightsMask(rightsIndex, 0, 0);
            const rightsToSet = security.rightsMask(rightsIndex, 0, 1);
            trustee['rights'] &= ~rightsToUnSet; // clear allow bits
            trustee['rights'] &= ~(rightsToUnSet << 16); // clear deny bits
            trustee['rights'] |= rightsToSet; // set allow bits
          }
            break;
          case AccessType.AT_ALLOW: {
            const rightsToUnSet = security.rightsMask(rightsIndex, 1, 0);
            const rightsToSet = security.rightsMask(rightsIndex, 1, 1);
            trustee['rights'] &= ~(rightsToSet << 16); // clear deny bits
            trustee['rights'] |= rightsToSet; // set allow bits
            trustee['rights'] &= ~rightsToUnSet; // clear allow bits
          }
            break;
          case AccessType.AT_DENY: {
            // Logic to Deny is similar to uncheck Allow (AT_NONE) but here we check Deny bits.
            // As we cannot deny the view published, we should exclude this right.
            const viewPublishedMask = ~AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED;
            const rightsToUnSet = security.rightsMask(rightsIndex, 0, 0) & viewPublishedMask;
            const rightsToSet = security.rightsMask(rightsIndex, 0, 1) & viewPublishedMask;
            trustee['rights'] &= ~rightsToUnSet; // clear allow bits
            trustee['rights'] |= (rightsToUnSet << 16); // set deny bits
            trustee['rights'] |= (rightsToSet << 16); // set deny bit
          }
            break;
        }
      }
    }
    return security;
  }

  public displayStatusIcon(item: ListItem, desc: BaseDesc): boolean {
    let status: number | string = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    if (!!item.id && item.id.indexOf('FilePart') > 0) {
      status = item['PD_ACTIVE_STATUS'];
    }
    if (!status && !!desc && desc.type==='folders' && desc.id==='checkedout') {
      status = '3';
    }
    switch (status) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '16':
      case '18':
      case '19':
      case '20':
      case 'C':
      case 'R':
      case 'Y':
        return true;
    }
    return false;
  }

  public displayMiniStatus(item: ListItem, desc: BaseDesc, property?: string): boolean {
    if (!item) {
      return false;
    }
    let status: string = item['STATUS'];
    let readOnly: string = item['READONLY'];
    if (!!item.id && item.id.indexOf('FilePart') > 0) {
      status = item['PD_ACTIVE_STATUS'];
    } else if (!!item.id && item.id.startsWith('FP-File') && item.APP_ID==='3') {
      status = 'C';
    }
    if (!status && !!desc && desc.type==='folders' && desc.id==='checkedout') {
      status = '3';
    }
    if (readOnly === 'N') {
      readOnly = item['RECORD'];
    }
    return ((status && status !== '0' && status !== 'O') || readOnly === 'Y') && (item.id !== '' || item.lib !== '') && (property === 'APP_ID' || !property);
  }

  public displayTopMiniStatus(item: ListItem, desc: BaseDesc, property?: string): boolean {
    return !!item.id && !!item.lib && (item['ITEM_TYPE'] === 'E' && item['MSG_ITEM'] === '1' && item['ATTACH_NUM'] === '-1');
  }

  public formatStatusIcon(item: ListItem, desc: BaseDesc): string {
    let status: any = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    const readOnly: string = item['READONLY'];
    if (status === '0') {
      status = (readOnly === 'Y' || status === '19') ? '19' : readOnly;
    }
    const record: string = item['RECORD'];
    let icon = 'assets/images/';
    let theStatus: string = record === 'Y' ? 'Y' : status;
    if (!!item && !!item.id) {
      if (item.id.indexOf('FilePart') > 0) {
        const filePartStatus: string = item['PD_ACTIVE_STATUS'];
        if (filePartStatus === 'R') {
          theStatus = 'R';
        } else if (filePartStatus === 'C') {
          theStatus = 'C';
        } else {
          theStatus = 'O';
        }
      } else if (item.id.startsWith('FP-File') && item.APP_ID==='3') {
        theStatus = 'C';
      }
    }
    if (!theStatus && !!desc && desc.type==='folders' && desc.id==='checkedout') {
      theStatus = '3';
    }
    switch (theStatus) {
      case '1':
      case '3':
        icon = icon + 'status_checked_out16.svg';
        break;
      case '4':
      case '5':
      case '6':
      case '16':
        icon = icon + 'col_hdr_deny24.svg';
        break;
      case '18':
        icon = icon + 'notification_error.svg';
        break;
      case '19':
        icon = icon + 'status_reserved16.svg';
        break;
      case 'Y':
        icon = icon + 'mime_overlay_record_16.svg';
        break;
      case 'R':
        icon = icon + 'file_part_reopened_16x16.svg';
        break;
      case 'C':
        icon = icon + 'file_part_closed_16x16.svg';
        break;
      default:
        icon = icon + 'mime_document.svg';
        break;
    }
    return icon;
  }

  public formatTopStatusIcon(item: ListItem, desc: BaseDesc): string {
    return (item['ITEM_TYPE'] === 'E' && item['MSG_ITEM'] === '1' && item['ATTACH_NUM'] === '-1') ? 'assets/images/action_attach.svg' : '';
  }

  public formatStatusText(item: ListItem): string {
    let status: number | string = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    let stat: string = status;
    switch (status) {
      case '0':
        stat = 'DOC_STATUS.AVAILABLE';
        if (item['RECORD'] === 'Y') {
          stat = 'DOC_STATUS.RECORD';
        }
        break;
      case '1':
        stat = 'DOC_STATUS.DOCEDIT';
        break;
      case '2':
        stat = 'DOC_STATUS.PROFILEEDIT';
        break;
      case '3':
        stat = 'DOC_STATUS.CHECKEDOUT';
        break;
      case '4':
        stat = 'DOC_STATUS.NOTAVILABLE';
        break;
      case '5':
        stat = 'DOC_STATUS.INDEXING';
        break;
      case '6':
        stat = 'DOC_STATUS.ARCHIVED';
        break;
      case '16':
        stat = 'DOC_STATUS.ARCHIVING';
        break;
      case '18':
        stat = 'DOC_STATUS.DELETED';
        break;
      case '19':
        stat = 'DOC_STATUS.READONLY';
        if (item['RECORD'] === 'Y') {
          stat = 'DOC_STATUS.RECORD';
        }
        break;
      case '20':
        stat = 'DOC_STATUS.PUBLISHED';
        break;
    }
    return this.localizer.getTranslation(stat);
  }

  private addLibQueryToUrl(urlOrDesc: string | BaseDesc, params?: string, queryargs?: string): string {
    let library: string;
    let libs = '';
    if (typeof urlOrDesc === 'string') {
      urlOrDesc = this.getDescFromURL(urlOrDesc);
    }
    if (urlOrDesc) {
      const idIsNumber = !isNaN(parseInt(urlOrDesc.id));
      const isSearch: boolean = (urlOrDesc.type === 'searches' && idIsNumber) || (['recentedits', 'checkedout', 'evaluation'].indexOf(urlOrDesc.id) >= 0) || (urlOrDesc['NODE_TYPE'] === '%DV_SEARCH' || urlOrDesc.id.indexOf('DV-%DV_SEARCH') !== -1) || urlOrDesc.type === 'activities';
      if (isSearch || (!!queryargs && queryargs.indexOf('&filter=')>=0 && idIsNumber && (urlOrDesc.type === 'folders' || urlOrDesc.type === 'workspaces'))) {
        if (params === 'profile' || params === 'references' || queryargs === 'delrefs') {
          if (urlOrDesc.lib && urlOrDesc.lib.length) {
            library = urlOrDesc.lib.split(',')[0];
          }
        } else {
          if (this.restAPIVersion() >= 0x00160700) {
            if (!queryargs || queryargs.indexOf('libs=') === -1) {
              library = urlOrDesc.lib ||  this.getPrimaryLibrary();
              if (isSearch) {
                libs = '&libs=' + this.getSelectedLibrariesString();
              } else {
                const libArrary: any[] = this.getLibraries();
                if (!!libArrary && !!libArrary.length) {
                  let libStrs = '';
                  let sep = '';
                  for (const lib of libArrary) {
                    if (lib.DISABLED === 'N') {
                      if (!libStrs) {
                        libStrs = '&libs=';
                      }
                      libStrs += sep + lib.LIBRARY_NAME;
                      sep = ',';
                    }
                  }
                  libs = libStrs || '';
                }
              }
            }
          } else {
            library = this.getSelectedLibrariesString(urlOrDesc.lib);
          }
        }
      } else if (urlOrDesc.lib && urlOrDesc.lib.length) {
        library = urlOrDesc.lib;
      }
    }
    if (!!queryargs && queryargs.indexOf('?library=') !== -1) {
      library = queryargs.split('&')[0].substr(9);
    }
    return '?library=' + (library || (this.getPrimaryLibrary() || 'primary')) + libs;
  }

  private formatDefaultURL(url: string, params?: string, queryargs?: string): string {
    if (url[0]!=='/') {
      url = '/'+url;
    }
    if (params) {
      if (params[0]!=='/') {
        url += '/';
      }
      url += params;
    }
    if (queryargs && queryargs.startsWith('?library=')) {
      url += queryargs;
      queryargs = null;
    }
    if (url.indexOf('?library=')===-1) {
      url += this.addLibQueryToUrl(url, params, queryargs);
    }
    if (queryargs) {
      url += '&'+queryargs;
    }
    return url;
  }

  private loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onerror = (error) => {
        document.head.removeChild(script);
        reject(error);
      };
      script.onload = () => {
        resolve();
      };
      script.src = url;
      document.head.appendChild(script);
    });
  }

  private loadBrava(): void {
    this.loadScript('./assets/bravaviewer/html-clients.js').then(() => {
      this.loadScript('./assets/bravaviewer/viewer.js').then(kNoOp, kNoOp);
    }, kNoOp);
  }

  private warmCacheDefProfileForm(formName: string, library: string): void {
    this.getFormTemplate(formName, library).subscribe((data: any) => {
      this.cacheForm(formName, library, JSON.stringify(data));
      this.sessionCache.save();
    }, error => {
      this.handleError(error);
    });
  }

  private checkAddTypeInAppIDs(altType: string, baseType: string, appIds: any[]): void {
    let foundAlt = false;
    let base: any;
    // add in alt as a copy of base if we do not have it
    for (const appID of appIds) {
      if (appID.ext.toUpperCase() === baseType) {
        base = appID;
      } else if (appID.ext.toUpperCase() === altType) {
        foundAlt = true;
        break;
      }
    }
    if (!foundAlt && !!base) {
      const alt: any = this.deepCopy(base);
      alt.ext = altType;
      appIds.push(alt);
    }
  }

  private warmTeamsCache(): void {
    if (this.device.bIsTeamsAddIn) {
      const askUser = (teamsEmbedData: any): void => {
        if (!!teamsEmbedData && !!teamsEmbedData['channels'] && !!teamsEmbedData['channels'][this.device.teamsContext.channelId]) {
          this.sessionCache.teamsCache = teamsEmbedData;
          this.sessionCache.save();
        } else {
          const title = this.localizer.getTranslation('FORMS.LOCAL.LINK.LINK');
          const formData: any = {};
          this.notify.warning(title,'__local_teams_appid',title,formData,true,true,true).then(confirmed => {
            if (confirmed && confirmed.confirm && confirmed.data) {
              let link = confirmed.data['$edx_url_appid'];
              if (!!link) {
                link = decodeURI(link);
                const parts = link.split('entity/');
                if (parts.length===2) {
                  const nextSlash = parts[1].indexOf('/');
                  const teamsAppID = parts[1].substring(0, nextSlash);
                  if (!teamsEmbedData) {
                    teamsEmbedData = {};
                  }
                  if (!teamsEmbedData['channels']) {
                    teamsEmbedData['channels'] = {};
                  }
                  teamsEmbedData['channels'][this.device.teamsContext.channelId] = teamsAppID;
                  this.sessionCache.teamsCache = teamsEmbedData;
                  this.sessionCache.save();
                  this.put('settings/system/embedded/teams', teamsEmbedData, null, null, {observe:'response'}).subscribe(res => {
                    if (res.status===200) {
                      this.notify.success(title, this.localizer.getTranslation('FORMS.LOCAL.LINK.APPID_SUCCESS'));
                    } else {
                      this.handleError(res);
                    }
                  }, err => {
                    this.handleError(err);
                  });
                }
              }
            }
          });
        }
      };
      this.get('settings/system/embedded/teams').subscribe(data => {
        askUser(data);
      }, err => {
        if (err.status !== 401) {
          askUser(null);
        }
      });
    }
  }

  public warmCache(): void {
    if (!this.bWarmingCache) {
      this.bWarmingCache = true;
      if (!this.offline()) {
        const _getFormKind = (kind: string, enFormName: string): void => {
          const key: string = 'APP_ID='+kind;
          if (!this.sessionCache.defaultForms[key]) {
            this.getFormsList(key).then(list => {
              const nForms = !!list ? list.length : 0;
              if (nForms > 0) {
                let form = list[0];
                if (nForms > 1) {
                  const lang = this.device.getUserLanguage(false);
                  for (let iForm=0; iForm<nForms; iForm++) {
                    form = list[iForm];
                    if ((lang==='en' && form.id===enFormName) || (lang!=='en' && form.id!==enFormName)) {
                      break;
                    }
                  }
                }
                this.sessionCache.defaultForms[key] = form;
                this.sessionCache.save();
              }
            }, error => {
              // ignore this error, we can live without this list
            });
          }
        };
        const _getAppIds = (aLib: string): void => {
          this.repopulateAppIDsInSessionCache(aLib);
        };
        const _getLookups = (aLib: string): void => {
          this.get('/lookups?library='+aLib).subscribe((listData: ListData) => {
            if (!!listData && !!listData.list) {
              this.setLookupsList(listData.list, aLib);
            }
          }, error => {
            // ignore this error, we can live without this list if we are offline
          });
        };
        const _getFormList = (aLib: string): void => {
          this.getFormsList('TYPE=PROFILE', aLib).then((list) => {
            this.setFormsList(list, aLib);
            const defaultProfileForm: any = this.getDefaultProfileForm(aLib);
            if (!!defaultProfileForm && !!defaultProfileForm.id) {
              this.warmCacheDefProfileForm(defaultProfileForm.id, aLib);
            }
          }, error => {
            // ignore this error, we can live without this list if we are offline
          });
        };
        const enabledLibraries: any[] = this.loginReply.LIBRARIES.filter(l => l.DISABLED!=='Y');
        enabledLibraries.forEach((enabledLibrary) => {
          const library: string = enabledLibrary.LIBRARY_NAME.toUpperCase();
          if (!this.sessionCache.appIds[library] || Object.keys(this.sessionCache.appIds[library]).length===0) {
            _getAppIds(library);
          }
          if (!this.sessionCache.lookups[library] || Object.keys(this.sessionCache.lookups[library]).length===0) {
            _getLookups(library);
          }
          if (!this.sessionCache.forms[library] || Object.keys(this.sessionCache.forms[library]).length===0) {
            _getFormList(library);
          }
        });
        if (!this.sessionCache.searchForms) {
          this.getFormsList('TYPE=SEARCH').then(list => {
            this.setSearchFormsList(list);
          });
        }
        _getFormKind('WORKSPACE', 'WORKSPACE_PROF');
        if (this.loginReply.RM_ENABLED) {
          _getFormKind('FILE_PART', 'PD_FILE_PART_PROF');
          _getFormKind('BOX', 'PD_BOX_PROF');
        }
        this.warmTeamsCache();
      }
    }
  }

  public customRestRequest(method: string, url: string, data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, this.getServerURLOrigin() + (url.startsWith('/') ? '' : '/') +  url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          try {
            const responseObj = !!xhr.responseText && xhr.responseText[0]==='{' ? JSON.parse(xhr.response) : xhr.response;
            resolve(responseObj);
          } catch (e) {
            reject(e.toString());
          }
        }
      };
      const hdrs = this.getDMHeaders();
      const keys = Object.keys(hdrs);
      for (const key of keys) {
        xhr.setRequestHeader(key, hdrs[key]);
      }
      xhr.onerror = (err) => {
        reject(err);
      };
      const isFormData = data instanceof FormData;
      if (!isFormData && !!data) {
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        data = JSON.stringify({data});
      }
      xhr.send(data);
    });
  }
  public async customFileContentAsBase64(lib: string, id: string, version: string, progressCB?: any): Promise<any> {
    const type = 'documents';
    const desc: BaseDesc = { type, id, lib };
    let base64FileContent: any;

    await this.downloadAsBase64(desc, 'versions/'+ version, null, progressCB).toPromise().then(b64Data => {
      base64FileContent = b64Data;
     }, err => {
      this.handleError(err);
     });

    return base64FileContent;
  }

  private checkCustomVendors(): void {
    const getCode = (vendor: string) => {
      const script = document.createElement('script');
      const url: string = this.getServerURLOrigin() + '/custom/' + vendor + '/code.js';
      script.onerror = (error) => {
        document.head.removeChild(script);
        if (!!this.customCommands[vendor]) {
          delete this.customCommands[vendor];
          this.customCommands[vendor] = null;
        }
      };
      script.src = url;
      document.head.appendChild(script);
    };
    ICC.globals = {
      userid: String(this.getUserID()),
      fullname: String(this.getUserFullName()),
      groupid: String(this.loginReply.HEADERS['X-DM-GROUPID']),
      primarylibrary: String(this.getPrimaryLibrary()),
      libraries: this.deepCopy(this.getLibraries()),
      refresh: this.refresh.bind(this),
      runform: this.app.runCustomForm.bind(this.app),
      restrequest: this.customRestRequest.bind(this),
      fileContentAsBase64: this.customFileContentAsBase64.bind(this),
      notify: this.notify
    };
    if (!!this.loginReply.CUSTOM_VENDORS) {
      for (const vendor of this.loginReply.CUSTOM_VENDORS) {
        this.get('/custom/' + vendor + '/commands.json').subscribe(data => {
          if (!!data && !!data.commands && data.commands.length) {
            this.customCommands[vendor] = data.commands;
            getCode(vendor);
          }
        }, err => {
          getCode(vendor);  // maybe code but no commands
        });
        this.get('/custom/' + vendor + '/forms.json').subscribe(data => {
          if (!!data && !!data.forms && data.forms.length) {
            this.formService.addCustomForms(vendor, data.forms);
          }
        }, kNoOp);
        this.get('/custom/' + vendor + '/schemas.json').subscribe(data => {
          if (!!data) {
            this.schemaService.addVendorSchemas(vendor, data);
          }
        }, kNoOp);
      }
    }
  }

  public getCustomCommands(vendor?: string): any {
    if (!!vendor) {
      return this.customCommands[vendor];
    }
    return this.customCommands;
  }

  public getCustomCommandFunction(cmd: string, functionName: string): any {
    const vendorkey: string = cmd.substr(8);
    const firstUS: number = vendorkey.indexOf('_');
    const vendor = vendorkey.substring(0, firstUS);
    const key = vendorkey.substr(firstUS+1);
    const obj = !!ICC[vendor] && !!ICC[vendor][key] ? ICC[vendor][key] : null;
    if (!!obj && typeof obj[functionName] === 'function') {
      const commandFunc = obj[functionName].bind(obj);
      return commandFunc;
    }
    return null;
  }

  public cacheIsLoaded(library?: string): boolean {
    library = library || this.getPrimaryLibrary();
    library = library.toUpperCase();
    if (this.sessionCache.forms && this.sessionCache.forms[library] && this.sessionCache.forms[library].length) {
      if (this.sessionCache.lookups && this.sessionCache.lookups[library]) {
        if (this.sessionCache.appIds && this.sessionCache.appIds[library] && this.sessionCache.appIds[library].length) {
          return true;
        }
      }
    }
    return false;
  }

  private doSite(url: string, fileName: string): void {
    const dAnchor: HTMLAnchorElement = document.createElement('a');
    dAnchor.rel = 'noopener';
    dAnchor.classList.add('download-iframe');
    dAnchor.style.display = 'none';
    dAnchor.href = url;
    if (!!fileName) {
      dAnchor.setAttribute('download', fileName);
    }
    document.body.appendChild(dAnchor);
    setTimeout(() => {
      dAnchor.click();
      if (!!fileName) {
        setTimeout(() => {
          window.URL.revokeObjectURL(dAnchor.href);
        }, 250);
      }
    }, 100);
  }

  public downloadSiteFile(url: string, fileName: string): void {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = (e) => {
      const blobUrl = window.URL.createObjectURL((e.target as any).response);
      this.doSite(blobUrl, fileName);
    };
    xhr.send();
  }

  public browseSite(url: string): void {
    this.doSite(url, null);
  }

  public getRootSiteUrl(): string {
    const path: string = location.pathname;
    const pathParts: string[] = path.split('/');
    pathParts.splice(pathParts.length-1, 1);
    return location.origin + pathParts.join('/');
  }

  private getSiteConfig(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url: string = this.getRootSiteUrl() + '/assets/config.json';
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 0) {
            try {
              const responseObj = JSON.parse(xhr.response);
              resolve(responseObj);
            } catch (e) {
              reject(e);
            }
          } else {
            reject('no config');
          }
        }
      };
      xhr.send();
    });
  }

  public getExternalData(url: string, headers: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let responseObj: any;
          try {
            responseObj = xhr.response ? JSON.parse(xhr.response) : null;
          } catch (e) { }
          if (!responseObj) {
            responseObj = { rapi_code: 0 };
          }
          responseObj.status = xhr.status;
          if (xhr.status === 200) {
            resolve(responseObj);
          } else {
            reject(responseObj);
          }
        }
      };
      if (!!headers) {
        const keys = Object.keys(headers);
        for (const key of keys) {
          xhr.setRequestHeader(key, headers[key]);
        }
      }
      xhr.onerror = reject;
      xhr.send();
    });
  }

  public postExternalData(url: string, headers: any, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      };
      if (!!headers) {
        const keys = Object.keys(headers);
        for (const key of keys) {
          xhr.setRequestHeader(key, headers[key]);
        }
      }
      xhr.onerror = reject;
      xhr.send(data);
    });
  }

  public makeExchangeSoapHeader(request: string): string {
    return `<?xml version="1.0" encoding="utf-8"?>
      <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xmlns:xsd="http://www.w3.org/2001/XMLSchema"
        xmlns:m="http://schemas.microsoft.com/exchange/services/2006/messages"
        xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
        xmlns:t="http://schemas.microsoft.com/exchange/services/2006/types">
        <soap:Header>
          <RequestServerVersion Version="Exchange2013" xmlns="http://schemas.microsoft.com/exchange/services/2006/types" soap:mustUnderstand="0" />
        </soap:Header>
        <soap:Body>${request}</soap:Body>
      </soap:Envelope>`;
  }

  public makeExchangeSoapInternetMessageHeader(headerName: string, itemId: string): string {
    return this.makeExchangeSoapHeader(
      `<m:GetItem>
        <m:ItemShape>
          <t:BaseShape>IdOnly</t:BaseShape>
          <t:AdditionalProperties>
            <t:ExtendedFieldURI DistinguishedPropertySetId="InternetHeaders" PropertyName="${headerName}" PropertyType="String" />' +
          </t:AdditionalProperties>
        </m:ItemShape>
        <m:ItemIds>
          <t:ItemId Id="${itemId}" />
        </m:ItemIds>
      </m:GetItem>`
    );
  }

  public getOutlookItemProfileData(outlookMailbox: any, headers: any, addOutlookItemMetadata: boolean, intoThisFolder?: BaseDesc, selectedAppID?: string): Promise<any> {
    const outlookItem = outlookMailbox.item;
    if (!outlookItem.itemId) {
      return Promise.resolve({});
    }
    let profileFormData: any;
    const messageRestURL: string = outlookMailbox.restUrl ? (outlookMailbox.restUrl + '/v2.0/me/messages/' + outlookMailbox.convertToRestId(outlookItem.itemId, Office.MailboxEnums.RestVersion.v2_0)) : null;
    return new Promise<any>((resolve, reject) => {
      const addProfileDefaults = () => {
        const appID = !selectedAppID || selectedAppID === 'DEFAULT' ? 'MS Outlook' : selectedAppID;
        this.getFormInfoForAppID(appID, intoThisFolder || this.getPrimaryLibrary()).then((ffi: FileFormInfo) => {
          const formName = !!ffi.defForm ? ffi.defForm : this.getDefaultProfileForm().id;
          const formIndex: number = ffi.forms.indexOf(formName);
          if (formIndex >= 0) {
            for (var i = 0; i < ffi?.profile_defaults?.length; i++) {
              Object.assign(ffi.profile_defaults[i], profileFormData);
            }
            profileFormData = ffi.profile_defaults[formIndex];
            profileFormData['FORMNAME'] = formName;
            if (addOutlookItemMetadata) {
              profileFormData['edx_trustees'] = ffi.trustees[formIndex];
              profileFormData['edx_fileFormInfo'] = this.deepCopy(ffi);
            }
          }
          resolve(profileFormData);
        });
      };
      const addMailAddress = (addrs: any[]): string  => {
        let sep = '';
        let addrsStr = '';
        if (addrs && addrs.length) {
          for (const addr of addrs) {
            addrsStr += sep + addr.emailAddress;
            sep = ',';
          }
        }
        return addrsStr;
      };
      const afterGetThreadIndex = (threadIndex: string, sentTime: string, receivedTime: string) => {
        let threadID = '';
        let hex: string;
        if (threadIndex) {
          const binaryStr: string = atob(threadIndex);
          const len: number = binaryStr.length;
          for (let i=0; i<len; i++) {
            hex = binaryStr.charCodeAt(i).toString(16);
            if (hex.length === 1) {
              hex = '0' + hex;
            }
            if (threadID.length < parentMailIdLength) {
              threadID += hex;
            }
          }
        }
        const searchForms: any[] = this.getSearchForms();
        const mailID: string = outlookItem.internetMessageId.startsWith('<') ? outlookItem.internetMessageId : ('<' + outlookItem.internetMessageId + '>');
        const fromStr: string = outlookItem.from.emailAddress;
        const toStr: string = addMailAddress(outlookItem.to);
        const ccStr: string = addMailAddress(outlookItem.cc);
        const bccStr: string = addMailAddress(outlookItem.bcc);
        if (!sentTime) {
          try {
            sentTime = !!outlookItem.dateTimeSent ? outlookItem.dateTimeSent.toString() : !!outlookItem.dateTimeModified ? outlookItem.dateTimeModified.toString() : '';
          } catch (e) {
            sentTime = '';
          }
        }
        if (!!sentTime) {
          sentTime = this.transforms.formatDateForDM(sentTime);
        }
        if (!!receivedTime) {
          receivedTime = this.transforms.formatDateForDM(receivedTime);
        } else {
          receivedTime = sentTime;
        }
        profileFormData = addOutlookItemMetadata ? { MAIL_ID:mailID, APP_ID:'MS OUTLOOK', ITEM_TYPE:'E', [EmailHeaders.From]:fromStr, [EmailHeaders.To]:toStr, [EmailHeaders.Cc]:ccStr, [EmailHeaders.Bcc]:bccStr, [EmailHeaders.DateSent]:sentTime, [EmailHeaders.DateReceived]:receivedTime, [EmailHeaders.EmailDate]:sentTime, MSG_ITEM: '1' } : { APP_ID:'MS OUTLOOK', ITEM_TYPE:'E' };

        if (outlookItem.attachments.length) {
          for (const row of outlookItem.attachments) {
            if (row.isInline === false) {
              profileFormData['ATTACH_NUM'] = '-1';
                break;
            }
          }
        }
        if (!!threadID) {
          const searchCriteria: any = {};
          const criteria: any = {};
          if (searchForms && searchForms.length) {
            searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
          }
          profileFormData['PARENTMAIL_ID'] = threadID;
          searchCriteria['DESCRIPTION'] = threadID;
          criteria['PARENTMAIL_ID'] = threadID;
          searchCriteria['criteria'] = criteria;
          this.post({id:'evaluation',lib:'',type:'searches'},searchCriteria,'' ,'start=0&max=1&ascending=SYSTEM_ID').subscribe((listData: ListData) => {
            if (!!listData && !!listData.list && listData.list.length) {
              profileFormData = Object.assign(profileFormData, listData.list[0]);
            }
            addProfileDefaults();
          }, error => {
            addProfileDefaults();
          });
        } else {
          addProfileDefaults();
        }
      };
      const afterHeaders = () => {
        let threadIndex: string = null;
        if (messageRestURL) {
          this.getExternalData(messageRestURL+'?$select=InternetMessageHeaders,sentDateTime,receivedDateTime', headers).then(messageData => {
            const threadIndexItem: any = messageData.InternetMessageHeaders ? messageData.InternetMessageHeaders.find(h => h.Name==='Thread-Index') : null;
            threadIndex = threadIndexItem ? threadIndexItem.Value : null;
            afterGetThreadIndex(threadIndex, messageData.SentDateTime, messageData.ReceivedDateTime);
          }, err => {
            afterGetThreadIndex(threadIndex, null, null);
          });
        } else {
          const soap: string = this.makeExchangeSoapInternetMessageHeader('Thread-Index', outlookMailbox.item.itemId);
          Office.context.mailbox.makeEwsRequestAsync(soap, ewsResult => {
            if (ewsResult.status === 'succeeded') {
              try {
                const xmlDoc = (new DOMParser()).parseFromString(ewsResult.value, 'text/xml');
                const itemNode = xmlDoc.getElementsByTagName('t:ItemId')[0];
                const itemIDNode = itemNode.attributes.getNamedItem('Id');
                threadIndex = itemIDNode.nodeValue;
              } catch (e) {}
              afterGetThreadIndex(threadIndex, null, null);
            } else {
              afterGetThreadIndex(threadIndex, null, null);
            }
          });
        }
      };
      if (!!headers) {
        afterHeaders();
      } else {
        const options = {isRest:!!messageRestURL,asyncContext:{app:'edx'}};
        outlookMailbox.getCallbackTokenAsync(options, response => {
          headers = {Authorization: 'Bearer ' + response.value};
          afterHeaders();
        });
      }
    });
  }

  public getCurrentOutlookExistingListItem(outlookItem: any): Promise<ListItem> {
    return new Promise<ListItem>((resolve, reject) => {
      const outlookMsgID: string = outlookItem.internetMessageId.startsWith('<') ? outlookItem.internetMessageId : ('<' + outlookItem.internetMessageId + '>');
      const searchCriteria: any = {};
      const searchForms: any[] = this.getSearchForms();
      if (searchForms && searchForms.length) {
        searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
      }
      searchCriteria['DESCRIPTION'] = outlookMsgID;
      searchCriteria['criteria'] = { MAIL_ID: outlookMsgID, ATTACH_NUM: '-1,0'};
      this.device.officeDMItem = null;
      this.post({id:'evaluation',lib:this.getSearchLibQuery(),type:'searches'},searchCriteria,'' ,'start=0&max=1&ascending=SYSTEM_ID').subscribe((listData: ListData) => {
        if (!!listData && !!listData.list && listData.list.length) {
          resolve(listData.list[0]);
        } else {
          reject('not found');
        }
      }, reject);
    });
  }

  private getJSONP(queryargs: string): Promise<any> {
    return this.doJSONP('get', queryargs);
  }

  private putpostJSONP(method, queryargs: string, data: any): Promise<any> {
    const dataStr: string = JSON.stringify(data);
    const b64Data: string = this.transforms.b64EncodeUnicode(dataStr);
    return this.doJSONP(method, queryargs + '&data='+encodeURIComponent(b64Data));
  }

  private postJSONP(queryargs: string, data: any): Promise<any> {
    return this.putpostJSONP('post', queryargs, data);
  }

  private putJSONP(queryargs: string, data: any): Promise<any> {
    return this.putpostJSONP('put', queryargs, data);
  }

  private doJSONP(method: string, queryargs: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const doIt = () => {
        const urlPath: string = '?method='+method+'&cv='+this.device.version+(queryargs.startsWith('&') ? '' : '&')+queryargs;
        const url: string = kLocalHostHTTPS + ':' + this.pftaPort + urlPath;
        const sendReq = (first: boolean) => {
          const script = document.createElement('script');
          const callbackName = 'jsonp_callback_' + Math.round(this.rand(0, 100000));
          window[callbackName] = (data) => {
            delete window[callbackName];
            document.body.removeChild(script);
            if (!data.error) {
              resolve(data);
            } else {
              if (!!data.error && data.error.code === PFTAErrorCode.ecUnreg && first) {
                this.checkForPFTA().then(success => {
                  if (success) {
                    setTimeout(() => {
                      sendReq(false);
                    }, 1000);
                  } else {
                    reject(data);
                  }
                }, err => {
                  reject(data);
                });
              } else {
                reject(data);
              }
            }
          };
          script.src = url + '&callback=' + callbackName + (!!this.keyPFTA ? ('&key='+this.keyPFTA) : '');
          script.onerror = (error) => {
            delete window[callbackName];
            document.body.removeChild(script);
            reject(error);
          };
          document.body.appendChild(script);
        };
        sendReq(true);
      };
      if (!!this.siteConfigurations.portServer && !this.bGotPFTAPort && !!this.loginReply.NETWORK_ID) {
        const script = document.createElement('script');
        const callbackName = 'jsonp_callback_' + Math.round(this.rand(0, 100000));
        const done = (res: any, error: any): void => {
          delete window[callbackName];
          document.body.removeChild(script);
          this.bGotPFTAPort = true;
          if (!!res && !!res.data && !!res.data.data && !!res.data.data.PFTA_PORT && !!parseInt(res.data.data.PFTA_PORT)) {
            this.pftaPort = res.data.data.PFTA_PORT;
          } else {
            if (!!error) {
              console.log(error.toString());
            }
          }
          doIt();
        };
        window[callbackName] = (data) => {
          done(data, null);
        };
        const url = this.siteConfigurations.portServer + '/pftaport?alias=' + encodeURIComponent(this.loginReply.NETWORK_ID);
        script.src = url + '&callback=' + callbackName + (!!this.keyPFTA ? ('&key='+this.keyPFTA) : '');
        script.onerror = (error) => {
          done(null, error);
        };
        document.body.appendChild(script);
      } else {
        doIt();
      }
    });
  }

  public authenticateExternal(desc: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (desc) {
        const library: string = desc.lib;
        const app: any = this.loginReply.APPLICATIONS.find(a => a.lib===library);
        if (app.authentication === 'basic') {
          const name: string = app ? app.name : '';
          const formData: any = { server: name };
          const title: string = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.AUTHENTICATE');
          const btn: string = this.localizer.getTranslation('SETTINGS.LOGIN');
          this.notify.info(title,'__local_authenticate',btn,formData,true,true,true).then(confirmed => {
            if (confirmed && confirmed.confirm) {
              this.post('/authenticate?library='+library, confirmed.data).subscribe((loginData: any) => {
                if (!!loginData) {
                  this.appendExternalHeaders(loginData.HEADERS, library);
                  resolve(true);
                } else {
                  const errorTitle: string = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.ERROR');
                  const errorMsg: string = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.EXTERNAL_APP_FAILED',[library]);
                  this.notify.error(errorTitle, errorMsg);
                  reject(false);
                }
              }, reject);
            } else {
              reject(false);
            }
          });
        } else if (app.authentication === 'oauth2') {
          let url: string = this.makeURL(desc, 'oauth2_uri', _AuthPopupWindow.GetQueryArgs(this.device, this.getRootSiteUrl()));
          let fullUrl: string = this.getServerURLOrigin()+url.replace(/[|]/g, '%7C');
          this.getExternalData(fullUrl,this.sessionHeaders).then(data1 => {
            if (!!data1) {
              const oauth2uri: string = data1['data'];
              const authWindow: _AuthPopupWindow = new _AuthPopupWindow(this.device, this.help, desc.lib);
              authWindow.startAuth(oauth2uri, this.getRootSiteUrl()).then(() => {
                url = this.makeURL(desc, 'oauth2_status');
                fullUrl = this.getServerURLOrigin()+url.replace(/[|]/g, '%7C');
                this.getExternalData(fullUrl,this.sessionHeaders).then(data2 => {
                  const status: string = !!data2 ? data2['data'] : null;
                  authWindow.close();
                  if (!!status && status === 'ok') {
                    resolve(true);
                  } else {
                    reject('bad status');
                  }
                }, error => {
                  if (error.status !== 503) {
                    authWindow.close();
                    const errorTitle: string = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.ERROR');
                    const errorMsg: string = this.localizer.getTranslation('FORMS.LOCAL.AUTHENTICATE.EXTERNAL_APP_FAILED', [library]);
                    this.notify.error(errorTitle, errorMsg);
                  }
                  reject(error);
                });
              }, reject);
            } else {
              reject('no url');
            }
          }, error2 => {
            reject(error2);
          });
        } else {
          reject('bad auth type');
        }
      } else {
        reject('no desc');
      }
    });
  }

  public checkLibrarySettingsByName(libraryName: string): Observable<any> {
    if (!!libraryName && !this.isExternalLib(libraryName) && !!this.loginReply && this.loginReply['LIBRARIES']) {
      const libraryInfo = this.loginReply['LIBRARIES']?.find(lib => lib['LIBRARY_NAME']?.toUpperCase() === libraryName.toUpperCase());
      if (!!libraryInfo) {
        return this.checkLibrarySettings(libraryInfo);
      } else {
        return new Observable(observer => {
          observer.complete();
        });
      }
    } else {
      return new Observable(observer => {
        observer.complete();
      });
    }
  }

  public checkLibrarySettings(libraryInfo: any): Observable<any> {
    return new Observable(observer => {
      if (['lib_defaults', 'EFFECTIVE_RIGHTS', 'ALLOW_LOGIN'].every(p => p in libraryInfo)) {
        observer.next(libraryInfo);
        observer.complete();
      } else {
        const libraryName = libraryInfo?.LIBRARY_NAME;
        if (!!libraryName) {
          const url = `/settings/library?library=${libraryName}`;
          this.get(url).subscribe(data => {
            for (const property in data) {
              libraryInfo[property] = data[property];
            }
            observer.next(libraryInfo);
            observer.complete();
          });
        } else {
          observer.next(libraryInfo);
          observer.complete();
        }
      }
    });
  }

  public isLibraryLoaded(libraryName: string): boolean {
    let isLoaded = true;
    if (!!libraryName && !this.isExternalLib(libraryName)) {
      isLoaded = (libraryName === this.getPrimaryLibrary());
      if (!isLoaded) {
        const libraryInfo = this.loginReply['LIBRARIES']?.find(lib => lib['LIBRARY_NAME']?.toUpperCase() === libraryName.toUpperCase());
        if (!!libraryInfo) {
          isLoaded = (['lib_defaults', 'EFFECTIVE_RIGHTS', 'ALLOW_LOGIN'].every(p => p in libraryInfo));
        }
      }
    }
    return isLoaded;
  }

  public calculateRights(item): number {
    const rights = item?.['%EFFECTIVE_RIGHTS'] || item?.['%SECURITY'];
    return rights;
  }

  public calculateReadonlyStatus(item): void {
    if (this.isItemReadonly(item)) {
      item['STATUS'] = '19';
    }
  }

  public isItemReadonly(item): boolean {
    const isReadonly = (!!item && ((item['READONLY'] === 'Y') || (item['RECORD'] === 'Y' || item['STATUS'] === '19')));
    return isReadonly;
  }

  public exportContents(item: BaseDesc): void {
    this.get(
      `/folders/${item.id}/contents?library=${item.lib}&exportcontents`,
      '', '', { responseType: 'blob' })
      .toPromise()
      .then(
        (blob) => {
          const formData: any = { DOCUMENTS: [item] };
          const title: string = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING');
          this.notify.progress(title, '__local_filetransfer', null, formData, false, false, false).then(kNoOp);
          item['$edx_progress'] = 0;

          item['$edx_progress'] = -1;
          const newFormData: any = { DOCUMENTS: [item] };
          this.notify.updateFormData(newFormData);

          const url = window.URL.createObjectURL(blob);
          this.doSite(url, `${item['DOCNAME']}.zip`);

          setTimeout(() => {
            this.notify.success(this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_DONE'));
          }, 500);
        },
        (error) => {
          this.handleError(error);
          return null;
        }
      );
  }

  // start base api functions no code follows
  private doVerb(verb: APIVerb, urlOrDesc: string | BaseDesc, data: any, params: string, queryargs: string, options: any={}, progressCB: any): Observable<any>  {
    const body = !!options.sendRaw ? data : JSON.stringify({data});
    let url: string = null;
    let desc: BaseDesc = null;

    if (typeof urlOrDesc==='string') {
      url = this.formatDefaultURL(urlOrDesc, params, queryargs);
    } else {
      desc = urlOrDesc;
      url = this.makeURL(urlOrDesc, params, queryargs);
    }
    let fullUrl: string = this.getServerURLOrigin()+url.replace(/[|]/g, '%7C');
    const isExternal: boolean = fullUrl.indexOf('library=ex_')!==-1;

    const done = (observer, res, err) => {
      if (!!res) {
        observer.next(options.observe !== 'response' && !!res.data ? res.data : res);
      }
      if (!!err) {
        observer.error(err);
      }
      observer.complete();
    };
    const getReq = (): Observable<HttpEvent<any>> => {
      let req: Observable<HttpEvent<any>>;
      let headers: HttpHeaders = null;
      if (!options.sendRaw && (verb===APIVerb.kPost || verb===APIVerb.kPut)) {
        headers = this.createHeaders({'Content-Type': 'application/json'});
      } else if (verb!==APIVerb.kGet || !url.startsWith('/libraries')) {
        headers = this.createHeaders();
      }
      if (!!headers) {
        options['headers'] = headers;
      }
      options['withCredentials'] = true;
      delete options.sendRaw;
      switch (verb) {
        case APIVerb.kGet:
          if (this.device.bIsIE && fullUrl.indexOf('&nocache')===-1) {
            fullUrl += '&nocache=' + new Date().getTime().toString();
          }
          req = this.http.get<any>(fullUrl, options);
          break;
        case APIVerb.kPost:
          req = this.http.post<any>(fullUrl, body, options);
          break;
        case APIVerb.kPut:
          req = this.http.put<any>(fullUrl, body, options);
          break;
        case APIVerb.kDel:
          req = this.http.delete<any>(fullUrl, options);
          break;
      }
      return req;
    };
    const reLogin = (observer, err) => {
      done(observer, null, err);
      if (this.device.bIsElectron || this.device.bIsCordova) {
        this.reAuth(err);
      } else {
        setTimeout(() => {
          this.logOff(true);
        }, 100);
      }
    };
    const tryAuth = (err, observer) => {
      if (isExternal && err.status===401) {
        this.authenticateExternal(desc).then(success => {
          if (success) {
            getReq().subscribe(response2 => {
              done(observer, response2, null);
            }, err2 => {
              done(observer, null, err2);
            });
          } else {
            done(observer, null, err);
          }
        }, error => {
          done(observer, null, error);
        });
      } else if (err.status===401) {
        if (url.startsWith('/connect')) {
          this.setSsoAuthErr('Retry');
          reLogin(observer, 'Retry');
        } else {
          this.refreshSession().then(success => {
            if (success) {
              getReq().subscribe(response2 => {
                done(observer, response2, null);
              }, err2 => {
                reLogin(observer, err2);
              });
            } else {
              reLogin(observer, err);
            }
          }, err3 => {
            reLogin(observer, err3);
          });
        }
      } else {
        done(observer, null, err);
      }
    };
    switch (verb) {
    case APIVerb.kGet:
    case APIVerb.kPost:
    case APIVerb.kPut:
    case APIVerb.kDel:
      return Observable.create(observer => {
        let attempts = 0;
        const tryReq = () => {
          getReq().subscribe(response => {
            done(observer, response, null);
          }, err => {
            if (!err.status) {
              if (++attempts < 3) {
                setTimeout(() => {
                  tryReq();
                }, 100);
              } else {
                done(observer, null, err);
              }
            } else {
              tryAuth(err, observer);
            }
          });
        };
        tryReq();
      });
    case APIVerb.kUpload:
    case APIVerb.kDownloadBase64:
      return observableFrom(new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const method: string = verb === APIVerb.kUpload ? 'POST' : 'GET';
        xhr.open(method, fullUrl, true);
        let responseObj: any;
        if (verb === APIVerb.kUpload) {
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              try {
                responseObj = xhr.response ? JSON.parse(xhr.response) : null;
              } catch (e) { }
              if (!responseObj) {
                responseObj = {rapi_code:0,status:0};
              }
              if (xhr.status === 200) {
                resolve(responseObj);
              } else {
                reject(responseObj);
              }
            }
          };
          xhr.upload.onprogress = typeof progressCB === 'function' ? progressCB : null;
        } else {
          xhr.responseType = 'blob';
          xhr.onload = () => {
            const reader: FileReader = new FileReader();
            reader.onloadend = () => {
              const result: string = reader.result.toString();
              const startIndex: number = result.indexOf('base64,');
              responseObj = result.substr(startIndex + 7);
              resolve(responseObj);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = (err) => {
            reject(err);
          };
          xhr.onprogress = typeof progressCB === 'function' ? progressCB : null;
        }
        const keys = Object.keys(this.sessionHeaders);
        for (const key of keys) {
          xhr.setRequestHeader(key, this.sessionHeaders[key]);
        }
        xhr.withCredentials = true;
        xhr.send(data);
      }));
    }
  }

  public get(urlOrDesc: string | BaseDesc, params?: string, queryargs?: string, options?: any): Observable<any> {
    return this.doVerb(APIVerb.kGet, urlOrDesc, '', params, queryargs, options, null);
  }

  public post(urlOrDesc: string | BaseDesc, data: any, params?: string, queryargs?: string, options?: any): Observable<any>  {
    let decodedUrlOrDesc = urlOrDesc;
    let fullQueryArgs = queryargs;
    if (urlOrDesc==='/connect') {
      this.connectInfo = data;
    } else if (typeof urlOrDesc !== 'string' && !!urlOrDesc.id && urlOrDesc.id.startsWith('evaluation')) {
      const parts: string[] = urlOrDesc.id.split('evaluation');
      if (parts.length>1) {
        decodedUrlOrDesc = this.deepCopy(urlOrDesc);
        (decodedUrlOrDesc as BaseDesc).id = 'evaluation';
        fullQueryArgs = (queryargs || '') + ('&ticks=' + parts[1]);
      }
    }
    return this.doVerb(APIVerb.kPost, decodedUrlOrDesc, data, params, fullQueryArgs, options, null);
  }

  public put(urlOrDesc: string | BaseDesc, data: any, params?: string, queryargs?: string, options?: any): Observable<any>  {
    return this.doVerb(APIVerb.kPut, urlOrDesc, data, params, queryargs, options, null);
  }

  public delete(urlOrDesc: string | BaseDesc, params?: string, queryargs?: string): Observable<any>  {
    return this.doVerb(APIVerb.kDel, urlOrDesc, '', params, queryargs, { observe: 'response' }, null);
  }

  public upload(urlOrDesc: string | BaseDesc, data: any, params?: string, queryargs?: string, progressCB: any=null): Observable<any>  {
    const options: any = { sendRaw: true, reportProgress: !!progressCB };
    return this.doVerb(APIVerb.kUpload, urlOrDesc, data, params, queryargs, options, progressCB);
  }

  public downloadAsBase64(urlOrDesc: string | BaseDesc, params?: string, queryargs?: string, progressCB?: any): Observable<any> {
    const options: any = { sendRaw: false, reportProgress: !! progressCB };
    queryargs = 'download' + (queryargs ? ('&'+queryargs) : '');
    return this.doVerb(APIVerb.kDownloadBase64, urlOrDesc, '', params, queryargs, options, progressCB);
  }
  public removeZeroSizeFiles(files: any) {
    const fileList = [];
    let emptyFileNames = '';
    let countEmptyFiles = 0;
    if (!!files) {
      for (const file of files) {
        if (file.size === 0) {
          countEmptyFiles = countEmptyFiles + 1;
          if (countEmptyFiles === 1) {
            emptyFileNames = file.name;
          } else {
            emptyFileNames = emptyFileNames + ',' + file.name;
          }
        } else {
          fileList.push(file);
        }
      }
    }
    if (countEmptyFiles !== 0) {
      this.notify.info(this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.UPLOADING'), this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.EMPTY_FILE', [emptyFileNames]));
    }
    return fileList;
  }

  public dismissPopup() {
    this.app.commandsComponent.dismissPopup(false);
  }

  public setDragDesc(dragDesc: any) {
    this.dragDesc = dragDesc;
  }

  public getDragDesc(): any {
    return this.dragDesc;
  }

  public addHistoryEntries(type: string, entries: any): void {
    let data: any;
    if (type !== 'documents' && type !== 'documentszip') {
      for (const entry of entries) {
        if (!entry['link'] && type !== 'url') {
          continue;
        }
        data = {
          REF_DOCUMENT: entry['id'],
          REF_LIB: entry['lib'],
          VERSION_LABEL: entry['verLabel'] || ' ',
          TYPE: this.kshareTypes[type]
        };
        const url = this.makeURL(entry, 'history');
        this.post(url, data).subscribe();
      }
    }
  }
  public getOutlookPreferences(header: string): any {
    const outlookPreferences = localStorage.getItem('$edx_outlook.'+ this.getUserID()) ;
    const outlookStoredval = !!outlookPreferences ? JSON.parse(outlookPreferences) : {};
    return  outlookStoredval[header];
  }

  public setOutlookPreferences(header: string, data: string): void {
    const outlookPreferences = localStorage.getItem('$edx_outlook.'+ this.getUserID()) ;
    const outlookStoredval = !!outlookPreferences ? JSON.parse(outlookPreferences) : {};
    outlookStoredval[header] = data;
    localStorage.setItem('$edx_outlook.'+ this.getUserID(), JSON.stringify(outlookStoredval));
  }

  public getDefaultAddressBook() :any {
    return kDefaultAddressBook;
  }

  public dropContainsFolders(event: DataTransfer): boolean {
    let isFilesExists = false;
    let isFoldersExists = false;
    const option: any = {
      'message' : {
        'folder_file_drop_restriction' : 'FORMS.LOCAL.UPLOAD.FOLDER_FILE_DROP_RESTRICTION',
        'folder_drop_restriction' : 'FORMS.LOCAL.UPLOAD.FOLDER_DROP_RESTRICTION'
      }
    };
    for (let i = 0; i < event?.items?.length; i++) {
      const item = event.items[i].webkitGetAsEntry();
      if (item?.isDirectory) {
        isFoldersExists = true;
      } else {
        isFilesExists = true;
      }
      if (this.device.bIsOfficeAddin) { // Block if upload items cobmination is files and folders or only folders in EIMO
        if (isFoldersExists || (isFoldersExists && isFilesExists)) {
          this.notify.warning(this.localizer.getTranslation(option['message']['folder_drop_restriction']));
          return true;
        }
      } else { // Block if upload items cobmination is files and folders in ic
        if (isFoldersExists && isFilesExists) {
          this.notify.warning(this.localizer.getTranslation(option['message']['folder_file_drop_restriction']));
          return true;
        }
      }
    }
    return false;
  }
}
