/**
 * Created by kevin on 2016-10-20.
 *
 * Implements a filter sidebar component as an accessory to a file/search list
 *
 */

import { Component, OnChanges, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseDesc } from '../models/base';
import { ListItem } from '../models/list-item';
import { Util } from '../utils/utils.module';
import { ShortcutsService, KeyCommand } from '../services/shortcuts.service';


@Component({
  selector: 'edx-filelist-sidebar',
  styleUrls: ['filelist-sidebar.component.scss'],
  template: `
    <div class="filelistcontainer" role="list" [ngClass]="{disabled:disabled}">
      <div *ngFor="let listitem of list; let i=index" role="listitem" [ngClass]="{ fileitem: true, selected: (selectIndex===i) }" [tabindex]="tabIndex" (click)="selectFile(i)" [id]="getElementId(i)" (keyup)="onKeyUp($event,i)">
        <img class="fileicon" src="{{icons[i]}}" alt="{{altTexts[i]}}">
        <img *ngIf="displayMiniStatus(listitem)" class="overlaystatusicon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
        <img *ngIf="displayTopMiniStatus(listitem)" class="overlaystatusicon top clickable" src="{{formatTopStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}" (click)="listItemClick(listitem, $event, 'APP_ID')">
        <div class="filename">{{listitem.DOCNAME}}</div>
        <div *ngIf="(selectIndex===i)" class="selectarrow"></div>
      </div>
    </div>
  `
})
export class FilelistSidebarComponent implements OnChanges, OnDestroy {
  @Input() receiver: FilelistReceiver;
  @Input() list: ListItem[] = [];
  @Input() selectIndex?: number = 0;
  @Input() rerender = 0;
  @Input() desc: BaseDesc;
  @Input() disabled?: boolean = false;
  @Input() tabIndex?: number = 0;
  private icons: string[] = [];
  private altTexts: string[] = [];
  private shortcutsSubscription: Subscription;

  constructor(private shortcutsService: ShortcutsService) {
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    switch (command.name) {
      case 'file-list-select-next':
        this.navigateToFile(this.selectIndex + 1);
        break;
      case 'file-list-select-prev':
        this.navigateToFile(this.selectIndex - 1);
        break;
    }
  }

  ngOnDestroy() {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
  }

  ngOnChanges() {
    this.getIcons();
  }

  private getIcons(): void {
    if (this.list) {
      this.icons = [];
      this.altTexts = [];
      for (const item of this.list) {
        this.icons.push(Util.Transforms.iconUrlFromDesc(item));
        this.altTexts.push(Util.Transforms.iconAltTextFromDesc(item));
      }
    }
  }

  private selectFile(index: number): void {
    if (!this.disabled) {
      this.selectIndex = index;
      this.receiver.fileSelected(index);
    }
  }

  private displayMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayMiniStatus(item, this.desc);
  }

  private displayTopMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayTopMiniStatus(item, this.desc);
  }

  private formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, this.desc);
  }

  private formatTopStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatTopStatusIcon(item, this.desc);
  }

  private formatStatusText(item: ListItem): string {
    return Util.RestAPI.formatStatusText(item);
  }

  public disable(disable: boolean): void {
    this.disabled = disable;
  }

  private getElementId(index: number): string {
    return 'edx_sidebar_item_' + index;
  }

  private onKeyUp(event: KeyboardEvent, index: number): void {
    let elementId: string = null;
    switch (event.key) {
      case 'ArrowDown':
        elementId = this.getElementId(++index);
        break;
      case 'ArrowUp':
        elementId = this.getElementId(--index);
        break;
      case 'ArrowRight':
        elementId = 'edx_header_menu';
        break;
      case 'ArrowLeft':
        elementId = 'edx_sidebar_item_' + this.selectIndex;
        break;
      case 'Enter':
      case ' ':
        this.selectFile(index);
        break;
    }
    if (!!elementId && !Util.Transforms.focusOnElementById(elementId)) {
      if (event.key === 'ArrowRight' && !Util.Transforms.focusOnElementByQuerySelector('#edx_action_bar .actionitem:not(.hidden)') && this.desc?.id === 'preferences') {
        this.receiver?.focusOnListTable();
      };
    }
  }

  private navigateToFile(index: number): void {
    const element: HTMLElement = document.getElementById(this.getElementId(index));
    if (!!element) {
      this.selectFile(index);
      element.focus();
    }
  }
}

export interface FilelistReceiver {
  fileSelected(index: number): void;
  focusOnListTable?(): void;
}
