/**
 * Created by kevin on 2016-10-07.
 *
 * Implements a pager component as an accessory to a list
 *
 */

import { Component, Input, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ListService } from '../services/list.service';
import { LocalizeService } from '../services/localize.service';
import { MenuId } from '../services/menu.service';
import { Util } from '../utils/utils.module';
import { CommandHandler } from '../models/command-handler';
import { KeyCommand, ShortcutsService } from '../services/shortcuts.service';

enum NavType {navNone=0, navPrev=1, navNext=2}
const NavDelayMSDefault = 512;

@Component({
  selector: 'edx-paginator',
  styleUrls: ['paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="paginator">
    <div class="info">
      <div class="count words">{{countStr}}</div>
      <div class="perpage words">{{perPageStr}}</div>
      <edx-menu [menuID]="menuID" [callback]="this" [ariaLabel]="altLabels['paginationMenu']" [forceMenuButton]="true" class="menu-button-paginator"></edx-menu>
    </div>
    <div #ribbonContEl *ngIf="pages.length>1">
      <div class="prev numbers" [ngClass]="{disabled: nextPrevDisabled}" [tabindex]="nextPrevDisabled?-1:0" role="button" [attr.aria-label]="altLabels['previousPage']" (click)="pageBack()" (keyup.enter)="pageBack()" (keyup.space)="pageBack()">
        <img *ngIf="!nextPrevDisabled" src="assets/images/caret_left16.svg">
      </div>
        <div class="ribbon">
          <div *ngFor="let page of pages;let i=index" tabindex="0" role="button" (keyup.enter)="nav(page)" class="page numbers" [ngClass]="{current:page===currentPage}" [id]="'edx_pagenumber_' +(page) " (keydown.tab)="onKeyDown($event,i) "(click)="nav(page)" [attr.aria-label]="altLabels['pageNumber']+' '+ page">{{page}}</div>
        </div>
        <div class="next numbers" [tabindex]="nextPrevDisabled?-1:0" role="button" (keyup.enter)="pageFoward()" (keyup.space)="pageFoward()" [ngClass]="{disabled: nextPrevDisabled}" (keydown.shift.tab)="onKeyDown($event)" (click)="pageFoward()" [attr.aria-label]="altLabels['nextPage']">
        <img *ngIf="!nextPrevDisabled" src="assets/images/caret_right16.svg">
      </div>
    </div>
  </div>
  `
})
export class PaginatorComponent implements CommandHandler {
  public pages: number[] = [];
  public countStr = '';
  public perPageStr = '';
  private currentPage = 1;
  private firstPage = 1;
  private navTimer: any = null;
  private curNav: NavType = NavType.navNone;
  private navDelayMS: number = NavDelayMSDefault;
  private useTouch: boolean = Util.Device.bIsTouchDevice;
  private nextPrevDisabled = true;
  public altLabels: any;
  private shortcutsSubscription: Subscription;
  @ViewChild('ribbonContEl') ribbonContEl: ElementRef;
  @Input() target: PaginatorTarget;
  @Input() menuID: MenuId;

  constructor(private listService: ListService, private localizer: LocalizeService, private changeRef: ChangeDetectorRef, private shortcutsService: ShortcutsService) {
    this.altLabels = {
      paginationMenu: this.localizer.getTranslation('ALT_TEXT.PAGINATION_MENU'),
      nextPage: this.localizer.getTranslation('PAGINATOR.PAGE_FORWARD'),
      previousPage: this.localizer.getTranslation('PAGINATOR.PAGE_BACK'),
      pageNumber: this.localizer.getTranslation('PAGINATOR.PAGE_NUMBER')
    };
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    let elementId: string;
    switch (command.name) {
      case 'list-firstpage':
        elementId = 'edx_row_selector_0';
        if (this.getCurrentPage() !== 1) {
          this.nav(1);
        }
        break;
      case 'list-lastpage':
        elementId = 'edx_row_selector_0';
        if (this.getCurrentPage() !== this.target.getPageCount()) {
          this.nav(this.target.getPageCount());
        }
        break;
      case 'list-nextpage':
        elementId = 'edx_row_selector_0';
        if (this.getCurrentPage() !== this.target.getPageCount()) {
          this.nav(this.getCurrentPage() + 1);
          this.focusOnSelectedPage();
        }
        break;
      case 'list-prevpage':
        elementId = 'edx_row_selector_0';
        if (this.getCurrentPage() !== 1) {
          this.nav(this.getCurrentPage() - 1);
          this.focusOnSelectedPage();
        }
        break;
    }
    setTimeout(() => {
      if (elementId) {
        (document.getElementById(elementId) as HTMLElement)?.focus();
      }
    }, 400);
  }

  private focusOnSelectedPage(): void {
    setTimeout(() => {
      Util.Transforms.focusOnElementByGivenClass('page numbers current');
    }, 0);
  }

  @HostListener('window:resize')
  private delayCheckLayout(): void {
    setTimeout(() => {
      const shouldDisableNexPrev: boolean = this.target.getPageCount() <= this.getNumberPagesInRibbon();
      if (shouldDisableNexPrev !== this.nextPrevDisabled) {
        this.nextPrevDisabled = shouldDisableNexPrev;
        this.changeRef.markForCheck();
      }
    }, 1);
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  public targetUpdated(start?: number): void {
    const nItems: number = this.target.getItemCount();
    const pageSize: number = this.target.getPageSize();

    this.setPageStr(pageSize);
    this.countStr = this.localizer.getTranslation('PAGINATOR.COUNT',[''+nItems]);
    this.setPages();
    if (start >= 0) {
      this.currentPage = start/pageSize + 1;
      setTimeout(() => {
        this.shiftPageIntoView(this.currentPage);
      }, 1);
    }
  }

  public reloading(): void {
    this.currentPage = 1;
  }

  private setPages(): void {
    const pages: number[] = [];
    const nPages: number = this.target.getPageCount();
    for (let i=this.firstPage; i<=nPages; i++) {
      pages.push(i);
    }
    this.pages = pages;
    this.changeRef.markForCheck();
    this.delayCheckLayout();
  }

  private setPageStr(pageSize: number): void {
    this.perPageStr = this.localizer.getTranslation('PAGINATOR.PER_PAGE',[''+pageSize]);
  }

  private getNumberPagesInRibbon(): number {
    let nPagesInRibbon = 1;
    const ribbonContainer = this.ribbonContEl ? this.ribbonContEl.nativeElement : null;
    if (!!ribbonContainer) {
      const kNextPrevRems = 6;
      const kPageNumberRems = 4;
      const ribbonContainerBounds = ribbonContainer.getBoundingClientRect();
      const ribbonContainerWidthRems = Util.pxToRems(ribbonContainerBounds.width);
      nPagesInRibbon = Math.ceil(ribbonContainerWidthRems/kPageNumberRems) - kNextPrevRems;
    }
    return nPagesInRibbon;
  }

  private shiftPageIntoView(page: number): void {
    let delta = 0;
    const nPagesInRibbon: number = this.getNumberPagesInRibbon();
    const lastVisPage: number = nPagesInRibbon + this.firstPage - 1;
    if (page < this.firstPage) {
      delta = page - this.firstPage;
    } else if (page > lastVisPage) {
      delta = page - lastVisPage;
    }
    if (delta) {
      this.firstPage += delta;
      this.setPages();
    }
  }

  private nav(page: number, bSendToTarget: boolean=true): void {
    this.currentPage = page;
    this.shiftPageIntoView(page);
    this.changeRef.markForCheck();
    if (bSendToTarget) {
      this.target.gotoPage(this.currentPage);
    }
  }

  private pageBack(): void {
    if (!this.nextPrevDisabled) {
      let page: number = this.currentPage - this.getNumberPagesInRibbon();
      if (page < 1) {
        page = 1;
      }
      this.nav(page);
    }
  }

  private pageFoward(): void {
    if (!this.nextPrevDisabled) {
      const maxPage: number = this.target.getPageCount();
      let page: number = this.currentPage + this.getNumberPagesInRibbon();
      if (page > maxPage) {
        page = maxPage;
      }
      this.nav(page);
    }
  }

  public onKeyDown(event: KeyboardEvent,index?: number): void {
    const key = event.key;
    const pageFowardButton = document.getElementsByClassName('next numbers')[0] as HTMLElement;
    const activeElement = document.activeElement;
    switch (key) {
      case 'Tab':
        if (event.shiftKey && activeElement === pageFowardButton) {
          const element = document.getElementById('edx_pagenumber_' + this.pages[this.getNumberPagesInRibbon() - 1]);
          element.focus();
          event.preventDefault();
        } else if (!!index && index + 1 === this.getNumberPagesInRibbon() && !!pageFowardButton) {
          pageFowardButton.focus();
          event.preventDefault();
        }
      break;
    }
  }

  doCommand(cmd: string): boolean {
    let pageSize = -1;
    switch (cmd) {
    case 'pageSize25':  pageSize = 25;  break;
    case 'pageSize50':  pageSize = 50;  break;
    case 'pageSize100': pageSize = 100; break;
    }
    if (pageSize!==-1) {
      if (this.target) {
        this.target.setPageSize(pageSize);
        this.targetUpdated();
      }
      this.currentPage = 1;
      this.shiftPageIntoView(this.currentPage);
      return true;
    }
    return false;
  }

  commandChecked(cmd: string): boolean {
    const pageSize: number = this.target ? this.target.getPageSize() : 25;
    switch (cmd) {
    case 'pageSize25':
      return pageSize===25;
    case 'pageSize50':
      return pageSize===50;
    case 'pageSize100':
      return pageSize===100;
    }
    return false;
  }
}

export interface PaginatorTarget {
  getItemCount(): number;
  getPageCount(): number;
  getPageSize(): number;
  gotoPage(pageNumber: number): void;
  setPageSize(pageSize: number): void;
}
