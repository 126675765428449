import { Component, Input, OnChanges, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { MenuService, MenuId, MenuDef } from '../services/menu.service';
import { MenuItem } from '../models/menu-item';
import { CommandHandler, MenuItemSetter } from '../models/command-handler';
import { LocalizeService } from '../services/localize.service';
import { Util, UserInterface } from '../utils/utils.module';

/**
 * Created by kevin on 2016-09-30.
 *
 * Menu component created based on either a passed-in MenuDef object OR a MenuId to pass to the menu service
 *
 */

@Component({
  selector: 'edx-menu',
  styleUrls: ['menu.component.scss'],
  templateUrl: 'menu.component.html'
})

export class MenuComponent implements OnChanges, MenuItemSetter {
  @Input() menuID: MenuId = null;
  @Input() callback: CommandHandler;
  @Input() menuTitle?: string;
  @Input() menuIcon?: string;
  @Input() forceMenuButton?: boolean;
  @Input() hideIfEmpty? : boolean;
  @Input() rerender?: number;
  @Input() tabIndex?: number = 0;
  @Input() menuId?: string = null;
  @Input() ariaLabel?: string = '';
  @Input() isCompare?: boolean = false;
  @Input() id?: string = null;
  @ViewChild('ul') ul: ElementRef;
  static nMenusShown = 0;
  public ui: UserInterface;
  private iOS: boolean;
  private safari: boolean;
  private officeAddin: boolean;
  private officeAddinDesktopMac: boolean;
  private menuDef: MenuDef = null;
  public isOpen = false;
  private isOpening = false;
  private isClosing = false;
  private allowChecks = false;
  private allowEditChecks = false;
  private drawToRight = true;
  private drawUp = false;
  private drawTopRight = false;
  private noSelect = false;
  private wide = false;
  private stopClosing: boolean;
  private buttonIcon: string = null;
  private buttonIconOpen: string = null;
  private buttonIconSize = 0;
  private nEnabledItems = 0;
  private lastEnabledItemIndex = 0;
  private items: MenuItem[] = [];
  private toolTip = '';
  private ulMaxHeight = '32rem';
  private isMenuShown = true;
  public altLabels: any;
  private isChrome: boolean;
  constructor(private menuService: MenuService, private localizer: LocalizeService, private cdr: ChangeDetectorRef) {
    this.ui = Util.Device.ui;
    this.iOS = Util.Device.bIsIOSDevice;
    this.safari = Util.Device.bIsSafari;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.isChrome = Util.Device.bIsChrome;
    this.officeAddinDesktopMac = this.officeAddin && Util.Device.bIsOfficeAddinDesktop && Util.Device.bIsMacintosh;
    this.altLabels = {
      collapsed: this.localizer.getTranslation('ALT_TEXT.COLLAPSED'),
      expanded: this.localizer.getTranslation('ALT_TEXT.EXPANDED')
    };
  }

  ngOnChanges(): void {
    this.menuDef = this.menuService.getMenu(this.menuID);
    this.buttonIconOpen = !!this.menuDef.buttonIcon ? ('assets/images/' + this.menuDef.buttonIcon) : null;
    this.buttonIcon = Util.Device.isWhiteIconLook() && !!this.menuDef.buttonIconLight ? this.menuDef.buttonIconLight : !!this.menuDef.buttonIcon ? this.menuDef.buttonIcon : null;
    if (this.buttonIcon) {
      this.buttonIcon ='assets/images/' + this.buttonIcon;
    }
    if (this.menuDef.buttonIconSize) {
      this.buttonIconSize = this.menuDef.buttonIconSize;
    }
    this.allowChecks = this.menuDef.allowChecks;
    this.allowEditChecks = this.menuDef.allowEditChecks ? this.menuDef.allowEditChecks : false;
    this.drawToRight = this.menuDef.drawToRight;
    this.drawUp = this.menuDef.drawUp;
    this.noSelect = this.menuDef.noSelect;
    this.wide = this.menuDef.wide ? this.menuDef.wide : false;
    this.toolTip = this.localizer.getTranslation(this.menuDef.toolTip);
    this.initMenuItems();
    this.checkIfMenuIsShown(true);
  }

  public setMenuItems(menuItems: MenuItem[]): void {
    this.items = menuItems;
    this.lastEnabledItemIndex = -1;
    if (!!this.items) {
      this.items.forEach(item => item.enabled ? ++this.lastEnabledItemIndex : this.lastEnabledItemIndex);
    }
    this.cdr.markForCheck();
  }

  public replaceMenuItems(menuItems: MenuItem[]): void {
    this.items = menuItems;
    this.enableMenuItems();
    this.cdr.markForCheck();
  }

  public prependMenuItems(menuItems: MenuItem[]): void {
    this.initMenuItems();
    this.items.splice(0,0,...menuItems);
    this.enableMenuItems();
    this.cdr.markForCheck();
  }

  public getMenuItems(): MenuItem[] {
    return this.items;
  }

  public setButtonIconSize(newSize: number): void {
    this.buttonIconSize = newSize;
    this.cdr.markForCheck();
  }

  public setButtonIcon(icon: string ): void {
    this.buttonIcon = 'assets/images/' + icon;
    this.cdr.markForCheck();
  }

  initMenuItems(): void {
    this.items = [];
    for (const itemDef of this.menuDef.items) {
      const item: MenuItem = new MenuItem(itemDef);
      item.name = this.localizer.getTranslation(item.name, item.nameargs);
      if (item.checkable && this.callback.commandChecked) {
        item.checked = this.callback.commandChecked(item.cmd);
      }
      this.items.push(item);
    }
  }

  enableMenuItems(): void {
    this.nEnabledItems = 0;
    for (const item of this.items) {
      if (item.cmd === 'menu_close') {
        ++this.nEnabledItems;
      } else if (this.callback.commandEnabled) {
        item.enabled = this.callback.commandEnabled(item.cmd);
        if (item.enabled) {
          ++this.nEnabledItems;
          this.lastEnabledItemIndex = this.items.indexOf(item);
        }
      } else {
        ++this.nEnabledItems;
      }
      if (item.checkable && this.callback.commandChecked) {
        if (item.enabled) {
          ++this.nEnabledItems;
          this.lastEnabledItemIndex = this.items.indexOf(item);
        }
        const checked = this.callback.commandChecked(item.cmd);
        if (checked !== undefined) {
          item.checked = checked;
        }
      }
    }
  }

  doCommand(itemIndex: number, event: Event): boolean {
    if (itemIndex >= 0 && itemIndex < this.items.length) {
      const item = this.items[itemIndex];
      if (this.callback && !this.noSelect || item.button) {
        this.callback.doCommand(item.cmd);
      } else if (this.noSelect && this.allowEditChecks) {
        this.toggleCheck(itemIndex, event);
      }
      if (item.cmd === 'menu_close' || (!this.noSelect || item.button)) {
        this.closeMenu(true);
      }
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    return false;
  }

  closeMenuOnFocusOut(itemIndex: number): boolean {
    if (itemIndex === this.lastEnabledItemIndex && !this.stopClosing) {
      this.closeMenu(false);
    } else if (itemIndex === -2) {
      this.closeMenu(false);
      return true;
    }
    return false;
  }

  toggleCheck(itemIndex: number, event: Event): boolean {
    const item = this.items[itemIndex];
    if (this.allowEditChecks) {
      if (itemIndex >= 0 && itemIndex <this.items.length) {
        this.items[itemIndex].checked = !item.checked;
        if (this.callback.checkCommand) {
          this.callback.checkCommand(item.cmd, item.checked);
        }
        if (item.cmd === 'select_all') {
          const checked = item.checked;
          this.items.forEach((menuitem: MenuItem) => {
            if (menuitem.checkable) {
               if (checked) {
                 menuitem.checked = true;
               } else if (!checked) {
                 menuitem.checked = false;
               }
            }
          });
        } else if (item.cmd !== 'select_all') {
          const selectAllIndex = this.items.findIndex((menuitem)=>menuitem.cmd==='select_all', item);
          const items = this.items.slice(selectAllIndex + 1);
          const allSelected = items.filter((i) => i.checked === false).length === 0;
          if (allSelected) {
            this.items[selectAllIndex].checked = true;
          } else if (allSelected && !item.checked) {
            this.items[selectAllIndex].checked = false;
          } else if (!allSelected && !item.checked) {
            this.items[selectAllIndex].checked = false;
          }
        }
      }
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
    return false;
  }

  public toggleMenuOpen(event): boolean {
    if (Util.Device.isTabletLook() || Util.Device.bIsOfficeAddin) {
      event = event.touches && event.touches.length ? event.touches[0] : event;
      this.drawTopRight = event.clientY < Util.Device.height/2;
    }
    if (this.isOpen) {
      this.closeMenu(false);
    } else {
      if (!this.drawUp) {
        const maxLargeScreenHeight = 32;
        this.ulMaxHeight = '' + maxLargeScreenHeight + 'rem';
        const delayCheckHeight = () => {
          setTimeout(() => {
            if (this.ul && this.ul.nativeElement) {
              const ulTop: number = this.ul.nativeElement.getBoundingClientRect().top;
              const pixForOneRem: number = Util.remsToPx(1);
              const minListHeight: number = Util.Device.bIsOfficeAddin ? 7 : 4;
              const minMaxHeight: number = Util.remsToPx(maxLargeScreenHeight + minListHeight);
              let availableHeight: number = Util.Device.height - ulTop;
              availableHeight = availableHeight < 144 ? 144 : availableHeight;
              if (!!this.menuID && this.menuID === 1) {
                this.drawUp = !this.isMenuInView(this.ul.nativeElement);
              }
              if (availableHeight < minMaxHeight) {
                const height: number = availableHeight - (pixForOneRem * minListHeight);
                this.ulMaxHeight = '' + (height / pixForOneRem) + 'rem';
                this.cdr.markForCheck();
              }
            } else {
              delayCheckHeight();
            }
          }, 1);
        };
        delayCheckHeight();
      }
      this.openMenu();
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    return false;
  }

  public isMenuInView(element: HTMLElement): boolean {
    const menuElem = element.getBoundingClientRect();
    return (menuElem.top >= 0 && menuElem.left >= 0 && menuElem.bottom <= (window.innerHeight || document.documentElement.clientHeight) && menuElem.right <= (window.innerWidth || document.documentElement.clientWidth));
}

  public openMenu(): void {
    if (this.callback.menuOpening) {
      this.callback.menuOpening(this, this.menuID);
    }
    this.enableMenuItems();
    if (this.nEnabledItems) {
      this.isOpen = true;
      this.isOpening = true;
      this.isClosing = false;
      if (!this.forceMenuButton) {
        Util.RestAPI.maskHeader(true);
      }
      MenuComponent.nMenusShown++;
    }
  }

  public closeMenu(immediate: boolean): void {
    if (this.callback.menuClosing) {
      this.callback.menuClosing(this, this.menuID);
    }
    if (immediate) {
      this.removeMenu();
    } else {
      this.isClosing = true;
    }
    if (!this.forceMenuButton) {
      Util.RestAPI.maskHeader(false);
    }
    MenuComponent.nMenusShown--;
    this.checkIfMenuIsShown(true);
    if (!!this.menuID && this.menuID === 1) {
      this.drawUp = false;
    }
  }

  public hideMenuIfEmpty(): void {
    this.checkIfMenuIsShown(true);
  }

  private checkIfMenuIsShown(reenableItems: boolean) {
    if (this.hideIfEmpty) {
      if (reenableItems) {
        this.enableMenuItems();
      }

      this.isMenuShown = this.items.filter(internalitem => !internalitem.button && !internalitem.heading && internalitem.enabled).length > 0;

      if (!this.isMenuShown) {
        this.cdr.markForCheck();
      }
    }
  }

  private removeMenu() {
    this.isOpen = false;
    this.isOpening = false;
    this.isClosing = false;
  }

  private overlayClick(event: Event): boolean {
    event.stopPropagation();
    event.preventDefault();
    this.closeMenu(false);
    return false;
  }

  private transitionComplete(): void {
    if (this.isClosing) {
      this.removeMenu();
    }
    this.isOpening = false;
    this.isClosing = false;
  }

  private getItemIcon(item: MenuItem): string {
    if (!!item.icon && !item.icon.startsWith('http') && !item.icon.startsWith('assets/images/')) {
      item.icon = 'assets/images/' + item.icon;
    }
    return item.icon;
  }

  private getMenuId(item: MenuItem): string {
    return 'edx_menu_' + item.cmd;
  }

  public getNextIndex(currentIndex: number, shift: number): number {
    currentIndex += shift;
    while (currentIndex <= this.lastEnabledItemIndex && currentIndex >= 0) {
      if (!!this.items[currentIndex] && this.items[currentIndex].enabled && !this.items[currentIndex].separator) {
        return currentIndex;
      }
      currentIndex += shift;
    }
    return -1;
  }

  private focusOnMenuItem(index: number) {
    if (!!this.items[index]) {
      const id = this.getMenuId(this.items[index]);
      const menuElement = document.getElementById(id);
      if (!!menuElement) {
        menuElement.focus();
      }
    }
  }

  protected onKeyUp(event: KeyboardEvent, currentIndex: number): void {
    let nextIndex;
    this.stopClosing = false;
    switch (event.key) {
      case 'Escape':
        if (this.isOpen) {
          this.toggleMenuOpen(event);
        }
        break;
      case 'ArrowLeft':
        if (!!this.menuId && this.menuId === 'edx_header_menu') {
          const selectedFileItem = document.getElementsByClassName('fileitem selected')[0] as HTMLElement;
          if (!!selectedFileItem) {
            selectedFileItem.focus();
          }
        }
        break;
      case 'ArrowDown':
        nextIndex = this.getNextIndex(currentIndex, 1);
        break;
      case 'ArrowUp':
        nextIndex = this.getNextIndex(currentIndex, -1);
        this.stopClosing = true;
        break;
      case 'Tab':
        if (event.shiftKey) {
          this.stopClosing = true;
        }
    }
    if (nextIndex !== -1) {
      this.focusOnMenuItem(nextIndex);
    }
  }
}
