
<div [ngClass]="{ mobile: ui>=2, phone: ui==2||ui==4, ios: iOS, android: android, firefox: firefox, safari: safari, edge: edge, ie: ie, oai:officeAddin, oaid: officeAddinDesktop,
                  mainfield: !field.isExtension, extensionfield: field.isExtension, readonly: formReadonly, heading: field.fldtype === 'text' || field.fldtype === 'box',
                  listfield: field.fldtype === 'list', indialog: !!inDialog, innotify: !!inNotify, columnview: formLayout === 'column', header: formLayout === 'header', first:first }"
     [ngSwitch]="field.controltype" [formGroup]="form">

  <label *ngIf="hasLabel()" attr.for="{{field.name}}" [ngClass]="{shortcuts: parent.formKind == 'shortcuts', errorkey: parent.formKind == 'shortcuts' && !!field.errormessage }">
    <span class="required" [attr.aria-required]="field.isRequired" [attr.aria-hidden]="true" *ngIf="field.isRequired && !viewReadonly">*</span>
    <ng-template [ngIf]="field.fldtype !== 'push'">
      <br *ngIf="field.label===' '"/>
      <span *ngIf="field.label!==' '" [title] = "stripLabel(field.label)">{{stripLabel(field.label)}}</span>
   </ng-template>
    <span *ngIf="field.fldtype === 'push'" >{{stripLabel(field.checkLabel)}}</span>
  </label>

  <ng-template [ngSwitchCase]="'edittext'">
    <ng-template [ngIf]="!viewReadonly">
      <input #editInput [edx-validate-onblur]="wantsValidateOnBlur()" [placeholder]="placeholders('TEXT', field.isExtension)" [formControlName]="field.name" [id]="field.name"
          [type]="inputType()" [maxlength]="maxLen()" [readOnly]="field.name === 'DOCNAME' && parent.formKind ==='profile_uploadfolders' " [attr.aria-required]="field.isRequired" [required]="field.isRequired ? true : null" autocomplete="off" aria-autocomplete="none" [title]="getDescriptionForTooltip()"
          class="edx_editable_input" [style.height] ="height" [ngClass]="{haslookup:  hasLookup(), copy: parent.formKind == 'profile_copy', savesearch: field.name=='save_search_as' || field.name=='update_saved_search' }"
          (focus)="editTextFocus($event)" (blur)="editTextBlur($event)" (keyup)="editTextKeyUp($event)" (keydown)="editTextKeyDown($event)" (paste)="editTextPaste($event)" [tabindex]="tabIndex">
      <ul *ngIf="(inFocus || usingLookupData) && suggestions.length>0" #dropList class="dropdown-list" [style.top]="ulTop" [style.left]="ulLeft">
        <li *ngFor="let item of suggestions" class="list-item" [ngClass]="{selected: item===currentItem, separator: item.id==='-'}" (mousedown)="itemSelected(item, $event)">
          <ng-template [ngIf]="item.id !== '-'">
            <div title="{{this.localizer.getTranslation('TOOLTIP.REMOVE_ITEM',[item.desc])}}" *ngIf="!usingLookupData" class="dropdown-delete" (mousedown)="deleteSuggestion(item, $event)"></div>
            <div title="{{item.desc}}">{{item.desc}}</div>
            <div class="iddiv" title="{{item.desc}}"> {{item.id}}</div>
          </ng-template>
        </li>
      </ul>
    </ng-template>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [hasExtension]="extensionShown" [fullWidth]="!hasLabel()" [toolTip]="getDescriptionForTooltip()" [rerender]="rerender" [formKind]="parent.formKind"></edx-read-only-text-field>
  </ng-template>
  <ng-template [ngSwitchCase]="'textarea'">
    <textarea [title] = "stripLabel(field.label)" *ngIf="!viewReadonly" placeholder="{{placeholders('TEXTAREA', field.isExtension)}}" [formControlName]="field.name" [id]="field.name"
        rows="{{field.rows}}" [readonly]="viewReadonly ? true : null" spellcheck="false"
        [maxlength]="maxLen()" [attr.aria-required]="field.isRequired" [required]="field.isRequired ? true : null" [tabindex]="tabIndex">
    </textarea>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [toolTip]="getDescriptionForTooltip()"></edx-read-only-text-field>
  </ng-template>

  <ng-template [ngSwitchCase]="'editnumber'">
    <edx-numeric-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [placeholder]="placeholders('NUMBER', field.isExtension)" [rerender]="rerender" [columnView]="formLayout === 'column'" [toolTip]="getDescriptionForTooltip()"></edx-numeric-field>
    <edx-numeric-field *ngIf="!viewReadonly" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [placeholder]="placeholders('NUMBER', field.isExtension)" [rerender]="rerender" [columnView]="formLayout === 'column'" [toolTip]="getDescriptionForTooltip()" [tabIndex]="tabIndex">
      <div lookupButton *ngIf="hasLookup()" [tabindex]="tabIndex" (click)="doLookup($event)" (keyup.enter)="doLookup($event)" (keyup.space)="doLookup($event)" role="button" class="lookupbutton" title="{{this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.name])}}" id="{{'edx_lkp_btn_'+field.name}}"></div>
    </edx-numeric-field>
  </ng-template>

  <ng-template [ngSwitchCase]="'editdate'">
    <div [title]="field.label" [formGroup]="form" *ngIf="!viewReadonly" class="controlcontainer datecontrol">
      <span class="datepicker-toggle" *ngIf="hasDatePicker()">
        <span class="datepicker-toggle-button" 
              [attr.aria-label]="stripLabel(field.label)+' '+datepickerStr">
        </span>
        <input 
          type="date" 
          class="datepicker-input"
          #datePicker
          id="{{'dateField_' + field.name}}"
          [attr.aria-label]="stripLabel(field.label)+' '+datepickerStr"
          (change)="setDateFormat($event,field.name)"
          [tabindex]="tabIndex"
          [min]="field.minDate"
          [max]="field.maxDate">
      </span>
      <input placeholder="{{placeholders('DATE', field.isExtension)}}" [formControlName]="field.name"
          [id]="field.name" [attr.aria-label]="stripLabel(field.label)" [ngClass]="{nodatepicker:!hasDatePicker(),datefields:ui!=0 || !(safari || officeAddinDesktop)}" (blur)="editTextBlur($event)" (change)="dateInputChanged($event)" [attr.aria-required]="field.isRequired" [required]="field.isRequired ? true : null" [tabindex]="tabIndex">
      <div *ngIf="!viewReadonly && hasLookup()" [tabindex]="tabIndex" (click)="doLookup($event)" (keyup.enter)="doLookup($event)" (keyup.space)="doLookup($event)" role="button" class="lookupbutton datelookupbutton" [ngClass]="{ readonly: viewReadonly, nodatepicker:!hasDatePicker()}" title="{{this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.label])}}" [attr.aria-label]="getAriaLabel(field)" id="{{'edx_lkp_btn_'+field.name}}"></div>      
    </div>    
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [hasExtension]="extensionShown" [fullWidth]="!hasLabel()" [toolTip]="getDescriptionForTooltip()"></edx-read-only-text-field>
    <div class="errormessage dateerror">{{field.errormessage}}</div>
  </ng-template>

  <edx-checkbox-field *ngSwitchCase="'checkbox'" [ariaLabel]="field.label !== ' ' && !!stripLabel(field.label) ? stripLabel(field.label):field.checkLabel" [itemIndex]="itemIndex" [checkBoxId]="getCheckboxId(itemIndex)" [field]="field" [form]="form" [formReadonly]="formReadonly" [tabIndex]="tabIndex" [parent]="this" [inDialog]="inDialog" [columnView]="formLayout === 'column'" [rerender]="rerender"></edx-checkbox-field>

  <edx-select-field *ngSwitchCase="'combo'" [field]="field" [form]="form" [parent]="this" [formControlName]="field.name" [items]="field.selectionMap" [rerender]="rerender" [tabIndex]="tabIndex"
        [columnView]="formLayout === 'column'" [initialPlaceHolder]="placeholders('SELECT', field.isExtension)" [formReadonly]="formReadonly" [inDialog]="inDialog" [inHeader]="formLayout==='header'" [inNotify]="inNotify" [fullWidth]="single && !extensionShown" [ariaLabel]="stripLabel(field.label)">
  </edx-select-field>

  <span *ngSwitchCase="'push'">
    <button *ngIf="!formReadonly && !bgImage" class="secondary" [title]="field.label" [ngClass]="{mobile:ui>=2, oai:officeAddin, header:formLayout==='header'}" [name]="field.name" [tabindex]="tabIndex" (click)="buttonClicked($event)" (keyup.enter)="buttonClicked($event)" (keyup.space)="buttonClicked($event)" id="{{field.name}}">{{field.label}}</button>
    <div *ngIf="!formReadonly && !!bgImage" class="buttoniconic" role="button" [title]="field.label" [tabindex]="tabIndex" (click)="buttonClicked($event)" (keyup.enter)="buttonClicked($event)" (keyup.space)="buttonClicked($event)" id="{{field.name}}" [style.backgroundImage]="bgImage" (mouseover)="imgButtonHover()" (mousedown)="imgButtonDown()" (mouseleave)="imgButtonDefault()" (focus)="imgButtonHover()" (focusout)="imgButtonDefault()"></div>
  </span>

  <ng-template [ngSwitchCase]="'radiogroup'">
    <div *ngIf="field.isCheckboxGroup&&!viewReadonly" class="radio-group">
      <div *ngFor="let button of field.buttonMap;let i=index" >
        <input type="text" value="{{button.value}}" tabindex="-1" [formControlName]="field.name" class="checkbox" [ngClass]="{checked:isCheckboxChecked(button.value)}" [readonly]="isCheckboxReadonly(button.value)" (click)="checkboxChanged(button.value,$event)">
        <span [title]="button.display" role="checkbox" [attr.aria-checked]="isCheckboxChecked(button.value)" class="checklabel" [tabindex]="isCheckboxReadonly(button.value)?-1:tabIndex" id="{{getRadioCheckboxId(field.name,i)}}" (keydown.space)="checkboxChanged(button.value,$event)" (click)="checkboxChanged(button.value,$event)" (keyup.arrowdown)="focusOnRadioCheckbox(field.name,i+1)" (keyup.arrowup)="focusOnRadioCheckbox(field.name,i-1)">{{button.display}}</span>
      </div>
    </div>
    <edx-radio-group *ngIf="!field.isCheckboxGroup&&!viewReadonly" [field]="field" [form]="form" [parent]="this" [formReadonly]="formReadonly" [inDialog]="inDialog" [inNotify]="inNotify" [columnView]="formLayout === 'column'" [hasLabel]="hasLabel()" [tabIndex]="tabIndex"></edx-radio-group>
    <edx-read-only-text-field *ngIf="viewReadonly" [formControlName]="field.name" [field]="field" [form]="form" [inDialog]="inDialog" [columnView]="formLayout==='column'" [hasExtension]="extensionShown" [fullWidth]="!hasLabel()" [toolTip]="getDescriptionForTooltip()"></edx-read-only-text-field>
  </ng-template>

  <ng-template [ngSwitchCase]="'checkboxgroup'">
    <div class="checkbox-group">
      <div class="checkbox-label">
      <span [title]="field.checkLabel">{{field.checkLabel}}</span>
      </div>
      <div class="checkbox-list">
        <div *ngFor="let subField of field.fields;let i=index" class="checkbox-item">
          <edx-checkbox-field [ariaLabel]="subField.label !== ' ' && !!stripLabel(subField.label) ? stripLabel(subField.label):getAriaLabelForCheckboxInGroup(subField)" [itemIndex]="itemIndex" [checkBoxId]="getCheckboxId(itemIndex, i)" [field]="subField" [form]="form" [formReadonly]="formReadonly" [tabIndex]="tabIndex" [parent]="this" [inDialog]="inDialog" [columnView]="formLayout === 'column'" [rerender]="rerender"></edx-checkbox-field>
        </div>
      </div>
    </div>
  </ng-template>

  <edx-link-field *ngSwitchCase="'link'" [formControlName]="field.name" [field]="field" [form]="form" [tabIndex]="tabIndex"></edx-link-field>

  <edx-group-box-field *ngSwitchCase="'box'" [field]="field" [form]="form" [inDialog]="inDialog" [formReadonly]="formReadonly" [parent]="parent" [formLayout]="formLayout" [rerender]="rerender" [showExtras]="showExtras" [tabIndex]="tabIndex"></edx-group-box-field>

  <div *ngSwitchCase="'text'" class="divider" [ngClass]="{errorkey: parent.formKind == 'shortcuts' && !!field.errormessage}" [tabindex]="tabIndex" role="region" [attr.aria-labelledby]="field.defaultValue">{{field.defaultValue}}</div>

  <div *ngSwitchCase="'guidetext'" class="guide" [tabindex]="!!field.defaultValue?tabIndex:-1">{{field.defaultValue}}</div>

  <edx-form-field-list *ngSwitchCase="'list'" [field]="field" [parent]="parent" [inDialog]="inDialog" [showExtras]="showExtras" [columnView]="formLayout==='column'" [rerender]="rerender"></edx-form-field-list>

  <edx-progress-field *ngSwitchCase="'progress'" [field]="field" [form]="form"></edx-progress-field>

  <edx-chooser-field *ngSwitchCase="'chooser'" [ariaLabel]="stripLabel(field.label)" [formControlName]="field.name" [field]="field" [form]="form" [parent]="this" [columnView]="formLayout==='column'" [tabIndex]="tabIndex"></edx-chooser-field>
   
  <div *ngIf="!viewReadonly && hasLookup() && field.controltype !== 'editdate' && field.controltype !== 'editnumber'" [tabindex]="tabIndex" (click)="doLookup($event)" (keyup.enter)="doLookup($event)" (keyup.space)="doLookup($event)" role="button" class="lookupbutton" [ngClass]="{ readonly: viewReadonly }" title="{{stripLabel(this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.label]))}}" [attr.aria-label]="getAriaLabel(field)" id="{{'edx_lkp_btn_'+field.name}}"></div>

  <edx-form-wrapper *ngIf="parent && parent.showInlineForm(field)" [desc]="null" [kind]="parent.inlineForm(field)" [inNotify]="true" [selections]="null" [formData]="null" [inlineParent]="parent" [tabIndex]="tabIndex"></edx-form-wrapper>

  <div class="errormessage" [ngClass]="{readonlyerror: parent.formKind == 'shortcuts' && !!field.errormessage}" *ngIf="canShowErrorMessage()">{{field.errormessage}}</div>
  
  <div class="statusmessage" *ngIf="!!field.statusMessage && !viewReadonly && field.controltype !== 'editnumber' && field.controltype !== 'editdate'">{{field.statusMessage}}</div>  
</div>
